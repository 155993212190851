import React from 'react';
import modules from 'build/modules';

class TextServices {

  private _texts: {
    [key: string]: any;
  };

  public language: string;

  constructor() {
    const language = navigator.language.split('-')[0]
    const themeText = this.findJson()
    this.language = 'es'
    if (themeText[language]) {
      this._texts = themeText[language];
      this.language = language;
    } else if ((themeText as any)?.es) {
      this._texts = (themeText as any)?.es;
    } else {
      this._texts = themeText;
    }
  }
    
  findJson() {
    const textRaw = modules?.lang
    if (!textRaw) throw new Error('Texts not found');
    this._texts = textRaw;
    return textRaw;
  }

  findByText(path: string, defaultValue?: string | JSX.Element | { es?: string | JSX.Element, en?: string | JSX.Element }, replace?: { [key: string]: string }) {
    const pathSeparated = path.split('/');
    const text = pathSeparated.reduce((acc: any, item: string) => {
      if (!acc || !acc[item]) return null;
      return acc[item];
    }, this._texts);
  
    if (!text) {
      if (this.language && typeof defaultValue === 'object') {
        if (Object.keys(defaultValue ?? {}).includes(this.language)) {
          return defaultValue[this.language]
        } else if (Object.keys(defaultValue ?? {}).includes('es')) {
          return (defaultValue as any)?.es
        }
      }

      return defaultValue ?? path;
    }
  
    let replacedText = text;
  
    if (replace && text !== ' ') {
      Object.keys(replace).forEach((key) => {
        const value = replace[key];

        function mapChildren(children: any) {
          return React.Children.map(children, (child: any) => {
            if (typeof child === 'string') {
              return child.replace(new RegExp(key, 'g'), value);
            } else if (React.isValidElement(child)) {
              return React.cloneElement(child, {}, mapChildren((child.props as any)?.children));
            } else {
              return child;
            }
          });
        }
        
        // Reemplazar en el texto
        if (typeof text === 'string') {
          replacedText = replacedText.replace(new RegExp(key, 'g'), value);
        }
  
        // Reemplazar en el JSX.Element
        if (React.isValidElement(replacedText)) {
          const children = mapChildren(replacedText)
  
          replacedText = React.cloneElement(replacedText, {}, children);
        }
      });
    }
  
    return replacedText;
  }

}

const textServices = new TextServices();

export default textServices