import styled from 'styled-components'
import { ButtonType, isActiveType, Type } from 'theme/types'

export const ButtonNavStyle = styled.div.attrs((props: ButtonType) => ({
  disabled: props.disabled,
  shouldUseWhiteBg: props.shouldUseWhiteBg,
  theme: props.theme,
  ...props
})) <ButtonType>`
  color: ${(props: ButtonType) => props.disabled ? !props.shouldUseWhiteBg ? props.theme?.home?.buttonDisableContrast : props.theme?.home?.buttonDisable : props.shouldUseWhiteBg ? props.theme?.home?.buttonColorContrast : props.theme?.home?.buttonColor};
  border-color: ${(props: ButtonType) => props.disabled && !props.shouldUseWhiteBg ? props.theme?.home?.buttonDisabledBorderContrast : props.theme?.home?.buttonDisabledBorder};
  background-color: ${(props: ButtonType) => props.disabled ? props.theme?.home?.buttonDisabledBackground : props.shouldUseWhiteBg ? props.theme?.home?.buttonBackgroundContrast : props.theme?.home?.buttonBackground};
  border-radius:${(props: ButtonType) => props.theme?.home?.buttonBorderRadius};
`

export const ButtonNavStyleFull = styled.div.attrs((props: ButtonType) => ({
  disabled: props.disabled,
  shouldUseWhiteBg: props.shouldUseWhiteBg,
  theme: props.theme,
  ...props
})) <ButtonType>`
  color: ${(props: ButtonType) => props.disabled ? !props.shouldUseWhiteBg ? props.theme?.home?.buttonDisableContrast : props.theme?.home?.buttonDisable : props.shouldUseWhiteBg ? props.theme?.home?.buttonColorContrast : props.theme?.home?.buttonColor};
  border-color: ${(props: ButtonType) => props.disabled && !props.shouldUseWhiteBg ? props.theme?.home?.buttonDisabledBorderContrast : props.theme?.home?.buttonDisabledBorder};
  background-color: transparent;
  border-radius:${(props: ButtonType) => props.theme?.home?.buttonBorderRadius};
`

export const DivLineStep = styled.div.attrs((props: isActiveType) => ({
  theme: props.theme,
  isActive: props.isActive
})) <isActiveType> `
  background-color: ${(props: isActiveType) => props.isActive ? props.theme?.home?.BgLineStepActive : props.theme?.home?.BgLineStep};
`

export const HrLineStep = styled.hr.attrs((props: isActiveType) => ({
  theme: props.theme,
  isActive: props.isActive
})) <isActiveType> `
  background-color: ${(props: isActiveType) => props.isActive ? props.theme?.home?.BgLineStepActive : props.theme?.home?.BgLineStep};
`

export const DivProgress = styled.div`
  ${({ theme }: Type) => theme?.home?.progress.base}
`

export const DivProgressSecond = styled.div`
  ${({ theme }: Type) => theme?.home?.progress.active}
`
