const Prizes = () => {
  const variant = [
    'miller',
    'silverWaiter'
  ]

  if (variant.includes(THEME)) {
    return `${AZURE_ICONS_URL}/${THEME}/prizes.svg`
  }

  return `${AZURE_ICONS_URL}/prizes.svg`
}

export default Prizes
