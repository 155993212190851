import { useSession } from 'contexts/session-context'
import useMainClass from 'hooks/use-main-cass'
import { TextProfileSecondary } from 'views/profile/text.style'

function Address() {
  const [{ user }] = useSession()
  useMainClass('profile__address')

  return (
    <div className="font-bold text-2xl px-5 py-6 flex-1 flex break-all">
      <div className="flex-1">
        {((user?.address && user?.address.street1) ? (
          <div>
            <TextProfileSecondary className="font-normal dinPro text-lg normal-case profile__response">
              {user?.address?.street1}, {user?.address?.streetNumber},
              {user?.address?.shippingDetails && <span> {user?.address?.shippingDetails},</span>}
            </TextProfileSecondary>
            {user?.address?.street2 && (
              <TextProfileSecondary className="font-normal dinPro text-lg normal-case profile__response">
                {user?.address?.street2}
              </TextProfileSecondary>
            )}
            <TextProfileSecondary className="font-normal dinPro text-lg normal-case profile__response">
              {user?.address?.state},
            </TextProfileSecondary>
            <TextProfileSecondary className="font-normal dinPro text-lg normal-case profile__response">
              {user?.address?.city},
            </TextProfileSecondary>
            <TextProfileSecondary className="font-normal dinPro text-lg normal-case profile__response">
              {user?.address?.neighborhood},
            </TextProfileSecondary>
            <TextProfileSecondary className="font-normal dinPro text-lg normal-case profile__response">
              CP {user?.address?.postalCode}
            </TextProfileSecondary>
          </div>
        ) : (
          <TextProfileSecondary className="font-normal dinPro text-lg normal-case profile__response">
            SIN INFORMACION
          </TextProfileSecondary>
        ))}
      </div>
      <div className="flex justify-start items-start"></div>
    </div>
  )
}

export default Address
