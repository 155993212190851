import styled from "styled-components";
import { SubTitle, Text, Title } from 'theme/theme';
import { Type } from "theme/types";

export const DivTitleUpload = styled.div`
  ${({theme}: Type) => theme?.photographer?.upload?.title?.div}
`

export const TitleUpload = styled(Title)`
  ${({theme}: Type) => theme?.photographer?.upload?.title?.h1}
`

export const ButtonUpload = styled.label`
  ${({theme}: Type) => theme?.photographer?.upload?.button?.container}
`

export const ButtonTitleUpload = styled(SubTitle)`
  ${({theme}: Type) => theme?.photographer?.upload?.button?.title}
`

export const DescriptionContainerUpload = styled.div`
  ${({theme}: Type) => theme?.photographer?.upload?.description?.container}
`

export const DescriptionTitleUpload = styled(SubTitle)`
  ${({ theme }: Type) => theme?.photographer?.upload?.description?.title}
`

export const DescriptionTextUpload = styled(Text)`
  display: contents;
  ${({ theme }: Type) => theme?.photographer?.upload?.description?.text}
`

export const ContainerImageUpload = styled.div`
  ${({theme}: Type) => theme?.photographer?.upload?.image?.container}
`

export const InputLabelUpload = styled.label`
  ${({theme}: Type) => theme?.photographer?.upload?.image?.label}
`

export const TitleDoneUpload = styled(Title)`
  ${({theme}: Type) => theme?.photographer?.upload?.done?.title}
`

export const DescriptionDoneUpload = styled(Text)`
  ${({ theme }: Type) => theme?.photographer?.upload?.done?.description}
`