import Routes from 'routes'
import Header from 'components/header'
import PopUpHealth from 'views/agegate/components/popUpHealth'
import { useLocation } from 'react-router-dom';
import { SessionProvider } from 'contexts/session-context'
import { ClientProvider } from 'contexts/client-num-context'
import { LoaderContext } from 'contexts/loader-context'
import TriviaProvider from 'contexts/trivia-context'
import { SocialProvider } from 'contexts/social-context'
import { SurveyProvider } from 'contexts/survey-context/survey.provider'
import { useAgegateConfig } from 'hooks/use-agegate'
import { useContext } from 'react'
import LoadingScreen from 'components/loading-screen'
import { MainProvider } from 'contexts/main-class-context'
import Footer from 'components/footer'
import FacebookSDK from 'components/facebookSDK';

import { GeneralProvider } from 'contexts/general-context';
import ComingSoon from './components/coming-soon'

function App() {
  const { load, head, footer } = useContext(LoaderContext)

  useAgegateConfig()
  const location = useLocation();

  return (
    <SessionProvider>
      <GeneralProvider>
        <ClientProvider>
          <TriviaProvider>
            <SocialProvider>
              <SurveyProvider>
                <MainProvider>
                  <ComingSoon>
                    {FC_CLIENTID && <FacebookSDK />}
                    {load && <LoadingScreen />}
                    {head && <Header />}
                    <div
                      className={`w-full flex-1 flex justify-center sm:max-w-sm z-1 app-container`}
                    >
                      <PopUpHealth />
                      <Routes />
                    </div>
                    {footer && location.pathname !== '/help' && <Footer />}
                  </ComingSoon>
                </MainProvider>
              </SurveyProvider>
            </SocialProvider>
          </TriviaProvider>
        </ClientProvider>
      </GeneralProvider>
    </SessionProvider>
  )
}

export default App
