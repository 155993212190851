import useRouter from 'hooks/use-router';
import React, { useState } from 'react'

const Accordion = ({ level = {}, userPoints }: any) => {
  const [active, setActive] = useState(false)
  const { name, endAt, initAt, description } = level;
  const { push } = useRouter()

  const handleClick = () => {
    push('/prizes')
  }

  return userPoints >= level.id ? (
    <div className={`my-4`} style={{ width: '90vw' }}>
      <div
        className={`${!active ? 'bg-blue-900 text-white' : 'bg-white text-blue-900'} border-2 border-blue-900 flex justify-between items-center p-5`}
        onClick={() => {
          if (userPoints >= level.id) {
            setActive(!active)
          }
        }}
      >
        <div className="">
          <h3 className="uppercase text-xl font-bold">{name}</h3>
          <p className="uppercase text-lg">
            {initAt} a {endAt} {POINTS_NAME}
          </p>
        </div>
        <div>
          {active ? (
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 15l7-7 7 7"></path>
            </svg>
          ) : (
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
            </svg>
          )}
        </div>
      </div>
      {active && (
        <div className=" border-b-2 border-r-2 border-l-2 border-blue-900 flex flex-col justify-center items-center">
          <p className="p-5">{description}</p>
          <button
            style={{ width: '70vw' }}
            className="uppercase px-10 py-2 my-4 border-2 border-blue-900 rounded-2xl font-bold mx-auto"
            onClick={handleClick}
          >
            Ver premios
          </button>
        </div>
      )}
    </div>
  ) : (
    <div className={`my-4 text-gray-300`} style={{ width: '90vw' }}>
      <div className={`border-2 border-gray-300 flex justify-between items-center p-5`}>
        <div className="">
          <h3 className="uppercase text-xl font-bold">{name}</h3>
          <p className="uppercase text-lg">
            {initAt} a {endAt} {POINTS_NAME}
          </p>
        </div>
        <div>
          <svg className="w-6 h-6 text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
          </svg>
        </div>
      </div>
    </div>
  )
}

export default Accordion