import Button from "components/button";
import useMainClass from "hooks/use-main-cass";
import { Link } from "react-router-dom";
import textServices from "services/text-services";

const WifiSuccess = () => {
  useMainClass('wifi')

  return (
    <div
      className={`flex-1 w-full flex flex-col justify-center gap-4 pt-4 px-4 pb-9 items-center wifi__success`}
    >
      <div className="w-full px-[16px] flex flex-col gap-4 flex-1 justify-between">
        <div
          className="w-full flex items-center justify-center"
        >
          <img src={`${AZURE_BASE_URL}/wifi-success.webp`} alt="success" />
        </div>

        <Link
          className="w-full"
          to="/profile"
        >
          <Button>{textServices.findByText('wifi/buttonId', { es: 'Quiero mi ID', en: 'I want my ID' })}</Button>
        </Link>
      </div>
    </div>
  );
};

export default WifiSuccess;
