import textServices from 'services/text-services'
import { LegalAgeImg } from 'theme/legalage'
import { TitleAge } from './style/text'
function ImgAndText() {
  return (
    <section className="flex-1 flex flex-col items-center justify-center bg-cover md:bg-contain bg-no-repeat bg-center gap-3 w-full mb-3">
      <TitleAge className="text-center text-4xl font-bold px-7">
        {textServices.findByText('legalage/title', '')}
      </TitleAge>
      <LegalAgeImg src={`${AZURE_BASE_URL}/agegate.${textServices.findByText('legalage/imageType', 'svg')}`} alt="agegate" className="w-full" />
    </section>
  )
}

export default ImgAndText