import { prettyError, prettyErrorDescription } from 'constants/errors'
import GenericError from 'components/generic-error'
import useRouter from 'hooks/use-router'
import { useEffect } from 'react'
import useMainClass from 'hooks/use-main-cass'

const ErrorView = () => {
  const { push, match } = useRouter()
  const errorId = parseInt(match.params.errorid, 10) || 9999
  useMainClass('error')
  useEffect(() => {
    const event = new CustomEvent('background-color', {
      detail: {
        shouldUseWhiteBg: true
      }
    })

    window.dispatchEvent(event)

    return () => {
      const event = new CustomEvent('background-color', {
        detail: {
          shouldUseWhiteBg: false
        }
      })

      window.dispatchEvent(event)
    }
  }, [])

  return (
    <div className="flex-1 flex justify-center w-full text-black">
      <GenericError
        error={prettyError(errorId)}
        description={prettyErrorDescription(errorId)}
        buttonText={
          <>
            ¿ES UN ERROR?
            <br /> PARA CUALQUIER DUDA INGRESA AQUI
          </>
        }
        onRetry={() => {
          gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'ERROR - Dudas', value: '10' })
          push('/help')
        }}
      />
    </div>
  )
}

export default ErrorView
