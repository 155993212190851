import { Dispatch, SetStateAction, createContext, useState } from 'react'
export interface ClientContextProps {
    clientNum: boolean
    setClientNum: Dispatch<SetStateAction<boolean>>
    showInputNumber: boolean
    setShowInputNumber: Dispatch<SetStateAction<boolean>>
}

const ClientContext = createContext<ClientContextProps>({} as ClientContextProps)

const ClientProvider = ({ children }) => {
  const [clientNum, setClientNum] = useState(false)
  const [showInputNumber, setShowInputNumber] = useState(false)

  const data = {
    clientNum,
    setClientNum,
    setShowInputNumber,
    showInputNumber
  }

  return <ClientContext.Provider value={data}>{children}</ClientContext.Provider>
}

export { ClientProvider, ClientContext }