import { createContext, Dispatch, FunctionComponent, useContext, useReducer } from 'react'
import socialReducer, { SocialAction, SocialState } from './social-reducer'
import { Social } from './types'

export interface SocialContextProps {
  state: SocialState
  dispatch: Dispatch<SocialAction>
}

const SocialContext = createContext<SocialContextProps>({} as SocialContextProps)

export const SocialProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(socialReducer, {})

  return <SocialContext.Provider value={{ state, dispatch }}>{children}</SocialContext.Provider>
}

export const useSocial = () => {
  const { state, dispatch } = useContext(SocialContext)


  const setCurrent = (current: Social) => dispatch({ type: 'SET_CURRENT', payload: current })

  const setError = (error: number) => {
    dispatch({ type: 'SET_ERROR', payload: error });
  }

  const actions = { setError, setCurrent }

  const returnValue: [typeof state, typeof actions] = [state, actions]
  return returnValue
}