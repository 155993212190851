import { ErrorMessage } from '@hookform/error-message'
import { yupResolver } from '@hookform/resolvers/yup'
import { AxiosError } from 'axios'
import Button from 'components/button'
import PasswordRecovery from 'components/form-steps/password-recovery'
import Input from 'components/input'
import Password from 'components/password'
import { LoaderContext } from 'contexts/loader-context'
import { AuthError } from 'firebase/auth'
import { dlTrackError, dlTrackEvent } from 'components/dataLayer/dataLayer'
import { MouseEventHandler, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocalStorage } from 'react-use'
import formServices from 'services/form-builder-services'
import ProfileService from 'services/profile-services'
import textServices from 'services/text-services'
import { Checkbox, DivCheckbox, Label, PCheckbox, ReferralInput, ReferralLabel, ErrorInput } from 'theme/theme'
import { BackBtn } from 'views/login/text.style'
import { Link } from 'react-router-dom'
import CONFIGS from 'build/Config'
import Steps from '../components/Steps'
import MailSentPassword from '../mail-sent-password'

function EmailPassword({
  state,
  onFormSubmitEndPoint,
  isSignUp,
  mailSent,
  setMailSent,
  prevStep,
  setError,
  htmlSteps
}: any) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError: setErrorForm
  } = useForm({ resolver: formServices.formValidateErrors(yupResolver(formServices.formGenerateSchema(['email', 'password', 'referral'])), isSignUp ? 'sign up :: step 3' : 'login', isSignUp ? 'sign up' : 'login'), reValidateMode: 'onSubmit' })
  const { setLoad } = useContext(LoaderContext)
  const [termsOfUse, setTermsOfUse] = useState(false)
  const [subscribe, setSubscribe] = useState(false)
  const [referralCode] = useLocalStorage('referralCode', '')

  const handleTermsOfUse = () => {
    if (!termsOfUse) setErrorForm('termsOfUse', null)

    dlTrackEvent('user_interaction', {
      action: 'select',
      element: 'Acepto terminos y condiciones de privacidad',
      section: 'sign up :: step 3'
    })

    gtag('event', 'Click', {
      event_category: GTM_EVENT_CATEGORY,
      event_label: 'MODIFICAR INFORMACION - Acepto terminos y condiciones de privacidad',
      value: '10'
    })
    setTermsOfUse((state) => !state)
  }

  const handleSubscribe = () => {
    dlTrackEvent('user_interaction', {
      action: 'select',
      element: 'Acepto terminos y condiciones de privacidad',
      section: 'sign up :: step 3'
    })
    gtag('event', 'Click', {
      event_category: GTM_EVENT_CATEGORY,
      event_label: 'MODIFICAR INFORMACION - Acepto recibir novedades',
      value: '10'
    })
    setSubscribe((state) => !state)
  }

  const handleSubmitValidate = async (data: any) => {
    if (isSignUp && !termsOfUse) {
      dlTrackError({
        error_type: 'sign up',
        error_code: '',
        error_message: 'Debes aceptar los términos y condiciones',
        section: 'sign up :: step 3'
      })
      return setErrorForm('termsOfUse', { type: 'required', message: 'Debes aceptar los términos y condiciones' })
    }

    try {
      setLoad(true)
      if (data.referral) await ProfileService.validateReferralCode(data.referral)
      dlTrackEvent('form_send', {
        element: isSignUp ? 'Siguiente' : 'Inicia sesión',
        form_name: isSignUp ? 'datos cuenta' : 'completa tus datos',
        form_field: 'button',
        section: isSignUp ? 'sign up :: step 3' : 'login'
      })
      await onFormSubmitEndPoint({ ...data, subscribe, termsOfUse })
    } catch (error) {
      const typedError = error as AuthError
      if (typedError.code) {
        gtag('event', 'Click', {
          event_category: GTM_EVENT_CATEGORY,
          event_label: 'SINGUP - Referral Code existente',
          value: '10'
        })
        setError(typedError.code)
      } else if ((error as AxiosError).isAxiosError) {
        return setError((error as AxiosError).response?.data.code ?? 9999)
      }
    } finally {
      setLoad(false)
    }
  }

  const recoverPassword: MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.preventDefault()
    if (!getValues('email')) {
      return alert(textServices.findByText('login/recovery/alert', { es: 'Debes ingresar un correo electrónico', en: 'You must enter an e-mail address' }))
    }
    setLoad(true)
    try {
      const response = await ProfileService.recoverPassword({ email: mailSent || getValues('email') })
      if (response.status === 200) setMailSent(mailSent || getValues('email'))
    } catch (err) {
      const typedError = err as AxiosError
      const { code } = typedError.response?.data || {}
      setError(code)
    } finally {
      setLoad(false)
    }
  }

  if (mailSent)
    return (
      <MailSentPassword
        isSignUp={isSignUp}
        mailSent={mailSent}
        setMailSent={setMailSent}
        recoverPassword={recoverPassword}
      />
    )

  return (
    <div className="flex flex-col flex-1 md:pt-10">
      {!CONFIGS?.stepsNewVersion && isSignUp && (
        <div className="flex flex-row items-center justify-around w-full mb-4">{htmlSteps}</div>
      )}

      <form
        className="flex h-full justify-between flex-col gap-2 mx-7 mb-7"
        onSubmit={handleSubmit(handleSubmitValidate)}
        noValidate
      >
        <div className="flex-1 flex flex-col gap-4">
          <div className="gap-2 flex flex-col input-container">
            <Label className="text-start text-2xl font-bold uppercase input-label">
              {textServices.findByText('profile/form/information/email/label', { es: 'Correo electrónico', en: 'E-mail address' })}
            </Label>
            <Input
              className="text-center saira font-bold text-sm"
              type="text"
              placeholder={textServices.findByText('profile/form/information/email/placeholder', { es: 'roberto@gmail.com', en: 'robert@gmail.com' })}
              name="email"
              defaultValue={state?.email}
              {...register('email', {
                onBlur: (e) => {
                  dlTrackEvent('form_interaction', {
                    form_field: 'email',
                    form_name: isSignUp ? 'datos cuenta' : 'completa tus datos',
                    form_action: e.target.value ? 'complete' : 'empty',
                    section: isSignUp ? 'sign up :: step 3' : 'login'
                  })
                }
              })}
            />
            <ErrorInput className='input-error'>
              <ErrorMessage errors={errors} name="email" />
            </ErrorInput>
          </div>
          <div className="gap-2 flex flex-col input-container">
            <Label className="text-start text-2xl font-bold uppercase input-label">
              {textServices.findByText('profile/form/information/password/label', { es: 'Contraseña', en: 'Password' })}
            </Label>
            <Password
              className="text-center saira font-bold text-sm"
              type="password"
              placeholder="***********"
              name="password"
              defaultValue={state?.password}
              {...register('password', {
                onBlur: (e) => {
                  dlTrackEvent('form_interaction', {
                    form_field: 'contraseña',
                    form_name: isSignUp ? 'datos cuenta' : 'completa tus datos',
                    form_action: e.target.value ? 'complete' : 'empty',
                    section: isSignUp ? 'sign up :: step 3' : 'login'
                  })
                }
              })}
            />
            <ErrorInput className='input-error'>
              <ErrorMessage errors={errors} name="password" />
            </ErrorInput>
          </div>
          <PasswordRecovery shouldEnable={!isSignUp} recoverPassword={recoverPassword} mailSent={mailSent} />
          {isSignUp && CONFIGS.allowReferral && (
            <div className="gap-2 flex flex-col input-container">
              <ReferralLabel className="text-start text-xl font-medium uppercase input-label">
                {textServices.findByText('profile/form/information/referral/label', { es: 'CÓDIGO REFERIDO (OPCIONAL)', en: 'REFERRAL CODE (OPTIONAL)' })}
              </ReferralLabel>
              <ReferralInput
                className="text-center saira font-bold text-sm"
                type="text"
                placeholder={textServices.findByText('profile/form/information/referral/placeholder', { es: "Ingresa código referido", en: "Enter referral code" })}
                name="referral"
                defaultValue={referralCode as string}
                {...register('referral', {
                  onBlur: (e) => {
                    dlTrackEvent('form_interaction', {
                      form_field: 'referral',
                      form_name: isSignUp ? 'datos cuenta' : 'completa tus datos',
                      form_action: e.target.value ? 'complete' : 'empty',
                      section: isSignUp ? 'sign up :: step 3' : 'login'
                    })
                  }
                })}
              />
              <div className="text-black">
                <ErrorInput className='input-error'>
                  <ErrorMessage errors={errors} name="referral" />
                </ErrorInput>
              </div>
            </div>
          )}
        </div>

        {CONFIGS?.stepsNewVersion && isSignUp && <Steps state={state} htmlSteps={htmlSteps} decrease={2} />}

        {isSignUp && (
          <>
            <DivCheckbox className="gap-4 flex items-center cursor-pointer input-container" onClick={handleTermsOfUse}>
              <Checkbox active={termsOfUse} className={`h-6 w-6 flex items-center justify-center`} id="termsOfUse" />
              <PCheckbox className="dinPro input-checkbox-label flex-1">
                {
                  textServices.findByText('signup/termsOfUse/label', {
                    es: <>
                      Acepto los{' '}
                      <a
                        href={`${process.env.PUBLIC_URL}/terms-and-conditions`}
                        rel="noreferrer"
                        target="_blank"
                        className="underline"
                      >
                        Términos y Condiciones
                      </a>{' '}
                      y el{' '}
                      <a
                        href={`${process.env.PUBLIC_URL}/privacy-politics`}
                        rel="noreferrer"
                        target="_blank"
                        className="underline"
                      >
                        Aviso de privacidad
                      </a>
                      .
                    </>,
                    en: <>
                      I accept the{' '}
                      <a
                        href={`${process.env.PUBLIC_URL}/terms-and-conditions`}
                        rel="noreferrer"
                        target="_blank"
                        className="underline"
                      >
                        Terms and Conditions
                      </a>{' '}
                      and the{' '}
                      <a
                        href={`${process.env.PUBLIC_URL}/privacy-politics`}
                        rel="noreferrer"
                        target="_blank"
                        className="underline"
                      >
                        Privacy Policy
                      </a>
                      .
                    </>
                  })
                }

              </PCheckbox>
            </DivCheckbox>
            <ErrorInput className='input-error'>
              <ErrorMessage errors={errors} name="termsOfUse" />
            </ErrorInput>
            <DivCheckbox className="gap-4 flex items-center cursor-pointer input-container" onClick={handleSubscribe}>
              <Checkbox className={`h-6 w-6 flex items-center justify-center`} id="suscribe" active={subscribe} />
              <PCheckbox className="dinPro input-checkbox-label flex-1">{textServices.findByText('signup/suscribe/label', { es: 'Quiero recibir novedades.', en: 'I want to receive news.' })}</PCheckbox>
            </DivCheckbox>
            <ErrorInput className='input-error'>
              <ErrorMessage errors={errors} name="subscribe" />
            </ErrorInput>
          </>
        )}

        <div className={`w-full flex justify-center items-center ${isSignUp ? 'hidden' : '' }`}>
          <Link to="/help" className='login__help' onClick={() => {
            dlTrackEvent('user_interaction', {
              element: 'help',
              section: 'login :: help'
            })
            gtag('event', 'Click', {
              event_category: GTM_EVENT_CATEGORY,
              event_label: 'login :: help',
              value: '10'
            })
          }}>
            {
              textServices.findByText('signup/needHelp', { es: '¿Necesitas ayuda? Click aqui', en: 'Need help? Click here' })
            }
          </Link>
        </div>
        <Button>
          <p className="uppercase">
            {isSignUp ? textServices.findByText('signup/button', { es: 'TERMINAR REGISTRO', en: 'END REGISTRATION' }) : textServices.findByText('login/button', { es: 'INICIAR SESION', en: 'LOGIN' })}
          </p>
        </Button>
        {isSignUp ? (
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => {
              dlTrackEvent('user_interaction', { action: 'click', element: 'volver', section: 'sign up :: step 3' })
              gtag('event', 'Click', {
                event_category: GTM_EVENT_CATEGORY,
                event_label: 'MODIFICAR INFORMACION - Volver atras',
                value: '10'
              })
              prevStep()
            }}
          >
            <BackBtn className={`din text-center mt-3 hover:text-red-100 uppercase underline button-back`} style={{ fontSize: 10 }}>
              {textServices.findByText('backButton', { es: 'VOLVER', en: 'BACK TO' })}
            </BackBtn>
          </button>
        ) : (
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => {
              dlTrackEvent('user_interaction', { action: 'click', element: 'volver', section: 'iniciar sesion' })
              gtag('event', 'Click', {
                event_category: GTM_EVENT_CATEGORY,
                event_label: 'INICIAR SESION - Volver atras',
                value: '10'
              })
              history.back()
            }}
          >
            <BackBtn className={`din text-center mt-3 hover:text-red-100 uppercase underline button-back`} style={{ fontSize: 10 }}>
              {textServices.findByText('backButton', { es: 'VOLVER', en: 'BACK TO' })}
            </BackBtn>
          </button>
        )}
      </form>
    </div>
  )
}

export default EmailPassword
