import { useAgegate } from 'hooks/use-agegate';
import useMainClass from 'hooks/use-main-cass'
import React from 'react'
import textServices from 'services/text-services'
import { ComingSoonContainer, ComingSoonImage } from './style/custom.styles';

function ComingSoonHtml() {
  useMainClass('coming-soon')
  const { getAgeData, saveAgeData } = useAgegate();

  const logout = () => {
    const currentAgeGate = getAgeData();
    window.localStorage.clear()
    saveAgeData(currentAgeGate);
    window.location.href = `${process.env.PUBLIC_URL}/start`
  }

  if (textServices.findByText('coming-soon/html', ' ') !== ' ') {
    const ComingSonComponent = textServices.findByText('coming-soon/html', ' ')
    return <ComingSonComponent logout={logout} />
  }

  return (
    <ComingSoonContainer style={{ backgroundImage: `url('${AZURE_BASE_URL}/cs-background.svg')` }}>
      <div className='flex justify-end m-3'>
        <button onClick={logout} className=''>
          <img src={`${AZURE_BASE_URL}/cross.svg`} alt="icono cerrar" />
        </button>
      </div>
      <ComingSoonImage className='flex justify-center items-center mx-5'>
        <img src={`${AZURE_BASE_URL}/cs-image.svg`} alt="cartel" />
      </ComingSoonImage>
    </ComingSoonContainer>
  )
}

export default ComingSoonHtml