import { UserData } from 'contexts/session-context/session-reducer'
import dayjs from 'dayjs'
import MetaCAPIService from 'services/meta-capi.services'

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[]
}

export declare const window: WindowWithDataLayer

type EventNames =
  | 'user_interaction'
  | 'form_interaction'
  | 'login'
  | 'sign_up'
  | 'error'
  | 'view_promotion'
  | 'select_promotion'
  | 'form_send'
  | 'authentication'

function getAge(birthDate: any) {
  const nan = isNaN(birthDate)
  if (nan) return undefined
  const today = dayjs()
  const age = today.diff(birthDate, 'year')
  return age
}

export const createUserProperties = (user: UserData, loginType: string | null) => {
  const { birthday, address, hashId } = user
  const ls = localStorage.getItem('state')
  const data = ls ? JSON.parse(ls) : {}
  const birthDate = dayjs(birthday)
  const existEventAuth = window.dataLayer?.find((f: any) => { return f?.event === 'authentication' });
  
  const userProperties = {
    event: 'authentication',
    userData: {
      userId: hashId ?? undefined,
      userInfo: {
        age: getAge(birthDate) ?? undefined,
        birthYear: isNaN(birthDate?.year()) ? undefined : birthDate?.year() ?? undefined,
        country: address?.country ?? undefined
      },
      profile: {
        loginType: loginType ?? data?.data?.providerId?.split('.')[0] ?? 'email',
        platform: 'web'
      }
    }
  }
  
  const evPageView = {
    event : "evPageView",
    pageInfo : {
      currentURL: window.location.href,
      hostName: window.location.hostname,
      pageName: window.location.pathname
    }
  }

  window.dataLayer = window.dataLayer || []

  if (!existEventAuth) {
    window.dataLayer.push(userProperties)
    window.dataLayer.push(evPageView)
    MetaCAPIService.emitPageView(window.location.href, MetaCAPIService.uuidv4())
  }
}

export const dlTrackEvent = (eventName: EventNames, eventParams: any) => {
  window.dataLayer.push({
    event: 'ga4.trackEvent',
    eventName: eventName,
    eventParams: eventParams
  })
}

export const dlSetUserInfo = (userId: string | number) => {
  window.dataLayer.push({
    user: {
      profile: {
        profileInfo: {
          userId: userId
        }
      }
    }
  })
}

export const dlTrackError = (eventParams: any) => {
  window.dataLayer.push({
    event: 'ga4.trackEvent',
    eventName: 'error',
    eventParams: eventParams
  })
}

export const dataLayer = (objAnalitcs: any) => {
  window.dataLayer.push(objAnalitcs)
}
