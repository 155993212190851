import { AxiosError } from "axios"

interface Playable {
  advance: any
  bestScore: number
  game: {
    description: string
    id: number
    name: string
    imageUrl: string
    prize: string
    status: number
    validFrom: string
    validTo: string
  }
  prize: {
    description: string
    imageUrl: string
    points: number
    productId: number
    profile: {
      totalPoints: number
      chances: number
    }
    temporalActivation: boolean
    title: string
  }
  status: number
}

export interface PlayableState {
  playables?: any[],
  playable: Playable,
  error?: AxiosError,
  finished: boolean,
  currentPlayable: any
  lastScore: number
  advance?: { level: number, points: number }[]
  prize?: any
}

export enum PlayableActionsType {
  RESET = 'RESET',
  SET_PLAYABLES = 'SET_PLAYABLES',
  SET_PLAYABLE = 'SET_PLAYABLE',
  SET_ERROR = 'SET_ERROR',
  SET_CONGRATS = 'SET_CONGRATS',
  SET_PLAY = 'SET_PLAY',
  SET_CURRENT_PLAYABLE = 'SET_CURRENT_PLAYABLE',
  SET_LAST_SCORE = 'SET_LAST_SCORE',
  SET_ADVANCE = "SET_ADVANCE",
  SET_ADVANCE_BASE = "SET_ADVANCE_BASE",
  SET_PRIZE = "SET_PRIZE"
}

export interface PlayableAction {
  payload?: any
  type: PlayableActionsType
}

const playableReducer = (state: PlayableState, action: PlayableAction): PlayableState => {
  switch (action.type) {
  case PlayableActionsType.SET_PLAYABLES:
    return { ...state, playables: action.payload }
  case PlayableActionsType.SET_ADVANCE:
    return { ...state, advance: [...(state.advance ?? []), action.payload] }
  case PlayableActionsType.SET_ADVANCE_BASE:
    return { ...state, advance: action.payload ?? []}
  case PlayableActionsType.SET_LAST_SCORE:
    return { ...state, lastScore: action.payload }
  case PlayableActionsType.SET_PRIZE:
    return { ...state, prize: action.payload }
  case PlayableActionsType.SET_PLAYABLE:
    return { ...state, playable: action.payload }
  case PlayableActionsType.SET_CURRENT_PLAYABLE:
    return { ...state, currentPlayable: action.payload }
  case PlayableActionsType.SET_PLAY:
    return { ...state, playable: action.payload }
  case PlayableActionsType.SET_CONGRATS:
    return { ...state, finished: true }
  case PlayableActionsType.SET_ERROR:
    return { ...state, error: action.payload }
  default:
    return state
  }
}

export default playableReducer