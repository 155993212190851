import Button from "components/button";
import { useUTMParams } from "components/utms/useUTMParams";
import { useMemo } from "react";
import { Link } from "react-router-dom";

export default function Xeerpa() {
  const utmParams = useUTMParams();

  const search = useMemo(() => {
    const searchParams = new URLSearchParams({ 'tointernal': 'true' });

    for (const key in utmParams) {
      if (utmParams[key] && !searchParams.has(key)) {
        searchParams.set(key, utmParams[key]);
      }
    }

    return searchParams?.toString()
  }, [utmParams])


  return (
    <Link to={{ pathname: '/xeerpa-manager', search }} className="w-full">
      <Button>
        INGRESAR
      </Button>
    </Link>
  )
}