import { lazy } from "react";
import RoutesConfigAlbum from "./routes-config";
const List = lazy(() => import('../../views/multimedia/views/list'))
const Ranking = lazy(() => import('../../views/multimedia/views/ranking'))
const ImageId = lazy(() => import('../../views/multimedia/views/image-id'))
const Upload = lazy(() => import('../../views/multimedia/components/onBoarding'))
const Profile = lazy(() => import('../../views/multimedia/views/profile'))

export const RoutesAlbumDefinitions = [
  new RoutesConfigAlbum("/album/:id/list", List),
  new RoutesConfigAlbum("/album/:id/ranking", Ranking, true),
  new RoutesConfigAlbum("/album/:id/image/:code", ImageId, true),
  new RoutesConfigAlbum("/album/:id/upload", Upload),
  new RoutesConfigAlbum("/album/:id/profile", Profile, true),
]