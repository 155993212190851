const Auctions = () => (
  <svg width="42" height="33" viewBox="0 0 42 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.3602 4.13797L0.987305 5.08813L1.6247 16.7424L18.9976 15.7922L18.3602 4.13797Z" stroke="currentColor" strokeMiterlimit="10" strokeLinejoin="round" />
    <path d="M10.3924 7.60542C10.1892 7.41846 9.87289 7.43164 9.68592 7.63486L6.63921 10.9466C6.45225 11.1498 6.46543 11.4661 6.66865 11.6531C6.87188 11.84 7.18819 11.8269 7.37515 11.6236L10.0833 8.67988L13.0271 11.3881C13.2303 11.575 13.5466 11.5619 13.7336 11.3586C13.9206 11.1554 13.9074 10.8391 13.7041 10.6521L10.3924 7.60542ZM10.8041 13.9658L10.5535 7.95257L9.55433 7.99421L9.80495 14.0074L10.8041 13.9658Z" fill="currentColor" />
    <path d="M12.1028 16.1693L12.9584 31.8127L9.37505 32.0087L8.51947 16.3652" stroke="currentColor" strokeMiterlimit="10" strokeLinejoin="round" />
    <path d="M41.1592 4.13784L24.0615 0.915039L21.8995 12.3847L38.9973 15.6075L41.1592 4.13784Z" stroke="currentColor" strokeMiterlimit="10" strokeLinejoin="round" />
    <path d="M32.2117 14.3285L29.3097 29.7243L25.7832 29.0596L28.6852 13.6638" stroke="currentColor" strokeMiterlimit="10" strokeLinejoin="round" />
    <path d="M30.5081 11.4715C30.6573 11.7039 30.9666 11.7714 31.199 11.6222L34.986 9.19154C35.2184 9.04238 35.2859 8.73307 35.1367 8.50068C34.9876 8.26828 34.6783 8.20081 34.4459 8.34997L31.0796 10.5106L28.919 7.14428C28.7699 6.91188 28.4605 6.84441 28.2282 6.99357C27.9958 7.14272 27.9283 7.45203 28.0774 7.68442L30.5081 11.4715ZM31.7231 5.21473L30.4404 11.0948L31.4174 11.308L32.7002 5.42787L31.7231 5.21473Z" fill="currentColor" />
  </svg>
)

export default Auctions;