import ButtonCloseMenu from 'components/button-close'
import Button from 'components/button'
import { DemandSpace, SurveyInfo } from 'contexts/survey-context/survey.interface'
import useMainClass from 'hooks/use-main-cass'
import { ImgBottomSurvey, ImgCardSurvey, TitleSurveyPolaris } from '../styles/styled.components'
import DemandSpaceVideo from './demandSpaceVideo'

interface IProps {
  redirect: () => void
  demandSpace: DemandSpace
  surveyInfo: SurveyInfo
}

function DemandSpaceComponent({ demandSpace, redirect, surveyInfo }: IProps) {
  useMainClass('survey__demand-space')

  if (demandSpace.videoUrl) return <DemandSpaceVideo demandSpace={demandSpace} redirect={redirect} surveyInfo={surveyInfo} />

  return (
    <div className="w-full pb-7 flex-1 flex flex-col px-8">
      <ButtonCloseMenu
        open={true}
        paramFn={() => {
          redirect()
          gtag('event', 'Click', {
            event_category: GTM_EVENT_CATEGORY,
            event_label: 'Survey - Cerrar modal de alerta para ofertar',
            value: '10'
          })
        }}
      />
      <TitleSurveyPolaris className="font-black text-4xl text-center py-4 title">Tu resultado fue:</TitleSurveyPolaris>
      <div className="flex-1 w-full flex justify-center items-start">
        <ImgCardSurvey src={demandSpace.imageUrl} alt={demandSpace.name} />
      </div>
      <Button onClick={redirect}>Continuar</Button>
      <div className="w-full flex items-center justify-center py-4 hidden">
        <ImgBottomSurvey src={`${AZURE_BASE_URL}/demad-space-bottom.png`} alt="footer" />
      </div>
    </div>
  )
}

export default DemandSpaceComponent