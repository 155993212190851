import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import Button from "components/button";
import Input from "components/input";
import ButtonCloseMenu from 'components/button-close'
import { LoaderContext } from "contexts/loader-context";
import useRouter from "hooks/use-router";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import formServices from "services/form-builder-services";
import ProfileService from "services/profile-services";
import textServices from "services/text-services";
import { Label, ErrorInput } from "theme/theme";
import CONFIGS from 'build/Config'
import { TextOtp, TextOtpLabel, TextOtpTwo, BackBtn } from "../text.style";

function Phone({ state, handleChange, nextStep, prevStep, setHelper, session, recaptchaRef, setError, onFormSubmitEndPoint }: any) {
  const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(formServices.formGenerateSchema(["phoneNumber"])) })
  const { history } = useRouter()
  const { setLoad } = useContext(LoaderContext)

  function onFormSubmitDirect(data: any) {
    handleChange(data)
    onFormSubmitEndPoint(data)
  }

  async function onFormSubmit(data: any) {
    recaptchaRef.current?.reset()
    await recaptchaRef.current?.executeAsync().then(async (recaptchaToken: any) => {
      setLoad(true)
      try {
        const result = await ProfileService.otpGenerate({ phone: data.phoneNumber, recaptchaToken, session })
        if (result.status === 201) {
          handleChange(data)
          setHelper({ expires: result.data?.expiresAt })
          nextStep()
        } else if (result.status === 200) {
          history.goBack()
        }
      } catch (err) {
        const typedError = err as AxiosError
        const { code } = typedError.response?.data || {}
        setError(code)
      } finally {
        setLoad(false)
      }
    })
  }

  return (
    <div className="flex flex-col flex-1 md:pt-10">
      <ButtonCloseMenu paramFn={() => {
        gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'MODIFICAR TELEFONO - Volver atras', value: '10' })
        history.goBack()
      }} />
      <form className="flex h-full justify-between flex-col gap-4 mx-7 mb-7" onSubmit={handleSubmit(CONFIGS?.profile?.phone?.useDirectPhone ? onFormSubmitDirect : onFormSubmit)} noValidate>
        <div className="flex-1 flex flex-col gap-4">
          <TextOtpLabel className="gap-2 flex flex-col input-container">
            <Label className="text-start text-2xl font-bold uppercase input-label">{textServices.findByText('otp/phone/title', { es: 'Número de celular', en: 'Phone number' })}</Label>
            <Input
              className="text-center saira font-bold text-sm"
              type="text"
              placeholder="Ingresa tu número"
              name="phoneNumber"
              defaultValue={state?.phoneNumber}
              {...register("phoneNumber")}
            />
            <ErrorInput className="input-error">
              <ErrorMessage errors={errors} name="phoneNumber" />
            </ErrorInput>
          </TextOtpLabel>
          <div className="flex-1 flex flex-col items-center justify-center gap-4 text-center mx-7 otp-text-container">
            <TextOtp className="text-center text-xl font-bold saira otp-text">{textServices.findByText('otp/phone/description', { es: 'Necesitamos validar tu número de teléfono para completar el registro.', en: 'We need to validate your phone number to complete the registration.' })}</TextOtp>
            <TextOtpTwo className="text-center text-xl font-normal saira otp-text-secondary">{textServices.findByText('otp/phone/message', { es: <>Te enviaremos un <b>código OTP</b> para que ingreses en la siguiente pantalla.</>, en: <>We will send you an <b>OTP code</b> to enter on the next screen.</> })}</TextOtpTwo>
          </div>
        </div>
        <Button>
          <p className="uppercase">{textServices.findByText('otp/phone/button', { es: 'PEDIR CÓDIGO OTP', en: 'REQUEST OTP CODE' })}</p>
        </Button>
        <button type="button" className="cursor-pointer" onClick={() => {
          gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'MODIFICAR TELEFONO - Volver atras', value: '10' })
          prevStep()
        }}>
          <BackBtn
            className={`din text-center mt-3 hover:text-red-100 uppercase underline button-back`}
            style={{ fontSize: 10 }}
          >
            {textServices.findByText('backButton', { es: 'VOLVER', en: 'BACK TO' })}
          </BackBtn>
        </button>
      </form>
    </div>
  )
}

export default Phone;