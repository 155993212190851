import { AxiosError } from 'axios'
import { TriviaItem } from 'views/trivia/types'

export interface TriviaState {
  interests: number[]
  trivias?: TriviaItem[]
  prize?: Prize
  congrats?: {
    urlRedirect: string
    buttonText: string,
    buttonText2: string,
    urlRedirect2: string,
    profileTime: number,
    useRanking: boolean
  }
  currentTrivia?: Trivia
  error?: AxiosError
  prizesCategory?: { id?: number; name: string }[]
}

export interface Prize {
  title: string
  points: number
  description: any
  productId: number
  imageUrl: string
  stickerId: number
}
export interface TriviaAction {
  payload?: any
  type: TriviaActionsType
}

export enum TriviaActionsType {
  RESET = 'RESET',
  SET_INTERESTS = 'SET_INTERESTS',
  SET_TRIVIAS = 'SET_TRIVIAS',
  SET_TRIVIA = 'SET_TRIVIA',
  SET_PRIZE = 'SET_PRIZE',
  SET_QUESTIONS = 'SET_QUESTIONS',
  SET_ERROR = 'SET_ERROR',
  SET_PRIZES_CATEGORIES = 'SET_INTEREST_LIST',
  SET_CONGRATS = 'SET_CONGRATS'
}

export interface Trivia {
  id: number
  title: string
  name: string
  description: string
  timeInSeconds: number
  costInPoints: number
  interest: string
  prize: string
  status: string
  validFrom: string
  validTo: string
  question: Question
  directAccess: boolean
  triviaUserStatus: number
  imageUrl: string
  prizeImageUrl: string
}

export interface Question {
  questionId: number
  question: string
  options: Option[]
}

export interface Option {
  optionId: number
  option: string
  imageUrl: string
}

const triviaReducer = (state: TriviaState, action: TriviaAction): TriviaState => {
  switch (action.type) {
  case TriviaActionsType.SET_INTERESTS:
    return { ...state, interests: action.payload }
  case TriviaActionsType.SET_TRIVIAS:
    return { ...state, trivias: action.payload }
  case TriviaActionsType.SET_TRIVIA:
    return { ...state, currentTrivia: action.payload }
  case TriviaActionsType.SET_QUESTIONS:
    return { ...state, currentTrivia: { ...(state.currentTrivia as Trivia), question: action.payload } }
  case TriviaActionsType.SET_PRIZE:
    return { ...state, prize: action.payload }
  case TriviaActionsType.SET_CONGRATS:
    return { ...state, congrats: action.payload }
  case TriviaActionsType.RESET:
    return { ...state, prize: action.payload, currentTrivia: action.payload, error: action.payload }
  case TriviaActionsType.SET_ERROR:
    return { ...state, error: action.payload }
  case TriviaActionsType.SET_PRIZES_CATEGORIES:
    return { ...state, prizesCategory: [{ id: undefined, name: 'Todas' }, ...action.payload] }
  default:
    return state
  }
}

export default triviaReducer
