import { useEffect } from 'react';

const FacebookSDK = () => {
  useEffect(() => {
    // Verifica si el SDK ya fue cargado para evitar duplicados
    if (!document.getElementById('facebook-jssdk')) {
      console.log('FACEBOOK CARGADO')
      // Crea un nuevo elemento script
      const scriptElement = document.createElement('script');
      scriptElement.id = 'facebook-jssdk';
      scriptElement.src = "https://connect.facebook.net/en_US/sdk.js";
      scriptElement.async = true;
      scriptElement.defer = true;
      scriptElement.crossOrigin = 'anonymous';

      // Inserta el elemento script en el documento
      document.body.appendChild(scriptElement);

      scriptElement.onload = () => {
        // Inicializa el SDK de Facebook una vez que el script se ha cargado
        console.log('FACEBOOK INIT')
        console.log(window.FB.init())
        window.FB.init({
          appId: FC_CLIENTID, // Reemplaza YOUR_APP_ID con el ID de tu aplicación
          cookie: true,
          xfbml: true,
          version: 'v9.0' // Usa la versión del SDK que prefieras
        });

        window.FB.AppEvents.logPageView();
      };


    }
  }, []);

  return null; // Este componente no necesita renderizar nada
};

export default FacebookSDK;