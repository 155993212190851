import styled from "styled-components";
import { Type } from "theme/types";
import Button from 'components/button'

export const DivTextHelp = styled.div`
    color: ${(props: Type) => props.theme?.help?.textHelp}
`

export const DivBgHelp = styled.div`
    background-color: ${(props: Type) => props.theme?.help?.backgroundHelp};
    ${({ theme }: Type) => theme?.help?.div}
`

export const AreaTextHelp = styled.textarea`
    color: ${(props: Type) => props.theme?.help?.textHelpArea}
`

export const TitleHelp = styled.h1`
    ${(props: Type) => props.theme?.help?.titleHelp}
`

export const SubtitleHelp = styled.h1`
    ${(props: Type) => props.theme?.help?.subtitleHelp}
`

export const ContactTitle = styled.h1`
    ${(props: Type) => props.theme?.help?.contactTitle}
`

export const ContactSubtitle = styled.h1`
    ${(props: Type) => props.theme?.help?.contactSubtitle}
`

export const CollapsibleStyle = styled.div`
    ${(props: Type) => props.theme?.help?.collapsibleStyle}
`

export const SectionHelp = styled.section`
    ${(props: Type) => props.theme?.help?.sectionHelp}
`

export const ButtonHelp = styled(Button)`
  ${(props: Type) => props.theme?.help?.buttonHelp}
`

export const CollapsibleStyleP = styled.p`
  ${(props: Type) => props.theme?.help?.collapsibleStyleP}
`