import Button from "components/button";
import { useRef } from "react";
import { Checkbox, DivCheckbox, Label, PCheckbox } from "theme/theme";
import Input from 'components/input'
import textServices from "services/text-services";

const WifiForm = ({ nameRef = useRef<any>(null), phoneRef = useRef<any>(null), emailRef = useRef<any>(null), termsOfUse = { value: false, setValue: (bool: boolean) => console.log(bool) }, subscribe = { value: false, setValue: (bool: boolean) => console.log(bool) } }) => {

  return (
    <div className="px-4 pt-4 pb-9 flex-1 flex flex-col w-full">
      <div className="flex-1 flex flex-col content-wrapper gap-6">
        <div className="w-full">
          <img className="mx-auto" src={`${AZURE_BASE_URL}/wifi-home.webp`} alt="Wifi" />
        </div>
        <h2 className="text-center title small">
          Completa los datos para ingresar
        </h2>
        <div className="flex-1 flex flex-col gap-4">
          <div className="gap-2 flex flex-col input-container">
            <Label className="text-start text-2xl font-bold uppercase input-label">Nombre</Label>
            <Input
              type="text"
              required
              placeholder="Roberto"
              className="text-center saira font-bold text-sm"
              ref={nameRef}
            />
          </div>
          <div className="gap-2 flex flex-col input-container">
            <Label className="text-start text-2xl font-bold uppercase input-label">Correo electrónico</Label>
            <Input
              type="email"
              required
              placeholder="Roberto@mail.com"
              className="text-center saira font-bold text-sm"
              name="username"
              id="username"
              ref={emailRef}
            />
          </div>
          <div className="gap-2 flex flex-col input-container">
            <Label className="text-start text-2xl font-bold uppercase input-label">Número de celular</Label>
            <Input
              type="tel"
              required
              pattern="[0-9]{10}"
              title="Debe contener 10 dígitos"
              placeholder="123456789"
              className="text-center saira font-bold text-sm"
              name="password"
              id="password"
              ref={phoneRef}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full flex-col px-[16px] justify-center items-center mt-10 gap-4">
        <DivCheckbox className="gap-4 w-full flex items-center cursor-pointer input-container" onClick={() => termsOfUse?.setValue(!termsOfUse?.value)}>
          <Checkbox active={termsOfUse?.value} className={`h-6 w-6 flex items-center justify-center`} id="termsOfUse" />
          <PCheckbox className="dinPro input-checkbox-label flex-1">
            {
              textServices.findByText('signup/termsOfUse/label', {
                es: <>
                  Acepto los{' '}
                  <a
                    href={`${process.env.PUBLIC_URL}/terms-and-conditions`}
                    rel="noreferrer"
                    target="_blank"
                    className="underline"
                  >
                    Términos y Condiciones
                  </a>{' '}
                  y el{' '}
                  <a
                    href={`${process.env.PUBLIC_URL}/privacy-politics`}
                    rel="noreferrer"
                    target="_blank"
                    className="underline"
                  >
                    Aviso de privacidad
                  </a>
                  .
                </>,
                en: <>
                  I accept the{' '}
                  <a
                    href={`${process.env.PUBLIC_URL}/terms-and-conditions`}
                    rel="noreferrer"
                    target="_blank"
                    className="underline"
                  >
                    Terms and Conditions
                  </a>{' '}
                  and the{' '}
                  <a
                    href={`${process.env.PUBLIC_URL}/privacy-politics`}
                    rel="noreferrer"
                    target="_blank"
                    className="underline"
                  >
                    Privacy Policy
                  </a>
                  .
                </>
              })
            }
          </PCheckbox>
        </DivCheckbox>
        <DivCheckbox className="gap-4 w-full flex items-center cursor-pointer input-container" onClick={() => subscribe?.setValue(!subscribe?.value)}>
          <Checkbox className={`h-6 w-6 flex items-center justify-center`} id="suscribe" active={subscribe?.value} />
          <PCheckbox className="dinPro input-checkbox-label flex-1">{textServices.findByText('signup/suscribe/label', { es: 'Quiero recibir novedades.', en: 'I want to receive news.' })}</PCheckbox>
        </DivCheckbox>
        <Button disabled={!termsOfUse?.value}>CONECTAR</Button>
      </div>
    </div>
  )
}

export default WifiForm;