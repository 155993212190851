import React, { useRef, useEffect, useState } from 'react';

type Point = {
  x: number;
  y: number;
};

type CustomBrush = {
  image: any;
  width: number;
  height: number;
};

type CustomCheckZone = {
  x: number;
  y: number;
  width: number;
  height: number;
};

interface Props {
  width: number;
  height: number;
  image: any;
  finishPercent?: number;
  onComplete?: () => void;
  brushSize?: number;
  fadeOutOnComplete?: boolean;
  children?: any;
  customBrush?: CustomBrush;
  customCheckZone?: CustomCheckZone;
}

const Scratch: React.FC<Props> = ({
  width,
  height,
  image,
  finishPercent = 70,
  onComplete,
  brushSize = 20,
  fadeOutOnComplete = true,
  children,
  customBrush,
  customCheckZone
}) => {
  const [loaded, setLoaded] = useState(false);
  const [, setFinished] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const isDrawing = useRef(false);
  const lastPoint = useRef<Point | null>(null);
  const ctx = useRef<CanvasRenderingContext2D | null>(null);
  const brushImage = useRef<HTMLImageElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const isFinished = useRef(false);

  const getFilledInPixels = (stride: number) => {
    const canvas = canvasRef.current;
    if (!canvas || !ctx.current || stride < 1) return 0;

    let x = 0;
    let y = 0;
    let canvasWidth = canvas.width;
    let canvasHeight = canvas.height;

    if (customCheckZone) {
      x = customCheckZone.x;
      y = customCheckZone.y;
      canvasWidth = customCheckZone.width;
      canvasHeight = customCheckZone.height;
    }

    const pixels = ctx.current.getImageData(x, y, canvasWidth, canvasHeight);
    const total = pixels.data.length / stride;
    let count = 0;

    for (let i = 0; i < pixels.data.length; i += stride) {
      if (parseInt(pixels.data[i].toString(), 10) === 0) {
        count++;
      }
    }

    return Math.round((count / total) * 100);
  };

  const getMouse = (e: any, canvas: HTMLCanvasElement) => {
    const { top, left } = canvas.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

    let x = 0;
    let y = 0;

    if (e && e.pageX && e.pageY) {
      x = e.pageX - left - scrollLeft;
      y = e.pageY - top - scrollTop;
    } else if (e && e.touches) {
      x = e.touches[0].clientX - left - scrollLeft;
      y = e.touches[0].clientY - top - scrollTop;
    }

    return { x, y };
  };

  const distanceBetween = (point1: Point | null, point2: Point | null) => {
    if (point1 && point2) {
      return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
    }
    return 0;
  };

  const angleBetween = (point1: Point | null, point2: Point | null) => {
    if (point1 && point2) {
      return Math.atan2(point2.x - point1.x, point2.y - point1.y);
    }
    return 0;
  };

  const handlePercentage = (filledInPixels = 0) => {
    if (isFinished.current) return;

    if (filledInPixels > finishPercent) {
      const canvas = canvasRef.current;
      if (fadeOutOnComplete && canvas) {
        canvas.style.transition = '1s';
        canvas.style.opacity = '0';
      }

      setFinished(true);
      if (onComplete) onComplete();
      isFinished.current = true;
    }
  };

  const handleMouseDown = (e: any) => {
    isDrawing.current = true;
    lastPoint.current = getMouse(e, canvasRef.current!);
  };

  const handleMouseMove = (e: any) => {
    if (!isDrawing.current) return;

    const currentPoint = getMouse(e, canvasRef.current!);
    const distance = distanceBetween(lastPoint.current, currentPoint);
    const angle = angleBetween(lastPoint.current, currentPoint);

    let x, y;

    for (let i = 0; i < distance; i++) {
      x = lastPoint.current ? lastPoint.current.x + Math.sin(angle) * i : 0;
      y = lastPoint.current ? lastPoint.current.y + Math.cos(angle) * i : 0;
      if (ctx.current) {
        ctx.current.globalCompositeOperation = 'destination-out';

        if (brushImage.current && customBrush) {
          ctx.current.drawImage(brushImage.current, x, y, customBrush.width, customBrush.height);
        } else {
          ctx.current.beginPath();
          ctx.current.arc(x, y, brushSize, 0, 2 * Math.PI, false);
          ctx.current.fill();
        }
      }
    }

    lastPoint.current = currentPoint;
    handlePercentage(getFilledInPixels(32));
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    ctx.current = canvas.getContext('2d');

    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      if (ctx.current) {
        ctx.current.drawImage(img, 0, 0, width, height);
        setLoaded(true);
      }
    };
    img.src = image;
    imageRef.current = img;

    if (customBrush) {
      const brushImg = new Image(customBrush.width, customBrush.height);
      brushImg.src = customBrush.image;
      brushImage.current = brushImg;
    }
  }, [image, width, height, customBrush]);

  return (
    <div
      className="scratch-card__container"
      style={{
        width: `${width}px`,
        height: `${height}px`,
        position: 'relative',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
      }}
    >
      <canvas
        ref={canvasRef}
        className="scratch-card__canvas"
        style={{ position: 'absolute', top: 0, zIndex: 1 }}
        width={width}
        height={height}
        onMouseDown={handleMouseDown}
        onTouchStart={handleMouseDown}
        onMouseMove={handleMouseMove}
        onTouchMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchEnd={handleMouseUp}
      />
      <div
        className="scratch-card__result"
        style={{
          visibility: loaded ? 'visible' : 'hidden',
          width: '100%',
          height: '100%',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Scratch;