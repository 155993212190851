import { AxiosError } from "axios";
import ButtonCloseMenu from "components/button-close";
import SelectAuctionCard from "components/card-auction-select";
import CardAuctions from "components/card-auctions";
import { LoaderContext } from "contexts/loader-context";
import { useSession } from "contexts/session-context";
import { useContext, useEffect, useState } from "react";
import { ArrowDown } from "@icon-park/react";
import textServices from "services/text-services";
import ErrorView from "components/error-view";

const Array = [
  {
    title: "2 Boletos vivelatino",
    urlImg: "",
    description: "El festival Vive Latino 2022 se celebrara el proximo 2 y 3 de septiembre en Zaragoza, España. 22 Festival iberoamericano de Cultura Musical, 19 y 20 de marzo de 2022.",
    currentOffer: 28300,
    expires: new Date("2022-09-10T00:00:00.000Z"),
    status: 1,
    category: "Festival",
    id: 1
  },
  {
    title: "2 Boletos vivelatino",
    urlImg: "",
    description: "El festival Vive Latino 2022 se celebrara el proximo 2 y 3 de septiembre en Zaragoza, España. 22 Festival iberoamericano de Cultura Musical, 19 y 20 de marzo de 2022.",
    currentOffer: 28300,
    expires: new Date("2022-09-10T00:00:00.000Z"),
    status: 2,
    category: "Deporte",
    id: 2
  },
  {
    title: "2 Boletos vivelatino current",
    urlImg: "",
    description: "El festival Vive Latino 2022 se celebrara el proximo 2 y 3 de septiembre en Zaragoza, España. 22 Festival iberoamericano de Cultura Musical, 19 y 20 de marzo de 2022.",
    currentOffer: 28300,
    expires: new Date("2022-09-10T00:00:00.000Z"),
    status: 3,
    category: "Tecnologia",
    id: 3
  },
  {
    title: "2 Boletos vivelatino minor",
    urlImg: "",
    description: "El festival Vive Latino 2022 se celebrara el proximo 2 y 3 de septiembre en Zaragoza, España. 22 Festival iberoamericano de Cultura Musical, 19 y 20 de marzo de 2022.",
    currentOffer: 28300,
    expires: new Date("2022-09-10T00:00:00.000Z"),
    status: 4,
    category: "Festival",
    id: 4
  },
]

const currentAuction = Array.find(item => item.status === (3) || item.status === (4))

function Auctions() {

  // eslint-disable-next-line no-undef
  const [{ user }] = useSession()
  const { setLoad } = useContext(LoaderContext)
  const [auctionSelected, setAuctionSelected] = useState(null)
  const [genericError, setGenericError] = useState(null)
  const [congrats, setCongrats] = useState(null)

  useEffect(() => {
    const event = new CustomEvent('background-color', {
      detail: {
        shouldUseWhiteBg: congrats || genericError && true
      }
    })

    window.dispatchEvent(event)
  }, [congrats, genericError])

  const handleSubmit = async (currentOffer: number) => {
    setLoad(true)
    try {
      setAuctionSelected(null)
      console.log(currentOffer)
      setCongrats(true)
    } catch (error) {
      const err = error as AxiosError
      setGenericError(err.response?.data.code || 99999)
    } finally {
      setLoad(false)
    }
  }

  if (genericError) return <ErrorView buttonMenu={() => setGenericError(null)} code={genericError} onRetry={() => setGenericError(null)} />

  if (congrats) {
    return (
      <>
        <ButtonCloseMenu open={true} paramFn={() => {
          gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'SUBASTAS - Volver atras', value: '10' })
          setCongrats(false)
        }} />
        <section className="w-auto flex-1 flex flex-col justify-center items-center max-w-full space-y-4 mx-8 my-8 text-black">
          <div className="flex-1  flex flex-col itemx-center justify-center gap-4">
            <h1 className="text-center text-5xl uppercase font-bold">¡{textServices.findByText('auctions/congrats/title', 'Excelente,')} {user?.firstName}!</h1>
            <p className="text-center mx-2 text-xl saira">{textServices.findByText('auctions/congrats/description', 'Tu oferta quedó como la más alta. En caso de que alguien más la supere te lo haremos saber para que puedas volver a ofertar si quieres.')}</p>
            <p className="text-center mx-2 text-xl font-bold saira"> TE QUEDAN {user?.totalPoints} {POINTS_NAME} <br /> VAMOS POR MÁS.</p>
          </div>
        </section>
      </>
    )
  }

  if (auctionSelected) {
    return (
      <SelectAuctionCard item={auctionSelected} setAuction={setAuctionSelected} currentAuction={currentAuction} onSubmit={handleSubmit} />
    )
  }

  return (
    <div className="flex-1 flex flex-col uppercase w-screen saira gap-7 pb-7">
      <div className="w-full pt-7 px-7 sm:px-0 flex flex-row items-center justify-between gap-4">
        <div className="flex-1 flex flex-col gap-4 items-start justify-center">
          <h1 className="font-medium text-4xl">{textServices.findByText('auctions/title', 'Subastas')}</h1>
          <p>Tienes {user?.totalPoints} {POINTS_NAME}</p>
        </div>
        <div className="flex h-full items-start justify-start text-xs font-normal cursor-pointer">
          <p className="flex flex-row items-center justify-center gap-2">
            ORDENAR POR
            <ArrowDown
              strokeWidth={4}
            />
          </p>
        </div>
      </div>
      {
        currentAuction && (
          <>
            <CardAuctions item={currentAuction} setAuction={setAuctionSelected} />
            <p className="text-center text-xs px-7">
              {textServices.findByText('auctions/current', 'Puedes seguir mirando las otras ofertas mientras se termina la que ya ofertaste.')}
            </p>
          </>
        )
      }
      {
        Array?.filter((item) => (item.status !== 3 && item.status !== 4)).map((item) => {
          return <CardAuctions key={`${item.id}-auctions`} item={item} setAuction={setAuctionSelected} />
        })
      }
    </div>
  );
}

export default Auctions;