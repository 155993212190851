const redirectUri = `${window.location.origin}${process.env.PUBLIC_URL}/callback`;
const clientId = GOOGLE_CLIENTID || "";

export const goToUrlGoogle = (flowType = '',  utm?: Record<string, string>) => {
  const flowTypeParam = flowType ? `&${flowType}` : '';
  const state = encodeURIComponent(`google${flowTypeParam}`);
  localStorage.setItem("stateAuthSocial", state);

  if (utm) {
    const utmString = Object.entries(utm)?.map(([key, value]) => `${key}=${value}`)?.join('&')
    localStorage.setItem("utmAuthSocial", `${utmString}`);
  }

  let url = "https://accounts.google.com/o/oauth2/v2/auth";
  url += "?response_type=token";
  url += "&client_id=" + encodeURIComponent(clientId);
  url += "&scope=" + encodeURIComponent(`https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile ${["dosequis", "tecate", "mixx"].includes(THEME) ? 'https://www.googleapis.com/auth/youtube.readonly' : ''}`);
  url += "&redirect_uri=" + encodeURIComponent(redirectUri);
  url += "&state=" + state;
  window.location.href = url;
}