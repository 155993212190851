const listAppBrowsers = [
  /\bFB[\w_]+\/(Messenger|MESSENGER)/,
  /\bFB[\w_]+\//,
  /\bTwitter/i,
  /\bLine\//i,
  /\bMicroMessenger\//i,
  /\bPuffin/i,
  /\bMiuiBrowser\//i,
  /\bInstagram/i
]

function isInAppBrowser() {
  const useragent = navigator.userAgent || navigator.vendor || (window as any)?.opera
  const inApp = listAppBrowsers.some((regex) => regex.test(useragent))
  return inApp
}

const isIOS = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent);
};

const isAndroid = () => {
  return /Android/.test(navigator.userAgent);
};

export { isIOS, isAndroid , isInAppBrowser};