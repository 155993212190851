import { SVGProps } from 'react'

function TwitchIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} className='twitch'>
      <circle cx="17.5" cy="17.5" r="17.5" fill="#9146FF" />
      <path d="M8.43209 8L7 11.8262V27.129H12.2486V30H15.1141L17.9767 27.129H22.2719L28 21.3923V8H8.43209ZM10.34 9.91187H26.0912V20.4338L22.7494 23.7821H17.5L14.6382 26.6492V23.7821H10.34V9.91187ZM15.5907 19.4786H17.5V13.7401H15.5907V19.4786ZM20.8405 19.4786H22.7494V13.7401H20.8405V19.4786Z" fill="white" />
    </svg>
  )
}

export default TwitchIcon