import { lazy, Suspense, useEffect } from 'react'
import { Route } from 'react-router-dom'
import RoutesWithNotFound from 'utilities/routes-with-not-found'
import useRouter from 'hooks/use-router'
import PlayableProvider from './context'

const Start = lazy(() => import('./views/start'))
const Game = lazy(() => import('./views/game'))
const Map = lazy(() => import('./views/map'))

function Playable() {
  const { pathname, history } = useRouter()

  useEffect(() => {
    if (pathname === '/playable') return history.push('/playable/list')
  }, [pathname])

  return (
    <PlayableProvider>
      <Suspense fallback={<>Cargando</>}>
        <RoutesWithNotFound>
          <Route path="/playable/list" component={Start} exact />
          <Route path="/playable/game/:id/start" component={Start} exact />
          <Route path="/playable/game/:id/map" component={Map} exact />
          <Route path="/playable/game/:id" component={Game} exact />
        </RoutesWithNotFound>
      </Suspense>
    </PlayableProvider>
  )
}

export default Playable