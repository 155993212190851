import React from 'react'

function InformationIcon() {
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.5 0.03125C7.50716 0.03125 6.58757 0.275391 5.74121 0.763672C4.91113 1.25195 4.25195 1.91927 3.76367 2.76562C3.27539 3.5957 3.03125 4.50716 3.03125 5.5C3.03125 6.42773 3.24284 7.29036 3.66602 8.08789C4.10547 8.88542 4.69954 9.53646 5.44824 10.041C4.52051 10.4479 3.69043 11.0094 2.95801 11.7256C2.24186 12.4417 1.68848 13.2637 1.29785 14.1914C0.890951 15.1517 0.6875 16.1608 0.6875 17.2188H2.25C2.25 16.0794 2.52669 15.0378 3.08008 14.0938C3.64974 13.1335 4.40658 12.3766 5.35059 11.8232C6.31087 11.2536 7.36068 10.9688 8.5 10.9688C9.63932 10.9688 10.681 11.2536 11.625 11.8232C12.5853 12.3766 13.3421 13.1335 13.8955 14.0938C14.4652 15.0378 14.75 16.0794 14.75 17.2188H16.3125C16.3125 16.1608 16.109 15.1517 15.7021 14.1914C15.3115 13.2637 14.75 12.4417 14.0176 11.7256C13.3014 11.0094 12.4795 10.4479 11.5518 10.041C12.3005 9.53646 12.8864 8.88542 13.3096 8.08789C13.749 7.29036 13.9688 6.42773 13.9688 5.5C13.9688 4.50716 13.7246 3.5957 13.2363 2.76562C12.748 1.91927 12.0807 1.25195 11.2344 0.763672C10.4043 0.275391 9.49284 0.03125 8.5 0.03125ZM8.5 1.59375C9.21615 1.59375 9.86719 1.77279 10.4531 2.13086C11.0553 2.47266 11.5273 2.94466 11.8691 3.54688C12.2272 4.13281 12.4062 4.78385 12.4062 5.5C12.4062 6.21615 12.2272 6.87533 11.8691 7.47754C11.5273 8.06348 11.0553 8.53548 10.4531 8.89355C9.86719 9.23535 9.21615 9.40625 8.5 9.40625C7.78385 9.40625 7.12467 9.23535 6.52246 8.89355C5.93652 8.53548 5.46452 8.06348 5.10645 7.47754C4.76465 6.87533 4.59375 6.21615 4.59375 5.5C4.59375 4.78385 4.76465 4.13281 5.10645 3.54688C5.46452 2.94466 5.93652 2.47266 6.52246 2.13086C7.12467 1.77279 7.78385 1.59375 8.5 1.59375Z" fill="currentColor" />
    </svg>
  )
}

export default InformationIcon