import styled from "styled-components";
import { Text, Title } from 'theme/theme';
import { Type } from "theme/types";

export const DivTitleStart = styled.div`
  ${({theme}: Type) => theme?.photographer?.start?.title?.div}
`

export const TitleStart = styled(Title)`
  ${({theme}: Type) => theme?.photographer?.start?.title?.h1}
`

export const DivCardStart = styled.div`
  ${({theme}: Type) => theme?.photographer?.start?.card?.container}
`

export const DescriptionCardStart = styled(Text)`
  ${({ theme }: Type) => theme?.photographer?.start?.card?.description}
`

export const ImgCardStart = styled.img`
  ${({ theme }: Type) => theme?.photographer?.start?.card?.img}
`

export const DivCardCarrouselStart = styled.div`
  ${({ theme }: Type) => theme?.photographer?.start?.cardCarrousel?.container}
`

export const ImgCardCarrouselStart = styled.img`
  ${({ theme }: Type) => theme?.photographer?.start?.cardCarrousel?.img}
`

export const DescriptionsCardCarrouselStart = styled(Text)`
  ${({ theme }: Type) => theme?.photographer?.start?.cardCarrousel?.description}
`

export const ContinueCardStart = styled(Text)`
  ${({ theme }: Type) => theme?.photographer?.start?.card?.continue}
`