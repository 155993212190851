import React, { SVGProps } from 'react'

function Right(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="17.5" cy="17.5001" r="17.5" transform="rotate(180 17.5 17.5001)" fill="#D9D9D9" />
      <path d="M25.7071 17.7072C26.0976 17.3167 26.0976 16.6835 25.7071 16.293L19.3431 9.92905C18.9526 9.53853 18.3195 9.53853 17.9289 9.92905C17.5384 10.3196 17.5384 10.9527 17.9289 11.3433L23.5858 17.0001L17.9289 22.657C17.5384 23.0475 17.5384 23.6807 17.9289 24.0712C18.3195 24.4617 18.9526 24.4617 19.3431 24.0712L25.7071 17.7072ZM10 18.0001L25 18.0001L25 16.0001L10 16.0001L10 18.0001Z" fill="white" />
    </svg>
  )
}

export default Right