import { useMemo } from 'react'
import { StepText, ProgressContainerSteps, ProgressSteps } from 'theme/theme'

interface IProps {
  current: number
  total: number
}

const Stepper = ({ current, total }: IProps) => {

  const percentageStep = useMemo(() => {
    return (current * 100) / total
  }, [current])

  return (
    <div className='flex flex-col step-container'>
      <StepText className='flex mb-3 step-text'>
        <span className='text-black step-current'>{current} <span className='text-black opacity-50 step-total'>/ {total}</span></span>
      </StepText>
      <ProgressContainerSteps className='rounded-sm step-bar'>
        <ProgressSteps className={`w-0 rounded-sm step-progress`} style={{ width: `${percentageStep}%`, transition: 'all 0.9s ease-out' }}></ProgressSteps>
      </ProgressContainerSteps>
    </div>
  )
}

export default Stepper
