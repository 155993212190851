import { useState, useEffect } from 'react'
import {
  TitlePopUp,
  TextPopUp,
  LiStyle,
  PopUpContainer,
  LinkWeb
} from '../custom.style'

const PopUpHealth = () => {


  const webPages = [
    {
      name: '• World Health Organization (WHO)',
      url: 'https://www.who.int/health-topics/alcohol#tab=tab_1'
    },
    {
      name: '• Knowledge Institute of Beer (KIB)',
      url: 'https://issuu.com/2b.com/docs/beer-and-health-booklet?e=25466010/37047325'
    },
    {
      name: '• National Institute on Alcohol Abuse and Alcoholism (NIAAA)',
      url: 'https://www.niaaa.nih.gov/alcohols-effects-health/alcohols-effects-body'
    },
    {
      name: '• NHS Choices',
      url: 'https://www.nhs.uk/live-well/alcohol-advice/alcohol-support/'
    },
    {
      name: '• International Alliance For Responsible Drinking (IARD)',
      url: 'https://www.iard.org/'
    }
  ]

  const [isOpen, setIsOpen] = useState(false);

  const disabledScroll = () => {
    const html = document.querySelector("html");
    html?.classList.add("overflow-hidden");
  }

  const enabledScroll = () => {
    const html = document.querySelector("html");
    html?.classList.remove("overflow-hidden");
  }

  const handleOpen = () => {
    if (window.location.href.includes("utm_medium=PopUp") && window.location.href.includes("utm_content=QR_AvisoAlcohol") && window.location.pathname.split('/')[2] !== 'agegate') {
      setTimeout(() => {
        disabledScroll()
        setIsOpen(true)
      }, 3000)
    }
  }

  const handleClose = () => {
    enabledScroll()
    setIsOpen(false)
  }

  useEffect(() => {
    handleOpen()
  }, [])

  return (
    <PopUpContainer className={`z-50 fixed w-screen h-screen bg-red-500 flex flex-col justify-between items-center top-0 p-5 ${isOpen ? 'flex' : 'hidden'}`}>
      <div className='w-full md:w-1/3 flex flex-col justify-between items-center h-screen'>

        <div className="w-full flex justify-end">
          <button onClick={handleClose}> 
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.9463 11.707L24.124 1.5293C24.2998 1.35352 24.3877 1.14258 24.3877 0.896484C24.3877 0.650391 24.2998 0.439453 24.124 0.263672C23.9482 0.0878906 23.7314 0 23.4736 0C23.2275 0 23.0166 0.0878906 22.8408 0.263672L12.6807 10.4238L2.52051 0.263672C2.34473 0.0878906 2.12793 0 1.87012 0C1.62402 0 1.41309 0.0878906 1.2373 0.263672C1.06152 0.439453 0.973633 0.650391 0.973633 0.896484C0.973633 1.14258 1.06152 1.35352 1.2373 1.5293L11.415 11.707L1.2373 21.8672C1.06152 22.043 0.973633 22.2539 0.973633 22.5C0.973633 22.7461 1.06152 22.957 1.2373 23.1328C1.33105 23.2266 1.43066 23.2969 1.53613 23.3438C1.65332 23.3789 1.77051 23.3965 1.8877 23.3965C1.99316 23.3965 2.09863 23.3789 2.2041 23.3438C2.32129 23.2969 2.42676 23.2266 2.52051 23.1328L12.6807 12.9727L22.8408 23.1328C22.9346 23.2266 23.0342 23.2969 23.1396 23.3438C23.2568 23.3789 23.3682 23.3965 23.4736 23.3965C23.5908 23.3965 23.7021 23.3789 23.8076 23.3438C23.9248 23.2969 24.0303 23.2266 24.124 23.1328C24.2998 22.957 24.3877 22.7461 24.3877 22.5C24.3877 22.2539 24.2998 22.043 24.124 21.8672L13.9463 11.707Z" fill="white"/>
            </svg>
          </button>
        </div>

        <div>
          <TitlePopUp className='pb-3'>Alcohol y salud</TitlePopUp>
          <TextPopUp>
            Cuando se consumen con moderación, la cerveza y otras bebidas alcohólicas puede formar parte de un estilo de vida equilibrado. Somos orgullosos cerveceros y promovemos el consumo responsable y la reducción del uso nocivo del alcohol. Creemos que es importante que los consumidores estén bien informados sobre el alcohol, nuestros productos y cómo pueden disfrutar de ellos de forma responsable. Es algo que destacamos en nuestros envases, en nuestra publicidad y en nuestras promociones, y a través de nuestras asociaciones. Ofrecemos una amplia gama de cervezas con diferentes grados de alcohol por volumen, que incluyen cada vez más cervezas sin o con bajo contenido de alcohol.
            El consumo de alcohol es una elección individual. Deben tenerse en cuenta los riesgos y beneficios personales antes de consumirlo. Algunas pruebas han demostrado que existe una relación entre el consumo de alcohol y ciertos problemas de salud. El alcohol afecta a las personas de diferentes maneras. Para algunas, el consumo moderado de alcohol también puede aumentar ciertos riesgos para la salud, como problemas cardiovasculares, diabetes y determinados tipos de cáncer. Si tienes más preguntas o dudas, ponte en contacto con tu médico.
            El consumo excesivo de alcohol puede tener consecuencias peligrosas a largo plazo, como dependencia física o adicción al alcohol, y, por lo tanto, debe siempre evitarse. Hay personas que nunca deberían beber. Por ejemplo, las personas que están por debajo de la edad legal, embarazadas, que van conducir o utilizar maquinaria, o que no pueden controlar su forma de beber.
            Muchos gobiernos publican pautas de consumo recomendadas que los consumidores pueden consultar. Las autoridades de salud pública también son una buena fuente de información..
          </TextPopUp>
        </div>

        <div>
          <TextPopUp className='py-5'>Para obtener más información sobre el alcohol y la salud, recomendamos consultar las siguientes fuentes:</TextPopUp>

          <ul>
            {webPages.map((webPage, index) => (
              <LiStyle key={index}>
                <a href={webPage.url} target='_blank' rel='noreferrer'>{webPage.name}</a>
              </LiStyle>
            ))}
          </ul>
        </div>

        <LinkWeb href="https://www.theheinekencompany.com/nl/node/3260" target='_blank' rel='noreferrer' className='py-5'>consulta la web</LinkWeb>
      </div>
    </PopUpContainer>
  )
}

export default PopUpHealth