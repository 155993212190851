import { SubTitle, Text, Title } from "theme/theme";
import styled from 'styled-components';
import { Type } from './../../../theme/types/generic.interface';

export const TitleMarketPlace = styled(Title)`
  font-size: 2.25rem;
  ${({ theme }: Type) => theme?.marketplaces?.text?.title}
`

export const SubTitleMarketPlace = styled(SubTitle)`
  font-size: 1rem;
  ${({ theme }: Type) => theme?.marketplaces?.text?.subtitle}
`

export const TextMarketPlace = styled(Text)`
  ${({ theme }: Type) => theme?.marketplaces?.text?.text}
`

export const ScopeMarketPlace = styled(Text)`
  ${({ theme }: Type) => theme?.marketplaces?.text?.scopeText}
`