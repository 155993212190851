import { createContext, Dispatch, FunctionComponent, useReducer } from 'react'
import MemoramaReducer, { MemoramaAction, MemoramaState } from './memorama-reducer'

export interface MemoramaContextProps {
  state: MemoramaState
  dispatch: Dispatch<MemoramaAction>
}

export const MemoramaContext = createContext<MemoramaContextProps>({} as MemoramaContextProps)

export const MemoramaProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(MemoramaReducer, {})

  return <MemoramaContext.Provider value={{ state, dispatch }}>{children}</MemoramaContext.Provider>
}