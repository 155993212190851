import { lazy, Suspense } from "react"
import { Redirect, Route } from "react-router-dom"
import RoutesWithNotFound from "utilities/routes-with-not-found"
import { RafflesProvider } from "./context"
const Error = lazy(() => import("./views/error"))
const Prizes = lazy(() => import("./views/prizes"))
const Description = lazy(() => import("./views/description"))
const Winners = lazy(() => import("./views/winners"))
const Welcome = lazy(() => import("./views/welcome"))

const Raffles = () => {
  return (
    <Suspense fallback={<>Cargando</>}>
      <RafflesProvider>
        <RoutesWithNotFound>
          <Route path="/raffles" component={() => <Redirect to="/raffles/welcome" />} exact />
          <Route path="/raffles/welcome" component={Welcome} exact />
          <Route path="/raffles/prizes" component={Prizes} exact />
          <Route path="/raffles/prize/description/:id" component={Description} exact />
          <Route path="/raffles/winners/:id" component={Winners} exact />
          <Route path="/raffles/error" component={Error} exact />
        </RoutesWithNotFound>
      </RafflesProvider>
    </Suspense>
  )
}

export default Raffles