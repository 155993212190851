import React from "react"

class RoutesConfigAlbum {

  public path = '/album'
  public component: React.LazyExoticComponent<() => JSX.Element>
  public exact = true
  public isActiveWhenAlbumIsComplete = false
  public routeToRedirect: string 

  constructor(path: string, component: React.LazyExoticComponent<() => JSX.Element>, isActiveWhenAlbumIsComplete: boolean = false, redirect = '/album/:id/ranking', exact: boolean = true) {
    this.path = path
    this.component = component
    this.isActiveWhenAlbumIsComplete = isActiveWhenAlbumIsComplete
    this.routeToRedirect = redirect
    this.exact = exact
  }

}

export default RoutesConfigAlbum