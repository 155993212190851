export interface RaffleDto {
  id: number
  title: string
  imageUrl: string
  secondaryImageUrl: string
  sorteoId: number
  description: string
}

export interface Raffle {
  productId: number
  title: string
  src: string
  secondaryImageUrl: string
  sortId: number
  description: string
}

export interface SurveyDto {
  questionId: number
  question: string
  options: [
    {
      optionId: number
      option: string
      imageUrl: string
    }
  ]
  type: {
    id: number
    name: string
  }
}

export interface Survey {
  id: number
  question: string
  options: Options[]
  type: string
}

export interface Options {
  id: number
  option: string
  src: string
}

export interface WinnerDto {
  ganador: string
  sorteoDate: Date
  sorteoId: number
  productId: number
  title: string
}

export interface Winner {
  name: string
  date: Date
  id: number
  productId: number
}

export const RaffleAdapter = (raffle: RaffleDto): Raffle => ({
  productId: raffle?.id,
  title: raffle?.title,
  src: raffle?.imageUrl,
  secondaryImageUrl: raffle?.secondaryImageUrl,
  sortId: raffle?.sorteoId,
  description: raffle?.description
})

export const SurveyAdapter = (survey: SurveyDto): Survey => ({
  id: survey?.questionId,
  question: survey?.question,
  options: survey?.options?.map((option) => ({
    id: option?.optionId,
    option: option?.option,
    src: option?.imageUrl
  })),
  type: survey?.type?.name
})
