import Button from 'components/button'
import useMainClass from 'hooks/use-main-cass'
import textServices from 'services/text-services'
import PopUp from '.'

function StartPopUp({ handleMenu }: { handleMenu: () => void }) {
  useMainClass('start__popup')

  return (
    <PopUp handleMenu={handleMenu}>
      {textServices.findByText('popUps/start/html')}
      <Button onClick={handleMenu}>{textServices.findByText('popUps/start/button', { es: 'ENTENDIDO', en: 'OK' })}</Button>
    </PopUp>
  )
}

export default StartPopUp