import { ErrorMessage } from '@hookform/error-message'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'components/button'
import { ErrorInput } from 'theme/theme'
import OtpInput from 'components/otp-input'
import { dlTrackEvent } from 'components/dataLayer/dataLayer';
import { useForm } from "react-hook-form";
import formServices from 'services/form-builder-services'
import useRouter from 'hooks/use-router';
import textServices from 'services/text-services';
import useMainClass from 'hooks/use-main-cass';
import { LoaderContext } from 'contexts/loader-context'
import { useContext, useEffect } from 'react'
import { SubTitleSignUpOtp, TextSignUpOtp, ColorDiv, BackBtn } from '../styles/custom.style';

interface IProps {
  email: string;
  handleConfirm: (data: any) => void;
}

function Otp({ email = '', handleConfirm }: IProps) {

  const { setFooter } = useContext(LoaderContext)

  const { handleSubmit, formState: { errors }, setValue } = useForm({ resolver: yupResolver(formServices.formGenerateSchema(["otp"])) })
  const { history } = useRouter()
  useMainClass('sign-up__welcome__otp')

  const onSubmit = (data) => {
    dlTrackEvent('form_send', {
      element: 'verificar',
      form_name: 'verifica tu email',
      section: 'sign up'
    })
    handleConfirm(data)
  }

  useEffect(() => {
    setFooter(true)
  }, [])

  return (
    <section className="flex flex-col flex-1 p-7 md:pt-10">
      <form className="flex h-full justify-between flex-col gap-4" onSubmit={handleSubmit(onSubmit)} noValidate>
        <SubTitleSignUpOtp className="text-center text-lg font-medium saira uppercase sign-up__welcome__otp__subtitle">{textServices.findByText('signup/welcome/otp/title', { es: 'Ingresa el código de 6 dígitos que recibiste para verificar tu email.', en: 'Enter the 6-digit code you received to verify your email address.' })}</SubTitleSignUpOtp>
        <div className="flex-1 flex flex-col gap-4">
          <ColorDiv className="gap-2 flex flex-col mb-3 input-container">
            <OtpInput setValue={setValue} />
            <ErrorInput className='input-error'>
              <ErrorMessage errors={errors} name="otp" />
            </ErrorInput>
          </ColorDiv>
          <div className="flex-1 flex justify-center">
            <div className="h-full flex flex-col items-center text-center w-4/5">
              <TextSignUpOtp className="my-4 sign-up__welcome__otp__description">
                {textServices.findByText('signup/welcome/otp/sendpassword', { es: <>Una contraseña de un solo uso se envió a <b className='sign-up__welcome__otp__email'>{email}</b></>, en: <>A one-time password was sent to <b className='sign-up__welcome__otp__email'>{email}</b></> }, { EMAIL: email })}
              </TextSignUpOtp>
            </div>
          </div>
        </div>
        <Button>
          <p className="uppercase">{textServices.findByText('signup/welcome/otp/button', { es: 'Verificar', en: 'Verify' })}</p>
        </Button>
        <button type="button" className="cursor-pointer">
          <BackBtn
            className={`din text-center mt-3 hover:text-red-100 uppercase underline button-back`}
            style={{ fontSize: 10 }}
            onClick={() => {
              dlTrackEvent('user_interaction', {
                action: "click",
                element: 'volver',
                section: "sign up"
              })
              history.goBack()
            }}
          >
            {textServices.findByText('backButton', { es: 'VOLVER', en: 'BACK TO' })}
          </BackBtn>
        </button>
      </form>
    </section>
  )
}

export default Otp