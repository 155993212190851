import { SVGProps } from 'react'

function Clock(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={26} height={25} viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M12.8213 6.3121V13.1232L18.456 16.4668" stroke="currentColor" />
      <circle cx="12.8214" cy="12.2915" r="11.3657" stroke="currentColor" />
    </svg>
  )
}

export default Clock
