/* eslint-disable @typescript-eslint/no-unused-vars */
import { TRIVIA_INTERESTS } from 'constants/api'
import useAxios from 'axios-hooks'
import { MouseEventHandler, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Button from 'components/button'
import { useSet } from 'react-use'
import { TextPref } from 'views/profile/text.style'
import { useSession } from '../../contexts/session-context'
import { useTrivia } from '../../contexts/trivia-context'
import { LoaderContext } from '../../contexts/loader-context'
import { ButtonPref } from '../../theme/profile'

const PrefPicker = () => {
  const [activeItems, activeItemsSet] = useSet(new Set<number>([]))
  const [, { setInterests }] = useTrivia()
  const [{ data: session, user }] = useSession()
  const location = useLocation()
  const [state] = useTrivia()
  const { load, setLoad } = useContext(LoaderContext)
  const [{ error, loading, data }] = useAxios<{ id: number; name: string }[]>({
    url: TRIVIA_INTERESTS,
    headers: { Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}` }
  })

  const name =
    user?.nickName ||
    (user?.firstName && user?.firstName.length > 20 ? user?.firstName.substring(0, 17) + '...' : user?.firstName)

  useEffect(() => {
    user?.interests.forEach(({ id }) => {
      activeItemsSet.add(id)
    })
  }, [user])

  useEffect(() => {
    setLoad(loading)
    return () => {
      setLoad(false)
    }
  }, [loading])

  const isProfile = location.pathname.endsWith('/profile/preferences')

  if (error) {
    return <pre>error.message</pre>
  }

  const handleOnClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.persist()

    const { currentTarget } = event

    currentTarget.toggleAttribute('data-selected')

    const id = parseInt(currentTarget.getAttribute('data-interest-id') as string, 10)

    activeItemsSet.toggle(id)
  }

  const sendInterest = async () => {
    gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'INTERESES - Guardar mis intereses', value: '10' })
    setLoad(true)
    await setInterests(Array.from(activeItems))
    setLoad(false)
  }

  if (load) {
    return <></>
  }

  const enableButton = activeItems.size < 3

  return (
    <div className={`flex-1 flex flex-col ${isProfile ? ' py-6 px-5 ' : ''}`}>
      <div className="flex gap-4 flex-wrap items-start">
        {!!data &&
          data.map((category) => {
            return (
              <ButtonPref
                handleOnClick={handleOnClick}
                isProfile={isProfile}
                state={state}
                category={category}
                datainterestid={category?.id}
                key={`interest-${category?.id}`}
              >
                <TextPref>{category?.name}</TextPref>
              </ButtonPref>
            )
          })}
      </div>
      <div className="flex-1 mt-4"></div>
      <Button disabled={enableButton} onClick={sendInterest}>
        <p>{isProfile ? 'GUARDAR' : 'CONTINUAR'}</p>
      </Button>
    </div>
  )
}

export default PrefPicker
