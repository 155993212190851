import styled from "styled-components";
import { Title } from "theme/theme";
import { Type } from "theme/types";

export const TitleTriviaQr = styled(Title)`
  ${({theme}: Type) => theme?.trivias?.triviaQr?.title}
`;

export const DescriptionTriviaQr = styled.div`
  ${({ theme }: Type) => theme?.trivias?.triviaQr?.description}
`;

export const ImgTriviaQr = styled.img`
  ${({ theme }: Type) => theme?.trivias?.triviaQr?.img}
`;

export const ContainerTriviaQr = styled.div`
  ${({ theme }: Type) => theme?.trivias?.triviaQr?.container}
`