import { Type } from 'theme/types';
import styled from "styled-components";

export const Div = styled.div`
  ${({theme}: Type) => theme?.otp?.div}
`; 

export const PTime = styled.div`
  ${({theme}: Type) => theme?.otp?.pTime}
`;

export const TitleOtp = styled.p`
  ${({theme}: Type) => theme?.otp?.titleOtp}
`