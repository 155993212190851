import { dlTrackEvent } from 'components/dataLayer/dataLayer'
import { useEffect, useRef } from 'react'
import { DivAgegate, InputAgegate } from 'theme/theme'

interface IProps {
  setValue?: (name: string, value: string) => void
  setState?: (value: any) => void
  defaultValue?: {
    day: number | string
    month: number | string
    year: number | string
  }
  tagging?: {
    formName: string
    section: string
  }
}

function AgegateInput({ setValue, setState, defaultValue, tagging }: IProps) {
  const day = useRef<HTMLInputElement>(null)
  const month = useRef<HTMLInputElement>(null)
  const year = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (defaultValue) {
      const birthdayDate = new Date(+year.current?.value, +month.current?.value - 1, +day.current?.value)
      setState(birthdayDate)
      setValue('birthday', `${day.current?.value}/${month.current?.value}/${year.current?.value}`)
    }
  }, [defaultValue])

  const handleBlurYear = () => {
    const completed = +year.current?.value > 0 && +month.current?.value - 1 > 0 && +day.current?.value > 0;
    dlTrackEvent('form_interaction', {
      form_field: 'fecha nacimiento',
      form_action: completed ? 'complete' : 'empty',
      form_name: tagging?.formName,
      section: tagging?.section
    })
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    if (name === 'day') {
      if (value.trim() === '') {
        day.current.value = ''
      } else if (value.length === 2) {
        if (+value > 31) {
          day.current.value = '31'
        } else {
          month?.current?.focus()
        }
      }
    } else if (name === 'month') {
      if (value.trim() === '') {
        month.current.value = ''
      } else if (value.length === 2) {
        if (+value > 12) {
          month.current.value = '12'
        } else {
          year?.current?.focus()
        }
      }
    } else if (name === 'year') {
      if (value.trim() === '') {
        year.current.value = ''
      } else if (value.trim().length === 4) {
        if (+value < 1900) {
          year.current.value = '1900'
        }
      }
    }
    const birthdayDate = new Date(+year.current?.value, +month.current?.value - 1, +day.current?.value)
    setState(birthdayDate)
    setValue('birthday', `${day.current?.value}/${month.current?.value}/${year.current?.value}`)
  }

  return (
    <DivAgegate className="h-16 p-4 w-full uppercase items-center justify-center gap-2 saira flex input-agegate-container">
      <div
        className="flex-grow flex justify-end items-center cursor-pointer"
        onClick={() => {
          day?.current?.focus()
          gtag('event', 'Click', {
            event_category: GTM_EVENT_CATEGORY,
            event_label: 'AGEGATE - Ingresar fecha de nacimiento',
            value: '10'
          })
        }}
      >
        <InputAgegate
          type="text"
          name="day"
          ref={day}
          className="w-8 flex items-center justify-center text-center font-bold input-agegate"
          placeholder="DD"
          maxLength={2}
          pattern="\d*"
          defaultValue={defaultValue?.day}
          onChange={handleChange}
          onFocus={(event) => event.target.select()}
        />
      </div>
      <span>/</span>
      <InputAgegate
        type="text"
        name="month"
        ref={month}
        className="w-10 flex items-center justify-center text-center cursor-text font-bold input-agegate"
        placeholder="MM"
        maxLength={2}
        pattern="\d*"
        onChange={handleChange}
        onFocus={(event) => event.target.select()}
        defaultValue={defaultValue?.month}
      />
      <span>/</span>
      <div
        className="flex-grow flex justify-start items-center cursor-pointer"
        onClick={() => {
          year?.current?.focus()
          gtag('event', 'Click', {
            event_category: GTM_EVENT_CATEGORY,
            event_label: 'AGEGATE - Ingresar fecha de nacimiento',
            value: '10'
          })
        }}
      >
        <InputAgegate
          type="text"
          name="year"
          ref={year}
          className="w-12 flex items-center justify-center text-center font-bold input-agegate"
          placeholder="YYYY"
          maxLength={4}
          pattern="\d*"
          defaultValue={defaultValue?.year}
          onFocus={(event) => event.target.select()}
          onChange={handleChange}
          onBlur={handleBlurYear}
        />
      </div>
    </DivAgegate>
  )
}

export default AgegateInput
