import axios from "axios"
import { LoaderContext } from "contexts/loader-context"
import { useOnline } from "contexts/online-context"
import { useSession } from "contexts/session-context"
import { useContext, useEffect, useState } from "react"

interface IProps {
  url?: string,
  offline: string
}

export default function useOfflineData <T>({ offline, url }: IProps){
  const isOnline = useOnline()
  const [data, setData] = useState<T>()
  const [error, setError] = useState<number>()
  const [{ data: session }] = useSession()
  const { setLoad } = useContext(LoaderContext)

  const handlerFetcher = async () => {
    const { data } = await axios.get(`${url}`, { headers: { Authorization: `Bearer ${(session as any)?._tokenResponse?.idToken}`, 'Ph-Api-Id': PH_API_ID ?? 1 } })
    setData(data)
  }

  const handlerOffline = async () => {
    const { default: json } = await import(`build/offline/${offline}.json`)
    setData(json[process.env.NODE_ENV])
  }

  const handler = async () => {
    setLoad(true)
    try {
      if (isOnline) {
        handlerFetcher()
      } else {
        handlerOffline()
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoad(false)
    }
  }

  useEffect(() => {
    handler()
  }, [])
  

  return {data, error, setError}
}