import { GET_RAFFLES } from 'constants/api'
import useAxios, { RefetchOptions } from 'axios-hooks'
import { useSession } from 'contexts/session-context'
import { createContext, Dispatch, FunctionComponent, useContext, useEffect, useReducer } from 'react'
import { LoaderContext } from 'contexts/loader-context'
import { AxiosPromise, AxiosRequestConfig } from 'axios'
import useRouter from 'hooks/use-router'
import { Raffle, RaffleAdapter, RaffleDto } from '../types'
import rafflesReducer, { RafflesAction, RafflesState } from './raffles-reducer'

export interface RafflesContextProps {
  state: RafflesState
  dispatch: Dispatch<RafflesAction>
  refetch: (config?: AxiosRequestConfig, options?: RefetchOptions) => AxiosPromise<RaffleDto[]>
}

const RafflesContext = createContext<RafflesContextProps>({} as RafflesContextProps)

export const RafflesProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(rafflesReducer, {})
  const { setLoad } = useContext(LoaderContext)
  const [{ data: session }] = useSession()
  const [{ data = [], loading }, refetch] = useAxios<RaffleDto[]>({
    url: GET_RAFFLES,
    headers: { Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}` }
  })

  useEffect(() => {
    setLoad(loading)
    return () => {
      setLoad(false)
    }
  }, [loading])

  useEffect(() => {
    if (data.length > 0) {
      dispatch({ type: 'SET_RAFFLES', payload: data.map((raffle) => RaffleAdapter(raffle)) })
    }
  }, [data])

  return <RafflesContext.Provider value={{ state, dispatch, refetch }}>{children}</RafflesContext.Provider>
}

export const useRaffles = () => {
  const { state, dispatch, refetch } = useContext(RafflesContext)
  const { push } = useRouter()

  const setRaffles = (raffles: Raffle[]) => dispatch({ type: 'SET_RAFFLES', payload: raffles })
  const refetchRaffles = (config?: AxiosRequestConfig) => refetch(config)
  const setSelectRaffle = (raffle?: Raffle) => dispatch({ type: 'SET_SELECT_RAFFLE', payload: raffle })
  const setCongrats = (congrats?: boolean) => dispatch({ type: 'SET_CONGRATS', payload: congrats })
  const setError = (error: number) => {
    dispatch({ type: 'SET_ERROR', payload: error })
    if (error) return push('/raffles/error')
  }
  const actions = { setRaffles, refetchRaffles, setSelectRaffle, setError, setCongrats }

  const returnValue: [typeof state, typeof actions] = [state, actions]
  return returnValue
}