import { useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import useMainClass from "hooks/use-main-cass";
import Button from "components/button";
import WifiForm from "./WifiForm";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function WifiDataInput() {
  useMainClass('wifi')
  const params: any = useParams();
  const query = useQuery();
  const [requestSend, setRequestSend] = useState(false);
  const [termsOfUse, setTermsOfUse] = useState(false)
  const [subscribe, setSubscribe] = useState(false)

  const paramContinueUrl = params?.type === 'Linktek' ? "continue_url" : 'user_continue_url'
  const paramBaseGrantUrl = params?.type === 'Linktek' ? "login_url" : 'base_grant_url'
  const paramNodeId = params?.type === 'Linktek' ? "client_mac" : 'node_id'


  const userContinueUrl = query.get(paramContinueUrl) || '';
  const nodeId = query.get(paramNodeId);
  const status = query.get("status");
  const baseGrantUrl = query.get(paramBaseGrantUrl);
  const devTest = query.get("dev_test");


  const nameRef = useRef<any>(null);
  const phoneRef = useRef<any>(null);
  const emailRef = useRef<any>(null);

  const actionForm = params?.type === 'Linktek' ? baseGrantUrl : `${baseGrantUrl}?continue_url=${userContinueUrl}`

  const onSubmit = async (e: any) => {
    e.preventDefault();

    try {
      if (!requestSend) {

        const obj = {
          "node_id": nodeId,
          "email": emailRef.current.value,
          "phone": phoneRef.current.value,
          "version": "1",
          "eventName": `${THEME}${dayjs().year()}`,
          "terms": termsOfUse,
          "news": subscribe
        }

        await axios.put(`https://${API_BASE_USER_UPDATE}/promohub/api/v1/onsite/splashsceenuser/save?apiToken=${API_TOKEN}`, obj, {
          headers: {
            'PH-Api-ID': PH_API_ID,
          }
        })

        e.target.submit();
        setRequestSend(true);

      }
    } catch (e) {
      console.error("Error:", e);
    }
  };

  const resetForm = () => {
    nameRef.current.value = ""
    emailRef.current.value = ""
    phoneRef.current.value = ""
  }

  if (!!status && status !== '0') {
    return (
      <div className={`flex-1 w-full flex flex-col justify-center gap-4 pt-4 px-4 pb-9 items-center wifi__error`}>
        <div className="m-auto flex flex-col gap-4 flex-1 justify-center ">
          <div className="w-full flex items-center justify-center">
            <img src={`${AZURE_BASE_URL}/wifi-error.webp`} alt="Error" className="max-w-[300px]" />
          </div>
        </div>
        <Button onClick={resetForm}>
          Reintentar
        </Button>
      </div>
    )
  }

  return (
    <form onSubmit={onSubmit} action={`${actionForm}`} method={devTest ? "get" : "post"} className={`flex-1 w-full flex justify-center wifi__data`}>
      <WifiForm nameRef={nameRef} emailRef={emailRef} phoneRef={phoneRef} termsOfUse={{ value: termsOfUse, setValue: setTermsOfUse }} subscribe={{ value: subscribe, setValue: setSubscribe }} />
    </form>
  );
};

export default WifiDataInput;
