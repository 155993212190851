import { Type } from 'theme/types';
import styled from "styled-components";
import { Title, SubTitle, Text } from "theme/theme";

export const TitleSignUpWelcome = styled(Title)`
  ${({theme}: Type) => theme?.signup?.welcome?.text?.title}
`;

export const ColorDiv = styled.div`
  ${({theme}: Type) => theme?.signup?.otp?.colorDiv}
`;

export const SubTitleSignUpWelcome = styled(SubTitle)`
  ${({theme}: Type) => theme?.signup?.welcome?.text?.subtitle}
`

export const TextSignUpWelcome = styled(Text)`
  ${({theme}: Type) => theme?.signup?.welcome?.text?.text}
`

export const TextSecondSignUpWelcome = styled(TextSignUpWelcome)`
  ${({theme}: Type) => theme?.signup?.welcome?.text?.textSecondary}
`

export const SubTitleSignUpOtp = styled(SubTitle)`
  ${({theme}: Type) => theme?.signup?.otp?.text?.subtitle}
`

export const TextSignUpOtp = styled(Text)`
  ${({theme}: Type) => theme?.signup?.otp?.text?.text}
`
export const BackBtn = styled.p`
  ${({theme}: Type) => theme?.signup?.welcome?.backBtn}
`