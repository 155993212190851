import { GET_PHOTOGRAPHER_ACTIVE, POST_PHOTOGRAPHER, GET_PHOTOGRAPHER_WINNERS, POST_PHOTOGRAPHER_URL, POST_PHOTOGRAPHER_WITH_TEXT, ALBUM_VALIDATE_TEXT } from 'constants/api'
import axios from 'axios'

export const PhotographerServices = {
  upload(id: number | string, data: FormData, session: any) {
    return axios.post(POST_PHOTOGRAPHER.replace('{ID}', `${id}`), data, {
      headers: { Authorization: `Bearer ${session?._tokenResponse?.idToken}`, 'Ph-Api-Id': PH_API_ID??1 }
    })
  },

  uploadImageUrl(id: number | string, data: any, session: any) {
    return axios.post(POST_PHOTOGRAPHER_URL.replace('{ID}', `${id}`), data, {
      headers: { Authorization: `Bearer ${session?._tokenResponse?.idToken}`, 'Ph-Api-Id': PH_API_ID??1 }
    })
  },

  uploadWithText(id: number | string, data: { name: string, body: string }, session: any) { 
    return axios.post(POST_PHOTOGRAPHER_WITH_TEXT.replace('{ID}', `${id}`), data, {
      headers: { Authorization: `Bearer ${session?._tokenResponse?.idToken}`, 'Ph-Api-Id': PH_API_ID??1 }
    })
  },

  validateWithText(id: number | string, data: { name: string, body: string }, session: any) { 
    return axios.post(ALBUM_VALIDATE_TEXT.replace('{ID}', `${id}`), data, {
      headers: { Authorization: `Bearer ${session?._tokenResponse?.idToken}`, 'Ph-Api-Id': PH_API_ID??1 }
    })
  },

  get(session: any) {
    return axios.get(GET_PHOTOGRAPHER_ACTIVE, {
      headers: { Authorization: `Bearer ${session?._tokenResponse?.idToken}`, 'Ph-Api-Id': PH_API_ID??1 }
    })
  },

  winners(session: any) {
    return axios.get(GET_PHOTOGRAPHER_WINNERS, {
      headers: { Authorization: `Bearer ${session?._tokenResponse?.idToken}`, 'Ph-Api-Id': PH_API_ID??1 }
    })
  }
}
