import { GET_PRIZES, GET_PRIZE_WARE_HOUSE } from 'constants/api'
import useAxios, { RefetchOptions } from 'axios-hooks'
import { useSession } from 'contexts/session-context'
import { createContext, Dispatch, FunctionComponent, useContext, useEffect, useReducer } from 'react'
import { LoaderContext } from 'contexts/loader-context'
import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'
import useRouter from 'hooks/use-router'
import { PrizeResponse, Prize, WareHouseType } from '../types'
import prizesReducer, { PrizesAction, PrizesState } from './prizes-reducer'
export interface PrizesContextProps {
  state: PrizesState
  dispatch: Dispatch<PrizesAction>
  refetch: (config?: AxiosRequestConfig, options?: RefetchOptions) => AxiosPromise<PrizeResponse>
}

const PrizesContext = createContext<PrizesContextProps>({} as PrizesContextProps)

export const PrizesProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(prizesReducer, {})
  const { setLoad } = useContext(LoaderContext)
  const [{ data: session }] = useSession()
  const { push } = useRouter()
  const [{ data = {} as PrizeResponse, loading, error }, refetch] = useAxios<PrizeResponse>(
    {
      url: GET_PRIZES,
      headers: { Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`, 'Ph-Api-Id': PH_API_ID ?? 1 }
    },
    { useCache: false }
  )
  useEffect(() => {
    setLoad(loading)
    return () => {
      setLoad(false)
    }
  }, [loading])

  useEffect(() => {
    if (data?.prize?.length > 0) {
      dispatch({
        type: 'SET_PRIZES', payload: data?.prize.map(item => {
          if (item?.status === 5 || (item.status === 10 && !item.imageUrl)) {
            item.imageUrl = `${AZURE_BASE_URL}/surprise.png`
          }
          return item
        })
      })
      dispatch({ type: 'SET_FLOW_TYPE_ID', payload: data?.configuration?.prizeFlowTypeId })
    }
  }, [data])

  useEffect(() => {
    if (error) {
      dispatch({ type: 'SET_ERROR', payload: error.response?.data?.code })
      return push('/prizes/error')
    }
  }, [error])

  return <PrizesContext.Provider value={{ state, dispatch, refetch }}>{children}</PrizesContext.Provider>
}

export const usePrizes = () => {
  const { state, dispatch, refetch } = useContext(PrizesContext)
  const { push } = useRouter()
  const [{ data: session }] = useSession()

  const setPrizes = (prizes: Prize[]) => dispatch({ type: 'SET_PRIZES', payload: prizes })
  const refetchPrizes = (config?: AxiosRequestConfig) => refetch(config)
  const setSelectPrize = (prize?: Prize) => dispatch({ type: 'SET_SELECT_PRIZE', payload: prize })
  const setSelectWareHouse = (selectWarehouse?: WareHouseType) => dispatch({ type: 'SET_SELECT_WAREHOUSE', payload: selectWarehouse })
  const setCongrats = (congrats?: boolean) => dispatch({ type: 'SET_CONGRATS', payload: congrats })
  const setFlowType = (flowTypeId?: number) => dispatch({ type: 'SET_FLOW_TYPE_ID', payload: flowTypeId })
  const setError = (error: number) => {
    dispatch({ type: 'SET_ERROR', payload: error })
    if (error) return push('/prizes/error')
  }
  
  const getWareHouse = async (id: number) => {
    const { data } = await axios.get(`${GET_PRIZE_WARE_HOUSE.replace(':id', `${id}`)}`, {
      headers: { Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`, 'Ph-Api-Id': PH_API_ID ?? 1 }
    })
    dispatch({ type: 'SET_SELECT_PRIZE_WAREHOUSE', payload: data })
  }

  const setPrizeExchange = (prize: Prize) => dispatch({ type: 'SET_PRIZE_EXCHANGE', payload: prize })

  const actions = { setPrizes, refetchPrizes, setSelectPrize, setError, setCongrats, setFlowType, getWareHouse, setSelectWareHouse, setPrizeExchange}

  const returnValue: [typeof state, typeof actions] = [state, actions]
  return returnValue
}
