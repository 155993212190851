import { SVGProps } from 'react'

function Raffles(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="34" height="37" viewBox="0 0 34 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="17.6975" cy="16.1001" r="14.9651" stroke="currentColor" />
      <path d="M3.8606 35.6352L15.9386 17.8735C16.7597 16.6659 18.5561 16.7169 19.3074 17.9691L29.9071 35.6352" stroke="currentColor" />
      <path d="M7.11636 35.635H0.604736" stroke="currentColor" />
      <path d="M33.163 35.635H26.6514" stroke="currentColor" />
      <circle cx="10.3723" cy="8.77464" r="1.62791" fill="currentColor" />
      <circle cx="8.74412" cy="18.542" r="1.62791" fill="currentColor" />
      <circle cx="12" cy="13.6582" r="1.62791" fill="currentColor" />
      <circle r="1.62791" transform="matrix(-1 0 0 1 25.0235 8.77464)" fill="currentColor" />
      <circle r="1.62791" transform="matrix(-1 0 0 1 16.8838 10.4026)" fill="currentColor" />
      <circle r="1.62791" transform="matrix(-1 0 0 1 28.2793 16.9143)" fill="currentColor" />
      <circle r="1.62791" transform="matrix(-1 0 0 1 23.3955 15.2859)" fill="currentColor" />
      <circle r="1.62791" transform="matrix(-1 0 0 1 16.8838 25.0539)" fill="currentColor" />
    </svg>

  )
}

export default Raffles
