import dayjs from 'dayjs'
import textServices from 'services/text-services'

export const calendar = {
  day: textServices.findByText('agegate/day', { es: 'DIA', en: 'DAY' }),
  month: textServices.findByText('agegate/month', { es: 'MES', en: 'MONTH' }),
  year: textServices.findByText('agegate/year', { es: 'AÑO', en: 'YEAR' })
}

export function calculateAge(birthday: Date) {
  const age = dayjs().diff(birthday, 'year', true)

  return Math.floor(age)
}