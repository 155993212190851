import { GoogleMap, Marker } from '@react-google-maps/api';
import LoadingScreen from 'components/loading-screen'
import { useEffect, useState } from 'react';
import { WareHouseType } from 'views/prizes/types';

function calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
  const earthRadius = 6371; // Radio de la Tierra en kilómetros

  // Convertir las coordenadas de grados a radianes
  const lat1Rad = (lat1 * Math.PI) / 180;
  const lon1Rad = (lon1 * Math.PI) / 180;
  const lat2Rad = (lat2 * Math.PI) / 180;
  const lon2Rad = (lon2 * Math.PI) / 180;

  // Diferencias de latitud y longitud
  const dLat = lat2Rad - lat1Rad;
  const dLon = lon2Rad - lon1Rad;

  // Fórmula de Haversine
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadius * c;

  return distance; // Distancia en kilómetros
}

interface IProps {
  searchResult: {
    lat: number,
    lng: number
  },
  userLocation: {
    lat: number,
    lng: number
  },
  info: WareHouseType,
  handleMouseOver: (name: {}) => void
  wareHouses: WareHouseType[],
  isLoaded: boolean
  center: {
    lat: number,
    lng: number
  }
}

const MapContainerComp = (props: IProps) => {

  const [zoom, setZoom] = useState(7);

  const containerStyle = {
    width: '100%',
    height: `${window.innerHeight - 300}px`
  }

  const optionsMap = {
    disableDefaultUI: true
  }

  useEffect(() => {
    if (props.wareHouses?.length > 0) {
      const distances = props.wareHouses.map((e) => {
        return calculateDistance(props.center.lat, props.center.lng, e.latitude, e.longitude)
      });
      
      const maxDistance = Math.max(...distances);
      // Define un factor de ajuste para el nivel de zoom
      const zoomAdjustmentFactor = 0.95; // Puedes ajustar esto según tus necesidades

      const calculatedZoom = 14 - Math.log2(maxDistance) * zoomAdjustmentFactor;
      // Actualiza el estado del zoom en función de la distancia máxima calculada
      setZoom(calculatedZoom === Infinity ? 6 : calculatedZoom);
    }
  }, [props]);

  return (
    props.isLoaded ?
      <div className={`marketplace__container-map__map ${props?.info?.name ? 'marketplace__container-map__map__info' : 'marketplace__container-map__map__no-info'}`}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={props.center}
          zoom={zoom}
          options={optionsMap}
        >
          {
            props.wareHouses?.length > 0 && props.wareHouses?.map((e, index) => {
              // console.log({ lat: (e?.latitude), lng: (e?.longitude) })
              return (
                <Marker
                  key={`${e?.name + index}`}
                  position={{ lat: Number(e?.latitude), lng: Number(e?.longitude) }}
                  icon={e?.name === props.info?.name ? `${AZURE_BASE_URL}/mapPinSelect.svg` : `${AZURE_BASE_URL}/mapPin.svg`}
                  onClick={() => props.handleMouseOver(e)}
                />
              )
            })
          }

          {
            (props.userLocation?.lat !== 0 || props.searchResult) && <Marker position={props.searchResult || props.userLocation} icon={`${AZURE_BASE_URL}/mapPinUbication.svg`} />
          }
        </GoogleMap>
      </div>
      : <LoadingScreen />
  )
}

export default MapContainerComp
