import { Link } from 'react-router-dom'
import { dlTrackEvent } from 'components/dataLayer/dataLayer'
import { useEffect, useState } from 'react'
import { isAndroid, isIOS } from 'utilities/detectDeviceAgent'

const TecateDownload = () => {
  return (
    <Link
      to="/profile/identificacion"
      className="w-full px-0"
      onClick={() => {
        gtag('event', 'event_to_ga', { event_category: 'navegacion_profile', event_label: 'Home links' })
        dlTrackEvent('user_interaction', { action: 'click', element: 'tecate id - download', section: 'home' })
      }}
    >
      <div
        className={`button-nav shadow-xl flex items-center text-center font-medium text-xs w-full`}
        style={{
          borderRadius: '10px'
        }}
      >
        <img
          src={`${AZURE_ICONS_URL}/${THEME}/tecateid-${isAndroid() ? 'android-' : isIOS() ? 'ios-' : ''}boton_bg.webp`}
          alt="Tecate ID"
          className="w-full "
        />
      </div>
    </Link>
  )
}

const TecateView = () => {
  return (
    <Link
      to="/profile/identificacion"
      className="w-full px-0"
      onClick={() => {
        gtag('event', 'event_to_ga', { event_category: 'navegacion_profile', event_label: 'Home links' })
        dlTrackEvent('user_interaction', { action: 'click', element: 'tecate id', section: 'home' })
      }}
    >
      <div
        className={`button-nav flex items-center justify-between h-24 text-center font-medium text-xs px-10`}
        style={{
          background: 'rgba(0, 0, 0, 0.85)',
          border: '1px solid rgba(0, 0, 0, 0.35)',
          borderRadius: '10px'
        }}
      >
        <img src={`${AZURE_ICONS_URL}/${THEME}/tecate-id.svg`} alt="Tecate ID" />
        <img src={`${AZURE_ICONS_URL}/${THEME}/arrow-id.svg`} alt="Flechas doradas" />
      </div>
    </Link>
  )
}

function TecateID() {
  // @media all and (display-mode: standalone) {
  // display: none;
  // }

  const [isStandalone, setIsStandalone] = useState(false)

  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setIsStandalone(true)
    }

    // Listener para cambios
    const handler = (e) => setIsStandalone(e.matches)
    window.matchMedia('(display-mode: standalone)').addListener(handler)

    // Limpieza del listener
    return () => window.matchMedia('(display-mode: standalone)').removeListener(handler)
  }, [])

  return isStandalone ? <TecateView /> : <TecateDownload />
}

export default TecateID
