import useRouter from "hooks/use-router"

const Footer = () => {
  const img = `${AZURE_BASE_URL}/footer_img.svg`

  const condition = THEME !== 'heinekenWaiter'

  
  const { pathname } = useRouter()

  return (
    <footer className={`footer flex-col items-center justify-center gap-0 ${(pathname !== 'home' && pathname !== 'start') && 'hidden'}`}>
      <div className="flex items-center gap-5">
        <p className='footer__text'>{ condition ? `v${APP_VERSION ?? 'LOCAL'}` : `UNA INICIATIVA DE`}</p>
        <img src={img} alt='Logo' className={`footer__img ${ condition ? 'hidden' : 'block'}`} />
      </div>
      {!condition && <p className='footer__text'>v{APP_VERSION ?? 'LOCAL'}</p>}

    </footer>
  )
}

export default Footer