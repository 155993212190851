import styled from "styled-components";
import Button from 'components/button';
import { Title, SubTitle } from "theme/theme";
import { Type } from './../../../theme/types/generic.interface';

export const TitleEnd = styled(Title)`
  font-size: 2.25rem;
  ${({ theme }:Type) => theme?.["end-route"]?.text?.title}
`
export const SubTitleEnd = styled(SubTitle)`
  font-size: 1rem;
  ${({ theme }:Type) => theme?.["end-route"]?.text?.subtitle }
`

export const ButtonEnd = styled(Button)`
  ${({ theme }:Type) => theme?.["end-route"]?.text?.button }
`