import React from 'react'

function More(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="74" height="75" viewBox="0 0 74 75" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="36.8677" cy="37.3378" r="35.7334" stroke="currentColor" strokeWidth="1.89735" />
      <path d="M35.6764 21.8402V36.0703H21.4462V38.9164H35.6764V53.1465H38.5224V38.9164H52.7525V36.0703H38.5224V21.8402H35.6764Z" fill="currentColor" />
    </svg>

  )
}

export default More