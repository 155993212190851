import { Memorama, MemoramaWinner } from '../types'

export type MemoramaActionType = 'SET_MEMORAMAS_LIST' | 'SET_MEMORAMA_CURRENT' |'SET_WINNER'

export interface MemoramaState {
  memoramasList?: Memorama[]
  currentMemorama?: Memorama
  winner?: MemoramaWinner
}

export interface MemoramaAction {
  type: MemoramaActionType
  payload: MemoramaState | Memorama[] | Memorama | MemoramaWinner
}

function albumReducer(state: MemoramaState, action: MemoramaAction): MemoramaState {
  switch (action.type) {
  case 'SET_MEMORAMAS_LIST':
    return { ...state, memoramasList: action.payload as Memorama[] }
  case 'SET_MEMORAMA_CURRENT':
    return { ...state, currentMemorama: action.payload as Memorama }
  case 'SET_WINNER':
    return { ...state, winner: action.payload as MemoramaWinner }
  default:
    return state
  }
}

export default albumReducer