import { dlTrackEvent } from "components/dataLayer/dataLayer";
import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { SubtitleSurveyLabel, SubtitleLabelSurvey } from "theme/survey";
import { DivCheckbox, PCheckbox, Checkbox } from "theme/theme";
import { Option } from "contexts/survey-context/survey.interface";

export interface CheckBoxProps {
  questionId: number;
  title: string;
  subtitle: string;
  items?: Option[];
  onChange: (key: number, selected: string[]) => void;
}

function CheckboxMapper(props: CheckBoxProps) {
  const [selected, setSelected] = useState<string[]>([]);
  const titleRef = useRef<HTMLHeadingElement>(null);

  const onClick: MouseEventHandler<HTMLDivElement> = (event) => {
    const itemId = event.currentTarget.id as string;
    dlTrackEvent('user_interaction', {
      action: 'select',
      element: `${props?.title} :: ${itemId}`,
      section: `survey`
    })  
    gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'SURVEY - Seleccionar encuesta', value: '10' })
    if (selected.includes(itemId)) {
      return setSelected(selected.filter((item) => item !== itemId));
    }
    return setSelected((state) => [...state, itemId]);
  };

  useEffect(() => {
    props.onChange(props?.questionId, selected);
  }, [selected, titleRef]);

  return (
    <>
      <div className="p-2 w-full label-survey">
        <SubtitleSurveyLabel className="font-black text-2xl text-center md:text-3xl che subtitle-option" ref={titleRef}>
          {props.title}
        </SubtitleSurveyLabel>
        <SubtitleLabelSurvey className="text-lg md:text-xl subtitle-option">{props.subtitle}</SubtitleLabelSurvey>

      </div>
      <div className={`flex flex-col justify-center my-2 gap-4 md:mx-auto w-full text-black`}>
        {props.items &&
          props.items.map(({ optionId, option }) => {
            const key = `${optionId}`;
            const isSelected = selected.includes(key);

            return (
              <DivCheckbox className="gap-4 flex items-center cursor-pointer checkbox" onClick={onClick} key={key} id={key}>
                <Checkbox className={`h-6 w-6 flex items-center justify-center checkbox__input`} id="suscribe" active={isSelected} />
                <PCheckbox className="dinPro checkbox__label">{option}</PCheckbox>
              </DivCheckbox>
            );
          })}
      </div>
    </>
  );
};

export default CheckboxMapper