import { CODE_EXCHANGE } from 'constants/api'
import { useContext, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios, { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import ReCAPTCHA from 'react-google-recaptcha'
import mergeRefs from 'merge-refs'
import clone from 'lodash.clone'
import Input from 'components/input'
import useLocalStorage from 'hooks/use-local-storage'

import textServices from 'services/text-services'
import styled from 'styled-components'
import modules from 'build/modules'
import { useSession } from '../../contexts/session-context'
import { LoaderContext } from '../../contexts/loader-context'
import { FormContainer, ButtonGo, FormContainerInput, RedeemTextFocus } from '../../theme/start'

export interface FormValues {
  code: string
}

const InputGo = styled(Input)`
  ${({ theme }: any) => theme?.start?.inputGo}
`

function ButtonGoRedeem({ setIsFocused, isFocused, redeemError, setRedeemError }) {

  const [session, { setUser }] = useSession()
  const history = useHistory()
  const { register, handleSubmit, formState: { errors } } = useForm<FormValues>({ defaultValues: { code: '' } })
  const [shouldListenToEvents, setShouldListenToEvents] = useState(false)
  const { setLoad, setHead, setFooter } = useContext(LoaderContext)
  const [secondCode, setSecondCode] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const [state, setState] = useLocalStorage('state', {})
  const [, setPrize] = useLocalStorage('prize', {})
  const [, setRange] = useLocalStorage('range', undefined as any)

  const inputFormProps = register('code')

  const onSubmit = handleSubmit(async (data) => {
    gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'REDEEM - Ingresó Código', value: '10' })
    if (secondCode)
      gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'REDEEM - Ingresó Código Nuevo', value: '10' })
    if (inputRef.current?.value && inputRef.current?.value.length < 4) {
      setRedeemError(456)
      return
    }
    recaptchaRef.current?.reset()
    inputRef.current?.blur()

    recaptchaRef.current?.executeAsync().then(async (recaptchaToken) => {
      if (data.code) {
        setLoad(true)
        try {
          const response = await axios.post(
            CODE_EXCHANGE,
            { ...data, recaptchaToken },
            {
              headers: { Authorization: `Bearer ${((session.data as any)?._tokenResponse as any).idToken}`, 'Ph-Api-Id': PH_API_ID ?? 1 }
            }
          )

          const newUser = clone(session.user) as any
          newUser.totalPoints = response.data.profile.totalPoints
          newUser.level = response.data.profile.level
          newUser.chances = response.data.profile.chances
          setState({ ...state, user: newUser })
          setUser(newUser)
          setPrize(response?.data)
          setSecondCode(true)
          if (response?.data?.profile?.isNewLevel) {
            setRange(response?.data?.profile)
          }
          history.push('/congrats')
          setHead(true)
          setFooter(true)
          gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'REDEEM - Ingreso éxitoso', value: '10' })

        } catch (error) {
          gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'REDEEM - Ingreso érroneo', value: '10' })
          const err = error as AxiosError
          inputRef.current?.focus()

          const code = err.response?.data.code || 99999
          setRedeemError(code)
          setLoad(false)
          setHead(true)
          setFooter(true)
          if (code === 66 || code === 67) {
            setHead(true)
            setFooter(true)
            history.push(`/error/${code}`)
          }
        } finally {
          setLoad(false)
        }
      }
    })
  })

  const onFocus = () => {
    setIsFocused(true)
    setHead(false)
    setFooter(false)

    setTimeout(() => {
      setShouldListenToEvents(true)
    }, 200)
  }

  const onBlur = (e: any) => {
    if (e.target.tagName === 'DIV') {
      setIsFocused(false)
      setHead(true)
      setFooter(true)
      setShouldListenToEvents(false)
    }
    gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'REDEEM - Focus en input', value: '10' })
  }

  const formContainerClasses = `pb-4 flex-col w-full transition-colors duration-300 items-center px-7  ${isFocused && 'flex absolute min-h-view h-screen top-0 px-7 justify-center'}`

  return (
    <FormContainer
      isFocused={isFocused}
      className={formContainerClasses}
      onClick={shouldListenToEvents ? onBlur : undefined}
    >
      <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={RECAPTCHA_SITEKEY} />
      {isFocused && (
        <RedeemTextFocus className="mb-4 text-center uppercase font-bold text-sm">
          {textServices.findByText('redeem/description2', { es: <>INGRESA EL CÓDIGO QUE SE ENCUENTRA <br /> DENTRO DE LOS 12 PACK</>, en: <>ENTER THE CODE THAT IS <br /> INSIDE THE 12 PACKS</> })}
        </RedeemTextFocus>
      )}
      <FormContainerInput
        className={`grid w-full mb-6 ${isFocused ? 'sm:max-w-sm' : ''}`}
        style={{ gridTemplateColumns: '1fr 5rem' }}
        onSubmit={onSubmit as any}
      >
        <InputGo
          {...inputFormProps}
          ref={mergeRefs(inputRef, inputFormProps.ref)}
          autoComplete="off"
          variant={isFocused}
          onFocus={onFocus}
          className={'font-bold sm:w-full'}
          style={{ borderTopRightRadius: '0', borderBottomRightRadius: '0' }}
          placeholder="INGRESA TU CÓDIGO"
          maxLength={20}
          onChange={() => {
            if (redeemError) {
              setRedeemError(null)
            }
          }}
        />
        <ButtonGo className={`w-20 h-26`} isFocused={isFocused} disabled={!!errors.code} style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }}>
          {modules?.json?.start?.codeButtonImg ? (
            <img src={`${AZURE_BASE_URL}${isFocused ? '/go-button.svg' : '/go-button-black.svg'}`} alt="go" />
          ) : (
            'GO'
          )}
        </ButtonGo>
      </FormContainerInput>
    </FormContainer>
  )
}

export default ButtonGoRedeem
