import { VERIFY } from 'constants/api'
import { useContext, useEffect, useState } from 'react'
import LoadingScreen from 'components/loading-screen'
import useRouter from 'hooks/use-router'
import axios, { AxiosError } from 'axios'
import { LoaderContext } from 'contexts/loader-context'
import textServices from 'services/text-services'
import Button from 'components/button'
import { Link } from 'react-router-dom'
import ErrorView from 'components/error-view'
import { TextEmail, TitleEmail } from './text.style'

const MailVerify = () => {
  const [error, setError] = useState(null)
  const { search, history } = useRouter()
  const { setLoad, load } = useContext(LoaderContext)
  const { push } = useRouter()
  const query = new URLSearchParams(search)
  const oobCode = query.get('oobCode')?.replace(/&$/i, '').replaceAll(' ', '+')
  const nickName = localStorage.getItem('nickName')

  gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'VERIFY MAIL - Verificar Email', value: '10' })

  const verifyMail = async () => {
    setLoad(true)
    try {
      const { status } = await axios.post(VERIFY, { oobCode })
      console.log(status)
    } catch (error) {
      return setError((error as AxiosError).response?.data.code ?? 9999)
    } finally {
      setLoad(false)
    }
  }

  useEffect(() => {
    verifyMail()
  }, [oobCode, history])


  useEffect(() => {
    const event = new CustomEvent('background-color', {
      detail: {
        shouldUseWhiteBg: error !== null
      }
    })
    window.dispatchEvent(event)
  }, [error])

  if (load) {
    return <LoadingScreen />
  }

  const handleMenu = () => {
    gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'EMAIL - Salir de la pantalla de error', value: '10' })
    setError(null)
    push('/')
  }

  const handleButton = () => {
    setError(null)
    push('/')
  }

  if (error) {
    return <ErrorView code={error} buttonMenu={handleMenu} onRetry={handleButton} />
  }

  return (
    <section className='w-full flex-1 flex flex-col items-center gap-4 p-7'>
      <div className='flex-1 flex flex-col items-center justify-center gap-6 w-full'>
        <TitleEmail className="text-center text-5xl font-bold uppercase mx-7 overflow-ellipsis overflow-hidden w-full">{textServices.findByText('mail/title', nickName ? <>Excelente<br />{nickName}</> : <>E-mail validado<br />con éxito</>, {apodo: nickName})}</TitleEmail>
        <TextEmail className="din text-center mt-4 mx-7">{textServices.findByText('mail/description', <>Has confirmado con éxito tu cuenta.<br />Ya puedes ingresar</>)}</TextEmail>
      </div>
      <Link to='/login' className='w-full'>
        <Button className='uppercase'>{textServices.findByText('mail/button', 'Ingresar')}</Button>
      </Link>
    </section>
  )
}

export default MailVerify
