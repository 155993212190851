import { usePhotographer } from "views/photographer/context"
import useRouter from "hooks/use-router"
import { useEffect, useState } from "react"
import { useSession } from "contexts/session-context"
import { Photos } from "views/photographer/context/types"
import Carrousel from "views/photographer/components/carrousel"
import Button from "components/button"
import { Link } from "react-router-dom"
import LazyLoad from 'react-lazyload';
import useGuard from "views/photographer/components/hooks/useGuard"
import BottomArrow from "components/icon/bottom-arrow"
import styles from "./styles.module.scss"
import { ContinueCardStart, DescriptionCardStart, DescriptionsCardCarrouselStart, DivCardCarrouselStart, DivCardStart, DivTitleStart, ImgCardCarrouselStart, ImgCardStart, TitleStart } from "./custom.style"

const Container = ({ children, title = 'FOTOS CARTA BLANCA' }: { children: React.ReactNode, title?: string }) => (
  <section className="flex flex-col gap-6">
    <div className='w-screen max-w-7xl z-1 flex flex-col gap-6 flex-1 items-center'>
      <DivTitleStart className="flex items-center px-10 w-screen py-5">
        <TitleStart className="flex-grow  max-w-full">{title}</TitleStart>
      </DivTitleStart>
      {children}
    </div>
  </section>
)

function Start() {

  const [{ user }] = useSession()
  const [{ photos }] = usePhotographer()
  const { query, push, pathname, search } = useRouter()
  const [myPhotos, setMyPhotos] = useState<Photos[] | null>(null)
  useGuard()

  useEffect(() => {
    const myPhotos = photos?.filter((photo) => photo?.profileId === user?.id)
    if (myPhotos?.length < 1 && pathname + search !== '/photographer/start') return push('/photographer/upload')
    setMyPhotos(myPhotos)
  }, [photos])

  const handleShare = async (url: string) => {
    const file = await fetch(url).then((r) => r.blob())
    const fileToShare = new File([file], 'carta-blanca.png', { type: 'image/png' })
    window.navigator.share({
      files: [fileToShare],
      title: 'Carta Blanca',
      text: `¡Hola! Les comparto mi creación #fotografocartero.`,
      url: `${URL_BASE}${process.env.PUBLIC_URL}/photographer/start`
    });
  }

  if ((query as any)?.isYou) {
    return (
      <Container title="SUS FOTOS">
        <Carrousel>
          {myPhotos?.map((photo) => (
            <div className="flex relative flex-col items-center justify-center gap-5 px-7 sm:px-2" key={photo?.code}>
              <DivCardCarrouselStart className="w-full relative h-96 flex flex-col items-center justify-center" >
                <div className="h-5/6 w-full">
                  <ImgCardCarrouselStart className="object-cover w-full h-full" src={photo?.imageUrl} alt={photo?.name} />
                </div>
                <DescriptionsCardCarrouselStart className="py-2 px-2 text-center flex-1 flex items-center justify-center">CÓDIGO {photo?.code}</DescriptionsCardCarrouselStart>
              </DivCardCarrouselStart>
              <div className="w-full my-5">
                <Button onClick={() => handleShare(photo?.imageUrl)}>COMPARTIR</Button>
              </div>
            </div>
          ))}
        </Carrousel>
        <Link to="/photographer/start" className="pb-7 -mt-5">
          <p className={`din text-center uppercase underline text-black`} style={{ fontSize: 10 }}>VER MAS FOTOS</p>
        </Link>
      </Container>
    )
  }

  const CardCarrousel = ({ photo, index }: { photo: Photos, index: number }) => (
    <>
      <LazyLoad className="bg-gray-200 rounded-3xl" key={photo?.code} once height={320} offset={[-320, 320]}>
        <DivCardStart className={`w-full ${styles['container-height']} relative flex flex-col items-center justify-center`}>
          <div className="h-5/6 w-full">
            <ImgCardStart className="object-cover w-full h-full" src={photo?.imageUrl} alt={photo?.name} />
          </div>
          <DescriptionCardStart className="py-2 px-2 text-center flex-1 flex items-center justify-center">CÓDIGO {photo?.code}</DescriptionCardStart>
        </DivCardStart>
      </LazyLoad>
      {index === 0 && <ContinueCardStart className={styles.continue}>DESLIZA <span><BottomArrow /></span> PARA VER MÁS FOTOS</ContinueCardStart>}
    </>
  )

  return (
    <Container>
      <article className={styles["container-grid"]}>
        {photos?.map((photo) => (
          <CardCarrousel photo={photo} index={photos.indexOf(photo)} key={photo?.code} />
        ))}
      </article>
    </Container>
  )
}

export default Start