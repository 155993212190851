import { SVGProps } from 'react'

function TriviasIconIndio(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="50" height="41" viewBox="0 0 50 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.285612 5.74392C0.127873 4.32113 1.1534 3.03985 2.5762 2.88211L25.9962 0.285643C27.419 0.127904 28.7002 1.15343 28.858 2.57623L30.1043 13.8178L28.6991 13.9736L27.4528 2.73202C27.3811 2.08529 26.7987 1.61914 26.152 1.69084L2.73199 4.28731C2.08526 4.35901 1.61911 4.94141 1.69081 5.58813L4.17945 28.0355C4.25212 28.6909 4.8485 29.1591 5.5024 29.074L21.8964 26.9418L22.0788 28.3438L5.68475 30.476C4.24616 30.6631 2.93411 29.6332 2.77426 28.1913L0.285612 5.74392Z" fill="white" />
      <path d="M12.9591 12.6025L12.8517 11.4955C12.814 11.1077 12.8533 10.7314 12.9694 10.3666C13.0855 10.0018 13.2622 9.67535 13.4994 9.38719C13.7378 9.11153 14.0248 8.88166 14.3605 8.69758C14.6961 8.51349 15.0641 8.40203 15.4644 8.36319C15.9272 8.31829 16.3553 8.37144 16.7488 8.52266C17.1548 8.67266 17.4992 8.90439 17.7821 9.21785C17.9242 9.38083 18.0401 9.53373 18.1297 9.67655C18.2306 9.80564 18.3146 9.95532 18.3816 10.1256C18.4995 10.4298 18.5864 10.8696 18.6422 11.445C18.6737 11.7702 18.6942 12.046 18.7035 12.2724C18.7242 12.485 18.7298 12.6739 18.7206 12.8389C18.7114 13.0039 18.6941 13.1508 18.6687 13.2795C18.6421 13.3958 18.6023 13.5069 18.5495 13.6131L17.4697 15.9527C17.3967 16.1113 17.3368 16.2749 17.2901 16.4436C17.2421 16.5998 17.2266 16.7654 17.2436 16.9405L17.3892 18.4415L15.4754 18.6272L15.3061 16.8823C15.2794 16.6071 15.2977 16.3402 15.361 16.0815C15.4243 15.8229 15.5132 15.568 15.6277 15.317L16.6392 13.2492C16.7235 13.0769 16.7691 12.8957 16.7759 12.7056C16.7952 12.5144 16.7957 12.3249 16.7775 12.1373L16.6811 11.1429C16.6556 10.8802 16.5399 10.6641 16.3341 10.4947C16.1408 10.3241 15.9128 10.2515 15.6501 10.277C15.4249 10.2988 15.2139 10.4014 15.017 10.5846C14.8202 10.7679 14.7369 11.0158 14.7673 11.3286L14.8729 12.4168L12.9591 12.6025ZM15.7959 21.9295L15.6102 20.0157L17.524 19.83L17.7096 21.7438L15.7959 21.9295Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M47.8413 15.4286L24.3455 13.6436C24.086 13.6239 23.8596 13.8183 23.8399 14.0778L22.1263 36.6338C22.1066 36.8933 22.301 37.1197 22.5605 37.1394L46.0563 38.9244C46.3158 38.9441 46.5422 38.7497 46.5619 38.4901L48.2755 15.9342C48.2952 15.6747 48.1008 15.4483 47.8413 15.4286ZM24.4526 12.2339C23.4145 12.155 22.509 12.9326 22.4301 13.9707L20.7166 36.5267C20.6377 37.5648 21.4153 38.4703 22.4534 38.5491L45.9492 40.3341C46.9873 40.413 47.8928 39.6354 47.9716 38.5972L49.6852 16.0413C49.7641 15.0032 48.9865 14.0977 47.9484 14.0189L24.4526 12.2339Z" fill="white" />
      <path d="M32.7942 23.5694L32.8834 22.4608C32.9147 22.0725 33.0196 21.709 33.1981 21.3704C33.3766 21.0317 33.608 20.7414 33.8923 20.4995C34.1755 20.2702 34.4985 20.0944 34.8613 19.9723C35.2242 19.8502 35.606 19.8053 36.0069 19.8376C36.4704 19.8749 36.8825 20.0026 37.2431 20.2207C37.6164 20.4399 37.9146 20.7286 38.1379 21.087C38.2491 21.2725 38.3362 21.4434 38.3993 21.5998C38.4759 21.7446 38.5322 21.9067 38.5682 22.0861C38.6306 22.4064 38.6387 22.8546 38.5923 23.4308C38.5661 23.7565 38.5377 24.0316 38.507 24.2561C38.4899 24.469 38.4622 24.6559 38.4241 24.8167C38.3859 24.9776 38.343 25.1191 38.2954 25.2414C38.2487 25.3511 38.19 25.4535 38.1193 25.5487L36.6444 27.6616C36.5446 27.8049 36.4569 27.9554 36.3811 28.1132C36.3064 28.2585 36.262 28.4188 36.2479 28.5942L36.1269 30.0974L34.2103 29.9432L34.3509 28.1957C34.3731 27.9201 34.4381 27.6606 34.546 27.4171C34.6538 27.1736 34.7862 26.9384 34.9432 26.7115L36.303 24.8541C36.4163 24.6993 36.493 24.529 36.5332 24.3431C36.5859 24.1582 36.6198 23.9718 36.6349 23.7839L36.7151 22.7881C36.7362 22.525 36.6604 22.292 36.4876 22.0889C36.3274 21.8869 36.1157 21.7753 35.8527 21.7542C35.6272 21.736 35.4014 21.7998 35.1754 21.9455C34.9493 22.0912 34.8237 22.3207 34.7985 22.6338L34.7108 23.7237L32.7942 23.5694ZM33.9442 33.2502L34.0984 31.3336L36.015 31.4878L35.8608 33.4044L33.9442 33.2502Z" fill="white" />
    </svg>

  )
}

export default TriviasIconIndio
