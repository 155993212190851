import textServices from 'services/text-services'
import Edit from 'components/icon/menu-profile/edit'
import Arrow from 'components/icon/menu-profile/arrow'
import { dlTrackEvent } from 'components/dataLayer/dataLayer'
import useRouter from 'hooks/use-router'
import { useSession } from 'contexts/session-context'
import { Link, matchPath } from 'react-router-dom'
import { ContainerProfile, TextProfile, HeaderColorArrow, EditColorIcon } from '../styles/header.style'


const HeaderProfile = ({ definitions, children }: any) => {
  const [{ user }] = useSession()
  const { pathname, history } = useRouter()

  const name = user?.nickName || (user?.firstName && user?.firstName.length > 20 ? user?.firstName.substring(0, 17) + '...' : user?.firstName)

  const definitionsFinded = definitions.find((item: any) => {
    const match = matchPath(pathname, {
      path: item.url,
      exact: true
    })
    return !!match
  })

  return (
    <div className='flex flex-1 flex-col'>
      <ContainerProfile className='profile__title-container w-screen flex p-4 px-5 justify-between items-center'>
        <div className='flex gap-4 items-center justify-center'>
          <Link to={'..'}
            onClick={(e) => {
              e.preventDefault();
              history.goBack();
              dlTrackEvent('user_interaction', {
                action: "click",
                element: 'volver',
                section: "mi perfil",
                subsection: definitionsFinded.title.toLowerCase()
              })
            }}>
            <HeaderColorArrow>
              {textServices.findByText('profile/view/arrow', <Arrow />)}
            </HeaderColorArrow>
          </Link>
          <TextProfile className='title'>{definitionsFinded?.title || name}</TextProfile>
        </div>
        {
          definitionsFinded?.update && (
            <Link to={`${definitionsFinded?.update}`} onClick={() => {
              dlTrackEvent('user_interaction', {
                action: "click",
                element: 'editar',
                section: "mi perfil",
                subsection: definitionsFinded.title.toLowerCase()
              })
            }}>
              <EditColorIcon className='icon-edit'>
                <Edit />
              </EditColorIcon>
            </Link>
          )
        }
      </ContainerProfile>
      {children}
    </div>
  )
}

export default HeaderProfile
