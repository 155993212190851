const MetaCAPIService = {
  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  },

  loadHeaders(sessionId: string) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("GUID", sessionId);
    return myHeaders;
  },

  createBaseObject(eventName: string, eventID: string, eventSourceUrl: string, contentName?: string | null) {
    return {
      "providerData": {
        "pixel": `${METACAPI_PIXEL}`,
      },
      "data": {
        "data": {
          "eventName": eventName,
          "eventID": eventID,
          "eventSourceUrl": eventSourceUrl,
          "actionSource": "website",
          "userData": {
            "city": "mx",
          },
          "customData": {
            "contentName": !contentName ? '' : contentName
          },
          "deliveryOptimization": false
        },
        "testEventCode": ""
      }
    };
  },

  createUserObject(email: string, firstName: string, lastName: string, birthday: string, eventSourceUrl: string, methodName: string, sessionId: string) {
    return {
      "providerData": {
        "pixel": `${METACAPI_PIXEL}`,
      },
      "data": {
        "data": {
          "eventName": "CompleteRegistration",
          "eventID": sessionId,
          "eventSourceUrl": eventSourceUrl,
          "actionSource": "website",
          "userData": {
            "city": "mx",
            "email": email,
            "firstName": firstName,
            "lastName": lastName,
            "birthday": birthday,
          },
          "customData": {
            "contentName": methodName
          },
          "deliveryOptimization": false
        },
        "testEventCode": ""
      }
    };
  },

  mustUseService() {
    return METACAPI_BASEURL && METACAPI_APITOKEN && METACAPI_PIXEL;
  },

  makeCall(requestOptions: any) {
    fetch(`${METACAPI_BASEURL}?apiToken=${METACAPI_APITOKEN}`, requestOptions)
      .then(response => response.text())
      .then(result => console.debug(result))
      .catch(error => console.error('error', error));
  },

  emitViewContent(eventSourceUrl: string, sessionId: string) {
    if (this.mustUseService()) {
      const myHeaders = this.loadHeaders(sessionId);
      const baseObject = this.createBaseObject('ViewContent', sessionId, eventSourceUrl, null);
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(baseObject),
        redirect: 'follow'
      };

      this.makeCall(requestOptions);
    }
  },
  emitPageView(eventSourceUrl: string, sessionId: string) {
    if (this.mustUseService()) {

      const myHeaders = this.loadHeaders(sessionId);
      const baseObject = this.createBaseObject('PageView', sessionId, eventSourceUrl, null);
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(baseObject),
        redirect: 'follow'
      };

      this.makeCall(requestOptions);
    }
  },
  emitShare(eventSourceUrl: string, contentName: string, sessionId: string) {
    if (this.mustUseService()) {
      const myHeaders = this.loadHeaders(sessionId);
      const baseObject = this.createBaseObject('Share', sessionId, eventSourceUrl, contentName);
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(baseObject),
        redirect: 'follow'
      };

      this.makeCall(requestOptions);
    }
  },
  emitB2C(eventSourceUrl: string, contentName: string, sessionId: string) {
    if (this.mustUseService()) {
      const myHeaders = this.loadHeaders(sessionId);
      const baseObject = this.createBaseObject('ClickIB2C', sessionId, eventSourceUrl, contentName);
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(baseObject),
        redirect: 'follow'
      };

      this.makeCall(requestOptions);
    }
  },
  emitCompleteRegistration(email: string, firstName: string, lastName: string, birthday: string, eventSourceUrl: string, methodName: string, sessionId: string) {
    if (this.mustUseService()) {
      const myHeaders = this.loadHeaders(sessionId);
      const baseObject = this.createUserObject(email, firstName, lastName, birthday, eventSourceUrl, methodName, sessionId);
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(baseObject),
        redirect: 'follow'
      };

      this.makeCall(requestOptions);
    }
  }
}

export default MetaCAPIService;