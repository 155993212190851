import { useState, useEffect } from 'react';
import useRouter from 'hooks/use-router';
function useRedirect() {
  const { push } = useRouter()
  const [url, setUrl] = useState('')

  useEffect(() => {
    const urlRedirect = localStorage.getItem('internal_redirect')
    setUrl(urlRedirect)
  }, [])

  const redirect = () => {
    push(url)
  }

  const setRedirect = (url: string) => {
    if (!url.includes('xeerpa-manager')) {
      localStorage.setItem('internal_redirect', url)
      setUrl(url)
    }
  }

  const clearRedirect = () => {
    localStorage.removeItem('internal_redirect')
    setUrl(null)
  }

  const getRedirectLocal = () => {
    return localStorage.getItem('internal_redirect')
  }

  const redirectByLocal = () => {
    const urlRedirect = localStorage.getItem('internal_redirect')
    push(urlRedirect)
  }
  
  return [{url}, {redirect, setRedirect, clearRedirect, redirectByLocal, getRedirectLocal}]
}

export default useRedirect