import { dlTrackEvent } from "components/dataLayer/dataLayer"
import { useRef } from "react"
import styled from "styled-components"
import { Type } from "theme/types"

const Input = styled.input`
  ${({ theme }: Type) => theme?.inputOtp}
`

function OtpInput({ setValue }: any) {

  const one = useRef<HTMLInputElement>(null)
  const two = useRef<HTMLInputElement>(null)
  const tree = useRef<HTMLInputElement>(null)
  const four = useRef<HTMLInputElement>(null)
  const five = useRef<HTMLInputElement>(null)
  const six = useRef<HTMLInputElement>(null)

  const inputBlur = () => {
    const refs: Record<string, HTMLInputElement | null> = {
      one: one.current,
      two: two.current,
      tree: tree.current,
      four: four.current,
      five: five.current,
      six: six.current
    }

    const valueRefs = `${refs.one?.value}${refs.two?.value}${refs.tree?.value}${refs.four?.value}${refs.five?.value}${refs.six?.value}`

    dlTrackEvent('form_interaction', {
      form_field: 'otp',
      form_name: 'verifica tu email',
      form_action: valueRefs.length === 6 ? 'complete' : 'empty',
      section: 'sign up'
    })
  }

  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    const refs: Record<string, HTMLInputElement | null> = {
      one: one.current,
      two: two.current,
      tree: tree.current,
      four: four.current,
      five: five.current,
      six: six.current
    }
    const valueRefs = `${refs.one?.value}${refs.two?.value}${refs.tree?.value}${refs.four?.value}${refs.five?.value}${refs.six?.value}`
    setValue("otp", valueRefs)
    if (value.length > 0) {
      const index =
        name === 'one'
          ? 'two'
          : name === 'two'
            ? 'tree'
            : name === 'tree'
              ? 'four'
              : name === 'four'
                ? 'five'
                : name === 'five'
                  ? 'six'
                  : 'one'
      refs[index]?.focus()
    }
  }

  const inputArr = [
    { name: "one", ref: one },
    { name: "two", ref: two },
    { name: "tree", ref: tree },
    { name: "four", ref: four },
    { name: "five", ref: five },
    { name: "six", ref: six }
  ]

  const completeOtpCode = (codeOtp: string) => {
    setValue("otp", codeOtp)
    const codeOtpArray = codeOtp.split('')
    inputArr.forEach(({ ref }, index) => {
      ref.current.value = codeOtpArray[index] || ''
    })
    inputBlur()
  }

  return (
    <div className="w-full flex justify-between text-black input-otp-container">
      {inputArr.map(({ name, ref }, index) => {
        return <Input
          type="tel"
          ref={ref}
          name={name}
          onFocus={(event) => event.target.select()}
          onChange={inputChange}
          onPaste={(event) => index === 0 && completeOtpCode(event.clipboardData.getData('text/plain'))}
          className="mx-auto uppercase text-center saira input-otp"
          maxLength={1}
          key={index}
          onBlur={index === 5 ? inputBlur : null}
          required
        />
      })}
    </div>
  )
}

export default OtpInput