import { Redirect, Route, Switch } from "react-router-dom";

interface Props {
  children: JSX.Element[] | JSX.Element | any;
}

const NotFound = () => <Redirect to="/" />

function RoutesWithNotFound({ children }: Props) {
  return (
    <Switch>
      {children}
      <Route path="*" component={NotFound} />
    </Switch>
  )
}

export default RoutesWithNotFound