import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Button from 'components/button'
import Range from 'components/range'
import useLocalStorage from 'hooks/use-local-storage'
import textServices from 'services/text-services'
import { PointsCongrats, SectionCongrats, TextButton3, TextCongrats, TitleCongrats } from 'theme/congrats'
import useMainClass from 'hooks/use-main-cass'
import CONFIGS from 'build/Config'
import { useSession } from '../../contexts/session-context'
import { Crown } from '../../theme/profile'

const Congrats = () => {
  useMainClass('congrats')

  const [data, setData] = useState<any>(null)
  const [range, setRange] = useState<{ level: { id: number; name: string; imageUrl: string } } | null>(null)
  const history = useHistory()
  const [{ user }, { setPoints }] = useSession()
  const [lPrize, , removeLPrize] = useLocalStorage('prize', undefined as any)
  const [lSticker, , removeLSticker] = useLocalStorage('sticker', undefined as any)
  const [lRange] = useLocalStorage('range', undefined as any)

  useEffect(() => {
    if (lRange) {
      setRange(lRange)
      return history.push('/congrats/range')
    }
    if (lPrize) {
      setData(lPrize)
      setPoints(lPrize?.points || 0)
      removeLPrize()
    } else if (lSticker) {
      history.push(`/collections/sticker/redeem/congrats/${lSticker?.collectionId}/${lSticker?.code}`)
      removeLSticker()
    }
  }, [])

  if (range) return <Range data={range} history={history} name={user?.firstName} />

  return (
    data && (
      <SectionCongrats className="flex-1 p-4 flex flex-col items-center justify-between gap-4 text-black">
        <div className="uppercase text-center text-5xl md:mt-20">
          <TitleCongrats className="font-bold text-center" style={{ maxWidth: 'calc(100vw - 2rem)' }}>
            ¡{textServices.findByText('congrats/title', { es: 'Eso ', en: 'Come on ' })}
            {user?.firstName}!{' '}
          </TitleCongrats>
          <TextCongrats className="mt-4 saira text-xl">
            {textServices.findByText('congrats/description', { es: `SIGUE JUNTANDO ${POINTS_NAME} y sube en el ranking`, en: `KEEP COLLECTING ${POINTS_NAME} and move up in the rankings` }, { VAR_POINTS: data.points })}
          </TextCongrats>
        </div>
        <Crown className="w-48 h-48 flex justify-center items-center" style={{ backgroundSize: 'contain' }}>
          <PointsCongrats className="text-6xl font-bold">{data.points}</PointsCongrats>
        </Crown>
        <div className="w-full text-center px-4 text-xl mb-8">
          <Link to={textServices.findByText('config/prizes', '/prizes')}>
            <Button
              className="uppercase flex justify-center items-center"
              onClick={() =>
                gtag('event', 'Click', {
                  event_category: GTM_EVENT_CATEGORY,
                  event_label: 'CONGRATS - Ver los Premios',
                  value: '10'
                })
              }
            >
              {textServices.findByText('congrats/button1', { es: 'Quiero ver los premios', en: 'I want to see the prizes' })}
            </Button>
          </Link>
          <Link to={textServices.findByText('congrats/button2Link', "/redeem")} onTouchStartCapture={() => history.push(textServices.findByText('congrats/button2Link', "/redeem"))}>
            <Button
              className="uppercase flex justify-center items-center mt-2"
              disabled
              onClick={() =>
                gtag('event', 'Click', {
                  event_category: GTM_EVENT_CATEGORY,
                  event_label: 'CONGRATS - Ingresar otro Código',
                  value: '10'
                })
              }
            >
              {textServices.findByText('congrats/button2', { es: 'Quiero ingresar otro código', en: 'I want to enter another code' })}
            </Button>
          </Link>
          {CONFIGS?.congrats?.showButton3 &&
            <Link type="button" to={textServices.findByText('congrats/button3Link', "/album/redirect/Historia/ranking")} className="cursor-pointer" >
              <TextButton3
                className={`din text-center uppercase underline pt-2`}
                style={{ fontSize: 10 }}
              >
                {textServices.findByText('congrats/button3', { es: 'QUIERO VER EL RANKING', en: 'I WANT TO SEE THE RANKING' })}
              </TextButton3>
            </Link>
          }
        </div>
      </SectionCongrats>
    )
  )
}

export default Congrats
