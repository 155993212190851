import useRouter from "hooks/use-router"
import { useEffect } from "react"
import { usePhotographer } from "views/photographer/context"
import ErrorView from "components/error-view"
function Error() {

  const [{ error }, { setError }] = usePhotographer()
  const { push, history } = useRouter()

  useEffect(() => {
    if (!error) return push('/photographer/start')
    return () => {
      setError(null)
    }
  }, [])

  const handleButton = () => {
    if (error === 900) return push('/profile/information')
  }

  const handleMenu = () => {
    if (error === 900) return push('/home')
    history.goBack()
  }

  return (
    <ErrorView onRetry={handleButton} buttonMenu={handleMenu} code={error} />
  )
}

export default Error