import { FAQS } from 'constants/api'
import useAxios from 'axios-hooks'
import useMainClass from 'hooks/use-main-cass'
import Input from 'components/input'
import LoadingScreen from 'components/loading-screen'
import { useRef } from 'react'
import Collapsible from 'react-collapsible'
import useRouter from 'hooks/use-router'
import { dlTrackEvent } from 'components/dataLayer/dataLayer'
import ErrorView from 'components/error-view'
import textServices from 'services/text-services'
import { useSession } from '../../contexts/session-context'
import {
  DivTextHelp,
  AreaTextHelp,
  DivBgHelp,
  TitleHelp,
  SubtitleHelp,
  ContactTitle,
  ContactSubtitle,
  CollapsibleStyle,
  SectionHelp,
  ButtonHelp,
  CollapsibleStyleP
} from '../../theme/help'
import style from './help.module.scss'

const Help = () => {
  useMainClass('help')
  const session = useSession()
  const [{ loading: loadingFaqs, error: errorFaqs, data: faqs }] = useAxios({ url: FAQS, headers: { 'Ph-Api-Id': PH_API_ID ?? 1 } })
  const { history } = useRouter()
  const [{ user, data = {} as any }] = session
  const { phoneNumber } = user || {}
  const [{ response, loading, error }, fetch] = useAxios(
    {
      method: 'POST',
      url: 'https://' + API_BASE_USER_UPDATE + '/promohub/api/v1/profiles/send/contact' + `${!user ? '/anonymous' : ''}`,
      headers: { Authorization: `Bearer ${data?._tokenResponse?.idToken}`, 'Ph-Api-Id': PH_API_ID ?? 1 }
    },
    { manual: true }
  )

  const nameRef = useRef<HTMLInputElement>(null)
  const lastnameRef = useRef<HTMLInputElement>(null)
  const emailRef = useRef<HTMLInputElement>(null)
  const phoneRef = useRef<HTMLInputElement>(null)
  const textRef = useRef<HTMLTextAreaElement>(null)

  const submitHandler = async (e: any) => {
    e.preventDefault()
    gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'HELP - Contactanos', value: '10' })

    const data = {
      phoneNumber: phoneRef.current?.value,
      message: textRef.current?.value,
      name: nameRef.current?.value ?? '',
      lastname: lastnameRef.current?.value ?? '',
      email: emailRef.current?.value ?? ''
    }

    // if (!user) {

    // }

    if (!phoneRef.current?.value) {
      alert('se deben completar ambos campos')
    }

    if (!textRef.current?.value) {
      alert('se deben completar ambos campos')
    }

    await fetch({ data })
  }

  const messageSuccess = !(error && loading) && !!response

  if (messageSuccess) {
    // Cambiar nombre de componente
    return <ErrorView buttonMenu={() => history.push('/home')} code={9630} />
  }

  if (errorFaqs || error) {
    return <ErrorView buttonMenu={() => history.push('/home')} code={9999} />
  }

  if (loadingFaqs || loading) {
    return <LoadingScreen />
  }

  return (
    <SectionHelp className="flex flex-col w-full p-7 pb-0 sm:p-0 items-center help__container-main">
      <DivTextHelp className="space-y-4 pb-2">
        <TitleHelp className="uppercase text-3xl text-center font-bold help__title-faq">{textServices.findByText('help/title', { es: '¿Tienes algún problema?', en: 'Do you have a problem?' })}</TitleHelp>
        <SubtitleHelp className="din uppercase text-center help__subtitle-faq">
          {textServices.findByText(
            'help/subtitle',
            { es: 'Revisa primero en nuestras preguntas frecuentes, si no encuentras la respuesta, contáctanos.', en: "Check first our frequently asked questions, if you don't find the answer, contact us." }
          )}
        </SubtitleHelp>
        <CollapsibleStyle className='help__collapsible'>
          {faqs.map((faq: any) => (
            <div className={style.collapsible} key={faq.title}>
              <Collapsible
                trigger={faq.title}
                className='help__collapsible-cnt'
                onOpen={() => {
                  dlTrackEvent('user_interaction', {
                    action: 'click',
                    element: faq.title,
                    section: `help`
                  })
                }}
              >
                <CollapsibleStyleP className='help__collapsible-text' dangerouslySetInnerHTML={{ __html: faq.content }}></CollapsibleStyleP>
              </Collapsible>
            </div>
          ))}
        </CollapsibleStyle>
      </DivTextHelp>
      <DivBgHelp className="w-screen flex-1 flex justify-center pt-4 min-h-screen p-7 help__area-container">
        <div className="flex flex-col w-full sm:max-w-sm space-y-6">
          <ContactTitle className="uppercase text-3xl text-center font-bold pt-2 help__title-doubt">
            {textServices.findByText('help/title', { es: '¿Más dudas?', en: 'more questions?' })}
          </ContactTitle>
          <ContactSubtitle className="din uppercase text-center help__subtitle-doubt">
            {textServices.findByText('help/subtitleMessage', { es: 'No te preocupes, déjanos un mensaje para solucionarlo.', en: "Don't worry, leave us a message to solve it." })}
          </ContactSubtitle>
          <form className="flex flex-1 flex-col space-y-4 input-container" onSubmit={submitHandler}>
            {
              !user && (
                <>
                  <Input
                    type="text"
                    placeholder="Ingresa tu nombre"
                    className="text-center din text-sm font-bold help__input"
                    ref={nameRef}
                    maxLength={25}
                    required
                    onBlur={() => {
                      dlTrackEvent('form_interaction', {
                        element: 'enviar mensaje',
                        form_name: 'mas dudas',
                        form_field: 'ingresa tu nombre',
                        form_action: 'empty',
                        section: `help`
                      })
                    }}
                  />
                  <Input
                    type="text"
                    placeholder="Ingresa tu apellido"
                    className="text-center din text-sm font-bold help__input mb-5"
                    ref={lastnameRef}
                    maxLength={25}
                    required
                    onBlur={() => {
                      dlTrackEvent('form_interaction', {
                        element: 'enviar mensaje',
                        form_name: 'mas dudas',
                        form_field: 'ingresa tu apellido',
                        form_action: 'empty',
                        section: `help`
                      })
                    }}
                  />
                  <Input
                    type="email"
                    placeholder="Ingresa tu email"
                    className="text-center din text-sm font-bold help__input"
                    ref={emailRef}
                    maxLength={50}
                    required
                    onBlur={() => {
                      dlTrackEvent('form_interaction', {
                        element: 'enviar mensaje',
                        form_name: 'mas dudas',
                        form_field: 'ingresa tu email',
                        form_action: 'empty',
                        section: `help`
                      })
                    }}
                  />
                </>
              )
            }
            <Input
              type="tel"
              placeholder={textServices.findByText('help/phone', { es: "Ingresa tu teléfono", en: "Enter your phone" })}
              className="text-center din text-sm font-bold help__input"
              ref={phoneRef}
              defaultValue={phoneNumber}
              maxLength={13}
              required
              onBlur={() => {
                dlTrackEvent('form_interaction', {
                  element: 'enviar mensaje',
                  form_name: 'mas dudas',
                  form_field: 'ingresa tu telefono',
                  form_action: 'empty',
                  section: `help`
                })
              }}
            />
            <AreaTextHelp
              ref={textRef}
              className="flex-1 din text-sm font-bold p-4 focus:outline-none help__textarea"
              placeholder={textServices.findByText('help/textArea/placeholder', { es: 'Dinos, ¿para qué somos buenos?', en: 'Tell us, what are we good for?' })}
              required
              onBlur={(e) => {
                dlTrackEvent('form_interaction', {
                  element: 'enviar mensaje',
                  form_name: 'mas dudas',
                  form_field: 'tus dudas',
                  form_action: e.target.value ? 'complete' : 'empty',
                  section: `help`
                })
              }}
            ></AreaTextHelp>
            <ButtonHelp
              className='help__button'
              onClick={() => {
                dlTrackEvent('form_send', {
                  element: 'enviar mensaje',
                  form_name: 'mas dudas',
                  form_field: 'faqs',
                  section: `help`
                })
              }}
            >
              {textServices.findByText('help/textArea/button', { es: 'ENVIAR MENSAJE', en: 'SEND MESSAGE' })}
            </ButtonHelp>
          </form>
        </div>
      </DivBgHelp>
    </SectionHelp>
  )
}

export default Help
