import UsePercentageProfile from 'hooks/use-percentage-profle'
import useRouter from 'hooks/use-router'
import { useEffect } from 'react'
import { usePhotographer } from 'views/photographer/context'

function useGuard() {

  const { pathname, history, search } = useRouter()
  const protectedRoutes = []  //  [ '/photographer/upload', '/photographer/start?isYou=true']
  const [{ current, winners }, { setError }] = usePhotographer()
  const { percentageProfile } = UsePercentageProfile()

  useEffect(() => {
    if (protectedRoutes.includes(pathname + search) && percentageProfile < 100 && percentageProfile !== 0) {
      setError(900)
      history.push('/photographer/error')
    }
    if (!current && winners) {
      history.push('/photographer/winners')
    }
  }, [pathname, search, percentageProfile, current, winners])

}

export default useGuard