import { SubTitle, Text, Title } from "theme/theme";
import styled from 'styled-components';
import { Type } from './../../../../theme/types/generic.interface';

export const TitleError = styled(Title)`
  font-size: 2.25rem;
  ${({ theme }: Type) => theme?.error?.text?.title}
`

export const SubTitleError = styled(SubTitle)`
  font-size: 1rem;
  ${({ theme }: Type) => theme?.error?.text?.subtitle}
`

export const TextError = styled(Text)`
  ${({ theme }: Type) => theme?.error?.text?.text}
`

export const LiError = styled.li`
  ${({ theme }: Type) => theme?.error?.text?.li}
`

export const IconError = styled.div`
  ${({ theme }: Type) => theme?.error?.text?.icon}
`