import React, { forwardRef, HTMLProps, ForwardRefRenderFunction, useRef, useEffect } from 'react'
import mergeRefs from 'merge-refs'
import { InputST } from '../../theme/theme'

export type InputProps = HTMLProps<HTMLInputElement> & { variant?: boolean }

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { className = '', variant, type, name, ...props },
  ref
) => {
  // @ts-ignore
  const innerRef = useRef(null)

  useEffect(() => {
    if (innerRef.current && (type === 'tel' || type === 'number')) {
      innerRef.current.oninput = function () {
        // @ts-ignore
        this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
      }
    }

    return () => {
      if (innerRef.current) {
        // @ts-ignore
        innerRef.current.oninput = undefined
      }
    }
  }, [type, name])

  return (
    <InputST
      theme={undefined}
      type={type}
      name={name}
      ref={mergeRefs(ref, innerRef) as any}
      variant={variant}
      className={`h-16 p-4 w-full mx-auto rounded-none uppercase input-generic ${className} ${variant && 'outline-none'}`}
      {...props} />
  )
}

const InputWithRef = forwardRef(Input)

export default InputWithRef
