import Button from 'components/button'
import { LoaderContext } from 'contexts/loader-context'
import useMainClass from 'hooks/use-main-cass'
import { useContext, useEffect } from 'react'
import textServices from 'services/text-services'
import useLocalStorage from 'hooks/use-local-storage'
import { TextEmail, TitleEmail } from 'views/mail-verify/text.style'
import AlreadyParticipate from './already-participate'

interface IProps {
  handleLogin: () => Promise<void>
  name: string
}

function Done({ handleLogin, name }: IProps) {

  const [flowType] = useLocalStorage('flowType')
  useMainClass('sign-up__done')

  const { setFooter } = useContext(LoaderContext)

  useEffect(() => {
    setFooter(false)
    return () => setFooter(true)
  }, [])

  if (flowType === 'alreadyParticipate') return <AlreadyParticipate handleLogin={handleLogin} />

  return (
    <section className='w-full flex-1 flex flex-col items-center gap-4 p-7'>
      <div className='flex-1 flex flex-col items-center justify-center gap-6 w-full'>
        <TitleEmail className="text-center text-5xl font-bold uppercase mx-7 overflow-ellipsis overflow-hidden w-full sign-up__done__title">{textServices.findByText('mail/title', { es: name ? <>Excelente<br />{name}</> : <>E-mail validado<br />con éxito</>, en: name ? <>Excellent<br />{name}</> : <>E-mail validated<br />successfully</> }, { name })}</TitleEmail>
        <TextEmail className="din text-center mt-4 sign-up__done__description">{textServices.findByText('mail/description', { es: <>Has confirmado con éxito tu cuenta.<br />Ya puedes ingresar</>, en: <>You have successfully confirmed your account.<br />You can now enter</> })}</TextEmail>
      </div>
      <Button className='uppercase' onClick={handleLogin}>{textServices.findByText('mail/button', { es: 'Ingresar', en: 'Continue' })}</Button>
    </section>
  )
}

export default Done