import textServices from 'services/text-services'
import { Back } from '../../../theme/theme'
import { StartImg } from '../../../theme/start'

function ImageAndText() {
  return (
    <Back className={`flex flex-col flex-1 w-full pt-24 justify-center sm:items-center`}>
      <div className="w-full flex flex-1 flex-col text-lg  font-bold text-center gap-4 items-center justify-evenly">
        <p className='px-7'>
          {textServices.findByText('start/imageandtext/title', { es: <>¿ESTAS LISTO PARA LO QUE SE VIENE?<br />CONOCE</>, en: <>ARE YOU READY FOR WHAT&apos;S AHEAD?<br />KNOW</> })}
        </p>
        <StartImg src={`${AZURE_BASE_URL}/fondo-inicial.svg`} alt="marca" className="max-w-full sm:max-w-sm" />
        <p className='px-7'>
          {textServices.findByText('start/imageandtext/description', { es: <>PÀSALE, PERO ANTES,<br />¿YA TE REGISTRASTE?</>, en: <>PASS HIM, BUT FIRST,<br />HAVE YOU REGISTERED YET?</> })}
        </p>
      </div>
    </Back>
  )
}

export default ImageAndText
