import { forwardRef } from "react"
import styled from "styled-components"
import { InputType } from "theme/types"

const Input = styled.input`
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ::after {
    content: "";
    display: block;
    margin: auto;
    border-radius: 9999px;
    width: 13px;
    height: 13.2px;
  }
  ${({ theme }) => theme?.inputRadio?.input}
`

const CustomRadio = forwardRef<HTMLInputElement, InputType>((props, ref) => {
  return <Input ref={ref} {...props} />
})

CustomRadio.displayName = 'CustomRadio'

export default CustomRadio;