import useRouter from "hooks/use-router";
import { useContext, useEffect, useMemo, useState } from "react"
import { useSession } from "contexts/session-context";
import { LoaderContext } from "contexts/loader-context";
import SurveyService from "services/survey.services";
import { SurveyContext } from "./survey.provider";
import { Survey, SurveyActionEnum, SurveyOptions, SurveyResponse, SurveyTypeEnum } from "./survey.interface";

export const useSurvey = () => {
  const { state, dispatch } = useContext(SurveyContext)
  const { setLoad } = useContext(LoaderContext)
  const [{ data: session }, { setPoints }] = useSession()
  const [selectedSurvey, setSelectedSurvey] = useState<Survey>()
  const [surveyLoading, setSurveyLoading] = useState(false)

  const setError = (err: any) => {
    dispatch({
      type: SurveyActionEnum.SET_ERROR,
      payload: err
    })
  }
  const updatePolarisSurvey = async (select: SurveyResponse[][], surveyId: number) => {  
    const response = await SurveyService.answerSurvey({ surveyId, select, session });
    
    let markAsAnswered = true;
    if (response?.data?.demandSpace) {
      dispatch({
        type: SurveyActionEnum.SET_DEMAND_SPACE,
        payload: response.data.demandSpace
      })
      dispatch({
        type: SurveyActionEnum.SET_INFO_SURVEY,
        payload: { buttonText: response?.data?.buttonText, resultUrl: response?.data?.resultUrl }
      })
      markAsAnswered = false;
    }

    if (response.data.prize) {
      setPoints(response.data.prize.points)
      dispatch({
        type: SurveyActionEnum.SET_PRIZE,
        payload: response.data.prize
      })
      markAsAnswered = false
    }

    if(markAsAnswered) {
      state.survey.isAnswered = true;
      dispatch({
        type: SurveyActionEnum.SET_SURVEY,
        payload: state.survey
      })
    }
  }

  const getSurveys = async () => {
    setSurveyLoading(true)
    setLoad(true)
    try {
      const response = await SurveyService.getSurveys({ session });
      dispatch({ type: SurveyActionEnum.SET_SURVEYS, payload: response.data })
      return response.data
    } catch (err) {
      console.error(err)
    } finally {
      setSurveyLoading(false)
      setLoad(false)
    }
  }

  const findNextSurvey = async (actionType?: SurveyTypeEnum | undefined) => {
    let surveys = state?.surveys
    surveys = await getSurveys();
    return surveys?.find(survey => (survey.activationType === actionType) && !survey.isAnswered)
  }

  const validateSurvey = async (surveyId: string | number, isDirectAccess: boolean) => {
    let returnData = false
    setSurveyLoading(true)
    setLoad(true)
    try {
      const response = await SurveyService.validateSurvey({ surveyId, isDirectAccess, session });

      if (response.data) {
        dispatch({ type: SurveyActionEnum.SET_SURVEY, payload: response.data })
        setSelectedSurvey(response.data)
        returnData = true
      }
    } catch (error) {
      console.error('validate err', error.response?.data.code)
      setError(error.response?.data.code)
      throw error
    } finally {
      setSurveyLoading(false)
      setLoad(false)
    }
    return returnData
  }

  const resetSurvey = () => {
    dispatch({
      type: SurveyActionEnum.RESET,
      payload: null
    })
  }

  const values = { state, selectedSurvey, surveyLoading }
  const actions = useMemo(() => ({ setSelectedSurvey, updatePolarisSurvey, validateSurvey, findNextSurvey, getSurveys, resetSurvey }), [setError, setSelectedSurvey, updatePolarisSurvey, validateSurvey, findNextSurvey, getSurveys, resetSurvey])

  return [values, actions] as const
}

export const useSurveyRedirect = (actionType?: SurveyTypeEnum | undefined, options?: SurveyOptions) => {
  const { autoRedirect = true, } = options ?? {}

  const [, { findNextSurvey }] = useSurvey()
  const { push } = useRouter()

  const hasVisited = (surveyId: number) => {
    const local = JSON.parse(localStorage.getItem(`Survey_${surveyId}`))
    if (!local) return false
    const previousTime = new Date(local)
    const currentTime = new Date()
    const diff = currentTime.getTime() - previousTime.getTime()
    const diffHours = Math.ceil(diff / (1000 * 60 * 60))
    return diffHours < 2
  }

  const redirectToSurvey = async () => {
    const selectedSurvey = await findNextSurvey(actionType);
    if (selectedSurvey) {
      // If the survey is optional and the user has already visited it, we will not redirect
      if (selectedSurvey?.optional && hasVisited(selectedSurvey?.id)) return null
      // If the survey is optional and the user has not visited it, save the date in localstorage and then redirect
      if (selectedSurvey?.optional) localStorage.setItem(`Survey_${selectedSurvey?.id}`, JSON.stringify(new Date()))
      push(`/survey/${selectedSurvey.id}`)
    }
  }

  useEffect(() => {
    const fetchSurveys = async () => {
      if (autoRedirect) redirectToSurvey()
    };
    fetchSurveys();
  }, [])

  return { redirectToSurvey }
}