import SearchIcon from "components/icon/SearchIcon"
import UbicationIcon from "components/icon/ubicationIcon"
interface IProps {
  searchLocation: string,
  handleSearch: () => void,
  userLocationFn: () => void,
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  inputRef: React.RefObject<HTMLInputElement>,
  search: any
  errorSearch: string | null
}

const ContainerInput = (props: IProps) => {

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    props.searchLocation !== '' && props.handleSearch()
  }

  return (
    <>
      <form onSubmit={handleSubmit} className="marketplace__container-input">
        <div className='gap-1 flex-1 marketplace__container-input__input' style={props.errorSearch ? { borderColor: '#CF202F' } : {}}>
          <input type="search" placeholder='Ingresa tu dirección' ref={props.inputRef} value={props.searchLocation} onChange={props.handleInputChange} />
          <button type="submit">
            <SearchIcon />
          </button>
        </div>
        <button type="button" onClick={props.userLocationFn} className="marketplace__container-input__location">
          <UbicationIcon />
        </button>
      </form>
      {props.errorSearch && <p className='text-xs marketplace__container-input__error' style={{ color: '#CF202F' }}>{props.errorSearch}</p>}
    </>
  )
}

export default ContainerInput
