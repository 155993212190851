import More from 'components/icon/more'
import { useContext, useState } from 'react'
import Button from 'components/button'
import { ErrorInput } from 'theme/theme'
import { LoaderContext } from 'contexts/loader-context'
import { PhotographerServices } from 'services/photographer.services'
import { useSession } from 'contexts/session-context'
import Check from 'components/icon/check'
import { usePhotographer } from 'views/photographer/context'
import { Link } from 'react-router-dom'
import useGuard from 'views/photographer/components/hooks/useGuard'
import ErrorView, { ErrorListView } from 'components/error-view'
import { AxiosError } from 'axios'
import InputWithRef from 'components/input'
import ProfileService from 'services/profile-services'
import { yupResolver } from '@hookform/resolvers/yup'
import formServices from 'services/form-builder-services'
import { ErrorMessage } from '@hookform/error-message'
import { useForm } from 'react-hook-form'
import useLocalStorage from 'hooks/use-local-storage'
import textServices from 'services/text-services'
import CONFIGS from 'build/Config'
import { ButtonTitleUpload, ButtonUpload, ContainerImageUpload, DescriptionContainerUpload, DescriptionDoneUpload, DescriptionTextUpload, DescriptionTitleUpload, DivTitleUpload, InputLabelUpload, TitleDoneUpload, TitleUpload } from './custom.style'

const Container = ({ children }: { children: React.ReactNode }) => (
  <section className="flex flex-col">
    <div className='w-screen max-w-lg z-1 flex flex-col flex-1 items-center'>
      <DivTitleUpload className="flex items-center px-10 w-screen py-6">
        <TitleUpload className="flex-grow  max-w-full">SUBA SU FOTO</TitleUpload>
      </DivTitleUpload>
      <article className='flex-1 w-full flex flex-col items-center px-7 pb-11 gap-6'>
        {children}
      </article>
    </div>
  </section>
)

function Upload() {

  const [{ data: session, user }, { setUser }] = useSession()
  const { setLoad } = useContext(LoaderContext)
  const [{ current }, { setPhoto }] = usePhotographer()
  const [image, setImage] = useState<string | ArrayBuffer | null>(null)
  const [file, setFile] = useState<File | null>(null)
  const [done, setDone] = useState(false)
  const [error, setError] = useState<{ list?: string[]; error: string | number }>(null)
  const showInstagram = !CONFIGS.notShowFields?.includes('instagram')
  const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(formServices.formGenerateSchema(["instagram"])) })
  const [state, setState] = useLocalStorage('state', {})
  useGuard()

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    const regexType = /^image\/(png|jpeg)$/
    if (!regexType.test(file?.type || '')) return setError({ error: 1506 })
    if (file) {
      setFile(file)
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        setImage(reader.result)
      }
    }
  }

  const handleReset = () => {
    setImage(null)
  }

  const handleInstagram = async (instagram: string) => {
    if (!showInstagram || !instagram) return null
    const response = await ProfileService.instagram(instagram, session)
    setUser(response.data)
    setState({ ...state, user: response.data })
  }

  const handleSubmitForm = async (dataInput: any) => {
    setLoad(true)
    try {
      await handleInstagram(dataInput?.instagram)
      const formData = new FormData();
      formData.append('file', file as File)
      formData.append("input", JSON.stringify({ ImageFileName: file?.name, ImageContentType: file?.type }));
      const response = await PhotographerServices.upload(current?.id, formData, session)
      setPhoto({
        code: response?.data?.code,
        profileId: response?.data?.profile?.id,
        name: file?.name,
        imageUrl: response?.data?.imageUrl,
        statusId: response?.data?.status,
        status: response?.data?.status,
        isWinner: false
      })
      setDone(true)
    } catch (error) {
      const err = error as AxiosError
      const list = err.response?.data?.data
      const code = err.response?.data.code || 99999
      setError({ error: code, list: list })
    } finally {
      setLoad(false)
    }
  }

  const handleRetry = () => {
    setError(null)
  }

  if (error) {

    if (error?.list)
      return <ErrorListView buttonMenu={handleRetry} list={error?.list} code={error?.error} onRetry={handleRetry} />

    return <ErrorView code={error?.error} buttonMenu={handleRetry} onRetry={handleRetry} />
  }

  if (done) {
    return (
      <section className="flex flex-col">
        <div className='w-screen max-w-lg z-1 flex flex-col px-7 py-7 flex-1 items-center'>
          <div className='flex-1 w-full flex flex-col items-center justify-center gap-8'>
            <div className='h-32 w-32 rounded-full border-4 border-white flex items-center justify-center p-6'>
              <Check className='w-full h-full' />
            </div>
            <TitleDoneUpload>FOTOGRAFÍA CARGADA CON ÉXITO</TitleDoneUpload>
            <DescriptionDoneUpload>Ha subido con éxito su foto</DescriptionDoneUpload>
          </div>
          <Link to='/photographer/start?isYou=true' className='w-full'><Button>VER FOTOGRAFÍA</Button></Link>
        </div>
      </section>
    )
  }

  if (image) {
    return (
      <Container>
        <ContainerImageUpload className='flex-1 w-full bg-cover bg-no-repeat bg-center' style={{ backgroundImage: `url(${image})`, minHeight: '325px' }} />
        <form onSubmit={handleSubmit(handleSubmitForm)} className='w-full flex flex-col gap-4'>
          {
            showInstagram && !user?.instagram && (
              <div className='w-full gap-2 flex flex-col'>
                <InputLabelUpload htmlFor='instagram'>INGRESE SU CUENTA DE INSTAGRAM</InputLabelUpload>
                <InputWithRef type='string' name='instagram' placeholder='@' id='instagram' {...register("instagram")} />
                <ErrorInput>
                  <ErrorMessage errors={errors} name="instagram" />
                </ErrorInput>
              </div>
            )
          }
          <Button>SUBIR</Button>
        </form>
        <p className={`din text-center uppercase underline cursor-pointer`} style={{ fontSize: 10 }} onClick={handleReset}>{textServices.findByText('backButton', { es: 'VOLVER', en: 'BACK TO' })}</p>
      </Container>
    )
  }

  return (
    <Container>
      <div className='flex-1'>
        <ButtonUpload className=' w-56 h-52 flex flex-col items-center justify-center py-4 cursor-pointer'>
          <input type='file' className='hidden' accept='.png,.jpeg,.jpg' onChange={handleUpload} />
          <More className='flex-1' />
          <ButtonTitleUpload>AGREGAR</ButtonTitleUpload>
        </ButtonUpload>
      </div>
      <DescriptionContainerUpload className='w-full flex flex-col'>
        <DescriptionTitleUpload className='px-5 py-5 w-full text-center'>REGLAS</DescriptionTitleUpload>
        <div className='flex-1 flex items-center justify-center py-6'>
          <ol className='list-decimal text-left pl-8 pr-4 list-outside'>
            <li><DescriptionTextUpload>No tocar ni beber el producto.</DescriptionTextUpload></li>
            <li><DescriptionTextUpload>No pueden aparecer más productos que personas, evitar el consumo en exceso.</DescriptionTextUpload></li>
            <li><DescriptionTextUpload>No menores de 18 años.</DescriptionTextUpload></li>
            <li><DescriptionTextUpload>El producto debe salir siempre cerrado y en buen estado.</DescriptionTextUpload></li>
          </ol>
        </div>
      </DescriptionContainerUpload>
    </Container >
  )
}

export default Upload