import GuardedRoute from 'routes/guards'
import useRedirect from 'hooks/use-redirect'
import useRouter from 'hooks/use-router'
import { useEffect, useState } from 'react'
import RoutesWithNotFound from 'utilities/routes-with-not-found'
import routes from './definition'

const Routes = () => {

  const { pathname } = useRouter()
  const [{ url }, { setRedirect, clearRedirect }] = useRedirect()
  const [currentTimeout, setCurrentTimeout] = useState<any>(null)

  useEffect(() => {
    if (currentTimeout) clearTimeout(currentTimeout)
    const timeout = setTimeout(() => {
      if (url && url === pathname) clearRedirect()
    }, 500)
    setCurrentTimeout(timeout)
  }, [pathname, url])

  useEffect(() => {
    if (pathname !== '/callback') {
      setRedirect(pathname + window.location.search)
    }
  }, [])

  return (
    <RoutesWithNotFound>
      {routes.map((props, index) => (
        <GuardedRoute key={`route-${index}`} {...props} />
      ))}
    </RoutesWithNotFound>
  )
}

export default Routes
