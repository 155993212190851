import React from 'react'

function History() {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.5 0.25C7.90495 0.25 6.40755 0.632487 5.00781 1.39746C3.6569 2.12988 2.55013 3.139 1.6875 4.4248V1.8125H0.125V7.28125H5.59375V5.71875H2.76172C3.44531 4.5306 4.37305 3.58659 5.54492 2.88672C6.76562 2.17057 8.08398 1.8125 9.5 1.8125C10.916 1.8125 12.2344 2.17057 13.4551 2.88672C14.627 3.57031 15.5547 4.49805 16.2383 5.66992C16.9544 6.89062 17.3125 8.20898 17.3125 9.625C17.3125 11.041 16.9544 12.3594 16.2383 13.5801C15.5547 14.752 14.627 15.6797 13.4551 16.3633C12.2344 17.0794 10.916 17.4375 9.5 17.4375C8.08398 17.4375 6.76562 17.0794 5.54492 16.3633C4.37305 15.6797 3.44531 14.752 2.76172 13.5801C2.04557 12.3594 1.6875 11.041 1.6875 9.625H0.125C0.125 11.3177 0.556315 12.8965 1.41895 14.3613C2.23275 15.7773 3.34766 16.9004 4.76367 17.7305C6.22852 18.5768 7.80729 19 9.5 19C11.1927 19 12.7715 18.5768 14.2363 17.7305C15.6523 16.9004 16.7754 15.7773 17.6055 14.3613C18.4518 12.8965 18.875 11.3177 18.875 9.625C18.875 7.93229 18.4518 6.35352 17.6055 4.88867C16.7754 3.47266 15.6523 2.35775 14.2363 1.54395C12.7715 0.681315 11.1927 0.25 9.5 0.25ZM8.71875 3.375V10.4062H14.1875V8.84375H10.2812V3.375H8.71875Z" fill="currentColor" />
    </svg>

  )
}

export default History