import Button from "components/button"
import useRouter from "hooks/use-router"
import textServices from "services/text-services"
import { Checkbox, DivCheckbox, Label, PCheckbox, ErrorInput, SelectGender } from "theme/theme"
import { BackBtn, TitleLogin } from "views/login/text.style"
import Input from 'components/input'
import { ErrorMessage } from "@hookform/error-message"
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup"
import formServices from "services/form-builder-services"
import { useState, useEffect, useContext } from "react"
import Password from "components/password"
import ProfileService from "services/profile-services"
import { dlTrackEvent } from "components/dataLayer/dataLayer"
import { LoaderContext } from "contexts/loader-context"

interface IProps {
  handleSubmit: (data: { name: string, email: string, password: string, subscribe: boolean, termsOfUse: boolean }) => Promise<void>
}

function FormBaseInformation(props: IProps) {

  const { history } = useRouter()
  const { register, handleSubmit, formState: { errors }, setError } = useForm({ resolver: yupResolver(formServices.formGenerateSchema(['firstName', 'password', 'email'])) })
  const [termsOfUse, setTermsOfUse] = useState(false)
  const [subscribe, setSubscribe] = useState(false)
  const [gender, setGender] = useState([])
  const { setLoad } = useContext(LoaderContext)

  async function getGenres() {
    setLoad(true)
    try {
      const response = await ProfileService.genres()
      setGender(response.data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoad(false)
    }
  }

  useEffect(() => {
    getGenres()
  }, [])

  const handleBack = () => history.goBack()

  const handleTermsOfUse = () => {
    if (!termsOfUse) setError('termsOfUse', null)
    gtag('event', 'Click', {
      event_category: GTM_EVENT_CATEGORY,
      event_label: 'MODIFICAR INFORMACION - Acepto terminos y condiciones de privacidad',
      value: '10'
    })
    setTermsOfUse((state) => !state)
  }

  const handleSubscribe = () => {
    gtag('event', 'Click', {
      event_category: GTM_EVENT_CATEGORY,
      event_label: 'MODIFICAR INFORMACION - Acepto recibir novedades',
      value: '10'
    })
    setSubscribe((state) => !state)
  }

  const handleFormSubmit = async (data: { firstName: string, email: string, password: string }) => {
    if (!termsOfUse) {
      return setError('termsOfUse', { type: 'required', message: 'Debes aceptar los términos y condiciones' })
    }
    await props?.handleSubmit({ subscribe, termsOfUse, ...data } as any)
  }

  return (
    <form className="flex-1 px-7 flex items-center flex-col pb-7 gap-4" onSubmit={handleSubmit(handleFormSubmit)}>
      <TitleLogin className="text-center text-5xl font-bold uppercase mb-7 mx-7 w-full login__title">{textServices.findByText('signup/title', { es: 'Registro', en: 'Register' })}</TitleLogin>
      <div className="flex-1 flex flex-col w-full gap-4">
        <div className="gap-2 flex flex-col input-container">
          <Label className="text-start text-2xl font-bold uppercase input-label">
            {textServices.findByText('profile/form/information/firstname/label', { es: '¿Cómo te llamas?', en: 'What is your name?' })}
          </Label>
          <Input
            className="text-center saira font-bold text-sm"
            type="text"
            placeholder={textServices.findByText('profile/form/information/firstname/placeholder', { es: 'PON TU NOMBRE AQUÍ', en: 'PUT YOUR NAME HERE' })}
            name="firstName"
            {...register('firstName')}
          />
          <ErrorInput className="input-error">
            <ErrorMessage errors={errors} name="firstName" />
          </ErrorInput>
        </div>
        <div className="gap-2 flex flex-col input-container">
          <Label className="text-start text-2xl font-bold uppercase input-label">
            {textServices.findByText('profile/form/information/gender/label', { es: '¿Cómo te identificas?', en: 'How do you identify yourself?' })}
          </Label>
          <SelectGender
            name="genreId"
            {...register('genreId', {
              onBlur: (e) => {
                dlTrackEvent('form_interaction', {
                  form_field: 'genero',
                  form_action: e.target.value ? 'complete' : 'empty',
                  form_name: 'datos personales',
                  section: 'sign up :: step 2'
                })
              }
            })}
            className="text-xs font-bold p-4 w-full min-h-2 mx-auto saira uppercase input-select"
          >
            <option value="">{textServices.findByText('profile/form/information/gender/placeholder', { es: 'Selecciona como te consideras', en: 'Select how you consider yourself' })}</option>
            {gender?.map((item: { id: number; name: string }) => (
              <option key={item.id} value={item.id}>
                {item?.name}
              </option>
            ))}
          </SelectGender>
          <ErrorInput className='input-error'>
            <ErrorMessage errors={errors} name="genreId" />
          </ErrorInput>
        </div>
        <div className="gap-2 flex flex-col input-container">
          <Label className="text-start text-2xl font-bold uppercase input-label">{textServices.findByText('profile/form/information/email/label', { es: 'Correo electrónico', en: 'E-mail address' })}</Label>
          <Input
            className="text-center saira font-bold text-sm"
            type="text"
            placeholder={textServices.findByText('profile/form/information/email/placeholder', { es: 'roberto@gmail.com', en: 'robert@gmail.com' })}
            name="email"
            {...register('email')}
          />
          <ErrorInput className="input-error">
            <ErrorMessage errors={errors} name="email" />
          </ErrorInput>
        </div>
        <div className="gap-2 flex flex-col input-container">
          <Label className="text-start text-2xl font-bold uppercase input-label">{textServices.findByText('profile/form/information/password/label', { es: 'Contraseña', en: 'Password' })}</Label>
          <Password
            className="text-center saira font-bold text-sm"
            type="password"
            placeholder="***********"
            name="password"
            {...register('password')}
          />
          <ErrorInput className="input-error">
            <ErrorMessage errors={errors} name="password" />
          </ErrorInput>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <DivCheckbox className="gap-4 flex items-center cursor-pointer input-container" onClick={handleTermsOfUse}>
          <Checkbox active={termsOfUse} className={`h-6 w-6 flex items-center justify-center`} id="termsOfUse" />
          <PCheckbox className="flex-1 dinPro input-checkbox-label">
            {
              textServices.findByText('signup/termsOfUse/label', {
                es: <>
                  Acepto los{' '}
                  <a
                    href={`${process.env.PUBLIC_URL}/terms-and-conditions`}
                    rel="noreferrer"
                    target="_blank"
                    className="underline"
                  >
                    Términos y Condiciones
                  </a>{' '}
                  y el{' '}
                  <a
                    href={`${process.env.PUBLIC_URL}/privacy-politics`}
                    rel="noreferrer"
                    target="_blank"
                    className="underline"
                  >
                    Aviso de privacidad
                  </a>
                  .
                </>,
                en: <>
                  I accept the{' '}
                  <a
                    href={`${process.env.PUBLIC_URL}/terms-and-conditions`}
                    rel="noreferrer"
                    target="_blank"
                    className="underline"
                  >
                    Terms and Conditions
                  </a>{' '}
                  and the{' '}
                  <a
                    href={`${process.env.PUBLIC_URL}/privacy-politics`}
                    rel="noreferrer"
                    target="_blank"
                    className="underline"
                  >
                    Privacy Policy
                  </a>
                  .
                </>
              })
            }
          </PCheckbox>
        </DivCheckbox>
        <ErrorInput className="input-error">
          <ErrorMessage errors={errors} name="termsOfUse" />
        </ErrorInput>
        <DivCheckbox className="gap-4 flex items-center cursor-pointer input-container" onClick={handleSubscribe}>
          <Checkbox className={`h-6 w-6 flex items-center justify-center`} id="suscribe" active={subscribe} />
          <PCheckbox className="flex-1 dinPro input-checkbox-label">{textServices.findByText('signup/suscribe/label', { es: 'Quiero recibir novedades.', en: 'I want to receive news.' })}</PCheckbox>
        </DivCheckbox>
        <ErrorInput className="input-error">
          <ErrorMessage errors={errors} name="subscribe" />
        </ErrorInput>
      </div>
      <Button>{textServices.findByText('signup/button', { es: "TERMINAR REGISTRO", en: "END REGISTRATION" })}</Button>
      <button
        type="button"
        className="cursor-pointer"
        onClick={handleBack}
      >
        <BackBtn className={`din text-center hover:text-red-100 uppercase underline button-back`} style={{ fontSize: 10 }}>
          {textServices.findByText('backButton', { es: 'VOLVER', en: 'BACK TO' })}
        </BackBtn>
      </button>
    </form>
  )
}

export default FormBaseInformation