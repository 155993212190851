import { Link } from "react-router-dom"
import { dlTrackEvent } from "components/dataLayer/dataLayer"

const MemoramaSilverIcon = () => {
  return (
    <Link
      to="/memorama/list"
      className="w-full px-0"
      onClick={() => {
        gtag('event', 'event_to_ga', { event_category: 'juega_memorama', event_label: 'Home links' })
        dlTrackEvent('user_interaction', { action:'click', element:'memorama icon', section: 'home' })
      }}
    >
      <div
        className={`button-nav flex items-center justify-between h-24 text-center font-medium text-xs px-10 gap-5`}
        style={{ 
          background: '#00A7B5',
          borderRadius: "10px"
        }}
      >
        <img src={`${AZURE_ICONS_URL}/${THEME}/memorama-icon.svg`} alt="Icono memorama" />
        <img src={`${AZURE_ICONS_URL}/${THEME}/memorama-text.svg`} alt="Texto memorama" />
      </div>
    </Link>
  )
}

export default MemoramaSilverIcon