import { COOKIESMX, COOKIESBACK } from 'constants/api'
import useAxios from 'axios-hooks'
import LoadingScreen from 'components/loading-screen'
import styled from 'styled-components'
import { Type } from 'theme/types'
import ErrorView from 'components/error-view'
import useRouter from 'hooks/use-router'
import CONFIGS from 'build/Config'
import stylecookies from './stylecookies.module.scss'

const Div = styled.div`
  ${({ theme }: Type) => theme?.termsAndConditions?.div}
`

const Cookies = () => {
  const { push } = useRouter()

  const fetchURL = CONFIGS.cookies !== 'MX' ? COOKIESBACK : COOKIESMX
  const [{ loading, error, data }] = useAxios({
    url: fetchURL,
    headers: { 'Ph-Api-Id': PH_API_ID ?? 1 }
  })

  if (error) {
    return <ErrorView code={9999} buttonMenu={() => push('/home')} />
  }

  if (loading) {
    return <LoadingScreen />
  }

  if (data) {
    const body = data.replace("{", "").replace('"body":"', '').replace('";', '').replace('}', '');
    return <Div className={stylecookies.wrappercookies}>
      {/* <!-- OneTrust Cookies List start --> */}
      <div id="ot-sdk-cookie-policy"></div>
      {/* <!-- OneTrust Cookies List end --> */}
      <div dangerouslySetInnerHTML={{ __html: body }}></div>
    </Div>
  }
}

export default Cookies
