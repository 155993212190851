import { Type } from 'theme/types';
import styled from "styled-components";
import { Text, Title } from "theme/theme";

export const TitleHome = styled(Title)`
  ${({ theme }: Type) => theme?.home?.text?.title}
`

export const TextHome = styled(Text)`
  ${({ theme }: Type) => theme?.home?.text?.text}
`

export const TextHomeSecondary = styled(Text)`
  ${({ theme }: Type) => theme?.home?.text?.textSecondary}
`

export const ContainerTextProgress = styled(Text)`
  ${({ theme }: Type) => theme?.home?.containerTextProgress}
`

export const TextHomeButton = styled(Text)`
  ${({ theme }: Type) => theme?.home?.text?.textButton}
`
export const TextPoints = styled.p`
  ${({ theme }: Type) => theme?.home?.text?.points}
`

export const TextDynamics = styled.p`
  ${({ theme }: Type) => theme?.home?.text?.dynamics}
`

export const FloatImgHome = styled.img`
  ${({ theme }: Type) => theme?.home?.sello}
`

export const TextArrow = styled.span`
  ${({ theme }: Type) => theme?.home?.textArrow}
`
export const TextTotalPoints = styled.p`
  ${({ theme }: Type) => theme?.home?.textTotalPoints}
`

export const TextArrowColor = styled.div`
  ${({theme}: Type) => theme?.home?.textArrowColor}
`