import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { SubtitleSurveyLabel, SubtitleLabelSurvey } from "theme/survey";
import { Option } from "contexts/survey-context/survey.interface";
import Radio from "components/radio";
import { dlTrackEvent } from "components/dataLayer/dataLayer";

export interface CheckBoxProps {
  questionId: number;
  title: string;
  subtitle: string;
  items?: Option[];
  onChange: (key: number, selected: string[]) => void;
}

function RadioMapper(props: CheckBoxProps) {
  const [selected, setSelected] = useState<string[]>([]);
  const titleRef = useRef<HTMLHeadingElement>(null);

  const onClick: MouseEventHandler<HTMLDivElement> = (event) => {
    const itemId = event.currentTarget.id as string;
    dlTrackEvent('user_interaction', {
      action: 'select',
      element: `${props?.title} :: ${itemId}`,
      section: `survey`
    })    
    gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'SURVEY - Seleccionar encuesta', value: '10' })
    return setSelected([itemId]);
  };

  useEffect(() => {
    props.onChange(props?.questionId, selected);
  }, [selected, titleRef]);

  return (
    <>
      <div className="p-2 w-full label-survey">
        <SubtitleSurveyLabel className="font-black text-2xl text-center md:text-3xl subtitle-option" ref={titleRef}>
          {props.title}
        </SubtitleSurveyLabel>
        <SubtitleLabelSurvey className="text-lg md:text-xl subtitle-option">
          {props.subtitle}
        </SubtitleLabelSurvey>
      </div>
      <div className={`flex flex-col my-2 px-2 gap-4 md:mx-auto w-full text-black container-radio`}>
        {props.items &&
          props.items.map(({ optionId, option }) => {
            const key = `${optionId}`;

            return (
              <Radio name={props.title} value={key} onClick={onClick} key={key} id={key}>
                {option}
              </Radio>
            );
          })}
      </div>
    </>
  );
};

export default RadioMapper