import React from 'react'

const Edit = () => {
  return (
    <svg width="27" height="26" viewBox={`0 0 27 26`} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.0552 0.959258C22.6235 0.963488 22.2015 1.1089 21.8713 1.42986L10.4652 12.527C10.2237 12.7501 10.1438 12.95 10.0226 13.2281C9.89734 13.5155 9.78213 13.8613 9.68295 14.2597C9.48459 15.0568 9.3653 16.0371 9.53893 17.014C9.5816 17.2752 9.80659 17.4983 10.0742 17.5449C11.0783 17.7138 12.0858 17.5977 12.9051 17.4048C13.3145 17.3083 13.6803 17.1861 13.9757 17.0642C14.2615 16.9463 14.467 16.8786 14.6963 16.6436C14.7039 16.6357 14.709 16.6314 14.7169 16.6238L26.1021 5.54697C26.8567 4.81249 26.6806 3.61595 25.9271 2.88282L24.6095 1.60086C24.2329 1.23442 23.7354 1.00309 23.2403 0.959992C23.1783 0.954704 23.1167 0.959463 23.055 0.959992L23.0552 0.959258ZM23.0552 2.24122C23.0917 2.23937 23.1294 2.23646 23.1685 2.24122C23.3251 2.26025 23.5014 2.33481 23.6729 2.50164C24.1169 2.93284 24.547 3.36221 24.9904 3.79368C25.3334 4.12733 25.3108 4.49351 25.1654 4.63497L24.2801 5.49635L21.9126 3.1928L22.7979 2.33142C22.8525 2.27828 22.946 2.24734 23.0552 2.24127L23.0552 2.24122ZM20.9861 4.10402L23.3433 6.39756L13.7598 15.7221C13.7448 15.7252 13.6234 15.8154 13.4611 15.8823C13.2451 15.9714 12.9402 16.0719 12.5965 16.1528C12.0595 16.2794 11.4283 16.3238 10.8156 16.2929C10.7854 15.6999 10.8305 15.0796 10.9596 14.5601C11.0427 14.2254 11.146 13.9391 11.2375 13.7289C11.3063 13.5713 11.4 13.4457 11.4022 13.4285L20.9861 4.10402ZM3.1984 5.44602C1.56753 5.44628 0.233887 6.74388 0.233887 8.33068V23.0731C0.233887 24.6599 1.56753 25.9575 3.19868 25.9575H18.3513C19.9825 25.9575 21.3161 24.6599 21.3161 23.0731V13.1374C21.321 12.7988 21.0053 12.4873 20.6572 12.4873C20.3091 12.4873 19.9934 12.7988 19.9983 13.1374V23.0731C19.9983 23.972 19.2752 24.6756 18.3513 24.6756H3.19865C2.2748 24.6756 1.55171 23.972 1.55171 23.0731V8.33068C1.55171 7.4318 2.2748 6.72825 3.19865 6.72825H14.7278C15.0759 6.73301 15.396 6.42606 15.396 6.08738C15.396 5.7487 15.0759 5.4415 14.7278 5.44652L3.1984 5.44602Z" />
    </svg>
  )
}

export default Edit
