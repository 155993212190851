const levels = [
  // {
  //   level: 0,
  //   name: "CHELADA",
  //   moves: 13,
  //   popupCover: `${AZURE_BASE_URL}/popup-nivel1_chelada-portada.webp`,
  //   popupMixCover: `${AZURE_BASE_URL}/popup-nivel1_chelada-mezcla.webp`,
  //   ingameMix: `${AZURE_BASE_URL}/portada-game-1.webp`,
  //   ingameBg: `playable__lvl__one`,
  //   ingameMixItems: ['limon_rodaja', 'sal', 'sol'],
  //   special: `lata_chelada`,
  //   items: ["sol", "sal", "limon_rodaja", "valija", "pelota", "hoja", "lima_gajo", "emoji"],
  //   flag: `${AZURE_BASE_URL}/nivel1_icono.webp`,
  //   mixAchieved: [
  //     `${AZURE_BASE_URL}/nievl1_logro-insignia.webp`,
  //     `${AZURE_BASE_URL}/logro-vacio.webp`,
  //     `${AZURE_BASE_URL}/logro-vacio.webp`
  //   ],
  //   combinationGif: `${AZURE_BASE_URL}/20puntos-magoyada.gif`
  // },
  {
    level: 1,
    name: "CHELADA",
    moves: 20,
    popupCover: `${AZURE_BASE_URL}/popup-nivel1_chelada-portada.webp`,
    popupMixCover: `${AZURE_BASE_URL}/popup-nivel1_chelada-mezcla.webp`,
    ingameMix: `${AZURE_BASE_URL}/portada-game-1.webp`,
    ingameBg: `playable__lvl__one`,
    ingameMixItems: ['limon_rodaja', 'sal', 'sol'],
    special: `lata_chelada`,
    items: ["sol", "sal", "limon_rodaja", "valija", "pelota", "hoja", "lima_gajo", "emoji"],
    flag: `${AZURE_BASE_URL}/nivel1_icono.webp`,
    mixAchieved: [
      `${AZURE_BASE_URL}/nievl1_logro-insignia.webp`,
      `${AZURE_BASE_URL}/logro-vacio.webp`,
      `${AZURE_BASE_URL}/logro-vacio.webp`
    ],
    combinationGif: `${AZURE_BASE_URL}/20puntos-magoyada.gif`
  },
  {
    level: 2,
    name: "MANGOYADA",
    moves: 15,
    popupCover: `${AZURE_BASE_URL}/popup-nivel2_mangoyada-portada.webp`,
    popupMixCover: `${AZURE_BASE_URL}/popup-nivel2_mangoyada-mezcla.webp`,
    ingameMix: `${AZURE_BASE_URL}/nivel2_mangoyada-mezcla.webp`,
    ingameBg: `playable__lvl__two`,
    ingameMixItems: ['mango', 'chamoy', 'sol'],
    special: `lata_mangoyada`,
    items: ["sol", "chamoy", "mango", "palmeras", "boletos", "tomate_chico", "limon_gajo", "camara_foto"],
    flag: `${AZURE_BASE_URL}/nivel2_icono.webp`,
    mixAchieved: [
      `${AZURE_BASE_URL}/nievl1_logro-insignia.webp`,
      `${AZURE_BASE_URL}/nievl2_logro-insignia.webp`,
      `${AZURE_BASE_URL}/logro-vacio.webp`
    ],
    combinationGif: `${AZURE_BASE_URL}/20puntos-magoyada.gif`
  },
  {
    level: 3,
    name: "CLAMATO",
    moves: 10,
    popupCover: `${AZURE_BASE_URL}/popup-nivel3_clamato-portada.webp`,
    popupMixCover: `${AZURE_BASE_URL}/popup-nivel3_clamato-mezcla.webp`,
    ingameMix: `${AZURE_BASE_URL}/nivel3_clamato-mezcla.webp`,
    ingameBg: `playable__lvl__three`,
    ingameMixItems: ['tomate_grande', 'almeja', 'sol'],
    special: `lata_clamato`,
    items: ["sol", "almeja", "tomate_grande", "balde_arena", "lima_gajo", "mango_gajo", "ojotas", "lima_rodaja"],
    flag: `${AZURE_BASE_URL}/nievl3_icono.webp`,
    mixAchieved: [
      `${AZURE_BASE_URL}/nievl1_logro-insignia.webp`,
      `${AZURE_BASE_URL}/nievl2_logro-insignia.webp`,
      `${AZURE_BASE_URL}/nievl3_logro-insignia.webp`,
    ],
    combinationGif: `${AZURE_BASE_URL}/20puntos-clamato.gif`
  },
];

export {
  levels
}