import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/button";
import Input from "components/input";
import { LoaderContext } from "contexts/loader-context";
import useMainClass from "hooks/use-main-cass";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import formServices from "services/form-builder-services";
import textServices from "services/text-services";
import { SelectGender, ReturnText, LabelStreet, SubtitleStreet, ErrorInput } from "theme/theme";

function StepOne({ state, helper, handleChange, onFormSubmitEndPoint, prevStep }: any) {

  useMainClass('form__step__two')

  const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(formServices.formGenerateSchema(["neighborhood"])) })

  function onFormSubmit(data: any) {
    handleChange(data)
    onFormSubmitEndPoint(data)
  }

  const { setFooter } = useContext(LoaderContext)

  useEffect(() => {
    setFooter(false)
    return () => setFooter(true)
  }, [])

  return (
    <div className="flex flex-col flex-1 md:pt-10">
      <form className="flex h-full justify-between flex-col gap-4 mx-7 mb-7" onSubmit={handleSubmit(onFormSubmit)} noValidate>
        <LabelStreet className="text-center text-xl font-medium saira title-street">
          {textServices.findByText('update/street/street', { es: 'Calle', en: 'Street' })} {`${state?.street1} ${state?.streetNumber}` || textServices.findByText('notInformation', { es: 'Sin información', en: 'No information' })}, <br />
          {helper?.city?.name || textServices.findByText('notInformation', { es: 'Sin información', en: 'No information' })}, <br />
          {helper?.state?.name || textServices.findByText('notInformation', { es: 'Sin información', en: 'No information' })}, <br />
          {textServices.findByText('update/street/cp', { es: 'CP:', en: 'CP - Postal code:' })} {state?.postalCode || textServices.findByText('notInformation', { es: 'Sin información', en: 'No information' })}
        </LabelStreet>
        <SubtitleStreet className="text-base text-center subtitle-street">{textServices.findByText('update/street/subtitle', { es: 'Si es correcto, completa los últimos datos y dale a siguiente, ya falta poco.', en: 'If it is correct, fill in the last data and click on the next button, you are almost there.' })}</SubtitleStreet>
        <div className="flex-1 flex flex-col gap-4">
          <div className="gap-2 flex flex-col input-container">
            <SelectGender
              name="neighborhood"
              className="text-black w-full h-16 din p-4 pr-7 input-select"
              {...register("neighborhood")}
            >
              {helper.neighborhood.map((neighborhood: any) => (
                <option value={neighborhood.id} key={`${neighborhood?.name}-${neighborhood?.id}`}>
                  {neighborhood?.name}
                </option>
              ))}
            </SelectGender>
            <ErrorInput className="input-error">
              <ErrorMessage errors={errors} name="neighborhood" />
            </ErrorInput>
          </div>
          <div className="gap-2 flex flex-col input-container">
            <Input
              className="text-center saira font-bold text-sm"
              type="text"
              placeholder={textServices.findByText('profile/form/information/city/placeholder', { es: "Municipio", en: 'City' })}
              name="city"
              {...register("city", { value: helper?.city?.name })}
              disabled
            />
            <ErrorInput className="input-error">
              <ErrorMessage errors={errors} name="city" />
            </ErrorInput>
          </div>
          <div className="gap-2 flex flex-col input-container">
            <Input
              className="text-center saira font-bold text-sm"
              type="text"
              placeholder={textServices.findByText('profile/form/information/state/placeholder', { es: "Estado", en: 'State' })}
              name="state"
              {...register("state", { value: helper?.state?.name })}
              disabled
            />
            <ErrorInput className="input-error">
              <ErrorMessage errors={errors} name="state" />
            </ErrorInput>
          </div>
        </div>
        <Button>
          <p className="uppercase">{textServices.findByText('profile/form/address/stepTwo/button', { es: "Actualizar", en: 'Update' })}</p>
        </Button>
        <button type="button" className="cursor-pointer" onClick={() => {
          prevStep()
          gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'MODIFICAR DIRECCION - Volver atras', value: '10' })
        }}>
          <ReturnText
            className={`din text-center mt-3 hover:text-red-100 uppercase underline button-back`}
            style={{ fontSize: 10 }}
          >
            {textServices.findByText('backButton', { es: 'VOLVER', en: 'BACK TO' })}
          </ReturnText>
        </button>
      </form>
    </div>
  )
}

export default StepOne;