/* eslint-disable no-case-declarations */
import { Photographer, Photos, Winners } from "./types"

export type PhotographerActionType = 'SET_PHOTOS' | 'SET_WINNERS' | 'SET_ERROR' | 'SET_CURRENT' | 'SET_PHOTO'

export interface PhotographerState {
  current?: Photographer
  photos?: Photos[]
  winners?: Winners[]
  error?: number
}

export interface PhotographerAction {
  type: PhotographerActionType
  payload: PhotographerState | Photos[] | Winners[] | Photographer | number | Photos
}

function photographerReducer(state: PhotographerState, action: PhotographerAction): PhotographerState {
  switch (action.type) {
  // Set one photo in the state on next line
  case 'SET_PHOTO': 
    const photos = [...state.photos]
    photos.unshift(action.payload as Photos)
    return { ...state, photos: photos}
  case 'SET_CURRENT':
    return { ...state, current: action.payload as Photographer }
  case 'SET_PHOTOS':
    return { ...state, photos: (action.payload as Photos[]) }
  case 'SET_WINNERS':
    return { ...state, winners: action.payload as Winners[] }
  case 'SET_ERROR':
    return { ...state, error: action.payload as number }  
  default:
    return state
  }
}

export default photographerReducer