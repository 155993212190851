import { EXTERNAL_CALLBACK } from "constants/api";
import { useContext, useEffect, useState } from "react";
import useMainClass from "hooks/use-main-cass"
import useRouter from "hooks/use-router"
import ErrorView from "components/error-view";
import { dlTrackError, dlTrackEvent } from "components/dataLayer/dataLayer";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { LoaderContext } from "contexts/loader-context";
import axios, { AxiosError } from "axios";
import ProfileService from "services/profile-services";
import { useAgegate } from "hooks/use-agegate";
import { useSession } from "contexts/session-context";
import ViewId from "./component/view-id";
import ViewData from "./component/view-data";
import ViewEmail from "./component/view-email";
import ViewComplete from "./component/view-complete";

export default function WifiCallback() {
  useMainClass('wifi__callback')
  const firbaseAuth = getAuth();
  const { location, push } = useRouter();
  const [stepper, setStepper] = useState<'id' | 'data' | 'email' | "complete">('id')
  const [data, setData] = useState<{ email: string, profileId: number, firebaseAccessToken: string, providerAccessToken: string, accessToken: string, credentials: any, displayName: string, mustValidate: boolean }>(null)
  const [error, setError] = useState<number>(null)
  const { setLoad } = useContext(LoaderContext)
  const [resend, setResend] = useState(0);
  const [, { setSession, setUser }] = useSession()
  const { setAgeLocalForm } = useAgegate();

  const isStepper = (match: string) => stepper === match

  const fetcher = async (callback: any) => {
    setLoad(true)
    try {
      return await callback()
    } catch (err) {
      const typedError = err as AxiosError
      const { code, message } = typedError.response?.data || {}
      dlTrackError({
        error_type: 'login',
        error_code: { code },
        error_message: { message },
        section: 'sign up'
      })
      setError(code)
    } finally {
      setLoad(false)
    }
  }

  const handleResend = async () => {
    if (resend > 2) return alert('No se puede reenviar el código, intenta más tarde.')
    setLoad(true)
    try {
      dlTrackEvent('user_interaction', {
        action: 'click',
        element: 'reenviar código',
        section: 'sign up'
      })
      gtag('event', 'reenviar', {
        event_label: 'Registro - Email',
        method: "Email"
      })
      await ProfileService.resendEmailVerify({ email: data?.email })
      setResend(resend + 1)
    } catch (error) {
      console.error(error)
    } finally {
      setLoad(false)
    }
  }

  const login = async (credentials: any) => {
    const loginResponse = await ProfileService.signInWithEmailAndPassword({ credentials })
    const isLegal = setAgeLocalForm(new Date(loginResponse.data?.profile?.birthday ?? ''))
    if (!isLegal?.error) {
      setSession(credentials)
      setUser(loginResponse.data?.profile)
      dlTrackEvent('login', { method: 'email' })
      gtag("event", "login", {
        method: "Email"
      });
      push('/home')
    } else {
      console.error('No dispone de la edad necesaria para participar')
    }
  }

  const getData = () => fetcher(async () => {
    const oobCode = location?.hash?.split('#')[1];
    const { data } = await axios.post(EXTERNAL_CALLBACK, { oobCode }, { headers: { 'Ph-Api-Id': PH_API_ID ?? 1 } })
    const credentials = await signInWithCustomToken(firbaseAuth, data.firebaseAccessToken);
    const accessToken = (credentials.user as any).accessToken;
    setData({ ...data, accessToken, credentials })

    if (!data?.mustValidate) login(credentials)
  })

  useEffect(() => {
    getData()
  }, [])

  if (error) return <ErrorView code={error} buttonMenu={() => setError(null)} onRetry={() => setError(null)} />

  if (isStepper('complete')) return <ViewComplete handleLogin={() => fetcher(async () => await login(data?.credentials))} displayName={data?.displayName} />

  if (isStepper('email')) return <ViewEmail email={data?.email} accessToken={data?.accessToken} setStepper={setStepper} handleResend={handleResend} resend={resend} />

  if (isStepper('data')) return <ViewData email={data?.email} name={data?.displayName} setStepper={setStepper} handleResend={handleResend} />

  if (isStepper('id')) return <ViewId id={Number(data?.profileId)} setStepper={setStepper} />

  return <></>
};