import { IMenu } from 'components/header/types';

export enum GeneralActionsType {
  SET_MENU = 'SET_MENU',
}

interface SetMenuAction {
  type: GeneralActionsType.SET_MENU;
  payload: IMenu | null;
}

export type GeneralActions = SetMenuAction;

export interface GeneralState {
  menu: IMenu | null;
}

export const initialState: GeneralState = {
  menu: null,
};

export const generalReducer = (state: GeneralState, action: GeneralActions): GeneralState => {
  switch (action.type) {
  case GeneralActionsType.SET_MENU:
    return {
      ...state,
      menu: action.payload,
    };
  default:
    return state;
  }
};
