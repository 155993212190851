import { AxiosError } from 'axios'
import ErrorView from 'components/error-view'
import LoadingScreen from 'components/loading-screen'
import { useSession } from 'contexts/session-context'
import { useSocial } from 'contexts/social-context'
import useRouter from 'hooks/use-router'
import { useEffect, useState } from 'react'
import { useLocalStorage } from 'react-use'
import ProfileService from 'services/profile-services'

function InstagramAuth() {

  const { query, push } = useRouter()
  const [{ data: session, user }] = useSession()
  const [, { setCurrent }] = useSocial()
  const [error, setError] = useState<number | null>(null)
  const [state, setState] = useLocalStorage('state', {})

  const handleLogin = async (code: string) => {
    try {
      const response = await ProfileService?.authInstagram(code, session)
      if (response) {
        setState({ ...state, user: { ...user, ...response?.data?.profile } })
        setCurrent(response?.data?.images)
        push('/album/redirect/Social/profile')
      }
    } catch (err) {
      setError((err as AxiosError).response?.data.code)
    }
  }

  useEffect(() => {
    if (query?.error_reason) return setError(query?.error_reason)
    if (query?.code && session && !error) handleLogin(query?.code)

    return () => {
      setError(null)
    }
  }, [session])

  if (error) {
    return <ErrorView buttonMenu={() => push('/home')} code={error} onRetry={() => setError(null)} />
  }

  return <LoadingScreen />
}

export default InstagramAuth