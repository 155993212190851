import styled from 'styled-components'

function HnkWaiterId() {
  const TextID = styled.h1`
    color: #b54540;
    font-family: Balinese Culture;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  `

  const Container = styled.div`
    height: 90px;
    border-radius: 10px;
    background-color: black;
    box-shadow: rgba(0, 0, 0, 0.71) 0px 0px 18px 0px;
    background-image: url(${AZURE_BASE_URL}/bg-id.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
  `

  return (
    <Container className="w-full drop-shadow-sm bg-white flex justify-between items-center">
      <TextID>MI ID</TextID>
      <img src={`${AZURE_ICONS_URL}/${THEME}/arrow-id.svg`} alt='Flecha' />
    </Container>
  )
}

export default HnkWaiterId
