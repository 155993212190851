import styled from 'styled-components'
import { Crown } from 'theme/profile'
import { Type, isActiveType, isSelectedType } from '../types'

export const DivTriviaList = styled.div`
  color: ${(props: Type) => props.theme?.trivias?.colorTextTrivia};
`

export const H1PrefPicker = styled.h1`
  color: ${(props: Type) => props.theme?.trivias?.ColorTextPref};
  ${(props: Type) => props.theme?.trivias?.textPrefTitle}
`

export const PPrefPicker = styled.p`
  color: ${(props: Type) => props.theme?.trivias?.ColorTextPref};
  ${(props: Type) => props.theme?.trivias?.TextPref}
`

export const TriviaDescription = styled.p`
  ${(props: Type) => props.theme?.trivias?.triviaDescription};
`

export const TriviaNumber = styled.p`
  ${(props: Type) => props.theme?.trivias?.triviaNumber};
`

export const DivTriviaListBorder = styled.div.attrs((props: isActiveType) => ({
  isActive: props.isActive,
  theme: props.theme,
  ...props
})) <isActiveType>`
  opacity: ${(props: isActiveType) => !props.isActive && '0.8'};
  background-color: ${(props: isActiveType) => !props.isActive && props.theme?.trivias?.backgroundTriviaDisabled};
  border-color: ${(props: Type) => props.theme?.trivias?.borderBottomTrivia};
`

export const DivTitleTriviaList = styled.div`
  color: ${(props: Type) => props.theme?.trivias?.colorTriviaTop};
  background-color: ${(props: Type) => props.theme?.trivias?.backgroundTitleTrivia};
  border-top: ${(props: Type) => props.theme?.trivias?.TitleTriviaBorder};
  border-bottom: ${(props: Type) => props.theme?.trivias?.TitleTriviaBorder};
`

export const H2TitleTriviaList = styled.h2`
  background-color: ${(props: Type) => props.theme?.trivias?.backgroundTitleClassTrivia};
  color: ${(props: Type) => props.theme?.trivias?.colorTitleTrivia};
  font-family: ${(props: Type) => props.theme?.trivias?.fontTitleTrivia};
  font-size: ${(props: Type) => props.theme?.trivias?.fontSizeTitleTrivia};
  ${(props: Type) => props.theme?.trivias?.titleTrivia}
`

export const DivModalContinue = styled.div`
  color: ${(props: Type) => props.theme?.trivias?.colorTextContinue};
  background: ${(props: Type) => props.theme?.trivias?.backgroundContinue};
  ${(props: Type) => props.theme?.trivias?.backgroundContinueImage && `
    background-image: url(${AZURE_BASE_URL}/textura.${props.theme?.loading?.config?.bgTexture || 'svg'})
  `};
`

export const ButtonModalContinue = styled.button`
  color: ${(props: Type) => props.theme?.trivias?.colorTextContinue};
`

export const DivSelector = styled.div`
  color: ${(props: Type) => props.theme?.trivias?.colorTextSelect};
`

export const DivSelectorTitle = styled.div`
  background-color: ${(props: Type) => props.theme?.trivias?.backgroundSelectTitle};
  color: ${(props: Type) => props.theme?.trivias?.colorTextSelectTitle};
`

export const LiSelectorOption = styled.li.attrs((props: isSelectedType) => ({
  isSelected: props.isSelected,
  theme: props.theme,
  ...props
})) <isSelectedType>`
  border-color: ${(props: isSelectedType) => props.theme?.trivias?.borderSelectOption};
  background-color: ${(props: isSelectedType) =>
    props.isSelected ? props.theme?.trivias?.backgroundOptionSelect : props.theme?.trivias?.backgroundOptionDesactive};
`

export const DivSelectOption = styled.div.attrs((props: isSelectedType) => ({
  isSelected: props.isSelected,
  theme: props.theme,
  ...props
})) <isSelectedType>`
  background-color: ${(props: isSelectedType) => props.isSelected && props.theme?.trivias?.backgroundOptionCircleActive};
`

export const H1Congrats = styled.h1`
    color: ${(props: Type) => props.theme?.trivias?.colorTextCongrats};
`

export const PCongrats = styled.p`
    color: ${(props: Type) => props.theme?.trivias?.colorTextCongrats};
`

export const QuestionHeader = styled.div`
  ${(props: Type) => props.theme?.trivias?.questions?.header};
`

export const QuestionNumber = styled.h2`
  ${(props: Type) => props.theme?.trivias?.questions?.number};
`

export const QuestionTitle = styled.h2`
  ${(props: Type) => props.theme?.trivias?.questions?.title};
`
export const DivTriviaContainer = styled.div`
  ${(props: Type) => props.theme?.trivias?.divTriviaContainer};
`
export const TriviaTitle = styled.h2`
  ${(props: Type) => props.theme?.trivias?.titleRight};
`

export const TriviaCountdown = styled.span`
  ${(props: Type) => props.theme?.trivias?.countdownStyle};
`

export const TriviaSubtitle = styled.p`
  ${(props: Type) => props.theme?.trivias?.subtitleCenter};
`

export const TriviaSubtitleSpan = styled.span`
  ${(props: Type) => props.theme?.trivias?.subtitleCenterSpan};
`

export const TriviaPrize = styled.h3`
  ${(props: Type) => props.theme?.trivias?.prizeCenter};
`

export const TriviaStock = styled.p`
  ${(props: Type) => props.theme?.trivias?.triviaStockCenter};
`

export const TriviaTime = styled.p`
  ${(props: Type) => props.theme?.trivias?.triviaTime};
`

export const TriviaTimeNumbers = styled.span`
  ${(props: Type) => props.theme?.trivias?.triviaTimeNumbers};
`

export const TriviaCost = styled.div`
  ${(props: Type) => props.theme?.trivias?.triviaCost};
`

export const TriviaCostNumber = styled.span`
  ${(props: Type) => props.theme?.trivias?.triviaCostNumber};
`

export const TriviaModalH1 = styled.h1`
  ${(props: Type) => props.theme?.trivias?.triviaModalH1};
`

export const TriviaModelP = styled.p`
  ${(props: Type) => props.theme?.trivias?.triviaModelP};
`

export const TriviaModalWarning = styled.p`
  ${(props: Type) => props.theme?.trivias?.triviaModalWarning};
`

export const TriviaTitlePage = styled.h1`
${(props: Type) => props.theme?.trivias?.triviaTitlePage};
`

export const SpanColorTitle = styled.span`
  ${(props: Type) => props.theme?.trivias?.spanColorTitle};
`

export const TriviaNotFoundContainer = styled.div`
  ${(props: Type) => props.theme?.trivias?.triviaNotFoundContainer};
`

export const TriviaNotFoundText = styled.p`
  ${(props: Type) => props.theme?.trivias?.triviaNotFoundText};
`

export const CrownTrivia = styled(Crown)`
  ${(props: Type) => props.theme?.trivias?.crownTrivia};
`
// TRIVIA IMG

export const QuestionImgContainer = styled.div`
  ${(props: Type) => props.theme?.trivias?.questionImgContainer};
`

export const QuestionImg = styled.img`
  ${(props: Type) => props.theme?.trivias?.questionImg};
`

export const QuestionImgTitleContainer = styled.div`
  ${(props: Type) => props.theme?.trivias?.questionImgTitleContainer};
`

export const QuestionImgTitle = styled.h2`
  ${(props: Type) => props.theme?.trivias?.questionImgTitle};
`

export const ColorCountdown = styled.p`
  ${(props: Type) => props.theme?.trivias?.colorCountdown};
`

export const CountdownContainer = styled.div`
  ${(props: Type) => props.theme?.trivias?.countdownContainer};
`

export const QuestionContainerTwoQuestions = styled.div`
  ${(props: Type) => props.theme?.trivias?.questionContainerTwoQuestions};
`

export const ImgLarge = styled.img`
  ${(props: Type) => props.theme?.trivias?.imgLarge};
`

export const OptionStyle = styled.p`
  ${(props: Type) => props.theme?.trivias?.optionStyle};
`
export const LiBorderColor = styled.div`
  ${(props: Type) => props.theme?.trivias?.liBorderColor};
`