import ButtonCloseMenu from 'components/button-close'

function PopUp({ children, handleMenu = () => null }: { children: JSX.Element | JSX.Element[], handleMenu: () => void }) {
  return (
    <section className='flex flex-col p-7 flex-1'>
      <ButtonCloseMenu 
        open={true}
        paramFn={handleMenu}
      />
      {children}
    </section>
  )
}

export default PopUp