import { GET_REFERRALS } from "constants/api"
import useAxios from "axios-hooks"
import { LoaderContext } from "contexts/loader-context"
import { useSession } from "contexts/session-context"
import { useContext, useEffect, useState } from "react"
import textServices from "services/text-services"
import { useBg } from "hooks/use-white-bg"
import modules from 'build/modules'
import { ButtonLeftReferrals, ButtonRightReferrals, CodeReferrals, SharedTextReferrals, SubtitleReferrals, TextDetailsReferrals, TitleReferrals, WinnerTextReferrals, ShowDetailsReferrals, Hr, SectionReferrals } from "./custom.styles"

function Referrals() {

  const [{ user, data: session }] = useSession()
  const [{ data = [], loading }] = useAxios<any>({
    url: GET_REFERRALS,
    headers: { Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}` }
  })
  const { setLoad } = useContext(LoaderContext)
  const [details, setDetails] = useState<boolean>(false)
  const [referrals, setReferrals] = useState<{ id: number, fullName: string, nick: string }[]>([])
  const [isUseSecondaryBg, setIsUseSecondaryBg] = useState(useBg)

  useEffect(() => {
    setLoad(loading)
    return () => {
      setLoad(false)
    }
  }, [loading])

  useEffect(() => {
    if (!details) {
      setIsUseSecondaryBg(useBg)
    }
  }, [useBg])

  useEffect(() => {
    const event = new CustomEvent('background-color', {
      detail: {
        shouldUseWhiteBg: details ? modules?.json?.referrals?.details?.useSecondBackground : isUseSecondaryBg
      }
    })

    window.dispatchEvent(event)
  }, [details])

  useEffect(() => {
    data.length > 0 && setReferrals(data?.map((referral) => ({
      id: referral?.id,
      fullName: `${referral?.firstName} ${referral?.lastName}`,
      nick: referral?.nickName
    })))
  }, [data])

  if (details) {
    return (
      <section className="flex-1 flex flex-col px-6 gap-3 text-black">
        <Hr className="border-black border mt-4" />
        <WinnerTextReferrals className="flex items-center justify-between p-6">
          <b>Ganadas</b>
          <b>{data.length}</b>
        </WinnerTextReferrals>
        <Hr className="border-black border" />
        {
          referrals.length > 0 ? referrals?.map(({ fullName, nick }) => (
            <div className="flex items-center justify-between p-6" key={fullName}>
              <TextDetailsReferrals>{nick}</TextDetailsReferrals>
              <TextDetailsReferrals>Ganada</TextDetailsReferrals>
            </div>
          ))
            : <TextDetailsReferrals className="text-center">Aún no usaron tu código de referido</TextDetailsReferrals>
        }
        <p
          className="din flex text-center items-end justify-center text-xs underline uppercase mb-6 flex-1 p-4"
          onClick={() => {
            gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'REFERRALS - SIGUE INVITANDO', value: '10' })
            setDetails(false)
          }}
        >
          SIGUE INVITANDO
        </p>
      </section>
    )
  }

  const handleLeft = () => {
    navigator.clipboard
      .writeText(`${URL_BASE}${process.env.PUBLIC_URL}/signup?referralCode=${user?.referralCode}`)
      .then(() => console.log("text copied"),
        () => console.log("error copying text"))
  }

  const handleRight = () => {
    window.navigator.share({
      title: textServices.findByText('refferal/title', 'Código de referido'),
      text: `${textServices.findByText('refferal/text1', `¡Hola! ¡Usa mi código `)} ${user?.referralCode} ${textServices.findByText('refferal/text2', ` para registrarte!`)}`,
      url: `${URL_BASE}${process.env.PUBLIC_URL}/signup?referralCode=${user?.referralCode}`
    });
  }

  return (
    <SectionReferrals className="flex-1 flex flex-col px-6 gap-3 text-black">
      <div className="text-center w-full mt-8 flex flex-col items-center md:items-start gap-4">
        <TitleReferrals className="font-bold uppercase text-4xl">{textServices.findByText('referrals/title', 'INVITA A UN AMIGO PARA TENER MÁS CHANCES DE GANAR')}</TitleReferrals>
        <SubtitleReferrals className="text-xl din">{textServices.findByText('referrals/description', 'Comparte tu código con amigos y aumenta tus chances de ganar.')}</SubtitleReferrals>
        <SharedTextReferrals className="my-4 text-base text-center w-full">{textServices.findByText('referrals/subtitle', <>RECUREDA ESTAS PARTICIPACIONES<br /><b>NO VENCEN</b></>)}</SharedTextReferrals>
      </div>
      <CodeReferrals className="flex items-center justify-center text-center p-4 border border-black h-20 dinPro font-bold text-2xl">{user?.referralCode}</CodeReferrals>
      <div className="flex gap-3 items-center justify-center w-full h-16">
        <ButtonLeftReferrals className="p-4 dinPro font-bold text-xs h-full bg-black text-white flex-1" onClick={handleLeft}>
          {textServices.findByText('referrals/left', 'COPIAR LINK')}
        </ButtonLeftReferrals>
        <ButtonRightReferrals className="p-4 dinPro font-bold text-xs h-full flex-1" onClick={handleRight}>
          {textServices.findByText('referrals/right', 'COMPARTIR')}
        </ButtonRightReferrals>
      </div>
      <Hr className="border-black border mt-4" />
      <WinnerTextReferrals className="flex items-center justify-between p-6">
        <b>Ganadas</b>
        <b>{data.length}</b>
      </WinnerTextReferrals>
      <Hr className="border-black border" />
      <ShowDetailsReferrals
        className="din flex text-center items-end justify-center text-xs underline uppercase mb-6 flex-1 p-4"
        onClick={() => {
          gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'REFERRALS - Ver detalles', value: '10' })
          setDetails(true)
        }}
      >
        VER DETALLE
      </ShowDetailsReferrals>
    </SectionReferrals>
  )
}

export default Referrals