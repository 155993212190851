import React from 'react'
import { Link } from 'react-router-dom'
import { ButtonNavStyle } from 'theme/home'

function AmstelIdNav() {
  return (
    <Link to='/profile/identificacion' className="w-full" onClick={() => { gtag('event', 'event_to_ga', { event_category: 'navegacion_perfil', event_label: 'Home links' }) }}>
      <ButtonNavStyle 
        className={`button-nav flex items-center justify-between py-3 pl-5 px-2 h-24 text-center font-medium text-xs bg-cover bg-no-repeat bg-center`} 
        style={{ 
          background: '#193973', 
          border: '1px solid rgba(0, 0, 0, 0.35)', 
          backgroundImage: `url(${AZURE_BASE_URL}/textura-button-home.png)`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <img src={`${AZURE_ICONS_URL}/${THEME}/amstel-id.svg`} alt="" />
        <img src={`${AZURE_ICONS_URL}/${THEME}/arrow.svg`} alt="" />
      </ButtonNavStyle>
    </Link>
  )
}

export default AmstelIdNav