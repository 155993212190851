import { AxiosError } from "axios";
import FormAddress from "components/form-address";
import { LoaderContext } from "contexts/loader-context";
import { useSession } from "contexts/session-context";
import useLocalStorage from "hooks/use-local-storage";
import useRouter from "hooks/use-router";
import { useContext } from "react";
import ProfileService from "services/profile-services";
import { BackgroundFormAdressUpdate } from "theme/addressform"
// import { ImgAllowCookies } from "theme/allowcookies"

function ProfileAddressUpdate() {

  const [{ data: session, user }, { setUser }] = useSession()
  const { setLoad } = useContext(LoaderContext)
  const [state, setState] = useLocalStorage('state', {})
  const { push } = useRouter()

  const onSubmit = async (data, setError) => {
    setLoad(true)
    try {
      const response = await ProfileService.updateAddres({ address: { ...data }, session })
      setUser(response?.data)
      setState({ ...state, user: response?.data })
      push('/profile/address')
    } catch (err) {
      const typedError = err as AxiosError
      const { code } = typedError.response?.data || {}
      setError(code)
    } finally {
      setLoad(false)
    }
  }

  return (
    <BackgroundFormAdressUpdate className="flex flex-col w-full pt-7">
      <FormAddress handleSubmit={onSubmit} user={user} />
    </BackgroundFormAdressUpdate>
  )
}

export default ProfileAddressUpdate;