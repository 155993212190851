export enum RallyType {
  'Consumo' = 'Consumo',
  'Ticket' = 'Ticket',
  'Historia' = 'Historia',
  'Foto' = 'Foto',
  'Social' = 'Social',
  'Card' = 'Card'
}

export enum RallyStatusEnum {
  PROGRESS = 2,
  TERMINADO = 3,
  COMPLETED = 5
}

interface Goalrule {
  errors: string[]
  goals: string[]
  status: boolean
}

export interface Album {
  imageUrl: string
  id: number
  name: string
  status: string
  statusId: RallyStatusEnum
  validFrom: string
  validTo: string
  multimedias: Multimedia[]
  goalRule: Goalrule
  rallyType: string
}

export interface Multimedia {
  id: number
  code: string
  profileId: number
  name: string
  imageUrl?: string
  isWinner: boolean
  statusId: RallyStatusEnum
  rallyType: RallyType
  status: string
  votes?: number
  body?: string
  validTo?: string
}
