import Button from "components/button";

interface IProps {
  email: string
  name: string
  setStepper: React.Dispatch<React.SetStateAction<"id" | "data" | "email" | "complete">>
  handleResend: () => Promise<void>
}

export default function ViewData({ email, name, setStepper, handleResend }: IProps) {
  return (
    <article className="w-full px-7 my-8 flex flex-col items-center flex-1 gap-4">
      <header>
        <h1 className="title medium text-center">Debemos validar tu cuenta</h1>
        <p className="description medium text-center">Vamos a validar la siguiente información:</p>
      </header>
      <section className="w-full flex-1 flex flex-col gap-3 mt-4">
        <ul className="w-full space-y-4 information">
          <li className="text medium">
            <b>Nombre y Apellido</b>
            <p>{name}</p>
          </li>
          <li className="text medium">
            <b>Mail</b>
            <p>{email}</p>
          </li>
        </ul>
      </section>
      <Button onClick={async () => {
        await handleResend()
        setStepper('email')
      }}>Validar mi cuenta</Button>
    </article>
  )
}