import styled from 'styled-components'
import { Type } from '../types'

export const LoadingDiv = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-repeat: ${(props: Type) => props.theme?.loading.config?.backgroundrepeat};
    background-position: ${(props: Type) => props.theme?.loading.config?.backgroundposition};
    background-size: ${(props: Type) => props.theme?.loading.config?.backgroundsize};
    background-color: ${(props: Type) => props.theme?.loading?.loadingBg};
    ${(props: Type) => props.theme?.loading?.useBackground && `
        background-image: url(${AZURE_BASE_URL}/loader.${props.theme?.loading?.config?.bgLoader || 'svg'}), 
                          url(${AZURE_BASE_URL}/textura.${props.theme?.loading?.config?.bgTexture || 'svg'});
    `}
    ${(props: Type) => props.theme?.loading.config.breakpoints && `
            @media only screen and (min-width: 568px) {
                background-position: ${props.theme?.loading.config[568]?.backgroundposition};
                background-size: ${props.theme?.loading.config[568]?.backgroundsize};
            }
            @media only screen and (min-width: 768px) {
                background-position: ${props.theme?.loading.config[768]?.backgroundposition};
            }
            @media only screen and (min-width: 992px) {
                background-position: ${props.theme?.loading.config[992]?.backgroundposition};
            }
            @media only screen and (min-width: 1400px) {
                background-position: ${props.theme?.loading.config[1400]?.backgroundposition};
            }
        `}
    ${(props: Type) => props.theme?.loading?.useBackground && `
        ${(props: Type) => props.theme?.loading?.backgroundrepeat}
    `}
`

export const Logo = styled.img`
    width: ${(props: Type) => props.theme?.imagesConfig?.logo};
`

export const Spinner = styled.img`
    width: ${(props: Type) => props.theme?.loading.config.spinnerWidth};
`

export const TextLoading = styled.p`
    ${(props: Type) => props.theme?.loading?.textStyle}
`