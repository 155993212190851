import { useEffect, useState } from "react"
import { ModalCancel, ModalCancelP, ModalDescription, ModalMessage } from "theme/header"
import textServices from "services/text-services"
import Button from "components/button"
import Header from "components/header"
import ButtonCloseMenu from "components/button-close"
import { useBg } from "hooks/use-white-bg"
import useMainClass from "hooks/use-main-cass"
import CONFIGS from 'build/Config'

interface IProps {
  handleRetry: () => void
  handleNavigate: () => void
}

function AlertPopUp({ handleRetry, handleNavigate }: IProps) {

  const [shouldUseWhiteBg] = useState(useBg)
  useMainClass('trivias__game__alert')

  useEffect(() => {
    setTimeout(() => {
      const event = new CustomEvent('background-color', {
        detail: {
          shouldUseWhiteBg: CONFIGS?.modalBackgroundTrivias
        }
      })

      window.dispatchEvent(event)
    }, 200)

    return () => {
      const event = new CustomEvent('background-color', {
        detail: {
          shouldUseWhiteBg
        }
      })

      window.dispatchEvent(event)
    }
  }, [])

  return (
    <div className={`fixed top-0 left-0 h-full w-full flex flex-col pb-4 gap-3 items-center justify-center z-50`}>
      <Header />
      <ButtonCloseMenu paramFn={handleRetry} />
      <div className='flex flex-1 flex-col justify-center items-center gap-2 px-5 sm:max-w-sm'>
        <ModalMessage className="trivias__game__alert__title">
          {textServices.findByText('trivias/modal/title', { es: <>¿Estás seguro que <br /> deseas salir?</>, en: <>Are you sure you <br />want to leave?</> })}
        </ModalMessage>
        <ModalDescription className="trivias__game__alert__subtitle">
          {textServices.findByText('trivias/modal/description', { es: <>Si cancelas ahora se perderá tu progreso <br /> y tendrás que volver a empezar.</>, en: <>If you cancel now your progress will be lost <br />and you will have to start over.</> })}
        </ModalDescription>
      </div>
      <div className='flex justify-center items-center flex-col w-full px-5 gap-3 pb-4 sm:max-w-sm'>
        <Button onClick={handleNavigate}>
          <p className="uppercase trivias__game__alert__btn__accept">
            {textServices.findByText('trivia/exit/accept', { es: 'aceptar', en: 'accept' })}
          </p>
        </Button>
        <ModalCancel>
          <ModalCancelP
            onClick={handleRetry}
            className="trivias__game__alert__btn__cancel button-back"
          >
            {textServices.findByText('trivia/exit/cancel', { es: 'cancelar', en: 'cancel' })}
          </ModalCancelP>
        </ModalCancel>
      </div>
    </div>
  )
}

export default AlertPopUp