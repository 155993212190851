/* eslint-disable no-case-declarations */
import { Social } from "./types"

export type SocialActionType = 'SET_PHOTOS' | 'SET_WINNERS' | 'SET_ERROR' | 'SET_CURRENT' | 'SET_PHOTO'

export interface SocialState {
  current?: Social[]
  error?: number
}

export interface SocialAction {
  type: SocialActionType
  payload: SocialState | Social | number
}

function socialReducer(state: SocialState, action: SocialAction): SocialState {
  switch (action.type) {
  case 'SET_CURRENT':
    return { ...state, current: action.payload as Social[] }
  case 'SET_ERROR':
    return { ...state, error: action.payload as number }  
  default:
    return state
  }
}

export default socialReducer