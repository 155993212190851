import { SURVEY_GET_RESPONSE } from 'constants/api'
import axios from 'axios'
import LoadingScreen from 'components/loading-screen'
import { useSession } from 'contexts/session-context'
import { DemandSpace, SurveyInfo } from 'contexts/survey-context/survey.interface'
import useRouter from 'hooks/use-router'
import { useEffect, useState } from 'react'
import DemandSpaceComponent from 'views/survey/components/demandSpace'

function SurveyResponse() {
  const { history, query } = useRouter()
  const [{ data: session }] = useSession()
  const [demandSpace, setDemandSpace] = useState<DemandSpace>(undefined)
  const [surveyInfo, setSurveyInfo] = useState<SurveyInfo>(undefined)

  const getResponse = async () => {
    try {
      const { data } = await axios.get(SURVEY_GET_RESPONSE.replace(':surveyId', query.id), {
        headers: {
          Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`,
          'Ph-Api-Id': PH_API_ID ?? 1
        }
      })

      setDemandSpace(data?.demandSpace)
      setSurveyInfo({
        buttonText: data?.buttonText,
        resultUrl: data?.resultUrl
      })

    } catch (error) {
      console.error(error)
      history.push(`/home`)
    }
  }

  useEffect(() => {
    if (session) getResponse()
  }, [session])

  const redirect = () => history.goBack()

  if (demandSpace) return <DemandSpaceComponent demandSpace={demandSpace} redirect={redirect} surveyInfo={surveyInfo} />

  return (
    <LoadingScreen />
  )
}

export default SurveyResponse