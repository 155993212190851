import { lazy, Suspense } from "react"
import { Route } from "react-router-dom"
import RoutesWithNotFound from "utilities/routes-with-not-found"
import { useSurveyRedirect } from "contexts/survey-context/useSurvey"
import { SurveyTypeEnum } from "contexts/survey-context/survey.interface"
import { PrizesProvider } from "./context"
const Preview = lazy(() => import("./preview"))
const Congrats = lazy(() => import("./congrats"))
const Description = lazy(() => import("./description"))
const ExChange = lazy(() => import("./exchange"))
const StartPrizes = lazy(() => import("./prizes"))
const Error = lazy(() => import("./error"))

const Prizes = () => {
  useSurveyRedirect(SurveyTypeEnum.PRIZES)

  return (
    <Suspense fallback={<>Cargando</>}>
      <PrizesProvider>
        <RoutesWithNotFound>
          <Route path="/prizes" component={StartPrizes} exact />
          <Route path="/prizes/preview/:id" component={Preview} exact />
          <Route path="/prize/description/:id" component={Description} exact />
          <Route path="/prizes/exchange" component={ExChange} exact />
          <Route path="/prizes/congrats" component={Congrats} exact />
          <Route path="/prizes/error" component={Error} exact />
        </RoutesWithNotFound>
      </PrizesProvider>
    </Suspense>
  )
}

export default Prizes
