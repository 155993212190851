import { DivLineStep, HrLineStep } from "theme/home";
import { TextHome } from "views/home/style/text.style";

interface LineStepsProps {
  steps: Record<string, string | boolean | number>[];
}

const LineSteps = ({ steps }: LineStepsProps) => {

  let activeIndex: number
  steps.forEach((step, index) => step.active && (activeIndex = index))

  return (
    <>
      <div className={`w-full flex items-center justify-between`} style={{ width: '90%' }}>
        {steps.map((step, index: number) => {
          return (
            <TextHome className='font-light text-center' key={index}>{`${step?.name}`}</TextHome>
          )
        })
        }
      </div>
      <div className="flex flex-col justify-center items-center relative mt-1" style={{ width: '85%' }}>
        <div className="w-full flex justify-between items-center relative">
          {
            steps.map((step, index: number) => {
              return (
                <>
                  <DivLineStep isActive={index <= activeIndex} key={index} className="h-4 w-4 text-xs font-medium saira flex items-center justify-center rounded-full" />
                  {
                    +steps?.length === (index + 1) ? '' : <HrLineStep isActive={index < activeIndex} className="flex-1 h-1  border-none" />
                  }
                </>
              )
            })
          }
        </div>
      </div>
    </>
  );

}

export default LineSteps