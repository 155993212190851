import Button from "components/button"
import { LoaderContext } from "contexts/loader-context"
import { useSession } from "contexts/session-context"
import useMainClass from "hooks/use-main-cass"
import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import ProfileService from "services/profile-services"
import textServices from "services/text-services"
import CONFIGS from 'build/Config'
import { ProfilePointsContainerPoints, ProfilePointsContainerTotalPoints, ProfilePointsPoints, ProfilePointsTitlePoints, ProfilePointsTitleTotalPoints, ProfilePointsTotalPoints } from "theme/profile"

function ProfilePoints() {

  const [{ data: session }] = useSession()
  const { setLoad } = useContext(LoaderContext)
  const [list, setList] = useState([])
  const [totalPoints, setTotalPoints] = useState(0)
  useMainClass('profile__points')

  const fetchPoints = async () => {
    setLoad(true)
    try {
      const response = await ProfileService.getMovements({ session })
      setList(response?.data?.movements)
      setTotalPoints(response?.data?.totalPoints)
    } catch (error) {
      console.error(error)
    } finally {
      setLoad(false)
    }
  }

  useEffect(() => {
    if (session) {
      fetchPoints()
    }
  }, [session])

  return (
    <div className="text-2xl pb-2 flex flex-col break-all flex-1 profile__points">
      <ProfilePointsContainerTotalPoints className="w-full flex items-center justify-between pl-7 pr-5 profile__points__total">
        <ProfilePointsTitleTotalPoints className="profile__points__total__title">Total {POINTS_NAME}</ProfilePointsTitleTotalPoints>
        <ProfilePointsTotalPoints className="profile__points__total__num">{totalPoints}</ProfilePointsTotalPoints>
      </ProfilePointsContainerTotalPoints>
      <div className="flex-1">
        {
          list.map((item) => (
            <ProfilePointsContainerPoints className="w-full flex items-center justify-between pl-7 pr-4 pt-4 profile__points__category" key={item?.categoryId}>
              <ProfilePointsTitlePoints className="profile__points__category__title">{item?.category}</ProfilePointsTitlePoints>
              {
                item?.categoryId ? (
                  <ProfilePointsPoints to={`/profile/movements/${item?.categoryId}/${item?.category}`} className="flex items-center justify-center gap-2 profile__points__category__num">
                    {item?.amount}
                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="profile__points__category__arrow">
                      <path d="M1.48438 0.140625L6.98438 5.64062L7.32812 6L6.98438 6.35938L1.48438 11.8594L0.765625 11.1406L5.90625 6L0.765625 0.859375L1.48438 0.140625Z" fill="currentColor" />
                    </svg>
                  </ProfilePointsPoints>
                ) : (
                  <p className="profile__points__category__num">
                    {item?.amount}
                  </p>
                )
              }
            </ProfilePointsContainerPoints>
          ))
        }
      </div>
      {
        CONFIGS.profile?.showButtonPoints ? (
          <div className="px-7 pb-5 mt-4">
            <Link to={textServices.findByText('config/prizes', '/prizes')}>
              <Button>{textServices.findByText('profile/button', { es: 'IR A VER LOS PREMIOS', en: 'VIEW PRIZES' })}</Button>
            </Link>
          </div>
        ) : null
      }
    </div>
  )
}

export default ProfilePoints