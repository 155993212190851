import { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useRouter from 'hooks/use-router'
import { useSession } from '../../contexts/session-context'
import { LoaderContext } from '../../contexts/loader-context'

const ToExternal = () => {
  const { match } = useRouter()
  const history = useHistory()
  const code = match.params.code || ''
  const [session] = useSession()
  const { setLoad, setHead, setFooter } = useContext(LoaderContext)

  useEffect(() => {
    setHead(true)
    setFooter(true)
  }, [])

  useEffect(() => {
    setLoad(true)
    
    if (code === 'tpn') {
      window.location.replace(
        `https://heinekenmexico.net/PalNorte/Login.php?id=${session.user?.id}&nickname=${session.user?.nickName}&email=${session.user?.email}&firstName=${session.user?.firstName}&lastName=${session.user?.lastName}`
      )
    } else if (code === 'original') {
      const url = localStorage.getItem('returnOriginal') || ''
      if (url) {
        localStorage.removeItem('returnOriginal')
        window.location.replace(url)
      } else {
        history.push('/home')
      }
    } else {
      history.push('/home')
    }
    setLoad(false)
  }, [session.user])

  return (
    <section className="flex-1 py-0 flex flex-col items-center din large-screen-padding" style={{ maxHeight: 1080 }}>
      Redirigiendo....
    </section>
  )
}

export default ToExternal
