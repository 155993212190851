import { POST_SUSCRIBE_RANKS } from 'constants/api'
import Button from 'components/button'
import { useContext, useState, useEffect } from 'react'
import axios, { AxiosError } from 'axios'
import useRouter from 'hooks/use-router'
import textServices from 'services/text-services'
import images from '../../images.json'
import { useSession } from '../../contexts/session-context'
import { LoaderContext } from '../../contexts/loader-context'
import ButtonCloseMenu from '../../components/button-close'

const Suscribe = () => {
  const [steps, setSteps] = useState(true)
  const [{ data: session }] = useSession()
  const { load, setLoad } = useContext(LoaderContext)
  const { pathname, push } = useRouter()

  useEffect(() => {
    if (pathname === '/ranking/suscribe') {
      setSteps(true)
    } else if (pathname === '/ranking/suscribe/confirm') {
      setSteps(false)
    }
  }, [pathname])

  const handleSuscription = async () => {
    if (steps) {
      gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'SUSCRIBE - Quiero Suscribirme', value: '10' })
      push('/ranking/suscribe/confirm')
    } else {
      setLoad(true)
      try {
        const codeResponse = await axios.post(POST_SUSCRIBE_RANKS, undefined, {
          headers: { Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}` }
        })
        if (codeResponse.status === 200) {
          gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'SUSCRIBE - Confirmar Suscripcion', value: '10' })
          push('/ranking')
          setLoad(false)
        } else {
          setLoad(false)
        }
      } catch (error) {
        const err = error as AxiosError
        if (err.response?.data.code === 66 || err.response?.data.code === 67) {
          setLoad(false)
          gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'SUSCRIBE - Error Suscripcion', value: '10' })
          push(`/error/${err.response?.data.code}`)
        } else if (err.response?.data.code) {
          setLoad(false)
          gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'SUSCRIBE - Error Suscripcion', value: '10' })
          push(`/error/${err.response?.data.code}`)
        }
      }
    }
  }

  return load ? (
    <></>
  ) : (
    <>
      <ButtonCloseMenu
        open={true}
        paramFn={() => {
          gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'SUSCRIBE - Volver', value: '10' })
          push(textServices.findByText('config/prizes', '/prizes'))
        }}
      />
      <div className={`flex w-full flex-col justify-evenly text-black`}>
        <div className="w-full flex justify-center items-end">
          <img
            src={`${AZURE_BASE_URL}${steps ? images['suscribe-instance-2'] : images['suscribe-instance-1']}`}
            alt="Suscribe"
            className="w-52"
          />
        </div>
        <div className="mx-7 mb-7">
          <h1 className="text-center text-3xl font-bold uppercase">
            {steps ? (
              textServices.findByText('ranking/suscribe/title', <>No estás<br />participando</>)
            ) : (
              textServices.findByText('ranking/suscribe/confirm/title', <>Confirmar<br />participación</>)
            )}
          </h1>
          <p className="din text-center mt-4">
            {steps ? (
              textServices.findByText('ranking/suscribe/description', <>Para poder ver el ranking de puntos tienes que estár participando con tus propios {POINTS_NAME}.</>)
            ) : (
              textServices.findByText('ranking/suscribe/confirm/description', <>Estás a punto de anotarte para participar con tus {POINTS_NAME} en el ranking.</>)
            )}
          </p>
          <p className="din text-center font-bold mt-4">
            {steps ? textServices.findByText('ranking/suscribe/subtitle', <>¿Quieres participar?</>) : textServices.findByText('ranking/suscribe/confirm/subtitle', <>¿Seguro que quieres participar?</>)}
          </p>
          <p className="din text-center mt-4">
            {textServices.findByText('ranking/suscribe/politic', 'Recuerda que para ganar el premio necesitas tener más de 21 años, tener visa y pasaporte vigente y esquema de vacunación completo.')}
          </p>
          <Button className="my-4 uppercase w-full" onClick={() => handleSuscription()}>
            {textServices.findByText('ranking/suscribe/button', 'Si, quiero participar')}
          </Button>
          <p className="din text-center text-xs underline uppercase" onClick={() => {
            gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'SUSCRIBE - Volver', value: '10' })
            push(textServices.findByText('config/prizes', '/prizes'))
          }}>
            {textServices.findByText('ranking/suscribe/back', 'No, volver')}
          </p>
        </div>
      </div>
    </>
  )
}

export default Suscribe
