import { ButtonMenu, SpanMenu } from 'theme/header'

interface IProps {
  paramFn: () => void,
  bgTransparent?: boolean
  open?: boolean
}

const ButtonCloseMenu = ({
  paramFn = () => null,
  bgTransparent = false,
  open = false
}: IProps) => {
  return (
    <ButtonMenu
      open={open}
      className="w-24 h-12 py-4 px-8 absolute top-0 right-0 z-50 header-app__button-container"
      style={{ backgroundColor: `${bgTransparent ? 'transparent' : ''}` }}
      onClick={paramFn}
      type="button"
    >
      <SpanMenu open={open} className={`transition block h-0.5 w-8 transform rotate-45 -mb-0.5 button-span`} />
      <SpanMenu open={open} className={`transition block h-0.5 w-8 transform -rotate-45 -mb-0.5 button-span`} />
    </ButtonMenu>
  )
}

export default ButtonCloseMenu
