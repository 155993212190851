import { Album, Multimedia } from '../types'
import AlbumConfig from '../configs/album-config'

export type AlbumActionType = 'SET_ALBUMS' | 'SET_SELECT_MULTIMEDIA' | 'SET_ERROR' | 'SET_SHOW_RULES' | 'SET_ALBUM_CONFIG' | 'SET_SELECT_MULTIMEDIA_PROFILE'

export interface AlbumState {
  albums?: Album[]
  multimedia?: Multimedia
  profile?: Multimedia
  albumConfig?: AlbumConfig
  showRules?: boolean
  error?: number
}

export interface AlbumAction {
  type: AlbumActionType
  payload: AlbumState | Album[] | Album | Multimedia | number  | boolean | AlbumConfig
}

function albumReducer(state: AlbumState, action: AlbumAction): AlbumState {
  switch (action.type) {
  case 'SET_ALBUMS':
    return { ...state, albums: action.payload as Album[] }
  case 'SET_SELECT_MULTIMEDIA':
    return { ...state, multimedia: action.payload as Multimedia }
  case 'SET_SELECT_MULTIMEDIA_PROFILE':
    return { ...state, profile: action.payload as Multimedia }
  case 'SET_ALBUM_CONFIG':
    return { ...state, albumConfig: action.payload as AlbumConfig }
  case 'SET_SHOW_RULES':
    return { ...state, showRules: action.payload as boolean }
  case 'SET_ERROR':
    return { ...state, error: action.payload as number }  
  default:
    return state
  }
}

export default albumReducer