import React from 'react'
import Slider from 'react-slick';
import Left from './left';
import Right from './right';
import './styles.scss'

function Carrousel({ children, initialSlide = 0 }: { children: React.ReactNode, initialSlide?: number }) {

  const settings = {
    className: "center",
    infinite: false, 
    speed: 500,
    initialSlide: initialSlide,
    prevArrow: <Left />,
    nextArrow: <Right />,
    responsive: [
      {
        breakpoint: 420,
        settings: {
          centerMode: false,
        }
      },
      {
        breakpoint: 650,
        settings: {
          centerMode: false,
        }
      },
      {
        breakpoint: 935,
        settings: {
          slidesToShow: 2,
          centerMode: true,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          centerMode: true,
        }
      },
      {
        breakpoint: 4000,
        settings: {
          slidesToShow: 4,
          centerMode: true,
        }
      },
    ]
  };

  return (
    <Slider {...settings} className={`w-full`}>{children}</Slider>
  )
}

export default Carrousel