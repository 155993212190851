import styled from 'styled-components';
import { Type } from 'theme/types';

export const TitlePopUp = styled.h2`
  ${({ theme }: Type) => theme?.health?.popUp?.title}
`

export const TextPopUp = styled.p`
  ${({ theme }: Type) => theme?.health?.popUp?.text}
`

export const LiStyle = styled.li`
  ${({ theme }: Type) => theme?.health?.popUp?.li}
`

export const PopUpContainer = styled.div`
  ${({ theme }: Type) => theme?.health?.popUp?.container}
`
export const LinkWeb = styled.a`
  ${({ theme }: Type) => theme?.health?.popUp?.web}
`