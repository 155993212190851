import { Type } from 'theme/types';
import styled from "styled-components";
import { Text } from "theme/theme";

export const TextOtp = styled(Text)`
  ${({theme}: Type) => theme?.profile?.text?.otp?.text}
`
export const TextOtpTwo = styled(Text)`
  ${({theme}: Type) => theme?.profile?.text?.otp?.textTwo}
`

export const TextOtpLabel = styled.div`
  ${({theme}: Type) => theme?.profile?.text?.otp?.label}
`
export const BackBtn = styled.p`
  ${({theme}: Type) => theme?.profile?.text?.otp?.backBtn}
`

export const TextOtpLabelTwo = styled(TextOtp)`
  ${({theme}: Type) => theme?.profile?.text?.otp?.labelTwo}
`

export const TextOtpResend = styled.span`
  ${({theme}: Type) => theme?.profile?.text?.otp?.resend}
`