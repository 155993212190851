import React, { SVGProps } from 'react'

function BottomArrow(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.5 0.46875C8.66081 0.46875 6.95182 0.932617 5.37305 1.86035C3.8431 2.75553 2.63053 3.9681 1.73535 5.49805C0.807617 7.07682 0.34375 8.78581 0.34375 10.625C0.34375 12.4642 0.807617 14.1732 1.73535 15.752C2.63053 17.2819 3.8431 18.4945 5.37305 19.3896C6.95182 20.3174 8.66081 20.7812 10.5 20.7812C12.3392 20.7812 14.0482 20.3174 15.627 19.3896C17.1569 18.4945 18.3695 17.2819 19.2646 15.752C20.1924 14.1732 20.6562 12.4642 20.6562 10.625C20.6562 8.78581 20.1924 7.07682 19.2646 5.49805C18.3695 3.9681 17.1569 2.75553 15.627 1.86035C14.0482 0.932617 12.3392 0.46875 10.5 0.46875ZM10.5 2.03125C12.0625 2.03125 13.5111 2.42188 14.8457 3.20312C16.1315 3.9681 17.1569 4.99349 17.9219 6.2793C18.7031 7.61393 19.0938 9.0625 19.0938 10.625C19.0938 12.1875 18.7031 13.6361 17.9219 14.9707C17.1569 16.2565 16.1315 17.2819 14.8457 18.0469C13.5111 18.8281 12.0625 19.2188 10.5 19.2188C8.9375 19.2188 7.48893 18.8281 6.1543 18.0469C4.86849 17.2819 3.8431 16.2565 3.07812 14.9707C2.29688 13.6361 1.90625 12.1875 1.90625 10.625C1.90625 9.0625 2.29688 7.61393 3.07812 6.2793C3.8431 4.99349 4.86849 3.9681 6.1543 3.20312C7.48893 2.42188 8.9375 2.03125 10.5 2.03125ZM9.71875 5.15625V12.9688H7.375L10.5 16.0938L13.625 12.9688H11.2812V5.15625H9.71875Z" fill="currentColor" />
    </svg>

  )
}

export default BottomArrow