import React, { SVGProps } from 'react'

function Left(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="17.5" cy="17.5" r="17.5" fill="#D9D9D9" />
      <path d="M9.29289 17.2929C8.90237 17.6834 8.90237 18.3166 9.29289 18.7071L15.6569 25.0711C16.0474 25.4616 16.6805 25.4616 17.0711 25.0711C17.4616 24.6805 17.4616 24.0474 17.0711 23.6569L11.4142 18L17.0711 12.3431C17.4616 11.9526 17.4616 11.3195 17.0711 10.9289C16.6805 10.5384 16.0474 10.5384 15.6569 10.9289L9.29289 17.2929ZM25 17L10 17V19H25V17Z" fill="white" />
    </svg>
  )
}

export default Left