import { Option } from "contexts/survey-context/survey.interface";
import { MouseEventHandler, ReactNode, useEffect, useRef, useState } from "react";
import { DivSurveyMultiSelectOptionContainer, ImgSurveyMultiSelectOption, PSurveyMultiSelectOption, SubtitleSurveyLabel, SubtitleLabelSurvey } from "theme/survey";

export interface MultiSelectProps {
  questionId: number;
  title: ReactNode;
  subtitle: string;
  items?: Option[];
  onChange: (key: number, selected: string[]) => void;
  amountAnswers?: number;
}

const MultiSelect = (props: MultiSelectProps) => {
  const [selected, setSelected] = useState<string[]>([]);
  const titleRef = useRef<HTMLHeadingElement>(null);

  const onClick: MouseEventHandler<HTMLDivElement> = (event) => {
    gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'SURVEY - Seleccionar encuesta', value: '10' })
    const itemId = event.currentTarget.id as string;
    const isSelected = selected.includes(itemId);

    if (isSelected) {
      setSelected((selectedItems) => selectedItems.filter((id) => id !== itemId));
    } else {
      const newSelectedList = [...selected];

      if (newSelectedList.length >= props?.amountAnswers ?? 1) {
        newSelectedList.shift();
      }

      newSelectedList.push(itemId);

      setSelected(newSelectedList);
    }
  };
  
  useEffect(() => {
    props.onChange(props?.questionId, selected);
  }, [selected, titleRef]);

  return (
    <>
      <div className="p-2 w-full label-survey">
        <SubtitleSurveyLabel className="font-black text-2xl text-center md:text-3xl subtitle-option" ref={titleRef}>
          {props.title}
        </SubtitleSurveyLabel>
        <SubtitleLabelSurvey className="text-lg md:text-xl subtitle-option">
          {props.subtitle}
        </SubtitleLabelSurvey>
      </div>
      <div className={`grid justify-center my-2 gap-2 md:mx-auto w-full`} style={{ gridTemplateColumns: props.items.length >= 3 ? 'repeat(3, minmax(0, 1fr))' : 'repeat(2, minmax(0, 0.35fr))' }}>
        {props.items &&
          props.items.map(({ optionId, imageUrl, option }) => {
            const key = `${optionId}`;
            const isSelected = selected.includes(key);

            return (
              <div
                key={key}
                className="aspect-square flex items-end relative multiselect-item"
                id={String(optionId)}
                data-kind={optionId}
                onClick={onClick}
              >
                <DivSurveyMultiSelectOptionContainer
                  isSelected={isSelected}
                  className={`w-full h-full absolute top-0 left-0 border-2 transition-colors ${isSelected ? "border-black active-img" : "border-transparent"} relative`}
                >
                  { option && <PSurveyMultiSelectOption className="font-bold absolute bottom-1 mx-auto text-center w-full flex items-center justify-center option-text">{option}</PSurveyMultiSelectOption>}
                  <ImgSurveyMultiSelectOption src={imageUrl} alt={option} className="w-full aspect-square min-h-6" />
                </DivSurveyMultiSelectOptionContainer>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default MultiSelect;
