import styled from 'styled-components'
import { FromContainerType, Type } from '../types'

export const FormContainer = styled.div.attrs((props: FromContainerType) => ({
  isFocused: props.isFocused,
  theme: props.theme,
  ...props
})) <FromContainerType>`
  background-color: ${props => props.isFocused ? props.theme?.start?.codeBg : props.theme?.start?.codeBgVariations};
  ${props => (props.theme?.redeem?.useTexture && window.location.pathname.split('/')[2] === 'redeem' && props.isFocused) && `background-image: url('${AZURE_BASE_URL}/${props.theme?.redeem?.textureName}')
  ${props.theme?.redeem?.useTextureTwo && `, url('${AZURE_BASE_URL}/textura.${props.theme?.loading?.config?.bgTexture || 'svg'}')`} 
  ${props.theme?.redeem?.useTextureThree && `, url('${AZURE_BASE_URL}/loader.${props.theme?.loading?.config?.bgLoader || 'svg'}')`};`}
`

export const ButtonGo = styled.button.attrs((props: FromContainerType) => ({
  isFocused: props.isFocused,
  theme: props.theme,
  ...props
})) <FromContainerType>`
  background-color: ${props => props.isFocused ? props.theme?.start?.codeButtonVariations : props.theme?.start?.codeButton};
  font-size: 1.6em;
  font-weight: 900;
  color: ${props => props.theme?.start?.codeButtonText};
  border-radius: ${props => props.theme?.start?.codeButtonRadius};
  ${({ theme }) => theme?.redeem?.btnStyle}
`

export const StartImg = styled.img`
  width: ${(props: Type) => props.theme?.imagesConfig?.start?.width};
  @media (min-width: 640px) {
    width: ${(props: Type) => props.theme?.imagesConfig?.start?.maxWidth};
  }
`

export const ProductsImg = styled.img`
  width: ${(props: Type) => props.theme?.imagesConfig?.products.width};
  max-width: ${(props: Type) => props.theme?.imagesConfig?.products.maxWidth};
  max-height: ${(props: Type) => props.theme?.imagesConfig?.products.maxHeight};
  display: ${(props: Type) => props.theme?.imagesConfig?.products.valid ? 'block' : 'none'};
`

export const FormContainerInput = styled.form`
  ${(props: Type) => props.theme?.start?.formContainerInput};
`

export const RedeemTextFocus = styled.p`
  ${(props: Type) => props.theme?.redeem?.redeemTextFocus};
`