import { WHEEL_GET_LIST, WHEEL_VALIDATE, WHEEL_VALIDATE_PLAY } from "constants/api"
import axios from "axios"
import { LoaderContext } from "contexts/loader-context"
import { useSession } from "contexts/session-context"
import { useContext, useEffect, useState } from "react"

export interface IWheelPrizes {
  name: string,
  imageUrl: string
}

export interface IWheelPrizeCongrats {
  title: string,
  points: number,
  description: string,
  productId: number,
  stickerId: number,
  imageUrl: string,
  couponTypeId: number
}

export interface IWheel {
  id: number,
  name: string,
  status: boolean,
  validFrom: string,
  validTo: string,
  difficulty: number,
  costInChances: number,
  imageUrl: null,
  couponId: 7,
  prizes: IWheelPrizes[]
  profileWheelFortuneChances: {
    chances: number
  }
}

export const useWheel = () => {

  const [wheel, setWheel] = useState<IWheel>()
  const [error, setError] = useState<number | string>()
  const [prize, setPrize] = useState<IWheelPrizeCongrats>()
  const [{ data: session }] = useSession()
  const { setLoad } = useContext(LoaderContext)

  const playWheel = async () => {
    setLoad(true)
    try {
      await axios.post(WHEEL_VALIDATE.replace(':id', `${wheel?.id}`), null, { headers: { Authorization: `Bearer ${(session as any)._tokenResponse?.idToken}`, 'Ph-Api-Id': PH_API_ID ?? 1 } })
      const { data: { prize } } = await axios.put(WHEEL_VALIDATE_PLAY.replace(':id', `${wheel?.id}`), null, { headers: { Authorization: `Bearer ${(session as any)._tokenResponse?.idToken}`, 'Ph-Api-Id': PH_API_ID ?? 1 } })
      setWheel((state) => ({...state, profileWheelFortuneChances: { chances: state?.profileWheelFortuneChances?.chances - 1 }}))
      if (prize) {
        setPrize(prize)
        return wheel?.prizes?.findIndex((item) => item.name === prize?.title)
      } else {
        setPrize({
          title: 'MALA SUERTE',
          imageUrl: `${AZURE_BASE_URL}/wheel/not-prize-secondary.webp`,
          productId: 0,
          stickerId: 0,
          couponTypeId: 0,
          description: 'MEJOR SUERTE LA PRÓXIMA',
          points: 0
        })
        return wheel?.prizes?.findIndex((item) => item.name === 'MALA SUERTE')
      }
    } catch (err) {
      setError(err?.response?.data?.code ?? 99999)
    } finally {
      setLoad(false)
    }
  }

  const getWheel = async () => {
    setLoad(true)
    try {
      const { data: dataList } = await axios.get<IWheel[]>(WHEEL_GET_LIST, { headers: { Authorization: `Bearer ${(session as any)._tokenResponse?.idToken}`, 'Ph-Api-Id': PH_API_ID ?? 1 } })
      if (dataList && dataList?.length > 0) {
        const currenWheel = dataList?.find((item) => item?.status)
        const prizes: IWheelPrizes[] = []
        let notPrizes: number = 0

        currenWheel?.prizes?.forEach((item, index) => {
          if (!item?.imageUrl) {
            item.imageUrl = `${AZURE_BASE_URL}/wheel/points.png`
          }

          prizes?.push(item)

          if ((((index + notPrizes) % 2) === 0) && (currenWheel?.difficulty || 6) > (currenWheel?.prizes?.length + notPrizes)) {
            notPrizes = notPrizes + 1
            prizes.push({
              imageUrl: `${AZURE_BASE_URL}/wheel/not-win.png`,
              name: 'MALA SUERTE'
            })
          }
        })

        setWheel({ ...currenWheel, prizes })
      }
    } catch (err) {
      setError(err?.response?.data?.code ?? 99999)
    } finally {
      setLoad(false)
    }
  }

  useEffect(() => {
    getWheel()
  },[])

  return [{ wheel, error, prize }, { playWheel, setError }]
}