function HeinekenID() {
  return (
    <div
      className='w-full bg-white flex justify-between items-center rounded-lg px-5'
      style={{
        height: '80px',
        background: `url(${AZURE_BASE_URL}/item-bg.webp)`,
        backgroundSize: 'cover'
      }}>
      <img src={`${AZURE_BASE_URL}/item-1.webp`} alt="" style={{ width: '50px' }} />
      <img src={`${AZURE_BASE_URL}/item-2.webp`} alt="" style={{ width: '155px' }} />
      <img src={`${AZURE_BASE_URL}/item-3.webp`} alt="" style={{ width: '24px' }} />
    </div>
  )
}

export default HeinekenID
