import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/button";
import Password from "components/password";
import { useForm } from "react-hook-form";
import formServices from "services/form-builder-services";
import textServices from "services/text-services";
import { Label, ErrorInput } from "theme/theme";
import { TitlePassword } from "./text.style";

function ChangePassword({ state, handleChange, onSubmit }: any) {
  const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(formServices.formGenerateSchema(["password", "password_confirm"])) })

  function onFormSubmit(data: any) {
    handleChange(data)
    onSubmit(data.password)
  }

  return (
    <div className="flex flex-col flex-1 md:pt-10">
      <TitlePassword className="text-center text-4xl font-bold uppercase my-7 mx-7 login__password-reset__title">{textServices.findByText('password/title', { es: 'Recuperar contraseña', en: 'Password recovery' })}</TitlePassword>
      <form className="flex h-full justify-between flex-col gap-4 mx-7 mb-7" onSubmit={handleSubmit(onFormSubmit)} noValidate>
        <div className="flex-1 flex flex-col gap-4">
          <div className="gap-2 flex flex-col input-container">
            <Label className="text-start text-2xl font-bold uppercase input-label">{textServices.findByText('password/label/newPassword', { es: 'Contraseña nueva', en: 'New password' })}</Label>
            <Password
              className="text-center saira font-bold text-sm"
              type="password"
              placeholder="**********"
              name="password"
              defaultValue={state?.password}
              {...register("password")}
            />
            <ErrorInput className="input-error">
              <ErrorMessage errors={errors} name="password" />
            </ErrorInput>
          </div>
          <div className="gap-2 flex flex-col input-container">
            <Label className="text-start text-2xl font-bold uppercase input-label">{textServices.findByText('password/label/newPasswordConfirm', { es: 'Confirmar contraseña nueva', en: 'Confirm new password' })}</Label>
            <Password
              className="text-center saira font-bold text-sm"
              type="password"
              placeholder="**********"
              name="password_confirm"
              defaultValue={state?.password_confirm}
              {...register("password_confirm")}
            />
            <ErrorInput className="input-error">
              <ErrorMessage errors={errors} name="password_confirm" />
            </ErrorInput>
          </div>
        </div>
        <Button>
          <p className="uppercase">{textServices.findByText('password/button', { es: 'Restablecer contraseña', en: 'Reset password' })}</p>
        </Button>
      </form>
    </div>
  )
}

export default ChangePassword;