import { SVGProps } from 'react'

function Google(props: SVGProps<SVGSVGElement>) {

  if (THEME === 'amstel') {
    return (
      <img src={`${AZURE_BASE_URL}/google_3.webp`} alt="Google icon" className='google'/>
    )
  }

  return (
    <svg width="35" height="35" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="50" height="50" rx="25" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M40.4249 25.3693C40.4249 24.217 40.3215 23.109 40.1295 22.0454H24.825V28.3312H33.5704C33.1937 30.3625 32.0488 32.0835 30.3278 33.2357V37.313H35.5795C38.6522 34.484 40.4249 30.3181 40.4249 25.3693Z" fill="#4285F4"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M24.8251 41.2499C29.2126 41.2499 32.891 39.7948 35.5796 37.313L30.3279 33.2357C28.8728 34.2107 27.0114 34.7868 24.8251 34.7868C20.5927 34.7868 17.0103 31.9283 15.7324 28.0874H10.3035V32.2976C12.9773 37.6084 18.4728 41.2499 24.8251 41.2499Z" fill="#34A853"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.7323 28.0874C15.4073 27.1124 15.2227 26.0709 15.2227 24.9999C15.2227 23.9289 15.4073 22.8874 15.7323 21.9124V17.7021H10.3034C9.20279 19.8959 8.57495 22.3777 8.57495 24.9999C8.57495 27.622 9.20279 30.1039 10.3034 32.2976L15.7323 28.0874Z" fill="#FBBC05"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M24.8251 15.2131C27.2108 15.2131 29.3529 16.033 31.037 17.6432L35.6978 12.9824C32.8836 10.3602 29.2052 8.75 24.8251 8.75C18.4728 8.75 12.9773 12.3915 10.3035 17.7023L15.7324 21.9125C17.0103 18.0716 20.5927 15.2131 24.8251 15.2131Z" fill="#EA4335"/>
    </svg>
  )
}

export default Google