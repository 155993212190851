import { Link } from 'react-router-dom'
import textServices from 'services/text-services'
import { SignInDiv } from 'theme/theme'
import { dlTrackEvent } from 'components/dataLayer/dataLayer'

function SignIn() {
  return (
    <Link
      to="/login"
      className="w-full sm:w-72"
      onClick={() => {
        gtag('event', 'Click', {
          event_category: GTM_EVENT_CATEGORY,
          event_label: 'Inicio de sesión - Email',
          value: 'Email'
        })

        dlTrackEvent('user_interaction', {
          action: 'click',
          element: textServices.findByText('start/signin', { es: 'YA TENGO USUARIO', en: 'I ALREADY HAVE A USER' }),
          section: 'login'
        })
      }}
    >
      <SignInDiv className='sign-in'>
        {textServices.findByText('start/signin', { es: 'YA TENGO USUARIO', en: 'I ALREADY HAVE A USER' })}
      </SignInDiv>
    </Link>
  )
}

export default SignIn
