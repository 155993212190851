const Profile = () => {
  const variant = [
    'cartablanca',
    'miller',
    'indio',
    'silverWaiter'
  ]

  if (variant.includes(THEME)) {
    return `${AZURE_ICONS_URL}/${THEME}/profile.svg`
  }
  
  return `${AZURE_ICONS_URL}/profile.svg`
}

export default Profile;