import { useEffect } from "react"
import { useSession } from "contexts/session-context"
import { useTrivia } from "contexts/trivia-context"
import { useRouteMatch } from "react-router-dom"
// import { useContext, useEffect, useState} from "react"
import { dlTrackEvent } from "components/dataLayer/dataLayer"
// import { LoaderContext } from "contexts/loader-context"
import { useLocalStorage } from "react-use"
import useRouter from "hooks/use-router"
import { useSurveyRedirect } from "contexts/survey-context/useSurvey"
import { SurveyTypeEnum } from "contexts/survey-context/survey.interface"
import CONFIGS from 'build/Config'
import utmServices from "services/utm-services"
import TriviaChallenge from "../trivia/trivia"
import PopUpError from "../trivia/components/popUpError"
import TriviaPopUp from "./trivia-popup"


function TriviaQr() {

  const [{ user, data: session }] = useSession()
  const [{ error }] = useTrivia()
  const route = useRouteMatch<{ id: string }>()
  const { history } = useRouter()
  const [popUp, setPopUp] = useLocalStorage('triviaQrPopUp', false)
  const { redirectToSurvey } = useSurveyRedirect(SurveyTypeEnum.TRIVIAQR)

  useEffect(() => {
    if (session) {
      redirectToSurvey()
    }
  }, [session])

  const handleClick = () => {
    gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'TRIVIA - Comencemos', value: '10' })
    dlTrackEvent('user_interaction', { action: 'click', element: 'comencemos', section: 'trivia' })
  
    if (!user) {
      const utm = utmServices.utmRead() || {};
      const utmString = Object.entries(utm)?.map(([key, value]) => `${key}=${value}`)?.join('&');
  
      const redirectUrl = utmString ? `/trivia/qr/${route.params.id}?${utmString}` : `/trivia/qr/${route.params.id}`;
      localStorage.setItem('internal_redirect', redirectUrl);
  
      return history.push('/start');
    }
  
    if (!popUp && CONFIGS?.triviaQrPopUp) {
      setPopUp(true);
    }
  };

  if (error) {
    return <PopUpError errorCode={error} />
  }

  if (!user || (!popUp && CONFIGS?.triviaQrPopUp)) {
    return <TriviaPopUp handleClick={handleClick} />
  }

  return (
    <TriviaChallenge isDirectAccess={true} />
  )
}

export default TriviaQr