import { useSession } from 'contexts/session-context'
import useWhiteBg from 'hooks/use-white-bg'
import { createContext, useEffect, useState } from 'react'
import { Main } from 'theme/theme'
import ProfileService from 'services/profile-services'
export interface MainContextProps {
  classMain: string[]
  setClassMain: React.Dispatch<React.SetStateAction<string[]>>
}

const MainContext = createContext<MainContextProps>({} as MainContextProps)

const MainProvider = ({ children }) => {
  const [classMain, setClassMain] = useState<string[]>([])
  const [intervalId, setIntervalId] = useState<any>()
  const { shouldUseWhiteBg, useTexture } = useWhiteBg()
  const [{ data: session }, { setTotalPoints }] = useSession();


  useEffect(() => {

    const getTotalPoints = async () => {
      // eslint-disable-next-line no-undef
      const response = await ProfileService.getMovements({ session });
      setTotalPoints(response?.data?.totalPoints)
    }

    clearInterval(intervalId);

    // Create an interval that increments the count every 1000ms (1 second)
    const interval = setInterval(() => {
      if (session) {
        getTotalPoints();
      }
    }, 75000);

    setIntervalId(interval);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);

  }, [session])


  const data = { classMain, setClassMain }

  return (
    <MainContext.Provider value={data}>
      <Main
        shouldUseWhiteBg={shouldUseWhiteBg}
        useTexture={useTexture}
        className={`min-h-view select-none flex flex-col items-center ${classMain.join(" ")}`}
      >
        {children}
      </Main>
    </MainContext.Provider>
  )
}

export { MainProvider, MainContext }