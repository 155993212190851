import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { AxiosError } from 'axios'
import { useHistory } from 'react-router'
import { useContext, useEffect, useState } from 'react'
import { useCustomForm } from 'hooks/use-custom-form';
import EmailPassword from 'components/form-information/email-password';
import ProfileService from 'services/profile-services'
import useRedirect from 'hooks/use-redirect';
import Otp from 'views/sign-up/components/otp';
import { dlSetUserInfo, dlTrackError, dlTrackEvent, createUserProperties } from 'components/dataLayer/dataLayer'
import ErrorView from 'components/error-view';
import Done from 'views/sign-up/components/done'
import useLocalStorage from 'hooks/use-local-storage'
import useMainClass from 'hooks/use-main-cass'
import { UserData } from 'contexts/session-context/session-reducer'
import { Prize } from 'views/prizes/types'
import ExchangeComponent from 'components/exchange'
import utmServices from 'services/utm-services'
import textServices from 'services/text-services';
import CONFIGS from 'build/Config'
import { useSession } from '../../contexts/session-context'
import { LoaderContext } from '../../contexts/loader-context';
import { TitleLogin } from './text.style';

const auth = getAuth()

const Login = () => {
  const history = useHistory()
  const [, { setSession, setUser }] = useSession()
  const [{ url }, { redirect }] = useRedirect();
  const { setLoad } = useContext(LoaderContext)
  const { state, handleChangeState } = useCustomForm({ step: 1, email: '', password: '' })
  const [mailSent, setMailSent] = useState(null)
  const [genericError, setGenericError] = useState(null)
  const [done, setDone] = useState(false)
  const [credentials, setCredentials] = useState(null)
  const [otp, setOtp] = useState(false)
  const [flowType] = useLocalStorage('flowType')
  const [dataUser, setDataUser] = useState<any>(null)
  const [prize, setPrize] = useState<Prize>(null)
  useMainClass('login')

  useEffect(() => {
    const event = new CustomEvent('background-color', {
      detail: {
        shouldUseWhiteBg: genericError !== null
      }
    })
    window.dispatchEvent(event)
  }, [genericError])

  const handleMenu = () => {
    setGenericError(null)
  }

  const handleUser = (user: UserData, credentials: any) => {
    dlTrackEvent('login', { method: 'email' })
    gtag("event", "login", { method: "Email" });
    dlSetUserInfo(user?.hashId ?? user?.id)
    createUserProperties(user, 'email')

    setSession(credentials);
    setUser(user);

    const external = localStorage.getItem('toexternal')
    if (external) {
      localStorage.removeItem('toexternal')
      return window.location.assign(decodeURI(JSON.parse(external)))
    };

    if (url) return redirect();

    const availableFields = CONFIGS?.notAvailableFields
    const isComplete = ((availableFields?.includes('nickName') ? false : !user?.nickName) || !user?.birthday || (!user?.genre || !user?.genreId))
    if (!CONFIGS?.signUp?.base && isComplete) return history.push('/information/update');

    return history.push('/home')
  }

  const handleError = (error: AxiosError) => {
    if (error?.isAxiosError) {
      const { code: errorCode, message: errorMessage } = error?.response?.data ?? {}
      dlTrackError({ error_type: 'login', error_code: { errorCode }, error_message: { errorMessage }, section: 'login' })
      return setGenericError(errorCode)
    }

    if (error?.code) return setGenericError(error.code)
  }

  const handleButton = (code: number) => {
    if (code === 50) setOtp(true)
    setGenericError(null)
  }

  const handleConfirm = async (data) => {
    setLoad(true)
    try {
      const response = await ProfileService.otpVerifyEmail({ code: data.otp, credentials })
      if (response?.status === 200) {
        setDone(true)
      }
    } catch (err) {
      const typedError = err as AxiosError
      const { code } = typedError.response?.data || {}
      setGenericError(code)
    } finally {
      setLoad(false)
    }
  }

  const handleOnSubmit = async ({ email, password }: any) => {
    setLoad(true)
    try {
      gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'LOGIN - Iniciar sesión', value: 'Email' })
      const credentials = await signInWithEmailAndPassword(auth, email, password)

      setCredentials(credentials)
      setDataUser({ email, password })

      if (flowType === 'alreadyParticipate' && !done) return setDone(true)
      const utm = utmServices.utmRead()
      const { data: { profile, prize } } = await ProfileService.signInWithEmailAndPassword({ credentials, utm })

      if (prize) {
        setDataUser(profile)
        return setPrize(prize)
      }

      handleUser(profile, credentials)
    } catch (error) {
      handleError(error)
    } finally {
      setLoad(false)
    }
  }

  const handleDone = async () => await handleOnSubmit({ email: dataUser?.email, password: dataUser?.password })

  if (genericError) return <ErrorView buttonMenu={handleMenu} code={genericError} onRetry={handleButton} />

  if (prize) return (
    <ExchangeComponent
      buttons={{
        handleMenu: () => handleUser(dataUser, credentials),
        handleButtonCommon: () => handleUser(dataUser, credentials),
        handleButtonSticker: () => handleUser(dataUser, credentials),
      }}
      item={prize}
      module="login"
      name={dataUser?.firstName}
    />
  )

  if (done) return <Done handleLogin={handleDone} name={dataUser?.email.split('@')[0]} />

  if (otp) return <Otp email={dataUser?.email} handleConfirm={handleConfirm} />

  return (
    <div className="mt-7 flex flex-col w-full">
      {!mailSent && <TitleLogin className="text-center text-5xl font-bold uppercase mb-7 mx-7 login__title">{textServices.findByText('login/title', { es: 'Inicia sesión', en: 'Login' })}</TitleLogin>}
      <EmailPassword
        state={state}
        handleChange={handleChangeState}
        onFormSubmitEndPoint={handleOnSubmit}
        mailSent={mailSent}
        setMailSent={setMailSent}
        setError={setGenericError}
      />
    </div>
  )
}

export default Login
