import Edit from 'components/icon/edit'
import Send from 'components/icon/send';
import Success from 'components/icon/success';
import { useSession } from 'contexts/session-context'
import React, { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast';
import ProfileService from 'services/profile-services';
import textServices from 'services/text-services';
import { Input, Label } from 'theme/theme'

function GamerTag() {
  const [{ user, data: session }, { setUser }] = useSession();
  const [gamerTag, setGamerTag] = useState(user?.nickName ?? '')
  const [disabled, setDisabled] = useState(true)
  const [isChange, setIsChange] = useState(false)
  const ref = useRef<any>()

  useEffect(() => {
    setGamerTag(user?.nickName)
  }, [user?.nickName])

  const onSubmit = async () => {
    toast.loading(textServices.findByText('survey/gamerTag/toast/load', { es: 'Procesando solicitud', en: 'Processing request' }), { position: 'top-center' })
    try {
      const response = await ProfileService.updateNickname({ nickName: gamerTag?.slice(0, 12), session })
      if (response?.status === 200) {
        setUser(response.data)
      }
      toast.dismiss()
      toast.success(textServices.findByText('survey/gamerTag/toast/success', { es: 'Ha cambiado su gamer tag con éxito', en: 'You have successfully changed your gamer tag' }), { position: 'top-center' })
      setDisabled(true)
      setIsChange(true)
    } catch (err) {
      toast.dismiss()
      toast.error(textServices.findByText('survey/gamerTag/toast/error', { es: 'Ha ocurrido un error intente nuevamente', en: 'An error has occurred try again' }), { position: 'top-center' })
      setDisabled(true)
    }
  }

  return (
    <div className='flex flex-col gap-2 w-full'>
      <Label>{textServices.findByText('survey/gamerTag/label', { es: 'Elige tu gamer tag', en: 'Choose your gamer tag' })}</Label>
      <div className='relative w-full'>
        <Input
          type='text'
          value={gamerTag?.slice(0, 12)}
          placeholder={textServices.findByText('survey/gamerTag/placeholder', { es: 'Escribe aquí tu gamer tag', en: 'Write your gamer tag here' })}
          className='w-full'
          onChange={(e) => setGamerTag(e.currentTarget?.value)}
          maxLength={12}
          disabled={disabled}
          ref={ref}
        />
        {
          disabled ?
            isChange ? (
              <button className='absolute right-0 top-0 h-full flex items-center justify-center' style={{ aspectRatio: '1/1' }}>
                <Success />
              </button>
            ) : (
              <button onClick={() => {
                setDisabled(false)
                setTimeout(() => { ref?.current?.focus() }, 100)
              }} className='icon-edit absolute right-0 top-0 h-full flex items-center justify-center' style={{ aspectRatio: '1/1' }}>
                <Edit />
              </button>
            ) : (
              <button onClick={() => onSubmit()} className='absolute right-0 top-0 h-full flex items-center justify-center' style={{ aspectRatio: '1/1' }}>
                <Send />
              </button>
            )
        }

      </div>
      {!isChange && <p className='text-white text-xs industry'>{textServices.findByText('survey/gamerTag/description', { es: 'Debe contener máximo 12 caracteres.', en: 'It must contain a maximum of 12 characters.' })}</p>}
    </div>
  )
}

export default GamerTag