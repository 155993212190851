import { SubTitle, Text, Title } from "theme/theme";
import styled from 'styled-components';
import { Type } from './../../../../theme/types/generic.interface';

export const TitleAge = styled(Title)`
  font-size: 2.25rem;
  ${({ theme }: Type) => theme?.agegate?.text?.title}
`

export const SubTitleAge = styled(SubTitle)`
  font-size: 1rem;
  ${({ theme }: Type) => theme?.agegate?.text?.subtitle}
`

export const TextTermsAge = styled(Text)`
  font-size: 10px;
  ${({ theme }: Type) => theme?.agegate?.text?.terms}
`

export const TextPolitics = styled.p`
  ${({ theme }: Type) => theme?.agegate?.text?.politics}
`