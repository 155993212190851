import { Type } from 'theme/types';
import styled from "styled-components";
import { Crown } from 'theme/profile';

export const ExchangeContainerModal = styled.div`
  ${({ theme }: Type) => theme?.exchange?.containerModal}
`

export const ExchangeContainerTop = styled.div`
  ${({ theme }: Type) => theme?.exchange?.containerTop}
`

export const ExchangeContainerText = styled.div`
  ${({ theme }: Type) => theme?.exchange?.containerText}
`

export const ExchangeTitle = styled.h1`
  ${({ theme }: Type) => theme?.exchange?.title}
`

export const ExchangeDescription = styled.p`
  ${({ theme }: Type) => theme?.exchange?.description}
`

export const ExchangeImage = styled.img`
  ${({ theme }: Type) => theme?.exchange?.image}
`

export const ExchangePointsContainer = styled(Crown)`  
  ${({ theme }: Type) => theme?.exchange?.pointsContainer}
`

export const ExchangePointsText = styled.p`
  ${({ theme }: Type) => theme?.exchange?.pointsText}
`