import React from 'react'

function UploadImageSilver() {
  return (
    <svg width="54" height="50" viewBox="0 0 54 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.9364 47.622H3.4642C2.89015 47.622 2.4357 47.1754 2.4357 46.6114V13.4491C2.4357 12.885 2.89015 12.4385 3.4642 12.4385H33.9603C34.5343 12.4385 34.9888 12.885 34.9888 13.4491V46.6114C34.9649 47.1754 34.5104 47.622 33.9364 47.622Z" stroke="#07672F" strokeWidth="1.82796" strokeMiterlimit="10" />
      <path d="M30.4682 37.1858H18.8677H7.26723C6.69319 37.1858 6.23874 36.7393 6.23874 36.1752V17.1381C6.23874 16.574 6.69319 16.1274 7.26723 16.1274H30.4682C31.0422 16.1274 31.4967 16.574 31.4967 17.1381V36.1752C31.4967 36.7158 31.0422 37.1858 30.4682 37.1858Z" stroke="#07672F" strokeWidth="1.82796" strokeMiterlimit="10" />
      <path d="M15.9555 27.7452L14.152 33.2998C14.152 33.3062 14.152 33.319 14.1584 33.3254C14.1584 33.3254 14.1712 33.3383 14.1841 33.3447C14.1969 33.3575 14.2162 33.3639 14.2162 33.3639C14.2226 33.3703 14.2354 33.3703 14.2418 33.3639L18.9586 29.9324L23.6815 33.3639C23.6879 33.3703 23.7008 33.3703 23.7072 33.3639C23.7072 33.3639 23.72 33.3575 23.7392 33.3447C23.752 33.3318 23.7649 33.3254 23.7649 33.3254C23.7713 33.319 23.7777 33.3062 23.7712 33.2998L21.9708 27.7452L26.694 24.3137C26.7004 24.3073 26.7068 24.2945 26.7004 24.288C26.7004 24.288 26.694 24.2688 26.694 24.256C26.6876 24.2367 26.6811 24.2239 26.6811 24.2239C26.6811 24.2111 26.6683 24.2047 26.6619 24.2047H20.8262L19.0193 18.6501C19.0193 18.6437 19.0064 18.6309 19 18.6309C19 18.6309 18.9808 18.6309 18.9616 18.6309C18.9423 18.6309 18.9295 18.6309 18.9295 18.6309C18.9167 18.6309 18.9103 18.6373 18.9103 18.6501L17.1131 24.2047H11.2774C11.2646 24.2047 11.2582 24.2111 11.2518 24.2239C11.2518 24.2239 11.2454 24.2431 11.239 24.256C11.2326 24.2752 11.2326 24.288 11.2326 24.288C11.2262 24.3009 11.2326 24.3073 11.2391 24.3137L15.9555 27.7452Z" fill="white" />
      <path d="M19.3461 12.4615L22.1446 3.10746C22.312 2.5669 22.8621 2.26137 23.4122 2.42588L50.8468 10.3463C51.3969 10.5108 51.7078 11.0514 51.5404 11.5919L42.6188 41.4403C42.4514 41.9809 41.9012 42.2864 41.3511 42.1219L34.9649 40.2887" stroke="#07672F" strokeWidth="1.82796" strokeMiterlimit="10" />
      <path d="M23.1013 12.4608L24.6081 7.43119C24.7756 6.89063 25.3257 6.5851 25.8758 6.74961L46.7088 12.7663C47.259 12.9308 47.5699 13.4714 47.4025 14.0119L42.3078 31.0984C42.1404 31.639 41.5903 31.9445 41.0401 31.78L34.9888 30.0408" stroke="#07672F" strokeWidth="1.82796" strokeMiterlimit="10" />
    </svg>
  )
}

export default UploadImageSilver