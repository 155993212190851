import { COLLECTION_GET } from 'constants/api'
import useAxios, { RefetchOptions } from 'axios-hooks'
import { useSession } from 'contexts/session-context'
import { createContext, Dispatch, FunctionComponent, useContext, useEffect, useReducer } from 'react'
import { LoaderContext } from 'contexts/loader-context'
import { AxiosPromise, AxiosRequestConfig } from 'axios'
import useRouter from 'hooks/use-router'
import { Collection, Sticker } from '../types'
import collectionReducer, { CollectionsAction, CollectionState } from './collections-reducer'

export interface CollectionsContextProps {
  state: CollectionState
  dispatch: Dispatch<CollectionsAction>
  refetch: (config?: AxiosRequestConfig, options?: RefetchOptions) => AxiosPromise<Collection[]>
}

const CollectionsContext = createContext<CollectionsContextProps>({} as CollectionsContextProps)

export const CollectionsProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(collectionReducer, {})
  const { setLoad } = useContext(LoaderContext)
  const [{ data: session }] = useSession()
  const [{ data = [], loading }, refetch] = useAxios<Collection[]>({
    url: COLLECTION_GET,
    headers: { Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}` }
  })
  useEffect(() => {
    setLoad(loading)
    return () => {
      setLoad(false)
    }
  }, [loading])

  useEffect(() => {
    if (data.length > 0) {
      dispatch({ type: 'SET_COLLECTIONS', payload: data })
    }
  }, [data])

  return <CollectionsContext.Provider value={{ state, dispatch, refetch }}>{children}</CollectionsContext.Provider>
}

export const useCollections = () => {
  const { state, dispatch, refetch } = useContext(CollectionsContext)
  const { push } = useRouter()

  const setCollections = (prizes: Collection[]) => dispatch({ type: 'SET_COLLECTIONS', payload: prizes })
  const refetchCollections = (config?: AxiosRequestConfig) => refetch(config)
  const setSelectSticker = (sticker?: Sticker) => dispatch({ type: 'SET_SELECT_STICKER', payload: sticker })
  const setCongrats = (congrats?: boolean) => dispatch({ type: 'SET_CONGRATS', payload: congrats })
  const setError = (error: number) => {
    dispatch({ type: 'SET_ERROR', payload: error })
    if (error) return push('/collections/error')
  }
  const actions = { setCollections, refetchCollections, setSelectSticker, setError, setCongrats }

  const returnValue: [typeof state, typeof actions] = [state, actions]
  return returnValue
}