import PrefPicker from 'components/pref-picker'
import textServices from 'services/text-services'
import { H1PrefPicker, PPrefPicker } from '../../theme/trivias'

const PreferencesPicker = () => {
  return (
    <div className="space-y-4 flex-1 pb-7 flex flex-col p-12">
      <H1PrefPicker className="uppercase text-3xl font-bold text-center">{textServices.findByText('trivias/prefpicker/title', { es: 'Primero cuentanos tus preferencias', en: 'First tell us your preferences' })}</H1PrefPicker>
      <PPrefPicker className="din uppercase text-xs  text-center mb-4">{textServices.findByText('trivias/prefpicker/description', { es: 'selecciona al menos tres categorías', en: 'select at least three categories' })}</PPrefPicker>
      <PrefPicker />
    </div>
  )
}

export default PreferencesPicker
