import styled from 'styled-components';
import Button from 'components/button';
import { Text, Title } from 'theme/theme';
import { Type } from '../types'

export const SectionCongrats = styled.section`
color: ${(props: Type) => props.theme?.congrats?.textColor};
`

export const TitleCongrats = styled(Title)`
${(props: Type) => props.theme?.congrats?.titleCongrats};
`

export const TextCongrats = styled(Text)`
${(props: Type) => props.theme?.congrats?.textCongrats};
`

export const PointsCongrats = styled.p`
${(props: Type) => props.theme?.congrats?.pointsCongrats};
`

export const ButtonSecondary = styled(Button)`
  ${(props: Type) => props.theme?.congrats?.buttonSecondary};
`

export const TextButton3 = styled.p`
${(props: Type) => props.theme?.congrats?.textButton3};
`