import { YEAR_REGEXP } from "constants/regexp"
import dayjs from 'dayjs';

export const getBirthdayLocal = () => {
  const birthdayLocal = localStorage.getItem('agegate')
  const birthdayParse = JSON.parse(birthdayLocal)
  const currentYear = parseInt(dayjs().year().toString().replace(YEAR_REGEXP, ''), 10)
  const year = birthdayParse?.age?.split('-')[2]
  const yearStart = parseInt(year, 10) > currentYear ? '19' : '20'
  const parsedYear = parseInt(`${yearStart}${year}`, 10)
  const birthdayParseYear = birthdayParse?.age?.replace(year, parsedYear.toString())
  const birthdayDayjs = dayjs(birthdayParseYear, 'DD-MM-YYYY');
  const date = birthdayDayjs.toDate();
  if (!birthdayDayjs?.isValid()) return new Date()
  return date
}