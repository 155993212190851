import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { initializeApp } from 'firebase/app'
import { BrowserRouter } from 'react-router-dom'
import { LoaderProvider } from 'contexts/loader-context'
import ScriptInjector from 'components/onetrust'
import { Theme } from 'build/Provider'
import OnlineProvider from 'contexts/online-context'
import { Toaster } from 'react-hot-toast';
import { PersistUTM } from 'components/utms/persistUTM'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals'

import './index.scss'

import firebaseConfig from './firebase.config'
import GtagWrapper from './components/gtag-wrapper'

initializeApp(firebaseConfig)

document.getElementsByTagName('body')[0].classList.add(THEME)

const App = require('./App').default

const WrappedApp = () => {

  return (
    <StrictMode>
      <OnlineProvider>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <PersistUTM>
            <ScriptInjector />
            <GtagWrapper></GtagWrapper>
            <Theme>
              <LoaderProvider>
                <App />
                <Toaster />
              </LoaderProvider>
            </Theme>
          </PersistUTM>
        </BrowserRouter>
      </OnlineProvider>
    </StrictMode>
  )
}

ReactDOM.render(<WrappedApp />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

reportWebVitals()
