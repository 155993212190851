import React from 'react'

function VoteSilverIcon() {
  return (
    <svg width="35" height="63" viewBox="0 0 35 63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.1929 42.3355C31.4651 42.1077 31.7087 41.9225 31.9237 41.7231C32.8409 40.8544 33.0415 39.8147 32.5256 38.6754C31.981 37.4648 30.9779 36.9806 29.7025 36.9663C27.8682 36.9521 26.0195 36.9663 24.1852 36.9521C22.9528 36.9521 22.3509 36.2115 22.5802 35.0152C22.7808 33.9898 23.0388 32.9786 23.1677 31.9389C23.5117 29.4324 23.0961 27.2106 20.6599 25.7865C19.0979 24.875 17.9801 25.4447 17.9371 27.2676C17.8941 28.7203 17.5788 30.0733 16.9339 31.3835C16.2891 32.6795 15.7445 34.0325 15.028 35.2858C14.4404 36.297 13.7382 37.2939 12.9357 38.1342C12.2335 38.8605 11.4883 39.0741 10.6715 38.9175V55.4808C10.9008 55.5093 11.1444 55.6374 11.388 55.8511C12.1189 56.492 12.9644 56.8053 13.9389 56.8053C18.2524 56.791 22.5802 56.791 26.8937 56.791C27.2233 56.791 27.5672 56.791 27.8968 56.7625C29.9604 56.5347 31.0352 54.4126 29.9891 52.6182C29.8458 52.376 29.6881 52.1482 29.5162 51.8918C30.3617 51.3649 31.0066 50.7525 31.1642 49.7413C31.3218 48.7444 30.9062 47.9611 30.333 47.2205C32.411 45.5399 32.6259 44.3151 31.1929 42.3355Z" stroke="#07672F" strokeWidth="1.8375" strokeMiterlimit="10" />
      <path d="M10.3132 56.449H2.57473C2.3741 56.449 2.20213 56.2781 2.20213 56.0787V38.4758C2.20213 38.2764 2.3741 38.1055 2.57473 38.1055H10.3132C10.5139 38.1055 10.6858 38.2764 10.6858 38.4758V56.0787C10.6858 56.2781 10.5139 56.449 10.3132 56.449Z" stroke="#07672F" strokeWidth="1.8375" strokeMiterlimit="10" />
      <path d="M17.1135 15.6928L15.0369 22.0461C15.0369 22.0534 15.0369 22.0681 15.0443 22.0754C15.0443 22.0754 15.0591 22.0901 15.0738 22.0975C15.0886 22.1121 15.1108 22.1195 15.1108 22.1195C15.1182 22.1268 15.1329 22.1268 15.1403 22.1195L20.5715 18.1945L26.0095 22.1195C26.0169 22.1268 26.0317 22.1268 26.0391 22.1195C26.0391 22.1195 26.0538 22.1121 26.076 22.0975C26.0907 22.0828 26.1055 22.0754 26.1055 22.0754C26.1129 22.0681 26.1202 22.0534 26.1128 22.0461L24.0397 15.6928L29.4782 11.7678C29.4856 11.7605 29.493 11.7458 29.4856 11.7385C29.4856 11.7385 29.4782 11.7165 29.4782 11.7018C29.4708 11.6798 29.4634 11.6651 29.4634 11.6651C29.4634 11.6505 29.4486 11.6431 29.4412 11.6431H22.7218L20.6413 5.28983C20.6413 5.28249 20.6265 5.26782 20.6191 5.26782C20.6191 5.26782 20.597 5.26782 20.5748 5.26782C20.5527 5.26782 20.5379 5.26782 20.5379 5.26782C20.5231 5.26782 20.5157 5.27516 20.5158 5.28983L18.4465 11.6431H11.727C11.7123 11.6431 11.7049 11.6505 11.6975 11.6651C11.6975 11.6651 11.6902 11.6871 11.6828 11.7018C11.6754 11.7238 11.6754 11.7385 11.6754 11.7385C11.6681 11.7532 11.6755 11.7605 11.6828 11.7678L17.1135 15.6928Z" fill="white" />
    </svg>
  )
}

export default VoteSilverIcon