import { ChangeEventHandler, FormEvent, useRef, useState } from 'react'
import textServices from 'services/text-services'
import { SubTitleAge, TitleAge, TextPolitics } from 'components/legal-age/components/style/text'
import CONFIGS from 'build/Config'
import { ButtonInitAgegate, ContainerCenterInput } from '../../theme/theme'
import DateInput from './date-input'

export interface DateInputProps {
  type: 'day' | 'month' | 'year'
  defaultValue?: number
  placeholder?: string
}

export interface FormState {
  day: string
  month: string
  year: string
}

const textSharedProps = {
  className: 'text-center saira mt-4',
  style: { fontSize: 10 }
}

enum DateType {
  day = 'day',
  month = 'month',
  year = 'year'
}

const BirthdayInput = ({ setAgeLocal }: { setAgeLocal: (day?: string, month?: string, year?: string) => void }) => {

  const [state, setFormState] = useState<Partial<FormState>>({})
  const yearRef = useRef<HTMLInputElement>(null)
  const monthRef = useRef<HTMLInputElement>(null)

  const onSubmitHandler = (event: FormEvent) => {
    event.preventDefault()

    const { day = '00', month = '00', year = '0000' } = state
    setAgeLocal(day, month, year)
  }

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = ({ target: { value, name } }) => {
    setFormState((formState: any) => ({ ...formState, [name]: value }))

    const refs: Record<string, HTMLInputElement | null> = {
      day: monthRef.current,
      month: yearRef.current
    }

    if (value.length === 2) {
      refs[name as string]?.focus()
    }
  }

  const placeholderType = (input: DateType): string => {
    if (!CONFIGS.placeholderDifferent) {
      return input === DateType.year ? '0000' : '00';
    }

    switch (input) {
    case DateType.day:
      return 'DD';
    case DateType.month:
      return 'MM';
    case DateType.year:
      return 'AAAA';
    default:
      return '00';
    }
  }

  return (
    <form className="flex-1 flex flex-col mb-7" onSubmit={onSubmitHandler}>
      <div className="flex-1 flex flex-col justify-center agegate__container">
        <div className="uppercase">
          <TitleAge className="font-bold text-5xl uppercase text-center my-6 mx-7 agegate__title">{textServices.findByText('agegate/title', { es: '¡Bienvenid@!', en: 'Welcome!' })}</TitleAge>
          <div className='w-full'>
            {textServices.findByText('agegate/description', { en: <SubTitleAge className="text-regular text-center saira font-medium mb-4 sm:mb-0 mx-7">Enter your date of birth, <br /> we want to validate your majority<br /> of age.</SubTitleAge>, es: <SubTitleAge className="text-regular text-center saira font-medium mb-4 sm:mb-0 mx-7">Ingresa tu fecha de nacimiento, <br /> queremos validar tu mayoría<br /> de edad.</SubTitleAge> })}
          </div>
        </div>
        <div className="flex w-full justify-between flex-col sm:h-auto px-7 agegate__input__container">
          <div className="w-full grid grid-cols-3 mx-auto my-4 agegate__inputs">
            <DateInput type="day" max="31" min="1" placeholder={placeholderType(DateType.day)} onChange={handleOnChange} />
            <ContainerCenterInput className='agegate__mid'>
              <DateInput ref={monthRef} type="month" max="12" min="1" placeholder={placeholderType(DateType.month)} onChange={handleOnChange} />
            </ContainerCenterInput>
            <DateInput maxLength={4} minLength={4} ref={yearRef} type="year" max="2100" min="0000" placeholder={placeholderType(DateType.year)} onChange={handleOnChange} />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center mx-7">
        <ButtonInitAgegate
          type="submit"
          className="din p-4 h-16 w-full mx-auto rounded-none din font-bold div button-generic"
          onClick={() => {
            gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'BIRTHDAY - Mayor de 18', value: '10' })
          }}
        >
          {textServices.findByText('agegate/button', { es: 'INGRESAR', en: 'CONTINUE' })}
        </ButtonInitAgegate>
        <TextPolitics {...textSharedProps}>
          {textServices.findByText('agegate/politics1',
            {
              es: <>AL ENVIAR ESTE FORMULARIO, ACEPTAS LA POLÍTICA DE PRIVACIDAD, <a href={`${process.env.PUBLIC_URL}/terms-and-conditions`} rel="noreferrer" target="_blank" className="underline">TÉRMINOS Y CONDICIONES Y USO DE COOKIES</a > DE ESTE SITIO WEB.</>,
              en: <>BY SUBMITTING THIS FORM, YOU AGREE TO THE PRIVACY POLICY, <a href={`${process.env.PUBLIC_URL}/terms-and-conditions`} rel="noreferrer" target="_blank" className="underline">TERMS AND CONDITIONS AND USE OF COOKIES</a > OF THIS WEBSITE.</>
            }
          )}
        </TextPolitics>
        <TextPolitics {...textSharedProps}>
          {textServices.findByText('agegate/politics2', { es: 'ESTE SITIO WEB UTILIZA COOKIES. PARA OBTENER MÁS INFORMACIÓN, LEE NUESTRA POLÍTICA DE PRIVACIDAD Y USO DE COOKIES.', en: 'THIS WEBSITE USES COOKIES. FOR MORE INFORMATION, PLEASE READ OUR PRIVACY AND COOKIE POLICY.' })}
        </TextPolitics>
      </div>
    </form >
  )
}

export default BirthdayInput
