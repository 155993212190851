import { GET_RANKS } from 'constants/api'
import { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import axios, { AxiosError } from 'axios'
import Button from 'components/button'
import textServices from 'services/text-services'
import { useSession } from '../../contexts/session-context'
import { LoaderContext } from '../../contexts/loader-context'
import { TextSub, TrRank } from '../../theme/theme'

const Ranking = () => {
  const [{ data: session }] = useSession()
  const history = useHistory()
  const { load, setLoad } = useContext(LoaderContext)
  const [ranking, setRanking] = useState(null)

  useEffect(() => {
    setLoad(true)
  }, [])

  const fetchRank = async () => {
    setLoad(true)
    try {
      const codeResponse = await axios.get(GET_RANKS, {
        headers: { Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}` }
      })
      setRanking(codeResponse.data)
    } catch (error) {
      const err = error as AxiosError
      setLoad(false)
      if (err.response?.data.code === 711) {
        history.push('/ranking/suscribe')
      } else if (err.response?.data.code) {
        history.push(`/error/${err.response?.data.code}`)
      }
    } finally {
      setLoad(false)
    }
  }

  useEffect(() => {
    if (session) {
      fetchRank()
    } else {
      setLoad(false)
      history.push('/home')
    }
  }, [])

  return load ? (
    <></>
  ) : (
    <>
      <div className={`flex w-full flex-col justify-evenly px-7`}>
        <div className="mx-7 mb-7">
          <h1 className="text-center text-4xl font-black uppercase ">
            <TextSub>Hola {ranking?.currentPosition?.nickName},</TextSub>
            <br />
            Tus {POINTS_NAME}: {ranking?.currentPosition?.points}
          </h1>
          <p className="din text-center mt-4 font-black text-xl">
            Estas en el lugar <TextSub>#{ranking?.currentPosition?.id}</TextSub>
          </p>
        </div>
        <section>
          {ranking &&
            ranking.rankWinners.map((item: any, index: number) => {
              return (
                <TrRank key={index} className="din font-black text-base w-full flex justify-around mb-1" index={index}>
                  <span className="py-2 pl-2">{item?.id}</span>
                  <span className="py-2 w-4/6">{item?.nickName}</span>
                  <span className="py-2 text-right pr-2">{item?.points}</span>
                </TrRank>
              )
            })}
        </section>
        <Button
          className="my-4 uppercase w-full"
          onClick={() => {
            gtag('event', 'Click', {
              event_category: GTM_EVENT_CATEGORY,
              event_label: 'RANKING - Nuevo Código',
              value: '10'
            })
            history.push('/redeem')
          }}
        >
          Quiero ingresar un código
        </Button>
        <p
          className="din text-center text-xs underline uppercase mb-4"
          onClick={() => {
            gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'RANKING - Volver', value: '10' })
            history.push(textServices.findByText('config/prizes', '/prizes'))
          }}
        >
          No, volver
        </p>
      </div>
    </>
  )
}

export default Ranking
