import { Type } from 'theme/types';
import styled from "styled-components";
import { Text, Title } from "theme/theme";

export const TextProfile = styled(Title)`
  ${( { theme }: Type ) => theme?.profile?.text.text }
`

export const TextProfileSecondary = styled(Text)`
  ${( { theme }: Type ) => theme?.profile?.text.textSecondary }
`

export const TextProfileNav = styled(Text)`
  ${( { theme }: Type ) => theme?.profile?.text.textNav }
`

export const TextNotFoundHistory = styled(Title)`
  ${( { theme }: Type ) => theme?.profile?.text?.textNotFoundHistory }
`

export const TextPref = styled.p`
  ${( { theme }: Type ) => theme?.profile?.text?.textPref }
`