/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import CONFIGS from 'build/Config'

const whitelist = CONFIGS.useSecondBackground

export let useBg = null

const useWhiteBg = () => {
  const { pathname } = useLocation()
  const [shouldUseWhiteBg, setShouldUseWhiteBg] = useState(null)
  const [useTexture, setUseTexture] = useState(false)

  useEffect(() => {
    const matches = matchPath(pathname, whitelist)?.path
    const isInList = whitelist.includes(matches)
    setShouldUseWhiteBg(isInList)
    useBg = isInList

    window.addEventListener('background-color', (event: any) => {
      setShouldUseWhiteBg(event.detail.shouldUseWhiteBg)
      setUseTexture(event.detail.useTexture)
      useBg = event.detail.shouldUseWhiteBg
    })
  }, [pathname])

  return {shouldUseWhiteBg, useTexture}
}

export default useWhiteBg
