import { useEffect, useState } from 'react'
import { useSurveyRedirect } from 'contexts/survey-context/useSurvey'
import { SurveyTypeEnum } from 'contexts/survey-context/survey.interface'
import useMainClass from 'hooks/use-main-cass'
import CONFIGS from 'build/Config'
import { useTrivia } from '../../contexts/trivia-context'
import PreferencesPicker from './pref-picker'
import TriviaList from './trivia-list'
import IntroductionTrivia from './components/introduction'

const Trivia = () => {
  const [trivia] = useTrivia()
  const [showIntroduction, setShowIntroduction] = useState(CONFIGS?.trivia?.showIntroduction)
  const introductionTrivia = localStorage.getItem('introductionTrivias') === 'true'
  useSurveyRedirect(SurveyTypeEnum.TRIVIAS)
  useMainClass('trivias')
  useEffect(() => {

    const event = new CustomEvent('background-color', {
      detail: {
        shouldUseWhiteBg: true,
        from: 'trivias page load'
      }
    })

    window.dispatchEvent(event)
  }, [])

  if (showIntroduction && !introductionTrivia) {
    return <IntroductionTrivia setShowIntroduction={setShowIntroduction} />
  }

  if (!trivia.interests.length && CONFIGS.trivia?.interestsRequired) {
    return <PreferencesPicker />
  }

  return <TriviaList />
}

export default Trivia
