const Collection = () => {

  const variant = [
    'matchpaint'
  ]

  if (variant.includes(THEME)) {
    return `${AZURE_ICONS_URL}/${THEME}/collection.svg`
  }

  return `${AZURE_ICONS_URL}/collection.svg`
}

export default Collection
