/* eslint-disable */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const useUTMParams = () => {
  const [utmParams, setUtmParams] = useState<{
    utm_source: string;
    utm_medium: string;
    utm_campaign: string;
    utm_term: string;
    utm_content: string;
    utm_id: string;
  }>()
  
  const query = useQuery();

  useEffect(() => {
    setUtmParams(
      {
        utm_source: query.get('utm_source'),
        utm_medium: query.get('utm_medium'),
        utm_campaign: query.get('utm_campaign'),
        utm_term: query.get('utm_term'),
        utm_content: query.get('utm_content'),
        utm_id: query.get('utm_id'),
      }
    )
  },[])

  return utmParams;
};