import { usePhotographer } from 'views/photographer/context'
import Button from 'components/button'
import { Link } from 'react-router-dom'
import { DescriptionCardWinner, DivCardWinner, DivTitleWinner, ImgCardWinner, TitleWinner } from './custom.style'

function Winners() {

  const [{ winners }] = usePhotographer()

  return (
    <section className="flex flex-col">
      <div className='w-screen max-w-7xl z-1 flex flex-col flex-1 items-center'>
        <DivTitleWinner className="flex items-center px-10 w-screen py-6">
          <TitleWinner className="flex-grow  max-w-full">GANADORES</TitleWinner>
        </DivTitleWinner>
        <article className='w-full flex flex-col flex-1 h-full px-7 pb-7 gap-4'>
          {winners?.length > 0 ?
            winners?.map((winner) => (
              <DivCardWinner className="w-full h-32 flex items-center justify-center" key={winner?.code}>
                <ImgCardWinner className="h-full w-32 object-cover" src={winner?.imageUrl} alt={winner?.code} />
                <DescriptionCardWinner className="py-2 px-3 text-center flex-1">CÓDIGO {winner?.code}</DescriptionCardWinner>
              </DivCardWinner>
            ))
            :
            <div className='flex-1 flex flex-col items-center justify-center'>
              <div className="flex-1 flex items-center justify-center">
                <TitleWinner className='text-center'>Aun no hay ganadores</TitleWinner>
              </div>
              <Link to="/photographer/upload" className='w-full'><Button>SUBIR UNA FOTO</Button></Link>
            </div>
          }
        </article>
      </div>
    </section>
  )
}

export default Winners