import React from 'react'

const Arrow = () => {
  return (
    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 1L0.891598 9.86858L11 18.7372" stroke="currentColor" strokeLinecap="round"/>
    </svg>
  )
}

export default Arrow
