// import { encryptAES } from './encrypt';

async function dynamicManifest(id: number) {
  if (id) {
    // const data = await encryptAES(JSON.stringify(id), SECRET_PASS)
    const manifest = {
      "short_name": `${PWA_TITLE ?? TITLE_NAME}`,
      "name": `${PWA_TITLE ?? TITLE_NAME}`,
      "icons": [
        {
          "src": `${AZURE_BASE_URL}/pwa/maskable_icon_x48.png`,
          "type": "image/png",
          "sizes": "48x48"
        },
        {
          "src": `${AZURE_BASE_URL}/pwa/maskable_icon_x72.png`,
          "type": "image/png",
          "sizes": "72x72"
        },
        {
          "src": `${AZURE_BASE_URL}/pwa/maskable_icon_x96.png`,
          "type": "image/png",
          "sizes": "96x96"
        },
        {
          "src": `${AZURE_BASE_URL}/pwa/maskable_icon_x128.png`,
          "type": "image/png",
          "sizes": "128x128"
        },
        {
          "src": `${AZURE_BASE_URL}/pwa/maskable_icon_x192.png`,
          "type": "image/png",
          "sizes": "192x192"
        },
        {
          "src": `${AZURE_BASE_URL}/pwa/maskable_icon_x384.png`,
          "type": "image/png",
          "sizes": "384x384"
        },
        {
          "src": `${AZURE_BASE_URL}/pwa/maskable_icon_x512.png`,
          "type": "image/png",
          "sizes": "512x512"
        }
      ],
      "version": "2024.03.01",
      // "start_url": `${window?.location?.origin}${process.env.PUBLIC_URL}/identification?id=${data}&`,
      "start_url": `${window?.location?.origin}${process.env.PUBLIC_URL}/start`,
      "display": "standalone",
      "theme_color": "#000000",
      "background_color": "#ffffff",
    }; 
    const stringManifest = JSON.stringify(manifest);
    const blob = new Blob([stringManifest], { type: 'application/json' });
    const manifestUrl = URL.createObjectURL(blob);
    document.querySelector('#dynamic-manifest').setAttribute('href', manifestUrl);
  }
}

export default dynamicManifest