import { MainContext } from 'contexts/main-class-context'
import { useContext, useEffect } from 'react'

function useMainClass(className: string) {
  const { setClassMain } = useContext(MainContext)

  const setter = (className: string) => {
    setClassMain((prev) => [...prev, className])
  }

  const remove = (className: string) => {
    setClassMain((prev) => [...prev].filter((item) => item !== className))
  }

  useEffect(() => {
    setter(className)

    return () => {
      remove(className)
    }
  },[])
}

export default useMainClass