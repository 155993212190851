import React from 'react'
import { Link } from 'react-router-dom'
import { ButtonNavStyle } from 'theme/home'

function MemoramaNavTecate() {
  return (
    <Link to='/memorama/list' className="w-full" onClick={() => { gtag('event', 'event_to_ga', { event_category: 'navegacion_memorama', event_label: 'Home links' }) }}>
      <ButtonNavStyle className={`button-nav flex items-center justify-evenly py-3 px-2 h-24 text-center font-medium text-xs`} style={{ background: 'white', border: '1px solid rgba(0, 0, 0, 0.35)' }}>
        <img src={`${AZURE_ICONS_URL}/${THEME}/memorama-nav.svg`} alt='Icono de memorama' />
        <p className='text-black roboto text-base font-bold'>JUEGA AL MEMORAMA</p>
      </ButtonNavStyle>
    </Link>
  )
}

export default MemoramaNavTecate