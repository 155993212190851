export interface Item {
  id: number,
  menuId: number
  name: string,
  title: string,
  url: string,
  status: boolean,
  order: number,
  menuItemLinkType: string
  mandatory: boolean
  menuItemSizeType: string
}

export interface IMenu {
  id: number,
  name: string,
  status: boolean,
  title: string,
  pointsStatus: boolean,
  pointsText: string,
  singStampEnum: string | null,
  singStampImageUrl: string | null,
  items: Item[]

}

// The API responds with the value of singStampEnum as a string
export enum singStamp {
  Empty = 'Empty',  // 1
  ShowLevels = 'ShowLevels',  // 2
  ShowCustomImage = 'ShowCustomImage',  // 3
  ShowCustomImageLevel = 'ShowCustomImageLevel',   // 4
}

export const menuOffline = {
  id: 1,
  name: 'menu',
  status: true,
  title: '¡Hola, {name}!',
  pointsStatus: false,
  pointsText: '',
  singStampEnum: 'Empty',
  singStampImageUrl: null,
  items: [
    {
      id: 28,
      menuId: 0,
      name: 'home',
      title: 'Inicio',
      url: '/',
      status: true,
      order: 1,
      menuItemLinkType: 'Internal',
      mandatory: true,
      menuItemSizeType: 'Normal'
    },
    {
      id: 25,
      menuId: 0,
      name: 'help',
      title: '¿Tienes alguna duda?',
      url: '/help',
      status: true,
      order: 96,
      menuItemLinkType: 'Internal',
      mandatory: true,
      menuItemSizeType: 'Small'
    },
    {
      id: 26,
      menuId: 0,
      name: 'tyc',
      title: 'Ver términos y condiciones',
      url: '/terms-and-conditions',
      status: true,
      order: 97,
      menuItemLinkType: 'Internal',
      mandatory: true,
      menuItemSizeType: 'Small'
    },
    {
      id: 27,
      menuId: 0,
      name: 'privacy',
      title: 'Aviso de privacidad',
      url: '/privacy-politics',
      status: true,
      order: 98,
      menuItemLinkType: 'Internal',
      mandatory: true,
      menuItemSizeType: 'Small'
    }
  ]
}