import { lazy, Suspense } from 'react'
import { Route } from 'react-router-dom'
import RoutesWithNotFound from 'utilities/routes-with-not-found'
import { SurveyTypeEnum } from 'contexts/survey-context/survey.interface'
import { useSurveyRedirect } from 'contexts/survey-context/useSurvey'
import { CollectionsProvider } from './context'
const Redeem = lazy(() => import('./redeem'))
const Start = lazy(() => import('./start'))
const Error = lazy(() => import("./error"))
const Album = lazy(() => import("./album"))
const Download = lazy(() => import("./download"))
const CongratsRedeem = lazy(() => import("./redeem/congrats"))

const Collections = () => {
  useSurveyRedirect(SurveyTypeEnum.COLLECTIONS)

  return (
    <Suspense fallback={<>Cargando</>}>
      <CollectionsProvider>
        <RoutesWithNotFound>
          <Route path="/collections" component={Start} exact />
          <Route path="/collections/sticker/redeem/congrats/:collectionid/:code" component={CongratsRedeem} exact />
          <Route path="/collections/sticker/redeem/:code" component={Redeem} exact />
          <Route path="/collections/error" component={Error} exact />
          <Route path="/collections/album/:collectionid" component={Album} exact />
          <Route path="/collections/album/:collectionid/sticker/:code" component={Download} exact />
        </RoutesWithNotFound>
      </CollectionsProvider>
    </Suspense>
  )
}

export default Collections
