import styled from 'styled-components';
import { forwardRef } from 'react'
import InputStyled from 'components/input'
import Check from 'components/icon/check';
import CONFIGS from 'build/Config'
import { Type, MainType, ButtonType, InputType, TRankingType } from './types'

export const Main = styled.div.attrs((props: MainType) => ({
  shouldUseWhiteBg: props.shouldUseWhiteBg,
  useTexture: props.useTexture,
  theme: props.theme
})) <MainType>`
  color: ${(props: Type) => props.theme?.init?.text};
  background-color: ${(props: MainType) => props.shouldUseWhiteBg ? props.theme?.init?.secondaryBackground : props.theme?.init?.primaryBackground};
  ${({ theme, useTexture }: MainType) => `
    background-image: ${(CONFIGS?.dontUseTexture?.includes(window.location.pathname.split('/')[2]) && !useTexture) ? '' : `url(${AZURE_BASE_URL}/textura.${theme?.loading?.config?.bgTexture || 'svg'}) 
    ${(theme?.main?.useSecondTexture && (CONFIGS?.useSecondTextureUrl?.includes(window.location.pathname.split('/')[2])) ? `, url(${AZURE_BASE_URL}/loader.${theme?.loading?.config?.bgLoader || 'svg'})` : '')}`};
  `}
`

export const Back = styled.div`
  background-color: ${(props: Type) => props.theme?.init?.tertiaryBackground};
`

export const BackRedeem = styled.div`
  background-color: ${(props: Type) => props.theme?.init?.tertiaryBackground};
  justify-content: ${(props: Type) => props.theme?.start?.backJustify};
  ${({ theme }: Type) => theme?.redeem?.imgContainer}
`
export const ImgRedeemContainer = styled.div`
  ${({ theme }: Type) => theme?.redeem?.ImgRedeemContainer}
`

export const ButtonInit = styled.button.attrs((props: ButtonType) => ({
  disabled: props.disabled,
  shouldUseWhiteBg: props.shouldUseWhiteBg,
  theme: props.theme,
  ...props
})) <ButtonType>`
  color: ${(props: ButtonType) => props.disabled ? !props.shouldUseWhiteBg ? props.theme?.button?.buttonDisableContrast : props.theme?.button?.buttonDisable : props.shouldUseWhiteBg ? props.theme?.button?.buttonColorContrast : props.theme?.button?.buttonColor};
  border-color: ${(props: ButtonType) => props.disabled && !props.shouldUseWhiteBg ? props.theme?.button?.buttonDisabledBorderContrast : props.theme?.button?.buttonDisabledBorder};
  background-color: ${(props: ButtonType) => props.disabled ? props.theme?.button?.buttonDisabledBackground : props.shouldUseWhiteBg ? props.theme?.button?.buttonBackgroundContrast : props.theme?.button?.buttonBackground};
  border-radius:${(props: ButtonType) => props.theme?.button?.buttonBorderRadius};
  ${({ theme }: ButtonType) => theme?.button?.buttonRest}
`

export const ButtonInitAgegate = styled(ButtonInit)`
  ${({ theme }: Type) => theme?.button?.buttonAgegate}
`

export const Input = styled.input`
  ${({ theme }: Type) => theme?.inputPrimary}
`

export const ReturnText = styled.p`
  ${({ theme }: Type) => theme?.returnText}
`

export const InputVariant = styled.input`
  ${({ theme }: Type) => theme?.inputVariant}
`

export const InputST = forwardRef<HTMLInputElement, InputType>((props, ref) => {
  if (props.variant) return <InputVariant {...props} ref={ref} />
  return <Input ref={ref} {...props} />
})

InputST.displayName = 'InputST'

export const InputBirthday = forwardRef<HTMLInputElement, InputType>((props, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return <InputBirth ref={ref} {...props} />
})

export const InputBirthdayLabel = styled.p`
  ${({ theme }: Type) => theme?.agegate?.inputBirthdayLabel}
`

const InputBirth = styled.input<InputType>`
  background:transparent;
  :-webkit-autofill:focus{
    background-color: transparent;
  }
  ${({ theme }: Type) => theme?.agegate?.inputBirthday}
`

InputBirthday.displayName = 'InputBirthday'


export const TextSub = styled.b`
  color: ${(props: Type) => props.theme?.init?.textSub};
`

export const TrRank = styled.div.attrs((props: TRankingType) => ({
  key: props.key,
  index: props.index,
  theme: props.theme,
  ...props
})) <TRankingType>`
  background-color: ${(props: TRankingType) => props.index <= 4 ? props.theme?.ranking.backgroundColorVariant : props.theme?.ranking?.backgroundColor};
  border-color: ${(props: TRankingType) => props.index <= 4 ? props.theme?.ranking?.borderColorVariant : props.theme?.ranking?.borderColor};
  color: ${(props: TRankingType) => props.index <= 4 ? props.theme?.ranking?.textColorVariant : props.theme?.ranking?.textColor};
  border-top:${(props: TRankingType) => props.index > 4 && props.theme?.ranking?.borderClass['border-top']};
  border-left:${(props: TRankingType) => props.index > 4 && props.theme?.ranking?.borderClass['border-left']};
  border-right:${(props: TRankingType) => props.index > 4 && props.theme?.ranking?.borderClass['border-right']};
  border-bottom:${(props: TRankingType) => props.index > 4 && props.theme?.ranking?.borderClass['border-bottom']};
  border-radius:${(props: TRankingType) => props.theme?.ranking?.borderClass['border-radius']};
`

export const DivAgegate = styled.div`
  ${({ theme }: Type) => theme?.inputAgegate?.div}
`

export const InputAgegate = styled.input`
  ${({ theme }: Type) => theme?.inputAgegate?.input}
`

export const SelectGender = styled.select`
  ${({ theme }: Type) => theme?.inputSelect}
`

export const DivCheckbox = styled.div`
  ${({ theme }: Type) => theme?.inputCheckbox?.div}
`

export const DivRedeemBottomText = styled.div`
  ${({ theme }: Type) => theme?.collections?.redeem?.divBottomText}
`

export const SpanCheckbox = styled.span`
  ${({ theme }: Type) => theme?.inputCheckbox?.span}
`

export const SpanCheckboxActive = styled.span`
  ${({ theme }: Type) => theme?.inputCheckbox?.spanActive}
`

export const PCheckbox = styled.p`
  ${({ theme }: Type) => theme?.inputCheckbox?.p}
`

export const Checkbox = ({ className, ...props }) => {
  if (props?.active) {
    return <SpanCheckboxActive className={`${className} input-checkbox input-checkbox-active checkbox__input__active`} {...props}>
      <Check />
    </SpanCheckboxActive>
  }
  return <SpanCheckbox className={`${className} input-checkbox`} {...props} />
}

export const H1Steps = styled.h1`
  ${({ theme }: Type) => theme?.steps?.h1}
`

export const H1StepsActive = styled.h1`
  ${({ theme }: Type) => theme?.steps?.h1Active}
`

export const Step = (props) => {
  if (props?.active) {
    return <H1StepsActive {...props}>
      {props.children}
    </H1StepsActive>
  }
  return <H1Steps {...props}>
    {props.children}
  </H1Steps>
}

export const SignInDiv = styled.div`
  ${({ theme }: Type) => theme?.start?.signIn}
`

export const SignUpDiv = styled.div`
  ${({ theme }: Type) => theme?.start?.signUp}
`

export const Title = styled.h1`
  ${({ theme }: Type) => theme?.fonts?.primary}
`
export const TitleWaiterPage = styled(Title)`
  ${({ theme }: Type) => theme?.waiterPage?.title}
`

export const SubTitle = styled.h2`
  ${({ theme }: Type) => theme?.fonts?.secondary}
`

export const Text = styled.p`
  ${({ theme }: Type) => theme?.fonts?.tertiary}
`

export const Label = styled.label`
  ${({ theme }: Type) => theme?.label}
`

export const ReferralLabel = styled(Label)`
  ${({ theme }: Type) => theme?.signup?.referral?.label}
`

export const ReferralInput = styled(InputStyled)`
  ${({ theme }: Type) => theme?.signup?.referral?.input}
`

export const ContainerCenterInput = styled.div`
  ${({ theme }: Type) => theme?.agegate?.containerCenterInput}
`
export const StepText = styled.p`
  ${({ theme }: Type) => theme?.signup?.welcome?.text?.steps}
`
export const ProgressContainerSteps = styled.div`
  ${({ theme }: Type) => theme?.signup?.welcome?.progress?.container}
`

export const ProgressSteps = styled.div`
  ${({ theme }: Type) => theme?.signup?.welcome?.progress?.steps}
`
export const LabelStreet = styled(Label)`
  ${({ theme }: Type) => theme?.labelStreet}
`
export const SubtitleStreet = styled.p`
  ${({ theme }: Type) => theme?.subtitleStreet}
`

export const ErrorInput = styled.p`
  ${({ theme }: Type) => theme?.error?.errorInput}
`

export const SubtitleWaiterPage = styled(Text)`
  ${({ theme }: Type) => theme?.waiterPage?.subtitle}
`

