export const myInformationAdapter = ((data, isSignUp, isLogin) => {
  
  if (isLogin) {
    return {
      email: data?.email,
      password: data?.password,
    }
  }

  if (isSignUp) {
    return {
      firstName: data?.firstName,
      lastName: data?.lastName,
      nickName: data?.nickName,
      birthday: data?.birthday,
      genreId: parseInt(data?.genreId),
      email: data?.email,
      password: data?.password,
      code: data?.referral,
      subscribe: data?.subscribe,
      termsOfUse: data?.termsOfUse,
    }
  }

  if (!isSignUp) {
    return {
      firstName: data?.firstName,
      lastName: data?.lastName,
      nickName: data?.nickName,
      birthday: data?.birthday,
      genreId: parseInt(data?.genreId),
    }
  }
  
})