import { FunctionComponent, createContext, useReducer } from 'react';
import { SurveyContextProps } from './survey.interface';
import surveyReducer from './survey.reducer';

export const SurveyContext = createContext<SurveyContextProps>({} as SurveyContextProps)

export const SurveyProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(surveyReducer, {})

  return <SurveyContext.Provider value={{ state, dispatch }}>{children}</SurveyContext.Provider>
}
