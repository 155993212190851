import { AxiosError } from 'axios'
import LoadingScreen from 'components/loading-screen'
import { useEffect, useState, VoidFunctionComponent } from 'react'
import { useHistory } from 'react-router-dom'
import { dlTrackEvent } from 'components/dataLayer/dataLayer'
import ErrorView from 'components/error-view'
// import { LoaderContext } from 'contexts/loader-context'
import { useTrivia } from '../../../contexts/trivia-context'
import OptionSelector from './selector'

export interface TriviaGameProps {
  id: number | string
  // trivia: any
}

const TriviaGame: VoidFunctionComponent<TriviaGameProps> = ({ id }) => {
  const [state, { playTrivia }] = useTrivia()
  const [error, setError] = useState('')
  const history = useHistory()

  useEffect(() => {
    const event = new CustomEvent('background-color', {
      detail: {
        shouldUseWhiteBg: true,
        from: 'trivias game page'
      }
    })

    window.dispatchEvent(event)

    playTrivia(id, state?.currentTrivia?.directAccess).catch((error: AxiosError) => {
      if (error.isAxiosError) {
        setError(error.response?.data.code)
      }
    })


    window.onbeforeunload = function () {
      gtag('event', 'event_to_ga', { event_category: 'Trivias', event_label: `Salió sin terminar`, value: id })
      dlTrackEvent('user_interaction', { action: 'click', element: 'abandonó el juego', section: `trivia :: ${id}` })
      return 'Estás seguro que deseas salir? Perderás el progreso'
    }

    return () => {
      const event = new CustomEvent('background-color', {
        detail: {
          shouldUseWhiteBg: false,
          from: 'trivia exit modal'
        }
      })
      window.onbeforeunload = null
      window.dispatchEvent(event)
    }
  }, [])

  const handleRetry = (code: number | string) => {
    if (code === 510) return history.push('/profile/address')
    history.goBack()
  }

  if (error) {
    return (
      <ErrorView
        code={(error as unknown as number) ?? 9999}
        buttonMenu={() => history.goBack()}
        onRetry={handleRetry}
      />
    )
  }

  if (!state.currentTrivia) {
    return <LoadingScreen />
  }

  return <OptionSelector trivia={state.currentTrivia} />
}

export default TriviaGame
