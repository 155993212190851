import styled from 'styled-components';
import { AuctionStatus, AuctionStatusExtend } from '../types'

export const DivCardContainer = styled.div.attrs((props: AuctionStatusExtend) => ({
  status: props.status,
  modal: props.modal,
  theme: props.theme
})) <AuctionStatusExtend>`
background-color: ${(props: AuctionStatusExtend) => props.theme?.auctions.cardBgAndText[props.status].bg};
color: ${(props: AuctionStatusExtend) => props.theme?.auctions.cardBgAndText[props.status].color};
border-color: ${(props: AuctionStatusExtend) => props.theme?.auctions.cardBorderColor};
border-radius: ${(props: AuctionStatusExtend) => !props.modal && props.theme?.auctions.cardRoundedTop};
`

export const DivCardTitle = styled.div`
border-radius: ${(props: AuctionStatusExtend) => props.theme?.auctions.cardRoundedTop};
`

export const DivCardOffert = styled.div.attrs((props: AuctionStatus) => ({
  status: props.status,
  theme: props.theme
})) <AuctionStatus>`
background-color: ${(props: AuctionStatus) => props.theme?.auctions.cardOffertBgAndText[props.status].bg};
color: ${(props: AuctionStatus) => props.theme?.auctions.cardOffertBgAndText[props.status].color};
`