import { ALBUM_GET_RANK_USER, ALBUM_GET } from 'constants/api'
import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from 'components/button';
import { useSession } from 'contexts/session-context';
import { Album } from 'views/album/types';
import LoadingScreen from 'components/loading-screen';
import { useHistory } from 'react-router-dom';
import textServices from 'services/text-services';

const ProfileRanking = () => {
  const history = useHistory()
  const [data, setData] = useState<any>(null);
  const [albums, setAlbums] = useState<Album[]>([])

  const [{ data: session }] = useSession()

  useEffect(() => {
    const div = document.querySelector('.profile__title-container') as HTMLElement | null;

    if (div) div.style.display = 'none';

    return () => {
      if (div) div.style.display = 'flex';
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<Album[]>(ALBUM_GET, {
          headers: {
            Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`,
            'Ph-Api-Id': PH_API_ID ?? 1
          }
        });
        setAlbums(response.data);
      } catch (error) {
        console.error('Failed to fetch albums:', error);
      }
    };

    fetchData()
  }, [session]);


  useEffect(() => {
    const fetchData = async (rallyId: number) => {
      const token = ((session as any)?._tokenResponse as any)?.idToken;

      try {
        const response = await axios.get(`${ALBUM_GET_RANK_USER}?id=${rallyId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Ph-Api-Id': PH_API_ID ?? 1

          }
        });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (albums) {
      const rally = albums.find(album => album?.rallyType === 'Ticket' && album?.statusId === 2);

      if (rally && rally.id) {
        fetchData(rally.id);
      }
    }
  }, [albums]);

  if (!data) <LoadingScreen />

  return (
    <div className='ranking-profile'>
      <img src={`${AZURE_BASE_URL}/ranking-img.webp`} alt="Imagen de item a ganar" className='w-full' />

      <div className='flex flex-col gap-5 mt-6'>
        <h2 className='ranking-profile__title'>
          Tus tickets
        </h2>

        <div className='flex items-center justify-center gap-5 ranking-profile__container__accumulated'>
          <div>
            <p className='ranking-profile__subtitle'>tickets acumulados:</p>
            <div className='ranking-profile__box'>
              {data && (data?.multimediasCount === 0 ? '-' : data?.multimediasCount)}
            </div>
          </div>

          <div className='ranking-profile__container__position'>
            <p className='ranking-profile__subtitle'>tu posición:</p>
            <div className='ranking-profile__box'>
              {data && (data?.position === 0 ? '-' : `${data?.position}°`)}
            </div>
          </div>
        </div>

        <p className='ranking-profile__text'>
          {textServices.findByText('profile/ranking/text', { es: '¡Recuerda! A máyor cantidad de tickets SORIANA QUE subas, MÁS OPORTUNIDAD DE GANAR UN FRIGOBAR TENDRÁS.', en: '' })}

          {/* 
            
          */}
        </p>
      </div>

      <div className='px-5 mt-6'>
        <Button onClick={() => {
          history.push('/album/redirect/Ticket/upload')
        }}>
          {textServices.findByText('profile/ranking/button', { es: 'Cargar un ticket', en: 'Upload ticket' })}
        </Button>
      </div>
    </div>
  )
}

export default ProfileRanking