import CustomRadio from "components/custom-radio";
import { InputProps } from "components/input";
import mergeRefs from "merge-refs";
import { forwardRef, ForwardRefRenderFunction, useRef } from "react";
import styled from "styled-components";

const P = styled.p`
  ${({ theme }) => theme?.inputRadio?.label}
`

const RadioSt: ForwardRefRenderFunction<HTMLInputElement, InputProps> = ({ children, className, ...rest }, ref) => {
  const innerRef = useRef(null)

  return (
    <label className="cursor-pointer flex items-center justify-center gap-2 radio input-radio-container">
      <CustomRadio theme={undefined} type="radio" ref={mergeRefs(ref, innerRef) as any} className={`${className} radio__input input-radio`} {...rest} />
      <P className="flex-1 radio__label input-radio-label">{children}</P>
    </label>
  )
}

const Radio = forwardRef(RadioSt)

export default Radio;