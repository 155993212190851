
import { GET_LINKEDACCOUNTS } from 'constants/api'
import axios from 'axios'
import CONFIGS from 'build/Config'
import ErrorView from 'components/error-view'
// import ExchangeComponent from 'components/exchange'
import FacebookIcon from 'components/icon/facebook'
import GoogleIcon from 'components/icon/google'
import { useSession } from 'contexts/session-context'
import useMainClass from 'hooks/use-main-cass'
// import useRedirect from 'hooks/use-redirect'
import { useContext, useEffect, useMemo, useState } from 'react'
// import { useHistory } from 'react-router-dom'
import textServices from 'services/text-services'
import { isInAppBrowser } from 'utilities/detectDeviceAgent'
// import { Prize } from 'views/prizes/types'
import { LoaderContext } from 'contexts/loader-context'
import { dlTrackEvent } from 'components/dataLayer/dataLayer'
import { goToUrlFacebook } from 'components/start/facebook/goToUrlFacebook'
import { goToUrlGoogle } from 'components/start/google/goToUrlGoogle'
import AlertAppView from 'components/start/alertAppView'
import TwitchIcon from 'components/icon/twitch'
import { goToUrlTwitch } from 'components/start/twitch/goToUrlTwitch'

function SocialProfile() {
  const [session] = useSession()
  const [loginError, setLoginError] = useState<number | undefined>()
  const [alertAppView, setAlertAppView] = useState(false);
  const typedThemeButtons: string[] = CONFIGS?.buttonsStart
  const { setLoad } = useContext(LoaderContext)
  const [linkedAccunts, setLinkedAccunts] = useState(null);

  const getStatus = async () => {
    setLoad(true)
    try {
      const response = await axios.get(GET_LINKEDACCOUNTS, {
        headers: {
          Authorization: `Bearer ${((session as any)?.data?._tokenResponse as any)?.idToken}`,
          'Ph-Api-Id': PH_API_ID ?? 1
        }
      })

      setLinkedAccunts(response.data)
    } catch (err) {
      console.error(err)
    } finally {
      setLoad(false)
    }
  }

  const handleMetaClick = () => {
    dlTrackEvent('user_interaction', {
      action: "click",
      element: 'facebook',
      section: "link"
    })
    gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'Link - Meta', value: 'Meta' })
    return goToUrlFacebook('linkaccount')
  }

  const handleGoogleClick = () => {
    const inBrowserApp = isInAppBrowser()
    if (inBrowserApp) return setAlertAppView(true);

    dlTrackEvent('user_interaction', {
      action: "click",
      element: 'google',
      section: "link"
    })
    gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'Link - Google', value: 'Google' })
    return goToUrlGoogle('linkaccount')
  }

  const handleTwitchClick = () => {
    const inBrowserApp = isInAppBrowser()
    if (inBrowserApp) return setAlertAppView(true);

    dlTrackEvent('user_interaction', {
      action: "click",
      element: 'twitch',
      section: "link"
    })
    gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'Link - Twitch', value: 'Twitch' })
    return goToUrlTwitch('linkaccount')
  }

  useEffect(() => {
    if (!status && session) getStatus()
  }, [session])

  const listSocial = useMemo(() => {
    return [
      {
        type: 'google',
        component:
          <>
            <>
              <AlertAppView open={alertAppView} setOpen={setAlertAppView} social='Google' />
              <div onClick={handleGoogleClick} className="cursor-pointer">
                <GoogleIcon className='google' />
              </div>
            </>
          </>,
      },
      {
        type: 'facebook',
        component:
          <>
            <div onClick={handleMetaClick} className="cursor-pointer">
              <FacebookIcon className='facebook' />
            </div>
          </>
      },
      {
        type: 'twitch',
        component:
          <>
            <AlertAppView open={alertAppView} setOpen={setAlertAppView} social='Twitch' />
            <div onClick={handleTwitchClick} className="cursor-pointer">
              <TwitchIcon className='twitch' />
            </div>
          </>
      },
    ].filter(({ type }) => {
      const isDisplayInTheme = typedThemeButtons.includes(type)
      const alreadyLinked = linkedAccunts?.map((y)=> y?.provider.toLowerCase() )?.includes(type)

      return isDisplayInTheme && session.user?.provider.toLowerCase() !== type && !alreadyLinked
    }).sort((a, b) => typedThemeButtons.indexOf(a.type) - typedThemeButtons.indexOf(b.type))
  }, [linkedAccunts])

  useMainClass('profile__social')

  if (loginError) {
    return <ErrorView code={loginError as number} buttonMenu={() => setLoginError(null)} onRetry={() => setLoginError(null)} />
  }

  return (
    <div className="font-bold text-2xl py-6 flex-1 flex break-all link-container">
      <div className="flex-1">
        <div className='flex w-full items-center justify-center gap-4 saira font-bold text-sm social-container'>
          {listSocial?.length > 0 && (
            <>
              <div>{textServices.findByText('profile/social', { es: <>Selecciona la cuenta que<br />deseas vincular</>, en: 'Select the account you want to link' })}</div>
              <div className=" flex flex-col items-center gap-4">
                <div className='flex w-full items-center justify-center gap-4 py-4 saira font-bold text-sm social-container'>
                  {listSocial?.map((social, index) => {
                    const isLinked = linkedAccunts?.filter((linked) => social.type === linked?.provider.toLowerCase());
                    if (isLinked?.length === 0) {

                      const inBrowserApp = isInAppBrowser()

                      if (inBrowserApp && ['google', 'twitch'].includes(social.type) && !['dosequis', 'mixx'].includes(THEME)) {
                        return null;
                      }

                      return (
                        <span className={`bg-white rounded-full border-2 border-black social`} key={index}>
                          {social.component}
                        </span>
                      )
                    }
                    return null;
                  })}
                </div>
              </div>
            </>
          )}
        </div>
        {linkedAccunts?.length > 0 && (
          <>
            <div className='flex w-full items-center justify-center gap-4 py-4 saira font-bold text-sm social-container active'>
              <div>{textServices.findByText('profile/social/linked', { es: 'Ya has vinculado:', en: 'Already linked:' })}</div>
              <div className="">
                {linkedAccunts?.map((social) => {
                  if (social?.provider.toLowerCase() === 'google')
                    return <GoogleIcon className='google' />
                  if (social?.provider.toLowerCase() === 'facebook')
                    return <FacebookIcon className='facebook' />
                  if (social?.provider.toLowerCase() === 'twitch')
                    return <TwitchIcon className='facebook' />                    
                  return null
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </div >
  )
}

export default SocialProfile
