import textServices from 'services/text-services'
import ErrorView from 'components/error-view'
import useMainClass from 'hooks/use-main-cass'
import { useHistory } from 'react-router-dom'
import { AxiosError } from 'axios'

interface IProps {
  errorCode: AxiosError
}

const PopUpError = ({ errorCode }: IProps) => {
  useMainClass('trivia__pop-up__error')

  const history = useHistory()

  const onRetry = async () => {
    history.push(textServices.findByText(`error/${errorCode}/button/link`, '/home'))
  }

  const buttonMenu = () => {
    history.push(textServices.findByText(`error/${errorCode}/button/link`, '/home'))
  }

  if (errorCode) {
    return <ErrorView code={Number(errorCode) ?? 9999} buttonMenu={buttonMenu} onRetry={onRetry} />
  }

  return null
}

export default PopUpError
