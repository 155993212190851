import ButtonCloseMenu from 'components/button-close'
import Header from 'components/header'
import { isAndroid, isIOS } from 'utilities/detectDeviceAgent'

import useMainClass from 'hooks/use-main-cass'
import textServices from 'services/text-services'
function InstructionPopUp({ setPopUp }: { setPopUp: any }) {
  useMainClass('profile__identification__instruction')

  return (
    <div className="fixed min-h-screen w-full top-0 left-0 bg-white overflow-y-auto z-1">
      <Header />
      <ButtonCloseMenu
        open={true}
        paramFn={() => {
          gtag('event', 'Click', {
            event_category: GTM_EVENT_CATEGORY,
            event_label: 'Identificacion - Cerrar instrucciones',
            value: '10'
          })
          setPopUp()
        }}
      />
      <div className="w-full p-4 h-screen">
        <h2>
          <span>{textServices.findByText('profile/identification/instructions/title', { es: 'INSTRUCCIONES PARA TENER TU', en: 'INSTRUCTIONS TO HAVE YOUR' })}</span>
          <br />
          <span> {textServices.findByText('profile/identification/name', 'ID')}</span>
          <span>{textServices.findByText('profile/identification/instructions/dispositive', { es: ' EN TU DISPOSITIVO', en: ' ON YOUR DEVICE' })}</span>
        </h2>

        <hr className="mt-6" />
        {isAndroid() && (
          <>
            {textServices.findByText('profile/identification/instructions/android', {
              es: <div className="py-8">
                <h3>DISPOSITIVOS ANDROID</h3>
                <p>
                  1. Descarga tu app <b>&quot;Google Wallet&quot;</b> ingresando <u><a target='_blank' rel='noreferrer' href="https://play.google.com/store/apps/details?id=com.google.android.apps.walletnfcrel">aquí</a></u>
                </p>
                <p>
                  2. Presiona en el botón <b>&quot;Agregar a Google Wallet&quot;</b>.
                </p>
                <p>
                  3. Visualiza tu <b>&quot;{textServices.findByText('profile/identification/name', 'ID')}&quot;</b>
                </p>
                <p>
                  4. Presiona en el botón <b>&quot;Añadir&quot;</b>.
                </p>
                <p>
                  5. ¡Listo! Ya tienes tu{' '}
                  <b>&quot;{textServices.findByText('profile/identification/name', 'ID')}&quot;</b> en tu wallet.
                </p>
              </div>,
              en: <div className="py-8">
                <h3>ANDROID DEVICES</h3>
                <p>
                  1. Download your app <b>&quot;Google Wallet&quot;</b> by entering <u><a target='_blank' rel='noreferrer' href="https://play.google.com/store/apps/details?id=com.google.android.apps.walletnfcrel">here</a></u>
                </p>
                <p>
                  2. Click on the button <b>&quot;Add to Google Wallet&quot;</b>.
                </p>
                <p>
                  3. Visualize your <b>&quot;{textServices.findByText('profile/identification/name', 'ID')}&quot;</b>
                </p>
                <p>
                  4. Click on the button <b>&quot;Add&quot;</b>.
                </p>
                <p>
                  5. You&apos;re done! You now have your{' '}
                  <b>&quot;{textServices.findByText('profile/identification/name', 'ID')}&quot;</b> in your wallet.
                </p>
              </div>
            })}
          </>
        )}
        {isIOS() && (
          <>
            {textServices.findByText('profile/identification/instructions/ios', {
              es: <div className="py-8">
                <h3>DISPOSITIVOS ANDROID</h3>
                <p>
                  1. Presiona en el botón <b>&quot;Añadir a Apple Wallet&quot;</b>.
                </p>
                <p>
                  2. Visualiza tu <b>&quot;{textServices.findByText('profile/identification/name', 'ID')}&quot;</b>
                </p>
                <p>
                  3. Presiona en el botón <b>&quot;Agregar&quot;</b>.
                </p>
                <p>
                  4. ¡Listo! Ya tienes tu{' '}
                  <b>&quot;{textServices.findByText('profile/identification/name', 'ID')}&quot;</b> en tu wallet.
                </p>
              </div>,
              en: <div className="py-8">
                <h3>IOS DEVICES</h3>
                <p>
                  1. Click on the button <b>&quot;Add to Apple Wallet&quot;</b>.
                </p>
                <p>
                  2. Visualize your <b>&quot;{textServices.findByText('profile/identification/name', 'ID')}&quot;</b>
                </p>
                <p>
                  3. Click on the button <b>&quot;Add&quot;</b>.
                </p>
                <p>
                  4. You&apos;re done! You now have your{' '}
                  <b>&quot;{textServices.findByText('profile/identification/name', 'ID')}&quot;</b> in your wallet.
                </p>
              </div>
            })}
          </>
        )}
      </div>
    </div>
  )
}

export default InstructionPopUp
