/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

const GtagWrapper = () => {

  const iframe = `
  <iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}"
    height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`

  useEffect(() => {
    const noscript = document.createElement('noscript')
    noscript.innerHTML = iframe

    document.body.insertBefore(noscript, document.body.childNodes[0])
  }, [])

  return (
    <Helmet>
      <script>
        {`(function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GTM_ID}');`}
      </script>
      <script>{`window.dataLayer = window.dataLayer || []; function gtag() {window.dataLayer.push(arguments); } gtag('js', new Date()); gtag('config', '${GTM_ID}');`}</script>
      {(API_KEY_MAPS) &&
        <script src={`https://maps.googleapis.com/maps/api/js?key=${API_KEY_MAPS}`}></script>
      }
    </Helmet>
  )
}

export default GtagWrapper
