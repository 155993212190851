import { Link } from 'react-router-dom'
import { dlTrackEvent } from 'components/dataLayer/dataLayer'
import { isAndroid, isIOS } from 'utilities/detectDeviceAgent'

const RockID = () => {
  return (
    <Link
      to="/profile/identificacion"
      className="w-full px-0"
      onClick={() => {
        gtag('event', 'event_to_ga', { event_category: 'navegacion_profile', event_label: 'Home links' })
        dlTrackEvent('user_interaction', { action: 'click', element: 'rock id - download', section: 'home' })
      }}
    >
      <div
        className={`button-nav shadow-xl flex items-center text-center font-medium text-xs w-full`}
        style={{
          borderRadius: '10px'
        }}
      >
        <img
          src={`${AZURE_ICONS_URL}/${THEME}/id/${isAndroid() ? 'android-' : isIOS() ? 'ios-' : ''}boton_bg.webp`}
          alt="Rock ID"
          className="w-full "
        />
      </div>
    </Link>
  )
}

export default RockID
