import Button from 'components/button'
import ButtonCloseMenu from 'components/button-close'
import ConfettiComponent from 'components/confetti'
import ScratchCard from 'components/scratch-card'
import { useSession } from 'contexts/session-context'
import { useState } from 'react'
import { PrizesCouponType } from 'types/prizes'

interface IProps {
  item: {
    points?: number
    title: string
    imageUrl?: string
    stickerId?: number
    couponTypeId?: PrizesCouponType
  },
  buttons: {
    text?: string
    handleMenu: () => void
    dontShowButtons?: boolean
    handleButtonSticker?: () => void
    handleButtonCommon?: () => void
  }
  module?: string
}

export default function ScratchView({ item, buttons }: IProps) {
  const [variants, setVariants] = useState('hidden')
  const [{ user }] = useSession()

  return (
    <div className={`py-5 px-4 w-full flex flex-col items-center animations-scratch animations-scratch-${variants}`}>
      <ButtonCloseMenu open={true} paramFn={buttons?.handleMenu} />
      {variants === 'show' && item?.title !== '' && <ConfettiComponent />}
      <div className='w-full flex-1 flex flex-col items-center gap-12 body'>
        <h3 className='title hidden opacity-0'>{item?.title !== '' ? <>¡Vengaaa {user?.firstName}!</> : <>¡Oh no {user?.firstName}!</>}</h3>
        <p className='description hidden opacity-0'>{item?.title !== '' ? <>Obtuviste <b>{item?.title}</b></> : ''}</p>
        <div className='rounded-xl shadow-lg scratch__card'>
          <ScratchCard
            brushSize={30}
            height={300}
            width={300}
            image={`${AZURE_BASE_URL}/test-prize-scratch.png`}
            finishPercent={85}
            onComplete={() => setVariants('show')}
          >
            <img height={300} width={300} src={`${item?.imageUrl}`} alt='' className='rounded-2xl' />
          </ScratchCard>
        </div>
        <div className='flex items-center justify-between gap-2 px-2 py-3 rounded-lg bg-gray-300 w-full scratch__info-cnt'>
          <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg" className='scratch__info-svg'>
            <path d="M10 0C10.8125 0 11.5104 0.302083 12.0938 0.90625C12.6979 1.48958 13 2.1875 13 3V6.5625L20.625 8.21875C21.3125 8.36458 21.875 8.71875 22.3125 9.28125C22.7708 9.82292 23 10.4479 23 11.1562V28H7V22.4062L0.28125 15.625L0 15.3125V14.9062C0 14.1146 0.260417 13.4375 0.78125 12.875C1.30208 12.2917 1.92708 12 2.65625 12C3.78125 12 4.77083 12.3125 5.625 12.9375C5.85417 13.1042 6.04167 13.2812 6.1875 13.4688L6.25 13.5625L7 13.75V3C7 2.1875 7.29167 1.48958 7.875 0.90625C8.47917 0.302083 9.1875 0 10 0ZM10 2C9.70833 2 9.46875 2.09375 9.28125 2.28125C9.09375 2.46875 9 2.70833 9 3V16.3438L7.75 16.0312L5.5 15.4375L5.09375 15.3125L4.90625 14.9688L4.8125 14.8438C4.72917 14.7396 4.61458 14.6354 4.46875 14.5312C3.98958 14.1771 3.38542 14 2.65625 14C2.36458 14 2.17708 14.1979 2.09375 14.5938L8.40625 21H21V11.1562C21 10.9062 20.9271 10.6875 20.7812 10.5C20.6354 10.3125 20.4479 10.1979 20.2188 10.1562L11.7812 8.375L11 8.1875V3C11 2.70833 10.9062 2.46875 10.7188 2.28125C10.5312 2.09375 10.2917 2 10 2ZM9 23V26H21V23H9Z" fill="#00A7B5" />
          </svg>
          <p className='flex-1 text-sm scratch__info-text'>Desliza tu dedo para raspar y descubrir si has ganado algún premio.</p>
        </div>
      </div>
      <Button className='opacity-0' onClick={buttons?.handleButtonCommon}>{buttons?.text}</Button>
    </div>
  )
}