import { DescriptionText, SpanPolitics } from 'components/birthday-input/style.custom'

const Arrow = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12.5" cy="12.5" r="9.5" fill="white" />
      <path
        d="M12.5 2.46875C14.3392 2.46875 16.0482 2.93262 17.627 3.86035C19.1569 4.75553 20.3695 5.9681 21.2646 7.49805C22.1924 9.07682 22.6562 10.7858 22.6562 12.625C22.6562 14.4642 22.1924 16.1732 21.2646 17.752C20.3695 19.2819 19.1569 20.4945 17.627 21.3896C16.0482 22.3174 14.3392 22.7812 12.5 22.7812C10.6608 22.7812 8.95182 22.3174 7.37305 21.3896C5.8431 20.4945 4.63053 19.2819 3.73535 17.752C2.80762 16.1732 2.34375 14.4642 2.34375 12.625C2.34375 10.7858 2.80762 9.07682 3.73535 7.49805C4.63053 5.9681 5.8431 4.75553 7.37305 3.86035C8.95182 2.93262 10.6608 2.46875 12.5 2.46875ZM12.5 4.03125C10.9375 4.03125 9.48893 4.42188 8.1543 5.20312C6.86849 5.9681 5.8431 6.99349 5.07812 8.2793C4.29688 9.61393 3.90625 11.0625 3.90625 12.625C3.90625 14.1875 4.29688 15.6361 5.07812 16.9707C5.8431 18.2565 6.86849 19.2819 8.1543 20.0469C9.48893 20.8281 10.9375 21.2188 12.5 21.2188C14.0625 21.2188 15.5111 20.8281 16.8457 20.0469C18.1315 19.2819 19.1569 18.2565 19.9219 16.9707C20.7031 15.6361 21.0938 14.1875 21.0938 12.625C21.0938 11.0625 20.7031 9.61393 19.9219 8.2793C19.1569 6.99349 18.1315 5.9681 16.8457 5.20312C15.5111 4.42188 14.0625 4.03125 12.5 4.03125ZM13.0615 7.62012L18.0664 12.625L13.0615 17.6299L11.9385 16.5312L15.0635 13.4062H7.03125V11.8438H15.0635L11.9385 8.71875L13.0615 7.62012Z"
        fill="#15636A"
      />
    </svg>
  )
}

export const languageES = {
  agegate: {
    title: <span className='invisible'></span>,
    description: <div className='flex flex-col justify-between items-center gap-5 flex-1'>
      <img src={`${AZURE_BASE_URL}/agegate.png`} alt="Titulo" />
      <DescriptionText className='text-center agegate__description'>
        Ingresa tu año de nacimiento para validar que eres mayor de edad.
      </DescriptionText>
    </div>,
    politics1: <span className='hidden'></span>,
    politics2: <SpanPolitics className='agegate__politics'>
      AL CONFIRMAR, ACEPTAS LA <a href={`${process.env.PUBLIC_URL}/privacy-politics`} rel="noreferrer" target="_blank" className="underline">POLÍTICA DE PRIVACIDAD</a >, <a href={`${process.env.PUBLIC_URL}/terms-and-conditions`} rel="noreferrer" target="_blank" className="underline">TÉRMINOS Y CONDICIONES Y USO DE COOKIES</a > DE ESTE SITIO WEB. ESTE SITIO WEB UTILIZA COOKIES. PARA OBTENER MÁS INFORMACIÓN, LEE NUESTRA POLÍTICA DE <a href={`${process.env.PUBLIC_URL}/privacy-politics`} rel="noreferrer" target="_blank" className="underline">PRIVACIDAD</a > Y USO DE <a href={`${process.env.PUBLIC_URL}/terms-and-conditions`} rel="noreferrer" target="_blank" className="underline">COOKIES</a >.
    </SpanPolitics>
  },
  signup: {
    title: 'Bienvenido',
    description:
      'Ahora eres parte del equipo. Revisa tus correos para comenzar a registrar tus latas para completar la colección. Consigue las 26 latas de Amstel Ultra y descubre los mensajes de Rafa Nadal.',
    button: 'SIGUIENTE',
    welcome: {
      subtitle: 'Solo falta validar tu cuenta.',
      description: <span>Te enviamos un código de 6 dígitos a tu correo electrónico para que valides<br /><br />Una contraseña de un solo uso se envió a EMAIL.</span>,
      description2: <></>,
      description3: <><b>¿No lo recibiste?</b></>
    }
  },
  start: {
    signup: 'REGÍSTRATE CON CORREO',
    signin: 'INGRESA CON CORREO',
    social: 'ENTRA CON:',
    title: {
      html: (
        <div className="flex-1 w-full pb-2 flex flex-col items-center justify-start">
          <img src={`${AZURE_BASE_URL}/fondo-inicial.webp`} alt="background" className="w-full" />
        </div>
      )
    }
  },
  home: {
    title: '¿Qué quieres hacer?',
    description: '¡Completa tus datos!',
    popup: {
      two: {
        mobile: 'https://stblobphcentral.blob.core.windows.net/silver/assets/popup-2-mobile.png',
        desktop: 'https://stblobphcentral.blob.core.windows.net/silver/assets/popup-2-desktop.png',
        'desktop-1500': 'https://stblobphcentral.blob.core.windows.net/silver/assets/popup-2-desktop-1500.png'
      }
    },
    arrowText: (
      <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.18945 0.716797L10.9082 4.43555L11.209 4.75L10.9082 5.06445L7.18945 8.7832L6.56055 8.1543L9.52734 5.1875H0.75V4.3125H9.52734L6.56055 1.3457L7.18945 0.716797Z" fill="#15636A" />
      </svg>
    )
  },
  profile: {
    form: {
      information: {
        firstname: {
          label: '¿Cómo te llamas?',
          placeholder: 'Pon tu nombre aquí'
        },
        lastname: {
          placeholder: 'Pon tu apellido aquí'
        },
        nickname: {
          label: '¿Apodo? (Opcional)',
          placeholder: '¿Cómo te gusta que te llamen?'
        },
        gender: {
          label: '¿Cómo te identificas?',
          placeholder: 'Elige una opción'
        },
        email: {
          label: 'Correo electrónico'
        },
        birthdate: {
          label: '¿Tu Fecha de nacimiento?'
        }
      }
    },
    information: 'DATOS PERSONALES',
    address: 'DIRECCIÓN',
    contact: 'CONTACTO',
    button: 'IR A VER LOS PREMIOS',
    state: 'HISTORIAL',
    stateLabel: 'Historial',
    identification: {
      nav: 'HNK ID',
      title: 'HEINEKEN ID',
      button: 'DESCARGAR ID',
      label: 'Mi HNK ID'
    },
    view: {
      arrow: <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.96094 0.0859375L8.03906 1.16406L2.95312 6.25H18V7.75H2.95312L8.03906 12.8359L6.96094 13.9141L0.585938 7.53906L0.0703125 7L0.585938 6.46094L6.96094 0.0859375Z" fill="#07672F" />
      </svg>
    },
    stateNotFound: <>Aún no has canjeado ningún premio. <br /> <b>¡Venga, tu puedes!</b></>,
  },
  password: {
    title: 'RESETEAR CONTRASEÑA',
    label: {
      newPassword: 'Nueva contraseña',
      newPasswordConfirm: 'Repetir nueva Contraseña'
    }
  },
  config: {
    prizes: '/prizes'
  },
  congrats: {
    range: {
      title: 'NUEVO LOGRO DESBLOQUEADO',
      description: <></>,
      description2: (
        <>
          Felicitaciones, has desbloqueado nuevos <br /> premios y obtienes más chances de ganar.
        </>
      )
    }
  },
  endroute: {
    title: (
      <>
        ¡Lo sentimos! Esto es sólo para mayores de edad
      </>
    ),
    description: ' '
  },
  referrals: {
    description: 'Comparte tu código con amigos y gana más oportunidades de ganar.',
    subtitle: 'COMPARTE TU CÓDIGO Y GANA',
    left: 'COPIAR',
    right: 'COMPARTIR'
  },
  trivias: {
    'not-found': <>No hay trivias disponibles por el momento, estate atento a nuestras nuevas dinámicas.</>,
    exchange: {
      button: {
        text: 'COMPLETAR MIS DATOS',
        url: '/profile'
      }
    },
    triviaQr: {
      register: {
        title: (
          <div className="text-white antonio">
            CONTESTA Y GANA
          </div>
        ),
        description: (
          <div className="text-white">
            <p>¿Ya tienes tu CRISTAL ID?</p>
            <b className="mt-3">SI NO LO TIENES CÓRRELE A REGISTRARTE PARA PODER PARTICIPAR</b>
            <img
              className="mx-auto mt-5"
              src="https://promohubstorage.blob.core.windows.net/cristal/assets/crista_id_ej.svg"
            />
          </div>
        ),
        html: (
          <>
            <div className="flex-1 flex-col gap-6 w-full flex px-7 items-center justify-center text-center pop-up-body">
              <h1 className="pop-up-body-title">
                CONTESTA Y GANA
              </h1>
              <img src={`${AZURE_BASE_URL}/trivia-qr.webp`} className='pop-up-body-img' />
              <p className='pop-up-body-subtitle'>¿Ya tienes tu ID?</p>
              <p className='pop-up-body-description'>Si no lo tienes córrele a registrarte para poder participar.</p>
            </div>
          </>
        )
      },
      late: {
        title: (
          <>
            NO DISPONIBLE
          </>
        ),
        description: <p className="text-white">Lamentablemente esta trivia ya no está disponible. Estate atento para contestar otra.</p>,
        button: {
          text: 'VER MÁS TRIVIAS'
        }
      }
    },
    questions1: <>Contesta </>,
    questions2: (
      <>
        {' '}
        <span className="font-bold" style={{ color: '#07672F' }}>
          preguntas
        </span>{' '}
        sobre el tema y gana:
      </>
    ),
    exit: {
      accept: "Si, claro",
      cancel: "No, volver",
      subtitle: 'Si cancelas ahora se perderá tu progreso y tendrás que volver a empezar.'
    },
    modal: {
      title: <>¿Estás seguro que deseas <br /> salir de la trivia ?</>,
      description: <>Si cancelas ahora se perderá tu progreso y<br /> tendrás que volver a empezar.</>
    },
  },
  tivias: {
    challenge: {
      modal: {
        description: '¿Seguro que quieres empezar a jugar? Una vez que des click no hay vuelta atrás.'
      }
    }
  },
  survey: {
    title: 'CUÉNTANOS DE TI',
    button: 'RESPONDER',
    onboarding: {
      html: (
        <div className='flex-1 px-4 space-y-5 text-center'>
          <h3>Contesta y gana</h3>
          <img src={`${AZURE_BASE_URL}/survey-banner-qr.png`} alt='cuanto sabes de heineken silver' />
          <div className='py-2 space-y-4'>
            <h4>¿Ya tienes tu ID?</h4>
            <p>Si no lo tienes córrele a registrarte para poder participar.</p>
          </div>
        </div>
      )
    }
  },
  prizes: {
    start: {
      title: <span style={{ fontFamily: 'Antonio', fontWeight: 500, fontSize: '24px' }}>PREMIOS</span>
    },
    description: {
      politic: <>Recuerda que debes tener tus datos actualizados para poder ganar</>
    },
    congrats: {
      description: <>Ahora atentx al mail que te vamos a mandar con toda la info del envío de tu canje<br />¡Felicidades!</>
    }
  },
  album: {
    title: 'Retos',
    notFound: 'No hay retos activos en este momento',
    states: {
      progress: 'ACTUALES',
      completed: 'PASADOS'
    }
  },
  help: {
    subtitle: 'Acá estamos para lo que necesites, checa primero en nuestras preguntas frecuentes si encuentras la respuesta.',
    subtitleMessage: 'No te preocupes, déjanos un mensaje para que podamos ayudarte mejor.'
  },
  popUps: {
    start: {
      html: (
        <div className="flex-1 flex flex-col gap-6 items-center pt-6">
          <div className='w-full px-6'>
            <img src={`${AZURE_BASE_URL}/start-pop-title.webp`} alt='The boring phone' className='w-full' />
          </div>
          <h4 className='popup-title'>HAY MÁS EN LA VIDA SOCIAL CUANDO HAY MENOS EN TU CELULAR</h4>
          <p className='popup-description'>Regístrate y participa para ganar un Boring Phone</p>
          <img src={`${AZURE_BASE_URL}/start-pop-bg.webp`} alt='The boring phone' className='w-full' />
        </div>
      ),
      button: 'REGISTRARSE'
    }
  },
  wifi: {
    buttonId: 'QUIERO MI HEINEKEN ID'
  },
  social: {
    Foto: {
      upload: {
        alreadyUploaded: {
          description: <>Para completar tu participación <br /> contesta la pregunta de la semana.</>,
          button: {
            text: '¡VAMOS!',
            url: '/trivias'
          }
        },
        title: 'Sube tu imagen',
        subtitle: 'CLIC PARA SUBIR TU FOTO',
        rules: {
          title: 'REGLAS PARA SUBIR CONTENIDO',
          list: (
            <ul style={{ color: '#007118', fontSize: '13px', fontFamily: 'Helvetica' }}>
              <li>
                <p className="flex gap-1">
                  <span>1.</span> Usa la cámara de tu celular para tomarle una foto a tu cerveza Heineken Silver.
                </p>
              </li>
              <li className="my-3">
                <p className="flex gap-1">
                  <span>2.</span> Busca un lugar iluminado para tomar la foto.
                </p>
              </li>
              <li>
                <p className="flex gap-1">
                  <span>3.</span> Mantente quieto al momento de tomar la foto para obtener una imagen de buena calidad.
                </p>
              </li>
            </ul>
          )
        }
      },
      onBoarding: {
        rules: {
          list: (
            <>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="source-serif-pro text-white font-normal text-xl">No aparecer fumando.</p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="source-serif-pro text-white font-normal text-xl">
                    No aparecer con consumo excesivo o aparentemente excesivo de bebidas alcohólicas.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="source-serif-pro text-white font-normal text-xl">
                    No mostrar la marca en un entorno de exceso, peligro o violencia.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="source-serif-pro text-white font-normal text-xl">
                    No aparecer con otras marcas de cerveza que no sea Heineken Silver.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="source-serif-pro text-white font-normal text-xl">No aparecer con menores de edad.</p>
                </div>
              </div>
            </>
          )
        }
      }
    },
    profile: {
      return: {
        text: 'VER GANADORES',
        url: '/album/redirect/Social/ranking?top=10'
      }
    },
    congrats: {
      range: {
        title: 'NUEVO LOGRO DESBLOQUEADO',
        description: <></>,
        description2: (
          <>
            Felicitaciones, has desbloqueado nuevos <br /> premios y obtienes más chances de ganar.
          </>
        )
      }
    },
  },
  mail: {
    title: <>¡Excelente name!</>,
    description: <>Has confirmado con éxito tu cuenta.<br />Ya puedes ingresar.</>
  },
  error: {
    505: {
      title: '¡Qué pena!',
      description: <>Esa no era la respuesta correcta. <br /> Inténtalo nuevamente.</>,
      button: {
        link: '/trivias',
        text: 'VOLVER A EMPEZAR'
      }
    },
    10: {
      title: '¡UPS! el código OTP es inválido',
      description: <>Dale clic en <b>reenviar</b> para recibir otro código e intentarlo de nuevo.</>
    }
  },
  header: {
    name: {
      br: <br />
    }
  },
  trivia: {
    exit: {
      accept: 'Si, claro',
      cancel: 'volver'
    }
  }
}