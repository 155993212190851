import styled from "styled-components";
import { Type } from "theme/types";

export const ContainerCard = styled.div`
  ${({theme}: Type) => theme?.profile?.identification?.card?.background ? ` background-image: url(${AZURE_BASE_URL}/${theme?.profile?.identification?.card?.background});` : '' }
  ${({theme}: Type) => theme?.profile?.identification?.card?.container}
`

export const SwitchCard = styled.span`
  ${({theme}: Type) => theme?.profile?.identification?.card?.switch}
`

export const ImgCard = styled.img`
  ${({theme}: Type) => theme?.profile?.identification?.card?.img}
`

export const TitleCard = styled.h2`
  ${({theme}: Type) => theme?.profile?.identification?.card?.title}
`

export const IdCard = styled.div`
  ${({theme}: Type) => theme?.profile?.identification?.card?.id}
`

export const PointsIdContainer = styled.div`
  ${({theme}: Type) => theme?.profile?.identification?.pointsId?.container}
`

export const PointsIdName = styled.p`
  ${({theme}: Type) => theme?.profile?.identification?.pointsId?.name}
`

export const PointsIdNumber = styled.p`
  ${({theme}: Type) => theme?.profile?.identification?.pointsId?.number}
`