import styled from 'styled-components';
import { Title, SubTitle, Text } from 'theme/theme';
import { Type } from './../../theme/types/generic.interface';

export const TitleReferrals = styled(Title)`
  ${({ theme }: Type) => theme?.referrals?.title}
`

export const SubtitleReferrals = styled(SubTitle)`
  ${({ theme }: Type) => theme?.referrals?.subtitle}
`

export const SharedTextReferrals = styled(Text)`
  ${({ theme }: Type) => theme?.referrals?.sharedText}
`

export const CodeReferrals = styled.div`
  ${({ theme }: Type) => theme?.referrals?.code}
`

export const ButtonLeftReferrals = styled.button`
  ${({ theme }: Type) => theme?.referrals?.buttonLeft}
`

export const ButtonRightReferrals = styled.button`
  ${({ theme }: Type) => theme?.referrals?.buttonRight}
`

export const WinnerTextReferrals = styled(Text)`
  ${({ theme }: Type) => theme?.referrals?.winnerText}
`

export const TextDetailsReferrals = styled(Text)`
  ${({ theme }: Type) => theme?.referrals?.details?.text}
`
export const ShowDetailsReferrals = styled.p`
  ${({ theme }: Type) => theme?.referrals?.details?.showDetailsReferrals}
`

export const Hr = styled.hr`
  ${({ theme }: Type) => theme?.referrals?.details?.hr}
`

export const SectionReferrals = styled.section`
  ${({ theme }: Type) => `
    ${theme?.referrals?.useTextureReferrals && `background-image: url(${AZURE_BASE_URL}/${theme?.referrals?.textureReferralsName});`}
    ${theme?.referrals?.textureProperties}
  `}
`