import LoadingScreen from 'components/loading-screen'
import { useContext, useEffect, useState } from 'react'
import { dlTrackEvent } from 'components/dataLayer/dataLayer'
import { LoaderContext } from 'contexts/loader-context'
import { useRouteMatch } from 'react-router-dom'
import { useSession } from 'contexts/session-context'
import { useTrivia } from '../../contexts/trivia-context'
import TriviaModal from './components/modal'
import TriviaGame from './components/trivia-game'
import PopUpError from './components/popUpError'

interface IProps {
  isDirectAccess?: boolean
}

const TriviaChallenge = ({ isDirectAccess = false }: IProps) => {
  const [{ error, currentTrivia }, { validateTrivia }] = useTrivia()
  const [{ data }] = useSession()
  const route = useRouteMatch<{ id: string }>()
  const [userAccepted, setUserAccepted] = useState(false)
  const { setLoad } = useContext(LoaderContext)
  const [executed, setExecuted] = useState(false)

  const triviaValidate = async (id: any) => {
    try {
      setLoad(true)
      await validateTrivia(id, isDirectAccess)
    } catch (error) {
      console.error(error)
    } finally {
      setLoad(false)
    }
  }

  useEffect(() => {
    if (data && !executed) {
      triviaValidate(route.params.id)
      setExecuted(!executed)
    }
  }, [data])

  const onAccept = () => {
    gtag('event', 'event_to_ga', { event_category: "Trivias", event_label: `Aceptar Jugar Trivia`, value: route.params.id })
    dlTrackEvent('user_interaction', { action: 'click', element: 'comienza a jugar', section: `trivia :: ${route.params.id}` })
    return setUserAccepted(true)
  }

  if (error) {
    return <PopUpError errorCode={error} />
  }

  if (!currentTrivia) {
    return <LoadingScreen />
  }

  if (!userAccepted) {
    return <TriviaModal onAccept={onAccept} />
  }

  return <TriviaGame id={route.params.id} />
}

export default TriviaChallenge
