import { Link } from 'react-router-dom'
import { ButtonNavStyle } from 'theme/home'

function MillerID() {
  return (
    <Link to='/album/start' className="w-full" onClick={() => { gtag('event', 'event_to_ga', { event_category: 'navegacion_perfil', event_label: 'Home links' }) }}>
      <ButtonNavStyle className={`button-nav flex items-center justify-center`}>
        <img src={`${AZURE_BASE_URL}/home-rallys.webp`} alt='Ir a mi MillerID' className='w-full' />
      </ButtonNavStyle>
    </Link>
  )
}

export default MillerID