import Button from "components/button";
import textServices from "services/text-services";
import { dlTrackEvent } from "components/dataLayer/dataLayer";
import ImgAndText from "./components/ImgAndText";
import { ButtonVariant } from "./components/style/button";
import { SubTitleAge, TextTermsAge } from "./components/style/text";

function LegalAge({ setAgeLocal }: { setAgeLocal: (day?: string, month?: string, year?: string) => void }) {

  return (
    <article className="flex-1 flex flex-col items-center justify-center mb-7">
      <ImgAndText />
      <section className="flex flex-col gap-7 px-7 w-full">
        <div className="gap-4 flex flex-col items-center justify-center">
          <SubTitleAge>
            {textServices.findByText('legalage/question', { es: '¿ERES MAYOR DE EDAD?', en: 'ARE YOU OF LEGAL AGE?' })}
          </SubTitleAge>
          <div className="flex justify-between gap-3 w-full">
            <Button className="w-full" onClick={() => {
              dlTrackEvent('user_interaction', {
                "action": "click",
                "element": textServices.findByText('legalage/button1', { es: 'SI, SOY MAYOR', en: 'YES, I AM OLDER' }),
                "section": "onboarding"
              })
              gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'AGEGATE - Soy mayor', value: '10' })
              setAgeLocal()
            }}>{textServices.findByText('legalage/button1', { es: 'SI, SOY MAYOR', en: 'YES, I AM OLDER' })}</Button>
            <ButtonVariant className="w-full variant" onClick={() => {
              dlTrackEvent('user_interaction', {
                "action": "click",
                "element": textServices.findByText('legalage/button2', { es: 'NO, SOY MENOR', en: 'NO, I AM A MINOR' }),
                "section": "onboarding"
              })
              gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'AGEGATE - Soy menor', value: '10' })
              setAgeLocal("21", "6", "22")
            }}>{textServices.findByText('legalage/button2', { es: 'NO, SOY MENOR', en: 'NO, I AM A MINOR' })}</ButtonVariant>
          </div>
        </div>
        <TextTermsAge className="text-center politics">{textServices.findByText('legalage/politics', {
          es: <>AL CONFIRMAR, ACEPTAS LA POLÍTICA DE PRIVACIDAD, TÉRMINOS Y CONDICIONES Y USO DE COOKIES DE ESTE SITIO WEB. ESTE SITIO WEB UTILIZA COOKIES. PARA OBTENER MÁS INFORMACIÓN, <a href={`${process.env.PUBLIC_URL}/terms-and-conditions`} rel="noreferrer" target="_blank" className="underline">LEE NUESTRA POLÍTICA DE PRIVACIDAD Y USO DE COOKIES</a >.</>,
          en: <>BY CONFIRMING, YOU ACCEPT THE PRIVACY POLICY, TERMS AND CONDITIONS AND USE OF COOKIES OF THIS WEBSITE.THIS WEBSITE USES COOKIES.CLICK HERE FOR MORE INFORMATION, <a href={`${process.env.PUBLIC_URL}/terms-and-conditions`} rel="noreferrer" target="_blank" className="underline">READ OUR PRIVACY AND COOKIE POLICY</a >.</>
        })}</TextTermsAge>
      </section>
    </article >
  )
}

export default LegalAge;