import { AuthError, UserCredential } from '@firebase/auth'
import { createContext, Dispatch, FunctionComponent, useContext, useEffect, useReducer } from 'react'
import dynamicManifest from 'utilities/dynamic-manifest'
import { createUserProperties } from 'components/dataLayer/dataLayer'
import sessionReducer, { SessionAction, SessionState, UserData } from './session-reducer'

export interface UserContextProps {
  state: SessionState
  dispatch: Dispatch<SessionAction>
}

const SessionContext = createContext<UserContextProps>({} as UserContextProps)

export const validateExpirationToken = () => {
  const ls = localStorage.getItem('state')
  let data = ls ? JSON.parse(ls) : {}
  if (data.data?.user?.stsTokenManager) {
    const expirationDate = data.data?.user?.stsTokenManager.expirationTime
    const isOutdated = Date.now() > expirationDate

    if (isOutdated) data = {};
  }
  return data;
}

export const SessionProvider: FunctionComponent = ({ children }) => {
  const data = validateExpirationToken();
  const [state, dispatch] = useReducer(sessionReducer, data)

  return <SessionContext.Provider value={{ state, dispatch }}>{children}</SessionContext.Provider>
}

export const goToLogin = () => {
  window.localStorage.removeItem('state')
  window.location.reload()
}

export const useSession = () => {
  const { state, dispatch } = useContext(SessionContext)
  useEffect(() => {
    const ls = localStorage.getItem('state')
    const data = ls ? JSON.parse(ls) : {}

    if (!Object.keys(data)) {
      goToLogin()
      return
    }

    if (data.data?.user?.stsTokenManager) {
      const expirationDate = data.data?.user?.stsTokenManager.expirationTime
      const isOutdated = Date.now() > expirationDate

      if (isOutdated) {
        goToLogin()
        return
      }
    }
    dispatch({ type: 'restore', payload: data })
  }, [dispatch])

  useEffect(() => {
    if (Object.keys(state).length) {
      localStorage.setItem('state', JSON.stringify({ user: state?.user, data: state?.data }))
      dynamicManifest(state?.user?.id)
    }
  }, [state])

  useEffect(() => {
    if (state?.user?.hashId) {
      createUserProperties(state?.user, state?.data?.providerId)
    }
  }, [])

  useEffect(() => {
    const ls = localStorage.getItem('state')
    const data = ls ? JSON.parse(ls) : {}

    if (data?.user?.hashId) {
      createUserProperties(data?.user, data?.data?.providerId)
    }
  }, [])

  const setError = (error: AuthError) => dispatch({ type: 'error', payload: error })
  const setSession = (session: UserCredential) => dispatch({ type: 'success', payload: session })
  const setUser = (user: UserData) => dispatch({ type: 'user', payload: user })
  const setPoints = (points: number) => dispatch({ type: 'points', payload: points })
  const setTotalPoints = (points: number) => dispatch({ type: 'totalpoints', payload: points })
  const actions = { setError, setSession, setUser, setPoints, setTotalPoints }
  const returnValue: [typeof state, typeof actions] = [state, actions]

  return returnValue
}
