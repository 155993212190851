import React, { useEffect, useRef, useState } from 'react'
import { HeaderST, Logo } from 'theme/header'
import useRouter from 'hooks/use-router'
import idConfigTheme from 'views/profile/views/profile-identification/id-config'
import { QRCodeCanvas } from 'qrcode.react'
import useMainClass from 'hooks/use-main-cass'
import { decryptAES } from 'utilities/encrypt'
import images from '../../images.json'

function IdentificationDynamic() {
  const refCanvas = useRef(null)
  const headerClasses = `w-full flex font-bold justify-center profile__identification__navbar`
  const logoClasses = `w-20 px-3`.trim()
  useMainClass('profile__identification')
  const { search } = useRouter()
  const query = new URLSearchParams(search)
  const oobCode = query.get('id')?.replace(/&$/i, '').replaceAll(' ', '+')
  const [id, setId] = useState(null)

  const generateCard = async () => {
    try {
      const canvasQR = document.getElementById('qr-id') as HTMLCanvasElement;
      const canvas = refCanvas.current;
      const context = canvas.getContext('2d');

      const backgroundImage = new Image();
      backgroundImage.crossOrigin = 'anonymous'
      backgroundImage.src = `${AZURE_BASE_URL}/card-qr-id.webp`;

      backgroundImage.onload = () => {
        context.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);
        const imageDataUrl = canvasQR.toDataURL();

        const borderRadius = 52;
        context.fillStyle = 'white';
        context.beginPath();
        context.moveTo(53 * 4 + borderRadius, 93 * 4);
        context.arcTo(53 * 4 + 220 * 4, 93 * 4, 53 * 4 + 220 * 4, 93 * 4 + 220 * 4, borderRadius);
        context.arcTo(53 * 4 + 220 * 4, 93 * 4 + 220 * 4, 53 * 4, 93 * 4 + 220 * 4, borderRadius);
        context.arcTo(53 * 4, 93 * 4 + 220 * 4, 53 * 4, 93 * 4, borderRadius);
        context.arcTo(53 * 4, 93 * 4, 53 * 4 + borderRadius, 93 * 4, borderRadius);
        context.closePath();
        context.fill();

        const image = new Image();
        image.crossOrigin = 'anonymous'
        image.src = imageDataUrl;

        image.onload = () => {
          context.drawImage(image, 69 * 4, 109 * 4, 188 * 4, 188 * 4);

          idConfigTheme[THEME](canvas, id)
        };
      }
    } catch (error) {
      console.error(error)
    }
  }

  const decryptId = async () => {
    try {
      const decryptedData = await decryptAES(
        oobCode,
        SECRET_PASS
      );

      const unStringifiedData = JSON.parse(decryptedData);
      setId(unStringifiedData)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (oobCode && idConfigTheme[THEME]) {
      decryptId()
    }
  }, [oobCode])

  useEffect(() => {
    if (id) {
      generateCard()
    }
  }, [id])

  return (
    <div className='absolute min-h-screen flex flex-col items-center w-full top-0 left-0 overflow-y-auto'>
      <HeaderST className={headerClasses}>
        <button className="h-12 cursor-default">
          <Logo
            data-url="/home"
            className={logoClasses}
            src={`${AZURE_BASE_URL}${images['logo-svg']}`}
            alt={`Logo de ${PROJECT_NAME}`}
          />
        </button>
      </HeaderST>
      <div className='flex-1 flex justify-center items-center'>
        {id &&
          <>
            <QRCodeCanvas id='qr-id' value={`${id}`} className='hidden' />
            <canvas ref={refCanvas} width={1296} height={1788} className='w-72 box-shadow-card-qr' />
          </>
        }
      </div>
    </div>
  )
}

export default IdentificationDynamic