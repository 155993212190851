import Clock from "components/icon/clock";
import Cup from "components/icon/cup";
import Point from "components/icon/point";
import { useEffect, useState } from "react";
import { useCountdown } from "rooks";
import { DivCardContainer, DivCardOffert, DivCardTitle } from "theme/auction";

function CardAuctions({ item, setAuction, modal }: any) {

  const count = useCountdown(item?.expires, { interval: 1000 })
  const [endTimeTransform, setEndTimeTransform] = useState<string | null>(null)

  const ofertText = {
    1: { text: 'OFERTA ACTUAL' },
    2: { text: 'OFERTA ACTUAL' },
    3: { text: 'VAS GANANDO', icon: <Cup /> },
    4: { text: 'TE SUPERARON', icon: <Point /> }
  }

  const secondsToString = (seconds: number) => {
    let days: any = Math.floor(seconds / 86400);
    days = (days < 10) ? '0' + days : days;
    let hour: any = Math.floor((seconds % 86400) / 3600);
    hour = (hour < 10) ? '0' + hour : hour;
    let minute: any = Math.floor((seconds / 60) % 60)
    minute = minute < 10 ? '0' + minute : minute
    let second: any = seconds % 60
    second = second < 10 ? '0' + second : second
    return `${days}d ${hour}h ${minute}m ${second}s`
  }

  useEffect(() => {
    if (count > 0) {
      setEndTimeTransform(secondsToString(count))
    }
  }, [count])

  return (
    <DivCardContainer status={item?.status} modal={modal} onClick={() => {
      setAuction && setAuction(item)
      gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'AUCTIONS - Ver mas sobre la subasta', value: '10' })
    }} className={`w-full flex flex-col items-center justify-center ${!modal && 'cursor-pointer'}`}>
      {!modal &&
        <DivCardTitle className="din text-center flex justify-center items-center w-full p-4 border text-lg font-semibold">
          <p>{item?.title}</p>
        </DivCardTitle>
      }
      <div className="h-48 w-full border"></div>
      <div className="w-full flex flex-row items-center justify-center border">
        <DivCardOffert status={item?.status} className={`din text-xl font-extrabold p-2 px-6 flex flex-col gap-1 items-center justify-center h-full`}>
          <p className="font-normal text-base">{ofertText[item.status].text}</p>
          <p className="flex items-center justify-center gap-2">{ofertText[item.status].icon}{item?.currentOffer}</p>
        </DivCardOffert>
        <div className="flex-1 flex flex-col items-center justify-center gap-1 h-full border-l">
          <p>Tiempo restante</p>
          <div className="flex flex-row items-center gap-2 justify-center font-semibold">
            <Clock />
            <p>{endTimeTransform}</p>
          </div>
        </div>
      </div>
    </DivCardContainer>
  )
}

export default CardAuctions;