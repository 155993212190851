import { TRIVIA_RANKING } from "constants/api"
import useAxios from "axios-hooks"
import { useSession } from "contexts/session-context"
import dayjs from "dayjs"
import useMainClass from "hooks/use-main-cass"
import useRouter from "hooks/use-router"
import { useCallback, useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"
import textServices from "services/text-services"
import LoadingScreen from "components/loading-screen"

const StatusEnum = {
  0: true,
  2: true,
  3: false
}

const Sum = () => {
  return <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.29169 0.375H5.04169V4.125H8.79169V4.875H5.04169V8.625H4.29169V4.875H0.541692V4.125H4.29169V0.375Z" fill="currentColor" />
  </svg>
}

const Arrow = () => {
  return <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.45117 0.107422L10.7988 1.45508L4.44141 7.8125H23.25V9.6875H4.44141L10.7988 16.0449L9.45117 17.3926L1.48242 9.42383L0.837891 8.75L1.48242 8.07617L9.45117 0.107422Z" fill="currentColor" />
  </svg>
}

const Clock = () => {
  return <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 0C9.35417 0 10.6172 0.345052 11.7891 1.03516C12.9219 1.6862 13.8203 2.57812 14.4844 3.71094C15.1615 4.88281 15.5 6.14583 15.5 7.5C15.5 8.85417 15.1615 10.1172 14.4844 11.2891C13.8203 12.4219 12.9219 13.3203 11.7891 13.9844C10.6172 14.6615 9.35417 15 8 15C6.64583 15 5.38281 14.6615 4.21094 13.9844C3.07812 13.3203 2.1862 12.4219 1.53516 11.2891C0.845052 10.1172 0.5 8.85417 0.5 7.5C0.5 6.14583 0.845052 4.88281 1.53516 3.71094C2.1862 2.57812 3.07812 1.6862 4.21094 1.03516C5.38281 0.345052 6.64583 0 8 0ZM8 1.25C6.86719 1.25 5.8125 1.53646 4.83594 2.10938C3.89844 2.65625 3.15625 3.39844 2.60938 4.33594C2.03646 5.3125 1.75 6.36719 1.75 7.5C1.75 8.63281 2.03646 9.6875 2.60938 10.6641C3.15625 11.6016 3.89844 12.3438 4.83594 12.8906C5.8125 13.4635 6.86719 13.75 8 13.75C9.13281 13.75 10.1875 13.4635 11.1641 12.8906C12.1016 12.3438 12.8438 11.6016 13.3906 10.6641C13.9635 9.6875 14.25 8.63281 14.25 7.5C14.25 6.36719 13.9635 5.3125 13.3906 4.33594C12.8438 3.39844 12.1016 2.65625 11.1641 2.10938C10.1875 1.53646 9.13281 1.25 8 1.25ZM7.375 2.5H8.625V6.875H11.75V8.125H7.375V2.5Z" fill="currentColor" />
  </svg>
}

function formatTime(ms: number) {
  const totalSeconds = Math.floor(ms / 1000);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  return `${formattedMinutes}m ${formattedSeconds}s`;
}


export default function TriviaRanking() {
  useMainClass('album__multimedia__ranking')
  useMainClass('trivia__ranking')
  const params: any = useParams()
  const { history } = useRouter()
  const [{ data: session }] = useSession()
  const [{ data: ranking, response, loading, error }] = useAxios({
    url: TRIVIA_RANKING.replace(':triviaId', params?.id), headers: {
      Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`,
      'Ph-Api-Id': PH_API_ID ?? 1
    },
  }, { useCache: false })


  const status = useMemo(() => {
    let currentStatus = false

    if (ranking) {
      const nowDate = dayjs(new Date())
      const validTo = new Date(ranking.validTo as string)
      const date = dayjs(validTo)
      currentStatus = StatusEnum[ranking?.profileTriviaStatus]

      if (nowDate > date) currentStatus = false
    }

    return currentStatus
  }, [ranking])

  const statusTime = useMemo(() => {
    let currentStatus = true

    if (ranking) {
      const nowDate = dayjs(new Date())
      const validTo = new Date(ranking.validTo as string)
      const date = dayjs(validTo)

      if (nowDate > date) currentStatus = false

    }

    return currentStatus
  }, [ranking])

  const handleButton = useCallback(() => {
    if (status) history.push(`/trivia/${(params as any)?.id}`)
    else history.push(`/trivias`)
  }, [status])

  useEffect(() => {
    if (error) return history.push('/trivias')
  }, [error])

  useEffect(() => {
    if (response?.status === 204) return history.push('/trivias')
  }, [response])

  if (loading || !ranking) return <LoadingScreen />

  return (
    <article className="w-full px-4 py-6">
      <div className='flex justify-between items-center mb-2 gap-2'>
        <h2 className="title medium flex items-center gap-2 flex-1" onClick={() => history.goBack()}>
          <Arrow /> {ranking.name}
        </h2>
        <button onClick={handleButton} className={`button-top py-1 px-2 flex items-center gap-1 ${status ? 'cursor-pointer visible' : 'cursor-default button-top__finished'}`}>
          <Sum /> <p>{status ? textServices.findByText('trivias/ranking-subtitle/button', { es: 'participar', en: 'participate' }) : textServices.findByText('trivias/ranking-subtitle/button-finish', { es: 'Ver trivias', en: 'See trivias' })}</p>
        </button>
      </div>
      <div className='flex items-center gap-3 mb-2'>
        <p className={`status text small status ${!statusTime && 'status__finished'}`}>
          {statusTime ? textServices.findByText('trivias/ranking-subtitle/active/title', { es: 'Activo', en: 'Active' }) : textServices.findByText('trivias/ranking-subtitle/finished/title', { es: 'Terminado', en: 'Finished' })}
        </p>
        <p className={`text small status-date ${!statusTime && 'status-date__finished'}`}>
          {statusTime ? textServices.findByText('trivias/ranking-subtitle/finished/time', { es: 'Finaliza ', en: 'End ' }) : textServices.findByText('trivias/ranking-subtitle/finished/time', { es: 'Finalizó ', en: 'Completed' })}
          {"el "}
          {dayjs(ranking.validTo).format('DD/MM/YYYY')}
        </p>
      </div>
      <p className="subtitle description medium">
        {
          statusTime
            ? textServices.findByText('trivias/ranking-subtitle/progress', {
              es: <>Este es el ranking con los mejores tiempos de respuesta de la trivia seleccionada.<br />Si no la jugaste aún puedes participar con el botón.</>, en: <>This is the ranking with the best response times of the selected trivia.<br />If you didn&apos;t play it you can still participate with the button.</>
            })
            : textServices.findByText('trivias/ranking-subtitle/completed', {
              es: <>Este es el ranking con los mejores tiempos de respuesta de la trivia seleccionada.<br />Esta trivia ya ha concluído, ya no puedes participar.</>, en: <>This is the ranking with the best response times of the selected trivia.<br />This trivia has already ended, you can no longer participate.</>
            })
        }
      </p>
      <div className="information-position border border-solid bg-gray-200 bg-opacity-50 py-3 px-4 gap-4 rounded-md flex justify-between items-center text-center mt-4">
        {
          (ranking?.profileRanking?.position)
            ? (
              <>
                <p className="text medium flex-1">Estás en la posición <b>{ranking?.profileRanking?.position}</b></p>
                <div className="h-10 division bg-black" style={{ width: '1px' }} />
                <div className="flex items-center gap-2 justify-center  flex-1">
                  <Clock />
                  <p className="text medium">{formatTime(ranking?.profileRanking?.time)}</p>
                </div>
              </>
            ) : (
              <div className="w-full flex items-center justify-center">
                <p className="text medium">
                  {
                    status ?
                      textServices.findByText('trivias/ranking-information/not-play', {
                        es: 'Aún no jugaste no estás en ninguna posición', en: 'You have not yet played you are not in any position'
                      }) :
                      textServices.findByText('trivias/ranking-information/not-play-complete', {
                        es: 'No jugaste esta trivia', en: 'You did not play this trivia'
                      })
                  }
                </p>
              </div>
            )
        }
      </div>
      <ul className="mt-4 flex flex-col gap-2 ranking">
        {
          ranking?.ranking?.map((item, key) => (
            <li key={key} className={`py-2 px-4 rounded-md bg-gray-200 gap-3 flex justify-between items-center ranking-cards ${key === 0 ? 'bg-opacity-50' : key === 1 ? 'bg-opacity-70' : key === 2 ? 'bg-opacity-90' : ''}`}>
              <div className="w-8 h-8 rounded-full border-2 border-solid flex items-center justify-center title small ranking-position"><p>{item?.position}</p></div>
              <div className="flex flex-col flex-1">
                <p className="subtitle medium"><b>{item?.firstName} {item?.lastName}</b></p>
                <p className="text small">ID {item?.profileId}</p>
              </div>
              <div className="flex items-center gap-2 ranking-time">
                <Clock />
                <p className="subtitle medium"><b>{formatTime(item?.time)}</b></p>
              </div>
            </li>
          ))
        }
      </ul>
    </article>
  )
}