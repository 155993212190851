import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { popUps } from "./utils/data"

function PopUp({ setPopUpFalse, popUp }) {

  const [state, setState] = useState<Record<string, any>>({ style: { backgroundColor: 'rgba(225,37,26,1)' } })
  const history = useHistory()

  const useStore = (newState: Record<string, any>) => {
    setState(state => ({ ...state, ...newState }))
  }

  useEffect(() => {
    if (!popUp || !popUps[popUp]) return setPopUpFalse()
  }, [popUp])

  const Component = popUps[popUp]

  return (
    <div className="fixed top-0 w-full" style={state?.style}>
      <section className="min-h-view flex flex-col w-full gap-4 saira font-bold items-center h-view">
        {popUps[popUp] && <Component history={history} useStore={useStore} setPopUpFalse={setPopUpFalse} />}
      </section>
    </div>
  )
}

export default PopUp