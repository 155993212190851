import { TYC } from 'constants/api'
import useAxios from 'axios-hooks'
import useMainClass from 'hooks/use-main-cass'
import LoadingScreen from 'components/loading-screen'
import styled from 'styled-components'
import { Type } from 'theme/types'
import ErrorView from 'components/error-view'
import useRouter from 'hooks/use-router'
import styles from './styles.module.scss'

const Div = styled.div`
  ${({ theme }: Type) => theme?.termsAndConditions?.div}
`

const TYCPage = () => {
  useMainClass('terms-and-conditions')
  const { push } = useRouter()
  const [{ loading, error, data }] = useAxios({
    url: TYC,
    headers: { 'Ph-Api-Id': PH_API_ID??1 }
  })

  if (error) {
    return <ErrorView code={9999} buttonMenu={() => push('/home')} />
  }

  if (loading) {
    return <LoadingScreen />
  }

  if (data) {
    return <Div className={`${styles.wrapper} container-terms`}>
      <div dangerouslySetInnerHTML={{ __html: data }}></div>
    </Div>
  }
}

export default TYCPage
