import { SVGProps } from 'react'

function SearchIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.4688 0.59375C11.6582 0.59375 12.7588 0.894531 13.7705 1.49609C14.7686 2.07031 15.5547 2.85645 16.1289 3.85449C16.7305 4.86621 17.0312 5.9668 17.0312 7.15625C17.0312 8.3457 16.7305 9.44629 16.1289 10.458C15.5547 11.4561 14.7686 12.2422 13.7705 12.8164C12.7588 13.418 11.6582 13.7188 10.4688 13.7188C9.68945 13.7188 8.94434 13.5889 8.2334 13.3291C7.53613 13.0693 6.90039 12.707 6.32617 12.2422L1.09668 17.4717L0.15332 16.5283L5.38281 11.2988C4.91797 10.7246 4.55566 10.0889 4.2959 9.3916C4.03613 8.68066 3.90625 7.93555 3.90625 7.15625C3.90625 5.9668 4.20703 4.86621 4.80859 3.85449C5.38281 2.85645 6.16895 2.07031 7.16699 1.49609C8.17871 0.894531 9.2793 0.59375 10.4688 0.59375ZM10.4688 1.90625C9.51172 1.90625 8.62988 2.14551 7.82324 2.62402C7.03027 3.08887 6.39453 3.72461 5.91602 4.53125C5.45117 5.32422 5.21875 6.19922 5.21875 7.15625C5.21875 8.11328 5.45117 8.99512 5.91602 9.80176C6.39453 10.5947 7.03027 11.2305 7.82324 11.709C8.62988 12.1738 9.51172 12.4062 10.4688 12.4062C11.4258 12.4062 12.3008 12.1738 13.0938 11.709C13.9004 11.2305 14.5361 10.5947 15.001 9.80176C15.4795 8.99512 15.7188 8.11328 15.7188 7.15625C15.7188 6.19922 15.4795 5.32422 15.001 4.53125C14.5361 3.72461 13.9004 3.08887 13.0938 2.62402C12.3008 2.14551 11.4258 1.90625 10.4688 1.90625Z" fill="currentColor"/>
    </svg>
  )
}

export default SearchIcon


