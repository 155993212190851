import { useSession } from 'contexts/session-context'
import Edit from 'components/icon/edit'
import { dlTrackEvent } from 'components/dataLayer/dataLayer'
import { Link } from 'react-router-dom'
import { TextProfile, TextProfileSecondary } from 'views/profile/text.style'
import textServices from 'services/text-services'
import useMainClass from 'hooks/use-main-cass'
import CONFIGS from 'build/Config'

function Phone() {
  const [{ user }] = useSession()
  const showInstagram = !CONFIGS.notShowFields?.includes('instagram')
  useMainClass('profile__phone')

  return (
    <>
      <div className="font-bold text-2xl px-5 py-6 pb-2 flex break-all">
        <div className="flex-1">
          <TextProfile className='profile__label'>{textServices.findByText('profile/view/contact/phoneTitle', { es: 'Numero de teléfono', en: 'Phone number' })}</TextProfile>
          <TextProfileSecondary className="font-normal dinPro text-lg normal-case profile__response">
            {
              !user?.phoneNumber && !user?.address?.phone
                ? textServices.findByText('profile/view/contact/notFound', { es: 'SIN INFORMACION', en: 'NO INFORMATION' })
                : `+52 ${user?.address?.phone ? user?.address?.phone : user?.phoneNumber}`
            }
          </TextProfileSecondary>
        </div>
        <Link to="/phone/update" className="flex justify-start items-center" onClick={() => {
          dlTrackEvent('user_interaction', {
            action: "click",
            element: 'editar teléfono',
            section: "mi perfil",
            subsection: 'contacto'
          })
        }}>
          <button className='icon-edit'>
            <Edit />
          </button>
        </Link>
      </div>
      <div className="font-bold text-2xl px-5 py-4 flex break-all">
        <div className="flex-1">
          <TextProfile className='profile__label'>{textServices.findByText('profile/view/contact/emailTitle', { es: 'Mail de contacto', en: 'Contact mail' })}</TextProfile>
          <TextProfileSecondary className="font-normal dinPro text-lg normal-case profile__response">
            {user?.contactEmail || textServices.findByText('profile/view/contact/notFound', { es: 'SIN INFORMACION', en: 'NO INFORMATION' })}
          </TextProfileSecondary>
        </div>
        <Link to="/mail/update" className="flex justify-start items-center" onClick={() => {
          dlTrackEvent('user_interaction', {
            action: "click",
            element: 'editar email',
            section: "mi perfil",
            subsection: 'contacto'
          })
        }}>
          <button className='icon-edit'>
            <Edit />
          </button>
        </Link>
      </div>
      {
        showInstagram && (
          <div className="font-bold text-2xl px-5 py-4 flex break-all">
            <div className="flex-1">
              <TextProfile className='profile__label'>Instagram</TextProfile>
              <TextProfileSecondary className="font-normal dinPro text-lg normal-case profile__response">
                {user?.instagram || 'SIN INFORMACION'}
              </TextProfileSecondary>
            </div>
            <Link to="/instagram/update" className="flex justify-start items-center">
              <button className='icon-edit'>
                <Edit />
              </button>
            </Link>
          </div>
        )
      }
    </>
  )
}

export default Phone
