/* eslint-disable indent */
import { BIRTHDAY, EMAIL_REGEXP, PHONE_REGEXP } from 'constants/regexp'
import { dlTrackError } from 'components/dataLayer/dataLayer'
import * as yup from 'yup'
import { RequiredStringSchema } from 'yup/lib/string'
import { AnyObject } from 'yup/lib/types'
import CONFIGS from 'build/Config'
import { IFields } from 'build/types/configs'
import textServices from './text-services'

yup.setLocale({
  mixed: {
    default: textServices.findByText('formBuilder/mixed/default', { es: 'El campo no es válido', en: 'The field is not valid' }),
    required: textServices.findByText('formBuilder/mixed/required', { es: 'El campo no puede estar vacío', en: 'The field cannot be empty' })
  },
  string: {
    email: textServices.findByText('formBuilder/string/email', { es: 'El email no es válido', en: 'The email is not valid' }),
    min: ({ min }) => textServices.findByText('formBuilder/string/min', { es: `Debe contener al menos ${min} caracteres`, en: `Must contain at least ${min} characters` }),
    max: ({ max }) => textServices.findByText('formBuilder/string/max', { es: `Debe contener como máximo ${max} caracteres`, en: `Must contain at most ${max} characters` }),
    length: ({ length }) => textServices.findByText('formBuilder/string/length', { es: `El campo debe contener una longitud de ${length}`, en: `The field must be ${length} characters long` }),
    url: textServices.findByText('formBuilder/string/url', { es: 'La URL no es válida', en: 'The URL is not valid' }),
    lowercase: textServices.findByText('formBuilder/string/lowercase', { es: 'El campo solo puede estar en minúsculas', en: 'The field must be in lowercase' }),
    uppercase: textServices.findByText('formBuilder/string/uppercase', { es: 'El campo solo puede estar en mayúsculas', en: 'The field must be in uppercase' })
  },
  number: {
    min: ({ min }) => textServices.findByText('formBuilder/number/min', { es: `Debe ser mayor o igual a ${min}`, en: `Must be greater than or equal to ${min}` }),
    max: ({ max }) => textServices.findByText('formBuilder/number/max', { es: `Debe ser menor o igual a ${max}`, en: `Must be less than or equal to ${max}` }),
    lessThan: ({ less }) => textServices.findByText('formBuilder/number/lessThan', { es: `Debe ser menor a ${less}`, en: `Must be less than ${less}` }),
    moreThan: ({ more }) => textServices.findByText('formBuilder/number/moreThan', { es: `Debe ser mayor a ${more}`, en: `Must be greater than ${more}` }),
    positive: textServices.findByText('formBuilder/number/positive', { es: 'Debe ser un valor positivo', en: 'Must be a positive value' }),
    negative: textServices.findByText('formBuilder/number/negative', { es: 'Debe ser un valor negativo', en: 'Must be a negative value' }),
    integer: textServices.findByText('formBuilder/number/integer', { es: 'Debe ser un valor entero', en: 'Must be an integer' })
  },
  date: {
    min: textServices.findByText('formBuilder/date/min', { es: 'Tiene que ser mayor', en: 'Must be later' }),
    max: textServices.findByText('formBuilder/date/max', { es: 'Tiene que ser menor', en: 'Must be earlier' })
  }
})

const yupRequired = (name: string, yup: RequiredStringSchema<string, AnyObject>) => {
  if (!CONFIGS.notAvailableFields?.includes(name as IFields)) {
    return yup.required()
  }
  return yup
}

const yupWhen = (schemaWhen) => {
  return (value: any, schema) => {
    if (value === '') return
    return schema.concat(schemaWhen)
  }
}

const formServices = {

  formBuilder(schema: any, value: string | number, name: string, type: any) {

    if (type === 'number' && value === '') {
      return 'El campo no puede estar vacío'
    }

    const formSchema = yup.object().shape({
      [name]: schema
    })
    const response = formSchema.validate({ [name]: value }).catch((err) => {
      return err.errors[0]
    })
    return response

  },

  formSelectYup(name: string) {
    switch (name) {
      case 'textcard':
        return yupRequired(name, yup.string().trim().when(yupWhen(yup.string().min(5).max(60))))
      case 'titleHistory':
        return yupRequired(name, yup.string().trim().when(yupWhen(yup.string().min(2).max(80))))
      case 'textHistory':
        return yupRequired(name, yup.string().trim().when(yupWhen(yup.string().min(30).max(280))))
      case 'firstName':
        return yupRequired(name, yup.string().trim().when(yupWhen(yup.string().matches(/^[aA-zZ\sñÑ\u00E0-\u00FC]+$/, textServices.findByText('formBuilder/firstName/matches', { es: 'El nombre debe ser alfabético', en: 'The name must be alphabetic' })).min(2).max(30))))
      case 'lastName':
        return yupRequired(name, yup.string().trim().when(yupWhen(yup.string().matches(/^[aA-zZ\sñÑ\u00E0-\u00FC]+$/, textServices.findByText('formBuilder/lastName/matches', { es: 'El apellido debe ser alfabético', en: 'The last name must be alphabetic' })).min(2).max(30))))
      case 'nickName':
        return yupRequired(name, yup.string().trim().when(yupWhen(yup.string().matches(/^[aA-zZ-Z0-9-ñÑ\u00E0-\u00FC]*$/, textServices.findByText('formBuilder/nickName/matches', { es: 'El apodo debe ser alfanumérico (sin espacios)', en: 'The nickname must be alphanumeric (no spaces)' })).min(2).max(30))))
      // return yup.string().trim().required().matches(/^[aA-zZ-Z0-9-ñÑ\u00E0-\u00FC]*$/, 'El apodo debe ser alfanumérico (sin espacios)').min(2).max(30)
      case 'email':
        return yupRequired(name, yup.string().email().when(yupWhen(yup.string().matches(EMAIL_REGEXP, textServices.findByText('formBuilder/email/matches', { es: 'El email no es válido', en: 'The email is not valid' })))))
      // return yup.string().email().required().matches(EMAIL_REGEXP, 'El email no es válido')
      case 'contactMail':
        return yupRequired(name, yup.string().email().when(yupWhen(yup.string().matches(EMAIL_REGEXP, textServices.findByText('formBuilder/contactMail/matches', { es: 'El email no es válido', en: 'The email is not valid' })))))
      // return yup.string().email().required().matches(EMAIL_REGEXP, 'El email no es válido')
      case 'password':
        return yupRequired(name, yup.string().when(yupWhen(yup.string().min(6))))
      // return yup.string().required().min(6)
      case 'password_confirm':
        return yupRequired(name, yup.string().when(yupWhen(yup.string().oneOf([yup.ref('password'), null], textServices.findByText('formBuilder/password/oneOf', { es: 'Las contraseñas no coinciden', en: 'Passwords do not match' })))))
      // return yup.string().required().oneOf([yup.ref('password'), null], 'Las contraseñas no coinciden')
      case 'curp':
        return yupRequired(name, yup.string().when(yupWhen(yup.string().min(17).max(18).matches(/^[aA-zZ0-9\sñÑ\u00E0-\u00FC]+$/, textServices.findByText('formBuilder/curp/matches', { es: 'El CURP debe ser alfanumérico', en: 'The CURP must be alphanumeric' })))))
      // return yup.string().min(17).max(18).matches(/^[aA-zZ0-9\sñÑ\u00E0-\u00FC]+$/, 'El CURP debe ser alfanumérico').required()
      case 'street1':
        return yupRequired(name, yup.string().when(yupWhen(yup.string().min(4).matches(/^[aA-zZ0-9\sñÑ\u00E0-\u00FC]+$/, textServices.findByText('formBuilder/street1/matches', { es: 'Debe ser alfanumérico', en: 'Must be alphanumeric' })))))
      // return yup.string().trim().required().min(4).matches(/^[aA-zZ0-9\sñÑ\u00E0-\u00FC]+$/, 'Debe ser alfanumérico')
      case 'streetType':
        return yupRequired(name, yup.string().nullable().when(yupWhen(yup.string())))
      // return yup.string().nullable().trim().required('Debe seleccionar una opción')
      case 'postalCode':
        return yupRequired(name, yup.string().trim().when(yupWhen(yup.string().min(4).max(5).matches(/^[aA-zZ0-9\sñÑ\u00E0-\u00FC]+$/, textServices.findByText('formBuilder/postalCode/matches', { es: 'El código postal debe ser numérico', en: 'The postal code must be numeric' })))))
      // return yup.string().trim().required().min(4).matches(/^[aA-zZ0-9\sñÑ\u00E0-\u00FC]+$/, 'Debe ser alfanumérico')
      case 'birthday':
        return yupRequired(name, yup.string().trim().when(yupWhen(yup.string().matches(BIRTHDAY, textServices.findByText('formBuilder/birthday/matches', { es: 'La fecha debe ser válida', en: 'The date must be valid' })).min(10, textServices.findByText('formBuilder/birthday/matches', { es: 'La fecha debe ser válida', en: 'The date must be valid' })))))
      // return yup.string().trim().required().matches(BIRTHDAY, 'La fecha debe ser válida').min(10, 'La fecha debe ser válida')
      case 'streetNumber':
        return yupRequired(name, yup.string().trim().when(yupWhen(yup.string().matches(/^[aA-zZ0-9\sñÑ\u00E0-\u00FC]+$/, textServices.findByText('formBuilder/streetNumber/matches', { es: 'Debe ser alfanumérico', en: 'Must be alphanumeric' })))))
      // return yup.string().trim().required().min(2).matches(/^[aA-zZ0-9\sñÑ\u00E0-\u00FC]+$/, 'Debe ser alfanumérico')
      case 'neighborhood':
        return yupRequired(name, yup.string().trim().when(yupWhen(yup.string().matches(/^[aA-zZ0-9\sñÑ\u00E0-\u00FC]+$/, textServices.findByText('formBuilder/neighborhood/matches', { es: 'Debe ser alfanumérico', en: 'Must be alphanumeric' })))))
      // return yup.string().trim().required().matches(/^[aA-zZ0-9\sñÑ\u00E0-\u00FC]+$/, 'Debe ser alfanumérico')
      case 'phoneNumber':
        return yupRequired(name, yup.string().trim().when(yupWhen(yup.string().matches(PHONE_REGEXP, textServices.findByText('formBuilder/phoneNumber/matches', { es: 'El teléfono debe contener 10 números', en: 'The phone number must contain 10 digits' })))))
      // return yup.string().trim().required().matches(PHONE_REGEXP, 'El celular debe contener 10 números')
      case 'otp':
        return yupRequired(name, yup.string().trim().when(yupWhen(yup.string().min(6, textServices.findByText('formBuilder/otp/min', { es: 'OTP Incorrecto', en: 'Incorrect OTP' })).max(6, textServices.findByText('formBuilder/otp/min', { es: 'OTP Incorrecto', en: 'Incorrect OTP' })))))
      // return yup.string().required().min(6, 'OTP Incorrecto').max(6, 'OTP Incorrecto')
      case 'genreId':
        return yupRequired(name, yup.string().when(yupWhen(yup.string())))
      // return yup.string().required()
      case 'instagram':
        return yupRequired(name, yup.string().when(yupWhen(yup.string().max(20, 'El nombre de usuario debe ser menor a 20 caracteres'))))
      case 'sign':
        return yupRequired(name, yup.string().when(yupWhen(yup.string().max(10,  textServices.findByText('formBuilder/sign/max', { es: 'La firma tiene que ser menor a 10 caracteres', en: 'The signature must be less than 10 characters' })))))
      case 'clientNum':
        return yupRequired(name, yup.string().trim().matches(/^[a-zA-Z0-9]{9}$/, 'El número de cliente debe contener solo letras y números').when(yupWhen(yup.string().max(9, 'El número de cliente es de 9 digitos').min(9, 'El número de cliente tiene minimo 9 digitos'))))
      default:
        return null
    }
  },

  formGenerateSchema(names: string[]) {
    const object = {}
    names.forEach((name) => {
      object[name] = this.formSelectYup(name)
    })

    return yup.object().shape({
      ...object
    })
  },

  formValidateErrors(yupResolver: any, section: string, errorType: string ) {
    return async (data: any, context: any, options: any) => {
      const resolver = await yupResolver(data, context, options) as any;
      if (resolver?.errors) {
        for (const key in resolver?.errors) {
          if (Object.prototype.hasOwnProperty.call(resolver?.errors, key)) {
            const element = resolver?.errors[key];
            dlTrackError({
              error_type: errorType,
              error_code: key,
              error_message: element?.message,
              section: section
            })
          }
        }
      }
      return resolver;
    }
  }

}

export default formServices
