import { createContext, useState } from 'react'
export interface LoaderContextProps {
    load: boolean
    setLoad: any
    head: boolean
    setHead: any
    footer: boolean
    setFooter: (value: boolean) => void;
}

const LoaderContext = createContext<LoaderContextProps>({} as LoaderContextProps)

const LoaderProvider = ({ children }) => {
  const [load, setLoad] = useState(false)
  const [head, setHead] = useState(true)
  const [footer, setFooter] = useState(true)

  const data = {
    load,
    setLoad,
    head, 
    setHead,
    footer,
    setFooter
  }

  return <LoaderContext.Provider value={data}>{children}</LoaderContext.Provider>
}

export { LoaderProvider, LoaderContext }