/* eslint-disable eqeqeq */
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import Button from "components/button";
import Input from "components/input";
import Radio from "components/radio";
import { LoaderContext } from "contexts/loader-context";
import useMainClass from "hooks/use-main-cass";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import formServices from "services/form-builder-services";
import ProfileService from "services/profile-services";
import textServices from "services/text-services";
import { Label, ReturnText, ErrorInput } from "theme/theme";

function StepOne({ state, handleChange, nextStep, session, setHelper, prevStep, setError }: any) {

  useMainClass('form__step__one')

  const { register, handleSubmit, formState: { errors } }
    = useForm({ resolver: formServices.formValidateErrors(yupResolver(formServices.formGenerateSchema(["street1", "streetType", "streetNumber", "apartament", "postalCode"])), 'address', 'datos cuenta') })
  const { setLoad, setFooter } = useContext(LoaderContext)

  useEffect(() => {
    setFooter(false)
    return () => setFooter(true)
  }, [])

  async function onFormSubmit(data: any) {
    handleChange(data)
    setLoad(true)
    try {
      const response = await ProfileService.checkZip({ postalCode: data.postalCode }, { session })
      handleChange({ city: response.data[0].cities[0].id, state: response.data[0].id })
      setHelper({ city: response.data[0].cities[0], state: response.data[0], neighborhood: response.data[0].cities[0].neighborhoods })
      nextStep()
    } catch (err) {
      const typedError = err as AxiosError
      const { code } = typedError.response?.data || {}
      setError(code)
    } finally {
      setLoad(false)
    }
  }

  return (
    <div className="flex flex-col flex-1 md:pt-10">
      <form className="flex h-full justify-between flex-col gap-4 mx-7 mb-7" onSubmit={handleSubmit(onFormSubmit)} noValidate>
        <div className="flex-1 flex flex-col gap-4">
          <div className="gap-2 flex flex-col input-container">
            <Label className="text-start text-2xl font-bold uppercase input-label">{textServices.findByText('profile/form/information/address/label', { es: '¿Tu dirección?', en: 'Your address?' })}</Label>
            <Input
              className="text-center saira font-bold text-sm"
              type="text"
              placeholder="Calle"
              name="street1"
              defaultValue={state?.street1}
              {...register("street1")}
            />
            <ErrorInput className="input-error">
              <ErrorMessage errors={errors} name="street1" />
            </ErrorInput>
          </div>
          <div className="flex items-center justify-evenly gap-3 input-container">
            <Radio type="radio" value="1" {...register("streetType")} defaultChecked={!state.streetType || state.streetType == 1}>
              {textServices.findByText('profile/form/information/street/label', { es: 'Calle', en: 'Street' })}
            </Radio>
            <Radio type="radio" value="2" {...register("streetType")} defaultChecked={state.streetType == 2}>
              {textServices.findByText('profile/form/information/avenue/label', { es: 'Avenida', en: 'Avenue' })}
            </Radio>
            <Radio type="radio" value="3" {...register("streetType")} defaultChecked={state.streetType == 3}>
              {textServices.findByText('profile/form/information/closed/label', { es: 'Cerrada', en: 'Closed' })}
            </Radio>
          </div>
          <ErrorInput className="input-error">
            <ErrorMessage errors={errors} name="streetType" />
          </ErrorInput>
          <div className="flex flex-wrap items-center justify-between">
            <div className="gap-2 flex flex-col input-container" style={{ width: '48%' }}>
              <Input
                className="text-center saira font-bold text-sm "
                type="text"
                placeholder={textServices.findByText('profile/form/information/streetNumber/placeholder', { es: "Número", en: 'Number' })}
                name="streetNumber"
                defaultValue={state?.streetNumber}
                {...register("streetNumber")}
              />
            </div>
            <div className="gap-2 flex flex-col input-container" style={{ width: '48%' }}>
              <Input
                className="text-center saira font-bold text-sm"
                type="text"
                placeholder={textServices.findByText('profile/form/information/apartment/placeholder', { es: "Apartamento", en: 'Apartment' })}
                name="apartament"
                defaultValue={state?.apartament}
                {...register("apartament")}
              />
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-between">
            <div className="gap-2 flex flex-col" style={{ width: '48%' }}>
              <ErrorInput className="input-error">
                <ErrorMessage errors={errors} name="streetNumber" />
              </ErrorInput>
            </div>
            <div className="gap-2 flex flex-col" style={{ width: '48%' }}>
              <ErrorInput className="input-error">
                <ErrorMessage errors={errors} name="apartament" />
              </ErrorInput>
            </div>
          </div>
          <div className="gap-2 flex flex-col input-container">
            <Input
              className="text-center saira font-bold text-sm"
              type="text"
              placeholder={textServices.findByText('profile/form/information/postalCode/placeholder', { es: "CP - Código postal", en: 'CP - Postal code' })}
              name="postalCode"
              defaultValue={state?.postalCode}
              {...register("postalCode")}
            />
            <ErrorInput className="input-error">
              <ErrorMessage errors={errors} name="postalCode" />
            </ErrorInput>
          </div>
        </div>
        <Button>
          <p className="uppercase">{textServices.findByText('profile/form/address/stepOne/button', { es: "Siguiente", en: 'Continue' })}</p>
        </Button>
        <button type="button" className="cursor-pointer" onClick={() => {
          prevStep()
          gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'MODIFICAR DIRECCION - Volver atras', value: '10' })
        }}>
          <ReturnText
            className={`din text-center mt-3 hover:text-red-100 uppercase underline button-back`}
            style={{ fontSize: 10 }}
          >
            {textServices.findByText('backButton', { es: 'VOLVER', en: 'BACK TO' })}
          </ReturnText>
        </button>
      </form>
    </div>
  )
}

export default StepOne;