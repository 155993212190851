import { PAGESWITHKEY } from 'constants/api'
import useAxios from 'axios-hooks'
import Button from 'components/button'
import ErrorView from 'components/error-view'
import LoadingScreen from 'components/loading-screen'
import useMainClass from 'hooks/use-main-cass'
import useRouter from 'hooks/use-router'

function PageWithKey() {
  const { query, push } = useRouter()
  useMainClass(`page-with-key page-with-key__${query?.key}`)

  const [{ loading, error, data }] = useAxios({
    url: PAGESWITHKEY.replace(':key', query?.key)
  })

  if (error) {
    return <ErrorView code={error?.response?.data?.code ?? 9999} buttonMenu={() => push('/home')} />
  }

  if (loading) {
    return <LoadingScreen />
  }

  return (
    <article className='w-full px-4 flex flex-col justify-between py-4 page-with-key__article'>
      <section className='page-with-key__section' dangerouslySetInnerHTML={{ __html: data }}></section>
      <Button className='page-with-key__button' onClick={() => push('/home')}>CERRAR</Button>
    </article>
  )
}

export default PageWithKey