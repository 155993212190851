import { Raffle } from '../types'

export type RafflesActionType = 'SET_RAFFLES' | 'SET_SELECT_RAFFLE' | 'SET_ERROR' | 'SET_CONGRATS'

export interface RafflesState {
  raffles?: Raffle[]
  selectRaffle?: Raffle
  error?: number
  congrats?: boolean
}

export interface RafflesAction {
  type: RafflesActionType
  payload: RafflesState | Raffle[] | Raffle | number  | boolean 
}

function raffleReducer(state: RafflesState, action: RafflesAction): RafflesState {
  switch (action.type) {
  case 'SET_RAFFLES':
    return { ...state, raffles: action.payload as Raffle[] }
  case 'SET_SELECT_RAFFLE':
    return { ...state, selectRaffle: action.payload as Raffle }
  case 'SET_CONGRATS':
    return { ...state, congrats: action.payload as boolean }  
  case 'SET_ERROR':
    return { ...state, error: action.payload as number }  
  default:
    return state
  }
}

export default raffleReducer