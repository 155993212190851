import styled from "styled-components";
import { Text, Title } from 'theme/theme';
import { Type } from "theme/types";

export const DivTitleWinner = styled.div`
  ${({theme}: Type) => theme?.photographer?.winner?.title?.div}
`

export const TitleWinner = styled(Title)`
  ${({theme}: Type) => theme?.photographer?.winner?.title?.h1}
`

export const DivCardWinner = styled.div`
  ${({theme}: Type) => theme?.photographer?.winner?.card?.container}
`

export const DescriptionCardWinner = styled(Text)`
  ${({ theme }: Type) => theme?.photographer?.winner?.card?.description}
`

export const ImgCardWinner = styled.img`
  ${({ theme }: Type) => theme?.photographer?.winner?.card?.img}
`