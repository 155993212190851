import styled from "styled-components";
import { Title } from "theme/theme";
import { Type } from "theme/types";

export const TitleSurveyQr = styled(Title)`
  ${({theme}: Type) => theme?.trivias?.triviaQr?.title}
`;

export const DescriptionSurveyQr = styled.div`
  ${({ theme }: Type) => theme?.trivias?.triviaQr?.description}
`;

export const ImgSurveyQr = styled.img`
  ${({ theme }: Type) => theme?.trivias?.triviaQr?.img}
`;

export const ContainerSurveyQr = styled.div`
  ${({ theme }: Type) => theme?.trivias?.triviaQr?.container}
`

export const TitleSurvey = styled(Title)`
  ${({theme}: Type) => theme?.surveys?.text?.title}
`

export const TitleSurveyPolaris = styled(Title)`
  ${({ theme }: Type) => theme?.surveys?.text?.titlePolaris}
`

export const CardContainerSurvey = styled.div` 
  ${({ theme }: Type) => theme?.surveys?.card?.container}
`

export const ImgCardSurvey = styled.img`
  ${({ theme }: Type) => theme?.surveys?.card?.img}
`

export const ImgBottomSurvey = styled.img`
  ${({ theme }: Type) => theme?.surveys?.imgBottom}
`

export const TextCardSurvey = styled.p`
  ${({ theme }: Type) => theme?.surveys?.card?.text}
`