import { useEffect, useState } from 'react'
import PrefPicker from 'components/pref-picker'
import Information from 'views/profile/views/profile-information'
import History from 'views/profile/views/profile-history'
import Address from 'views/profile/views/profile-address'
import Phone from 'views/profile/views/profile-phone'
import useRouter from 'hooks/use-router'
import UsePercentageProfile from 'hooks/use-percentage-profle'
import textServices from 'services/text-services'
import { Route } from 'react-router-dom'
import HistoryIcon from 'components/icon/menu-profile/history'
import InformationIcon from 'components/icon/menu-profile/information'
import PhoneIcon from 'components/icon/menu-profile/phone'
import PointsIcon from 'components/icon/menu-profile/points'
import ConsumptionCenterIcon from 'components/icon/menu-profile/consumptionCenter'
import CardIcon from 'components/icon/menu-profile/card'
import PrizesIcon from 'components/icon/menu-profile/prizesIcon'
import SiteMapIcon from 'components/icon/menu-profile/sitemapicon'
import PreferencesIcon from 'components/icon/menu-profile/preferences'
import AddressIcon from 'components/icon/menu-profile/gps'
import useMainClass from 'hooks/use-main-cass'
import CONFIGS from 'build/Config'
import { IRoutesProfile } from 'build/types/configs'
import RoutesWithNotFound from 'utilities/routes-with-not-found'
import Identification from './views/profile-identification'
import ConsumptionProfile from './views/profile-consumption'
import ProfilePoints from './views/profile-points'
import ProfileDetailsPoints from './views/profile-points/details'
import MenuProfile from './components/MenuProfile'
import HeaderProfile from './components/HeaderProfile'
import ProfileRanking from './views/profile-ranking'
import SocialProfile from './views/profile-social'


const Profile = () => {
  const { url, pathname } = useRouter()
  const { requiredField } = UsePercentageProfile()
  const [information, setInformation] = useState(false)
  const [consumptionCenter, setConsumptionCenter] = useState(false)
  const [address, setAddress] = useState(false)
  const [phone, setPhone] = useState(false)
  useMainClass('profile')

  useEffect(() => {
    setInformation(["firstName", "lastName", "nickName", "email", "birthday", "genre"].filter(field => requiredField?.includes(field)).length > 0)
    setAddress(["neighborhood", "postalCode", "city", "state", "country", "street1", "streetNumber"].filter(field => requiredField?.includes(field)).length > 0)
    setPhone(["phoneNumber"].filter(field => requiredField?.includes(field)).length > 0)
    setConsumptionCenter(["consumptionCenter"].filter(field => requiredField?.includes(field)).length > 0)
  }, [requiredField])

  const isAllowRoute = (name: string): boolean => {
    return (CONFIGS.routesProfile as string[]).includes(name)
  }

  const definitions = [
    {
      url: `${url}/information`,
      label: textServices.findByText('profile/informationLabel', { es: 'Datos personales', en: 'Personal data' }),
      title: textServices.findByText('profile/information', { es: 'MI INFORMACIÓN', en: 'MY INFORMATION' }),
      path: `information`,
      helper: information,
      isAllow: isAllowRoute('information'),
      component: Information,
      noArrow: false,
      update: '/information/update',
      icon: InformationIcon
    },
    {
      url: `${url}/address`,
      label: textServices.findByText('profile/addressLabel', { es: 'Dirección', en: 'Address' }),
      title: textServices.findByText('profile/address', { es: 'Dirección', en: 'Address' }),
      path: `address`,
      helper: address,
      isAllow: isAllowRoute('address'),
      component: Address,
      noArrow: false,
      update: '/address/update',
      icon: AddressIcon
    },
    {
      url: `${url}/phone`,
      label: textServices.findByText('profile/contactLabel', { es: 'Contacto', en: 'Contact' }),
      title: textServices.findByText('profile/contact', { es: 'Contacto', en: 'Contact' }),
      path: `phone`,
      helper: phone,
      isAllow: isAllowRoute('phone'),
      component: Phone,
      noArrow: false,
      icon: PhoneIcon
    },
    {
      url: `${url}/preferences`,
      label: textServices.findByText('profile/prefLabel', { es: 'Preferencias', en: 'Preferences' }),
      title: textServices.findByText('profile/pref', { es: 'Preferencias', en: 'Preferences' }),
      path: `preferences`,
      isAllow: isAllowRoute('preferences'),
      component: PrefPicker,
      noArrow: false,
      icon: PreferencesIcon
    },
    {
      url: `${url}/identificacion`,
      label: textServices.findByText('profile/identification/label', { es: 'Identificación', 'en': 'Identification' }),
      title: textServices.findByText('profile/identification/nav', { es: 'Identificación', 'en': 'Identification' }),
      path: `identificacion`,
      isAllow: isAllowRoute('identificacion'),
      component: Identification,
      noArrow: false,
      icon: CardIcon
    },
    {
      url: `${url}/consumption`,
      label: textServices.findByText('profile/consumptionLabel', { es: 'Centro de consumo', en: 'Center of consume' }),
      title: textServices.findByText('profile/consumption', { es: 'CENTRO DE CONSUMO', en: 'CENTER OF CONSUME' }),
      path: `consumption`,
      helper: consumptionCenter,
      isAllow: isAllowRoute('consumption'),
      component: ConsumptionProfile,
      noArrow: false,
      icon: ConsumptionCenterIcon,
      update: '/consumption/update',
    },
    {
      url: `${url}/movements`,
      label: textServices.findByText('profile/movementsLabel', { es: `Mis ${POINTS_NAME}`, en: `My ${POINTS_NAME}` }),
      title: textServices.findByText('profile/movements', { es: `Mis ${POINTS_NAME}`, en: `MY ${POINTS_NAME}` }),
      isAllow: isAllowRoute('movements'),
      component: ProfilePoints,
      path: "movements",
      icon: PointsIcon,
    },
    {
      url: `${url}/history`,
      label: textServices.findByText('profile/stateLabel', { es: 'Historial de canjes', en: 'Swap history' }),
      title: textServices.findByText('profile/state', { es: 'Historial de canjes', en: 'Swap history' }),
      path: `history`,
      isAllow: isAllowRoute('history'),
      component: History,
      noArrow: false,
      icon: HistoryIcon
    },
    {
      url: `${url}/movements/:categoryId/:category`,
      isAllow: isAllowRoute('movements'),
      title: textServices.findByText('profile/movementsDetails', { es: `DETALLE ${pathname.split('/').slice(-1).pop().toUpperCase()}`, en: `DETAILS ${pathname.split('/').slice(-1).pop().toUpperCase()}` }),
      component: ProfileDetailsPoints,
      dontShowItem: true
    },
    {
      url: `/prizes`,
      label: textServices.findByText('profile/prizeslabel', { es: 'Premios', en: 'Prizes' }),
      title: textServices.findByText('profile/prizes', { es: 'PREMIOS', en: 'PRIZES' }),
      isAllow: isAllowRoute('prizes'),
      icon: PrizesIcon,
      path: `prizes`,
    },
    {
      url: `${url}/social`,
      label: textServices.findByText('profile/social', { es: 'Vincular cuentas', en: 'Add accounts' }),
      title: textServices.findByText('profile/social', { es: 'VINCULAR CUENTAS', en: 'ADD ACCOUNTS' }),
      isAllow: isAllowRoute('social'),
      component: SocialProfile,
      icon: SiteMapIcon,
      path: `social`,
    },
  ]

  const definitionsFiltered = [...definitions].filter((item: any) => item?.isAllow).sort((a, b) => {
    return CONFIGS.routesProfile.indexOf(a?.path as IRoutesProfile) - CONFIGS.routesProfile.indexOf(b?.path as IRoutesProfile);
  })


  return (
    <RoutesWithNotFound>
      <Route path={url} component={() => <MenuProfile definitions={definitionsFiltered} />} exact />
      <HeaderProfile definitions={definitionsFiltered}>
        {definitionsFiltered.map((definition: any, index: number) => <Route key={index} path={`${definition.url}`} component={definition.component} exact />)}
        <Route path={`${url}/ranking`} component={ProfileRanking} exact />
      </HeaderProfile>
    </RoutesWithNotFound>
  )
}

export default Profile
