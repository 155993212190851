import { AxiosError } from "axios"
import { Survey, SurveyAction, SurveyState, SurveyActionEnum, Prize, DemandSpace, SurveyInfo } from "./survey.interface"

function surveyReducer(state: SurveyState, action: SurveyAction): SurveyState {
  switch (action.type) {
  case SurveyActionEnum.SET_SURVEYS:
    return { ...state, surveys: action.payload as Survey[] }
  case SurveyActionEnum.SET_SURVEY:
    return { ...state, survey: action.payload as Survey }
  case SurveyActionEnum.SET_ERROR:
    return { ...state, error: action.payload as AxiosError }
  case SurveyActionEnum.SET_PRIZE:
    return { ...state, prize: action.payload as Prize }
  case SurveyActionEnum.SET_DEMAND_SPACE:
    return { ...state, demandSpace: action.payload as DemandSpace }
  case SurveyActionEnum.SET_INFO_SURVEY:
    return { ...state, surveyInfo: action.payload as SurveyInfo }
  case SurveyActionEnum.RESET:
    return { ...state, demandSpace: null, prize: null, error: null, survey: null, surveys: null}     
  default:
    return state
  }
}

export default surveyReducer