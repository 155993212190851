async function loadImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const i = new Image();
    i.crossOrigin = 'anonymous';
    i.onload = () => {
      console.log(`Image loaded: ${url}`);
      resolve(i);
    };
    i.onerror = (err) => {
      console.error(`Failed to load image: ${url}`, err);
      reject(err);
    };
    i.src = url;
  });
}

function convertBlobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export async function downloadImageAsBase64(url: string): Promise<string> {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    return await convertBlobToBase64(blob);
  } catch (error) {
    throw new Error(`Error descargando la imagen: ${error}`);
  }
}

export default loadImage