import { SVGProps } from 'react'

function UbicationIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="5" fill="currentColor"/>
      <path d="M10 2V0" stroke="currentColor"/>
      <path d="M10 20L10 18" stroke="currentColor"/>
      <path d="M2 10L0 10" stroke="currentColor"/>
      <path d="M20 10L18 10" stroke="currentColor"/>
      <circle cx="9.99997" cy="10.0002" r="8.38889" stroke="currentColor"/>
    </svg>
  )
}

export default UbicationIcon


