import { SVGProps, useState } from 'react'
import Button from 'components/button'
import { Question, Option, SurveyResponse } from 'contexts/survey-context/survey.interface'
import { dlTrackEvent } from 'components/dataLayer/dataLayer'
import Slider from 'react-slick'
import useRouter from 'hooks/use-router'
import textServices from 'services/text-services'
import ButtonCloseMenu from 'components/button-close'
import { TitleSurvey } from '../../../styles/styled.components'

interface IProps {
  id: number;
  title: string;
  setInfo: any;
  info: any;
  changeStep: (count?: string) => void;
  answers: any;
  question: Question;
  setAnswers: any;
  handleSubmit: (callback: () => Promise<void>) => void
  updatePolarisSurvey: (select: SurveyResponse[][], id: number) => Promise<void>
}

function Left(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="49" height="16" viewBox="0 0 49 16" fill="none" xmlns="http://www.w3.org/2000/svg"  {...props}>
      <path d="M0.292892 7.2929C-0.0976296 7.68342 -0.0976295 8.31659 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41422 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928936C7.68054 0.538411 7.04738 0.538411 6.65685 0.928936L0.292892 7.2929ZM48.5 7L1 7L1 9L48.5 9L48.5 7Z" fill="black" />
    </svg>
  )
}

function Right(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="49" height="16" viewBox="0 0 49 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M48.2071 8.70711C48.5976 8.31658 48.5976 7.68342 48.2071 7.29289L41.8431 0.928932C41.4526 0.538408 40.8195 0.538408 40.4289 0.928932C40.0384 1.31946 40.0384 1.95262 40.4289 2.34315L46.0858 8L40.4289 13.6569C40.0384 14.0474 40.0384 14.6805 40.4289 15.0711C40.8195 15.4616 41.4526 15.4616 41.8431 15.0711L48.2071 8.70711ZM0 9H47.5V7H0V9Z" fill="black" />
    </svg>
  )
}

const Tag = ({ name }: { name: string }) => {
  return (
    <div className='flex justify-center items-center' style={{ minWidth: '70px', padding: '3px', border: '1px solid black', background: '#EDEDED', borderRadius: '4px' }}>
      <div className='flex justify-center items-center w-full' style={{ border: '1px solid black', borderRadius: '2px', padding: '4px 8px' }}>
        <p style={{ fontFamily: 'DINPro', fontSize: '10px', fontWeight: 800 }}>
          {name}
        </p>
      </div>
    </div>
  )
}

const Card = ({ item }: { item: Option }) => {
  return (
    <div className='bg-white gap-4 py-6 px-4 flex justify-evenly flex-col items-center w-full' style={{ width: '285px', borderRadius: '8px', aspectRatio: '0.67/1' }}>
      <h2 className='w-full' style={{ color: 'black', fontFamily: 'Bebas Neue', fontSize: '36px', lineHeight: '35px', fontWeight: 400, fontStyle: 'normal' }}>
        {item?.option}
      </h2>
      <div className='bg-gray-700 flex-1 w-full rounded-lg bg-center bg-cover' style={{ backgroundImage: `url(${item.imageUrl})` }} />
      <div className='flex gap-2 self-start flex-wrap w-full'>
        {
          item?.description?.split(';').map((tag, index) => {
            return <Tag name={tag} key={`${tag}-${index}`} />
          })
        }
      </div>
    </div>
  )
}

const StepTwo = ({ id, title, changeStep, setInfo, info, question, answers, setAnswers, handleSubmit, updatePolarisSurvey }: IProps) => {

  const [currentSlider, setCurrentSlider] = useState(0)
  const { history } = useRouter();

  const settings = {
    className: "center",
    centerMode: true,
    infinite: false,
    speed: 500,
    initialSlide: 0,
    prevArrow: <Left />,
    nextArrow: <Right />,
    afterChange: (current: number) => {
      dlTrackEvent('form_interaction', {
        form_field: 'corriente urbana',
        form_action: 'move',
        form_name: 'nomina tu cp',
        section: 'survey :: nomina cp'
      })
      setCurrentSlider(current)
      setInfo({
        ...info,
        corrienteUrbana: question?.options[current]
      })
    },
  };

  const onSubmit = async () => {

    dlTrackEvent('form_send', {
      element: 'siguiente :: corriente urbana',
      form_name: 'nomina tu cp',
      section: 'survey :: nomina cp'
    })

    answers.push({
      optionIds: [question?.options[currentSlider]?.optionId],
      questionId: question?.questionId,
      textValue: ``
    })

    await updatePolarisSurvey(answers as any as SurveyResponse[][], id)

    changeStep('increment')
  }

  return (
    <div className="flex justify-between flex-col items-center h-full w-full">
      <ButtonCloseMenu
        open={true}
        paramFn={() => {
          gtag('event', 'Click', {
            event_category: GTM_EVENT_CATEGORY,
            event_label: 'Survey - Cerrar modal',
            value: '10'
          })
          history.goBack();
        }}
      />
      <div className='flex justify-center flex-col items-center w-full'>
        <TitleSurvey className="font-black text-4xl text-center py-4 title-survey w-full" style={{ fontSize: '38px' }}>{title}</TitleSurvey>

        <div className={`w-full z-1 py-7 flex flex-col flex-1`} id="slider-indio" style={{ maxWidth: '384px' }}>
          <Slider {...settings}>
            {
              question?.options?.map((item, index) => {
                return <Card item={item} key={index} />
              })
            }
          </Slider>
        </div>
      </div>

      <div className='mb-5' style={{ minWidth: '323px' }}>
        <Button className='w-full' onClick={() => handleSubmit(onSubmit)}>
          Confirmar
        </Button>
        <p onClick={() => {
          changeStep()
          setAnswers([])
        }} className="text-center mt-2 underline cursor-pointer font-medium uppercase" style={{ fontFamily: 'Raleway', fontSize: '10px' }}>
          {textServices.findByText('backButton', { es: 'VOLVER', en: 'BACK TO' })}
        </p>
      </div>
    </div>
  )
}

export default StepTwo