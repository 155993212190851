import styled from 'styled-components'
import { useState } from 'react'
import { Type, ProfileSelectedType, ButtonPrefPickerType } from 'theme/types'
import { Link } from 'react-router-dom'

export const BorderButtonProfile = styled.button.attrs((props: ProfileSelectedType) => ({
  selected: props.selected,
  theme: props.theme,
  ...props
})) <ProfileSelectedType>`
  border-color: ${(props: ProfileSelectedType) => props.selected && props.theme?.profile?.borderNavSelect};
`

export const ButtonHelperProfile = styled.div<Type>`
  background-color: ${(props: Type) => props.theme?.profile?.bgNavHelper};
`

export const DivProfileTitle = styled.div`
  ${({ theme }: Type) => theme?.profile?.title?.div}
`

export const PProfileTitle = styled.p`
  ${({ theme }: Type) => theme?.profile?.title?.p}
`

export const LiStateAccount = styled.li`
  border-color: ${(props: Type) => props.theme?.profile?.borderStateAccount};
`

export const DivStateAccount = styled.div`
  background-color: ${(props: Type) => props.theme?.profile?.backgroundStateAccount};
`

export const PStateAccount = styled.p`
  color: ${(props: Type) => props.theme?.profile?.colorTextTitleStateAccount};
  ${(props: Type) => props.theme?.profile?.colorTextTitleStateAccountRest}
`

export const TextStateAccount = styled.p`
  ${(props: Type) => props.theme?.profile?.textStateAccount};
`

export const SectionProfileText = styled.section`
  color: ${(props: Type) => props.theme?.profile?.textProfile};
`

export const Crown = styled.div`
  border: ${(props: Type) => props.theme?.congrats?.genericBackground && `4px solid ${props.theme?.profile?.crownBg}`};
  border-radius: ${(props: Type) => props.theme?.congrats?.genericBackground && '50%'};
  background-image: ${(props: Type) => !props.theme?.congrats?.genericBackground && `url(${AZURE_BASE_URL}/canjes.svg)`};
`

export const ButtonPrefPicker = styled.button.attrs((props: ButtonPrefPickerType) => ({
  isProfile: props.isProfile,
  active: props.active,
  state: props.state,
  key: props.state,
  category: props.category,
  theme: props.theme,
  ...props
})) <ButtonPrefPickerType>`
    height: 3.5rem;
    border-width: 1px;
    border-color: ${(props: ButtonPrefPickerType) => props.active ? props.theme?.profile?.borderButtonSelectProfile : props.theme?.profile?.borderButtonProfile};
    color: ${(props: ButtonPrefPickerType) => props.active ? props.theme?.profile?.textButtonSelectProfile : props.theme?.profile?.textButtonProfile};
    border-radius: ${(props: Type) => props.theme?.profile?.borderRadiusButton};
    font-weight: 700;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    text-transform: uppercase;
    background-color:${(props: ButtonPrefPickerType) => props.active && props.theme?.profile?.backgroundButtonSelectProfile};
    ${(props: ButtonPrefPickerType) => props.active ? props.theme?.profile?.buttonSelectActive : props.theme?.profile?.buttonSelectInactive};
`

export const ButtonPref = ({ handleOnClick, isProfile, state, category, key, datainterestid, children }: any) => {
  const [active, setActive] = useState(!!(isProfile && state.interests.includes(category.id)))

  return <ButtonPrefPicker
    onClick={(event) => {
      gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'INTERESES - Seleccionar interes', value: '10' })
      handleOnClick(event)
      setActive(!active)
    }}
    isProfile={isProfile}
    state={state}
    category={category}
    data-interest-id={datainterestid}
    key={key}
    active={active}
  >
    {children}
  </ButtonPrefPicker>
}

export const CrownState = styled.div`
  border: ${(props: Type) => props.theme?.congrats?.genericBackground && `4px solid ${props.theme?.profile?.crownState}`};
  border-radius: ${(props: Type) => props.theme?.congrats?.genericBackground && '50%'};
  background-image: ${(props: Type) => !props.theme?.congrats?.genericBackground && `url(${AZURE_BASE_URL}/canjes.svg)`};
`

export const CrownStateText = styled.p`
  ${(props: Type) => props.theme?.profile?.crownStateText};
`

// -- MIS PUNTOS --

export const ProfilePointsContainerTotalPoints = styled.div`
  ${(props: Type) => props.theme?.profile?.points?.containerTotalPoints};
`

export const ProfilePointsTitleTotalPoints = styled.h2`
  ${(props: Type) => props.theme?.profile?.points?.titleTotalPoints};
`

export const ProfilePointsTotalPoints = styled.p`
  ${(props: Type) => props.theme?.profile?.points?.totalPoints};
`

export const ProfilePointsContainerPoints = styled.div`
  ${(props: Type) => props.theme?.profile?.points?.containerPoints};
`

export const ProfilePointsTitlePoints = styled.h3`
  ${(props: Type) => props.theme?.profile?.points?.titlePoints};
`

export const ProfilePointsPoints = styled(Link)`
  ${(props: Type) => props.theme?.profile?.points?.points};
`

// -- MIS PUNTOS DETALLES --

export const ProfileDetailsPointsContainerTotalPoints = styled.div`
  ${(props: Type) => props.theme?.profile?.points?.details?.containerTotalPoints};
`

export const ProfileDetailsPointsTitleTotalPoints = styled.h2`
  ${(props: Type) => props.theme?.profile?.points?.details?.titleTotalPoints};
`

export const ProfileDetailsPointsTotalPoints = styled.p`
  ${(props: Type) => props.theme?.profile?.points?.details?.totalPoints};
`

export const ProfileDetailsPointsContainerPoints = styled.div`
  ${(props: Type) => props.theme?.profile?.points?.details?.containerPoints};
`

export const ProfileDetailsPointsDatePoints = styled.p`
  ${(props: Type) => props.theme?.profile?.points?.details?.datePoints};
`

export const ProfileDetailsPointsTitlePoints = styled.h3`
  ${(props: Type) => props.theme?.profile?.points?.details?.titlePoints};
`

export const ProfileDetailsPointsPoints = styled.p`
  ${(props: Type) => props.theme?.profile?.points?.details?.points};
`

export const ProfileDetailsPointsDescription = styled.p`
  ${(props: Type) => props.theme?.profile?.points?.details?.description};
`

export const ProfileDetailsPointsDescriptionPoints = styled.p`
  ${(props: Type) => props.theme?.profile?.points?.details?.descriptionPoints};
`

export const TextStateAccountDate = styled.p`
  ${(props: Type) => props.theme?.profile?.textStateAccountDate};
`