const Redeem = () => {
  const variant = [
    'miller',
    'silverWaiter'
  ]

  if (variant.includes(THEME)) {
    return `${AZURE_ICONS_URL}/${THEME}/redeem.svg`
  }

  return `${AZURE_ICONS_URL}/redeem.svg`
}

export default Redeem
