const redirectUri = `${window.location.origin}${process.env.PUBLIC_URL}/callback`;
const clientId = TH_CLIENTID || "";

export const goToUrlTwitch = (flowType = '',  utm?: Record<string, string>) => {
  const flowTypeParam = flowType ? `&${flowType}` : '';
  const state = encodeURIComponent(`twitch${flowTypeParam}`);
  localStorage.setItem("stateAuthSocial", state);

  if (utm) {
    const utmString = Object.entries(utm)?.map(([key, value]) => `${key}=${value}`)?.join('&')
    localStorage.setItem("utmAuthSocial", `${utmString}`);
  }

  let url = "https://id.twitch.tv/oauth2/authorize";
  url += "?response_type=token";
  url += "&client_id=" + encodeURIComponent(clientId);
  url += "&scope=" + encodeURIComponent("user:read:email");
  url += "&redirect_uri=" + encodeURIComponent(redirectUri);
  url += "&state=" + state;
  window.location.href = url;
}