import React from 'react'

function RankingSilverIcon() {
  return (
    <svg width="58" height="61" viewBox="0 0 58 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.5689 55H9.4311C9.19469 55 9 54.7329 9 54.4087V29.5913C9 29.2671 9.19469 29 9.4311 29H19.5689C19.8053 29 20 29.2671 20 29.5913V54.4087C20 54.7329 19.8053 55 19.5689 55Z" stroke="#07672F" strokeWidth="1.84" strokeMiterlimit="10" />
      <path d="M49.625 55H39.375C39.1667 55 39 54.7711 39 54.4849V36.5151C39 36.2289 39.1667 36 39.375 36H49.625C49.8333 36 50 36.2289 50 36.5151V54.504C50 54.7711 49.8333 55 49.625 55Z" stroke="#07672F" strokeWidth="1.84" strokeMiterlimit="10" />
      <path d="M34.5139 55H24.4861C24.2222 55 24 54.6918 24 54.3257V22.6743C24 22.3082 24.2222 22 24.4861 22H34.5139C34.7778 22 35 22.3082 35 22.6743V54.3257C34.9861 54.6918 34.7778 55 34.5139 55Z" stroke="#07672F" strokeWidth="1.84" strokeMiterlimit="10" />
      <path d="M11.8147 19.4493L10.3406 23.989C10.3406 23.9943 10.3406 24.0048 10.3459 24.01C10.3459 24.01 10.3563 24.0205 10.3668 24.0257C10.3773 24.0362 10.3931 24.0415 10.3931 24.0415C10.3983 24.0467 10.4088 24.0467 10.414 24.0415L14.2691 21.2369L18.1292 24.0415C18.1344 24.0467 18.1449 24.0467 18.1502 24.0415C18.1502 24.0415 18.1606 24.0362 18.1763 24.0257C18.1868 24.0153 18.1973 24.01 18.1973 24.01C18.2025 24.0048 18.2078 23.9943 18.2025 23.989L16.731 19.4493L20.5913 16.6446C20.5966 16.6394 20.6018 16.6289 20.5966 16.6237C20.5966 16.6237 20.5913 16.6079 20.5913 16.5975C20.586 16.5817 20.5808 16.5712 20.5808 16.5712C20.5808 16.5608 20.5703 16.5555 20.5651 16.5555H15.7955L14.3187 12.0157C14.3187 12.0105 14.3082 12 14.303 12C14.303 12 14.2872 12 14.2715 12C14.2558 12 14.2453 12 14.2453 12C14.2348 12 14.2296 12.0052 14.2296 12.0157L12.7608 16.5555H7.99121C7.98073 16.5555 7.97549 16.5608 7.97026 16.5712C7.97026 16.5712 7.96504 16.587 7.9598 16.5975C7.95458 16.6132 7.95459 16.6237 7.95459 16.6237C7.94935 16.6342 7.9546 16.6394 7.95984 16.6446L11.8147 19.4493Z" fill="white" />
      <path d="M42.8663 26.4493L41.3922 30.989C41.3922 30.9943 41.3922 31.0048 41.3975 31.01C41.3975 31.01 41.408 31.0205 41.4185 31.0257C41.429 31.0362 41.4447 31.0415 41.4447 31.0415C41.4499 31.0467 41.4604 31.0467 41.4657 31.0415L45.3208 28.2369L49.1808 31.0415C49.1861 31.0467 49.1966 31.0467 49.2018 31.0415C49.2018 31.0415 49.2123 31.0362 49.228 31.0257C49.2385 31.0153 49.2489 31.01 49.2489 31.01C49.2542 31.0048 49.2594 30.9943 49.2542 30.989L47.7826 26.4493L51.643 23.6446C51.6482 23.6394 51.6535 23.6289 51.6482 23.6237C51.6482 23.6237 51.643 23.6079 51.6429 23.5975C51.6377 23.5817 51.6324 23.5712 51.6324 23.5712C51.6324 23.5608 51.6219 23.5555 51.6167 23.5555H46.8471L45.3703 19.0157C45.3703 19.0105 45.3598 19 45.3546 19C45.3546 19 45.3389 19 45.3232 19C45.3074 19 45.297 19 45.297 19C45.2865 19 45.2812 19.0052 45.2812 19.0157L43.8124 23.5555H39.0429C39.0324 23.5555 39.0271 23.5608 39.0219 23.5712C39.0219 23.5712 39.0167 23.587 39.0114 23.5975C39.0062 23.6132 39.0062 23.6237 39.0062 23.6237C39.001 23.6342 39.0062 23.6394 39.0115 23.6446L42.8663 26.4493Z" fill="white" />
      <path d="M26.405 10.6861L24.6862 15.9797C24.6862 15.9858 24.6862 15.998 24.6923 16.0041C24.6923 16.0041 24.7045 16.0164 24.7167 16.0225C24.729 16.0347 24.7473 16.0408 24.7473 16.0408C24.7534 16.0469 24.7657 16.0469 24.7718 16.0408L29.267 12.7705L33.768 16.0408C33.7741 16.0469 33.7863 16.0469 33.7924 16.0408C33.7924 16.0408 33.8046 16.0347 33.8229 16.0225C33.8352 16.0103 33.8474 16.0041 33.8474 16.0041C33.8535 15.998 33.8596 15.9858 33.8535 15.9797L32.1376 10.6861L36.6389 7.41583C36.645 7.40972 36.6511 7.39749 36.645 7.39138C36.645 7.39138 36.6389 7.37304 36.6389 7.36082C36.6327 7.34248 36.6266 7.33025 36.6266 7.33025C36.6266 7.31803 36.6144 7.31191 36.6083 7.31191H31.0468L29.3248 2.01834C29.3248 2.01223 29.3125 2 29.3064 2C29.3064 2 29.2881 2 29.2698 2C29.2514 2 29.2392 2 29.2392 2C29.227 2 29.2209 2.00611 29.2209 2.01834L27.5082 7.31191H21.9467C21.9345 7.31191 21.9284 7.31803 21.9223 7.33025C21.9223 7.33025 21.9162 7.34859 21.9101 7.36082C21.904 7.37915 21.904 7.39138 21.904 7.39138C21.8979 7.4036 21.904 7.40972 21.9101 7.41583L26.405 10.6861Z" fill="white" />
    </svg>

  )
}

export default RankingSilverIcon