import { SVGProps } from 'react'

function LineUpIndio(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="42" height="39" viewBox="0 0 42 39" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0.75" y="0.75" width="40.5" height="10.7353" rx="1.25" stroke="white" strokeWidth="1.5" />
      <rect x="0.75" y="16.3501" width="17.82" height="8.64" rx="1.25" stroke="white" strokeWidth="1.5" />
      <rect x="0.75" y="29.6101" width="10.26" height="8.64" rx="1.25" stroke="white" strokeWidth="1.5" />
      <rect x="15.87" y="29.6101" width="10.26" height="8.64" rx="1.25" stroke="white" strokeWidth="1.5" />
      <rect x="30.99" y="29.6101" width="10.26" height="8.64" rx="1.25" stroke="white" strokeWidth="1.5" />
      <rect x="23.43" y="16.3501" width="17.82" height="8.64" rx="1.25" stroke="white" strokeWidth="1.5" />
    </svg>

  )
}

export default LineUpIndio
