import { createContext, Dispatch, FunctionComponent, useContext, useEffect, useReducer } from 'react'
import { LoaderContext } from 'contexts/loader-context'
import { PhotographerServices } from 'services/photographer.services'
import { useSession } from 'contexts/session-context'
import photographerReducer, { PhotographerAction, PhotographerState } from './photographer-reducer'
import { Photographer, Photos, Winners } from './types'

export interface PhotographerContextProps {
  state: PhotographerState
  dispatch: Dispatch<PhotographerAction>
}

const PhotographerContext = createContext<PhotographerContextProps>({} as PhotographerContextProps)

export const PhotographerProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(photographerReducer, {})
  const { setLoad } = useContext(LoaderContext)
  const [{ data: session }] = useSession()

  const getPhotographer = async () => {
    setLoad(true)
    try {
      const { data } = await PhotographerServices.get(session)
      const typeData = data as Photographer
      dispatch({ type: 'SET_CURRENT', payload: typeData })
      dispatch({ type: 'SET_PHOTOS', payload: typeData?.multimedias })
    } catch (error) {
      console.error(error)
    } finally {
      setLoad(false)
    }
  }

  const getWinners = async () => {
    setLoad(true)
    try {
      const { data } = await PhotographerServices.winners(session)
      const typeData = data as Photographer
      dispatch({ type: 'SET_WINNERS', payload: typeData?.multimedias })
    } catch (error) {
      console.error(error)
    } finally {
      setLoad(false)
    }
  }

  useEffect(() => {
    if (!session) return
    getPhotographer().then(() => {
      getWinners()
    })
  }, [session])

  return <PhotographerContext.Provider value={{ state, dispatch }}>{children}</PhotographerContext.Provider>
}

export const usePhotographer = () => {
  const { state, dispatch } = useContext(PhotographerContext)

  const setPhoto = (photo: Photos) => dispatch({ type: 'SET_PHOTO', payload: photo })
  const setPhotos = (photos: Photos[]) => dispatch({ type: 'SET_PHOTOS', payload: photos })
  const setWinners = (winners: Winners[]) => dispatch({ type: 'SET_WINNERS', payload: winners })
  const setError = (error: number) => {
    dispatch({ type: 'SET_ERROR', payload: error })
  }
  const setCurrent = (current: Photographer) => dispatch({ type: 'SET_CURRENT', payload: current })
  const actions = { setPhotos, setWinners, setError, setCurrent, setPhoto }

  const returnValue: [typeof state, typeof actions] = [state, actions]
  return returnValue
}
