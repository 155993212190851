/* eslint-disable indent */
import { myInformationAdapter } from "adapters/my-information-adapter";
import { UserData } from "contexts/session-context/session-reducer";
import { useCustomForm } from "hooks/use-custom-form";
import useRouter from "hooks/use-router";
import { useContext, useEffect, useState } from "react";
import { LoaderContext } from "contexts/loader-context";
import ErrorView from "components/error-view";
import AgeGender from "./age-gender";
import EmailPassword from "./email-password";
import Names from "./names";

function FormInformation({ isSignUp, handleSubmit, user, quantityStep }: { isSignUp?: boolean, handleSubmit: (data: any, setError: (error: number) => void) => Promise<void>, user?: UserData, quantityStep?: number }) {

  const { history } = useRouter()
  const { state, handleChangeState, setQuantitySteps, nextStep, prevStep, backHistory, htmlSteps } = useCustomForm({ step: 1, firstName: user?.firstName || '', lastName: user?.lastName || '', nickName: user?.nickName || '', birthday: user?.birthday, genreId: user?.genreId || user?.genre || '', email: user?.email || '', password: '' })
  const [error, setError] = useState(null)
  const { setLoad } = useContext(LoaderContext)
  async function onFormSubmitEndPoint(data: any) {
    const newData = myInformationAdapter({ ...state, ...data }, isSignUp, false)
    await handleSubmit(newData, setError)
  }

  useEffect(() => {
    if (quantityStep) {
      setQuantitySteps(quantityStep)
    }
  }, [])

  useEffect(() => {
    if (error) {
      handleChangeState({ step: 10 } as any)
    }
  }, [error])

  const handleMenu = () => {
    gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'MODIFICAR INFORMACION - Salir de la pantalla de error', value: '10' })
    setError(null)
    if (state?.step <= 1) return;
    backHistory()
  }

  const handleButton = () => {
    setError(null)
    if (state?.step <= 1) return;
    backHistory()
  }

  switch (state.step) {
    case 1:
      return (
        <Names
          state={state}
          handleChange={handleChangeState}
          nextStep={nextStep}
          prevStep={() => history.goBack()}
          htmlSteps={htmlSteps}
          setError={setError}
        />
      )
    case 2:
      return (
        <AgeGender
          state={state}
          handleChange={handleChangeState}
          nextStep={nextStep}
          isSignUp={isSignUp}
          onFormSubmitEndPoint={onFormSubmitEndPoint}
          prevStep={prevStep}
          setError={setError}
          setLoad={setLoad}
          htmlSteps={htmlSteps}
        />
      )
    case 3:
      return (
        <EmailPassword
          state={state}
          handleChange={handleChangeState}
          nextStep={nextStep}
          prevStep={prevStep}
          onFormSubmitEndPoint={onFormSubmitEndPoint}
          isSignUp={isSignUp}
          setError={setError}
          htmlSteps={htmlSteps}
        />
      )
    default:
      return (
        <ErrorView buttonMenu={handleMenu} code={error} onRetry={handleButton} />
      )
  }
}

export default FormInformation;