import { Prize, WareHouseType } from '../types'

export type PrizesActionType = 'SET_FLOW_TYPE_ID' | 'SET_PRIZES' | 'SET_SELECT_PRIZE' | 'SET_ERROR' | 'SET_CONGRATS' | 'SET_SELECT_PRIZE_WAREHOUSE' | 'SET_SELECT_WAREHOUSE' | 'SET_PRIZE_EXCHANGE'

export interface PrizesState {
  prizes?: Prize[]
  selectPrize?: Prize
  error?: number
  congrats?: boolean
  flowTypeId?: number
  wareHouse?: WareHouseType[]
  selectWarehouse?: WareHouseType
  prizeExchange?: Prize
}

export interface PrizesAction {
  type: PrizesActionType
  payload: PrizesState | Prize[] | Prize | number  | boolean | WareHouseType
}

function prizesReducer(state: PrizesState, action: PrizesAction): PrizesState {
  switch (action.type) {
  case 'SET_FLOW_TYPE_ID':
    return { ...state, flowTypeId: action.payload as number }
  case 'SET_PRIZES':
    return { ...state, prizes: action.payload as Prize[] }
  case 'SET_SELECT_PRIZE':
    return { ...state, selectPrize: action.payload as Prize }
  case 'SET_SELECT_PRIZE_WAREHOUSE':
    return { ...state, wareHouse: action.payload as WareHouseType[]}
  case 'SET_SELECT_WAREHOUSE':
    return { ...state, selectWarehouse: action.payload as WareHouseType}
  case 'SET_PRIZE_EXCHANGE':
    return { ...state, prizeExchange: action.payload as Prize}
  case 'SET_CONGRATS':
    return { ...state, congrats: action.payload as boolean }  
  case 'SET_ERROR':
    return { ...state, error: action.payload as number }
  default:
    return state
  }
}

export default prizesReducer