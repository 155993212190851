import styled from 'styled-components';
import { SpanMenu } from 'theme/header';
import { Type } from './../../../theme/types/generic.interface';

export const Dots = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 9999px;
  opacity: 0.7;
  ${({ theme }: Type) => theme?.home?.dots}
`

export const Points = styled.span`
  ${({ theme }: Type) => theme?.home?.points}
`

export const PopUpTwo = styled.div`
  ${({ theme }: Type) => theme?.home?.popup?.two}
`

export const PopUpSpanMenu = styled(SpanMenu)`
  ${({ theme }: Type) => theme?.home?.popup?.spanMenu}
`

export const HrHome = styled.hr`
  ${({ theme }: Type) => theme?.home?.hr}
`

export const NavContainer = styled.nav`
  ${({ theme }: Type) => theme?.home?.navContainer}
`