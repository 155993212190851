import { HISTORY_TRACKER } from 'constants/api'
import Button from 'components/button'
import { useContext, useEffect, useState } from 'react'
import ButtonCloseMenu from 'components/button-close'
import { Main } from 'theme/theme'
import useMainClass from 'hooks/use-main-cass'
import { Spinner } from 'theme/loading'
import images from 'images.json'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { useSession } from 'contexts/session-context'
import dayjs from 'dayjs'
import GenericError from 'components/generic-error'
import calendar from 'dayjs/plugin/calendar'
import Countdown from 'react-countdown'
import { LoaderContext } from 'contexts/loader-context'
import textServices from 'services/text-services'
import CONFIGS from 'build/Config'

interface IProps {
  item: any
  closePopup: any
  timeResult: any
  setProductExchange: any
}

const PopUpCoupon = ({ item, closePopup, timeResult, setProductExchange }: IProps) => {
  const [{ data: session }] = useSession()
  dayjs.extend(calendar)
  useMainClass('profile__history__popup__coupon')
  const [showTimePopUp, setShowTimePopUp] = useState<boolean>(false)
  const [dateCountdown, setDateCountdown] = useState<any>(null)
  const [expired, setExpired] = useState<boolean>(false)
  const history = useHistory()
  const { setLoad } = useContext(LoaderContext)

  const countDownFn = (expiresAt: string) => {
    const validFrom = new Date(expiresAt)
    const date = dayjs(validFrom)
    setDateCountdown(date.toDate())
  }

  const historyTracker = async () => {
    setLoad(true)
    try {
      const newItem = await axios.get(HISTORY_TRACKER.replace(':historyId', item?.id), {
        headers: {
          Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`,
          'Ph-Api-Id': PH_API_ID ?? 1
        }
      })
      setProductExchange({
        ...item,
        temporalCouponExpiresAt: newItem?.data?.expiresAt
      })
      countDownFn(newItem?.data?.expiresAt)
    } catch (error) {
      return (
        <GenericError
          error={`Este cupón ya fue usado`}
          onRetry={() => history.push('/profile/history')}
          buttonText="VOLVER A CANJES"
          description="Tranqui...todo tiene solución"
        />
      )
    } finally {
      setLoad(false)
    }
  }

  const acceptUseCoupon = () => {
    setShowTimePopUp(!showTimePopUp)
    historyTracker()
  }

  useEffect(() => {
    if (timeResult(item?.temporalCouponExpiresAt)) {
      setShowTimePopUp(true)
      countDownFn(item?.temporalCouponExpiresAt)
      setLoad(false)
    }
    setLoad(false)
  }, [])

  const Clock = () => {
    return (
      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path id="clock-hand" d="M12.9995 6.63965V13.8844L18.9931" stroke="currentColor" strokeWidth="1.71221" />
        <path d="M12.9995 6.63965V13.8844L18.9931" stroke="currentColor" strokeWidth="1.71221" />
        <circle cx="13" cy="13" r="12.1439" stroke="currentColor" strokeWidth="1.71221" />
      </svg>
    )
  }

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      setExpired(true)
      return <span>{textServices.findByText('profile/coupon/popup/timeFinish', { es: 'Tiempo finalizado', en: 'Finished time' })}</span>
    } else {
      // Render a countdown
      return (
        <span>
          {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </span>
      )
    }
  }

  if (showTimePopUp) {
    return (
      <Main>
        <ButtonCloseMenu paramFn={closePopup} bgTransparent={!CONFIGS?.captureBtnBgTransparent} />
        <div className="profile__history__popup__coupon__container">
          <img src={item?.imageUrl} alt={item?.title} />

          <div className="flex flex-col items-center gap-5">
            <h2 className="profile__history__popup__coupon__title__exchange">{textServices.findByText('profile/coupon/popup/title', { es: 'canje', en: 'exchange' })} {item?.title}</h2>

            <p className="profile__history__popup__coupon__text">
              {textServices.findByText('profile/coupon/popup/staff', { es: 'Ahora muestra tu pantalla en atención al cliente.', en: "Now show your screen to the person who is serving you at the cashier's desk." })}
            </p>

            <p className="profile__history__popup__coupon__text-bold">
              {textServices.findByText('profile/coupon/popup/expire', { es: 'EXPIRA:', en: "EXPIRE:" })} {new Date(item?.temporalCouponExpiresAt).toLocaleString()}
            </p>
          </div>

          <div className="flex flex-col items-center gap-5">
            {dateCountdown ? (
              <>
                <p className="profile__history__popup__coupon__timer">
                  {!expired && <Clock />}
                  <Countdown date={dateCountdown} renderer={renderer} />
                </p>
                <div className='flex items-center justify-center mt-5 ml-5'>
                  {!expired && textServices.findByText('profile/coupon/popup/spinner', <Spinner src={`${AZURE_BASE_URL}${images['loader-spinner']}`} className="spinner animate-spin animation-8" />)}
                </div>
              </>
            ) : (
              <p className="profile__history__popup__coupon__timer">{textServices.findByText('profile/coupon/popup/timeFinish', { es: 'Tiempo finalizado', en: 'Finished time' })}</p>
            )}
          </div>
        </div>
      </Main>
    )
  }

  return (
    <Main>
      <ButtonCloseMenu paramFn={closePopup} bgTransparent={!CONFIGS?.captureBtnBgTransparent} />
      <div className="profile__history__popup__coupon__container">
        <img src={item?.imageUrl} alt={item?.title} />

        <h2 className="profile__history__popup__coupon__title">{textServices.findByText('profile/coupon/popup/wait', { es: '¡Espera!', en: 'Wait!' })}</h2>

        <p className="profile__history__popup__coupon__text">
          {textServices.findByText('profile/coupon/popup/description', { es: 'Estás a punto de activar tu cupón de canje para mostrar en caja.', en: 'You are about to activate your redemption coupon to show at the cash register.' })}
        </p>

        <p className="profile__history__popup__coupon__text">
          {' '}
          {textServices.findByText('profile/coupon/popup/warning', { es: <>Recuerda que este cupón es válido para usarlo <span>una sola vez</span> y tendrás unicamente {' '} <span>15 minutos</span> para hacerlo.</>, en: <>Remember that this coupon is valid for <span>one time use only</span> and you will only have {' '} <span>15 minutes</span> to use it.</> })}
        </p>

        <p className="profile__history__popup__coupon__text-bold">
          {textServices.findByText('profile/coupon/popup/sure', { es: '¿Seguro que quieres activarlo ahora?', en: 'Are you sure you want to activate it now?' })}
        </p>

        <div className="flex flex-col justify-center gap-5 w-full">
          <Button onClick={acceptUseCoupon}>{textServices.findByText('profile/coupon/popup/button', { es: 'Si, claro', en: 'Yes, of course' })}</Button>

          <button onClick={closePopup} className="profile__history__popup__coupon__back-btn">
            {textServices.findByText('backButton', { es: 'VOLVER', en: 'BACK TO' })}
          </button>
        </div>
      </div>
    </Main>
  )
}

export default PopUpCoupon
