import { Link } from 'react-router-dom'
import { ButtonNavStyle } from 'theme/home'

const SilverID = () => {
  return (
    <Link to='/profile/identificacion' className="w-full" onClick={() => { gtag('event', 'event_to_ga', { event_category: 'navegacion_perfil', event_label: 'Home links' }) }}>
      <ButtonNavStyle className={`button-nav flex items-center justify-evenly text-center font-medium text-xs bg-bottom bg-no-repeat bg-cover`}>
        <img src={`${AZURE_BASE_URL}/home-id.webp`} alt='Heineken Id' />
      </ButtonNavStyle>
    </Link>
  )
}

export default SilverID