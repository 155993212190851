/* eslint-disable @typescript-eslint/no-unused-vars */
import { ALBUM_GET } from 'constants/api'
import LoadingScreen from 'components/loading-screen'
import textServices from 'services/text-services'
import { useGeneralCfg } from 'contexts/general-context'
import { useEffect, useState, VoidFunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import useWhiteBg from 'hooks/use-white-bg'
import useRouter from 'hooks/use-router'
import { useAgegate } from 'hooks/use-agegate'
import { dlTrackEvent } from 'components/dataLayer/dataLayer'
import axios from 'axios';
import { Album } from 'views/album/types'
import images from '../../images.json'
import { useSession } from '../../contexts/session-context'
import {
  HeaderST,
  ButtonMenu,
  SpanMenu,
  Menu,
  Logo,
  Points,
  SpanMenuAlternative,
  PointTextStyle,
  ImgMenu
} from '../../theme/header'
import { SubTitleHeader, TextHeader, TextLevel, TitleHeader } from './text.style'
import { singStamp, Item } from './types'

interface ILink {
  link: Item,
  onAfterAction: (text: string) => void;
  onLinkClick: (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
}

const LinkNormal = ({ link, onAfterAction, onLinkClick }: ILink) => {
  return (
    <SubTitleHeader className="uppercase text-xl" key={`link-${link?.url}-${link?.title}`}>
      {link?.menuItemLinkType?.toLowerCase() === "blank" ? (
        <a
          href={link?.url}
          className="w-full sm:w-72"
          rel="noreferrer"
          target="_blank"
          onClick={() => {
            onAfterAction(link?.title)
          }}
        >
          {link?.title}
        </a>
      ) : (
        <Link to={link?.url} className="w-full sm:w-72 text-big" onClick={onLinkClick} data-url={link?.url}>
          {link?.title}
        </Link>
      )}
    </SubTitleHeader>
  )
}

const LinkLocked = ({ link }: { link: Item }) => {
  return (
    <SubTitleHeader className="uppercase text-xl cursor-not-allowed" aria-disabled style={{ opacity: '40%' }}>
      <p className="w-full sm:w-72 text-big">
        {link.title}
      </p>
    </SubTitleHeader>
  )
}

const Header: VoidFunctionComponent = () => {
  const { getAgeData, saveAgeData } = useAgegate()
  const [session] = useSession()
  const [open, setOpen] = useState(false)
  const { location, history, pathname } = useRouter()
  const logged = !!session.data
  const [loading] = useState(false)
  const { shouldUseWhiteBg } = useWhiteBg()
  const { showAgegate, recheckAge } = useAgegate()

  // START - GET MENU FROM BACK
  const { menu, getMenu } = useGeneralCfg();

  useEffect(() => {
    getMenu();
  }, []);
  // END - GET MENU FROM BACK

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
      document.documentElement.style.overflow = 'hidden'
      return
    }
    document.body.style.overflow = 'auto'
    document.documentElement.style.overflow = 'auto'
  }, [open])

  useEffect(() => {
    recheckAge()
  }, [pathname])

  const toggle = () => {
    setOpen((isOpen) => !isOpen)
  }

  const onAfterAction = (text: string) => {
    dlTrackEvent('user_interaction', {
      action: 'click',
      element: text.toLowerCase(),
      section: 'menu'
    })
  }

  const onLinkClick = (event: any) => {
    event.preventDefault()
    const url = event.target.getAttribute('data-url')
    const text = event.target.innerText
    history.push(url)
    onAfterAction(text)
    setOpen(false)
  }

  const logout = () => {
    const currentAgeGate = getAgeData()
    window.localStorage.clear()
    saveAgeData(currentAgeGate)
    window.location.href = `${process.env.PUBLIC_URL}/start`
  }

  const headerClasses = `w-full flex font-bold header-app ${logged ? 'justify-between' : 'justify-center'} 
  ${open ? 'fixed z-50' : logged && location.pathname === '/redeem' ? 'absolute z-30' : 'relative'}`

  const logoClasses = `w-20 px-3 logo ${logged ? 'ml-4' : ''}`.trim()
  const name = session.user?.firstName?.split(' ')[0]

  if (loading) {
    return <LoadingScreen></LoadingScreen>
  }


  const [albums, setAlbums] = useState<Album[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<Album[]>(ALBUM_GET, {
          headers: {
            Authorization: `Bearer ${((session?.data as any)?._tokenResponse as any)?.idToken}`,
            'Ph-Api-Id': PH_API_ID ?? 1
          }
        });
        setAlbums(response.data);
      } catch (error) {
        console.error('Failed to fetch albums:', error);
      }
    };

    if (open) {
      fetchData();
    }
  }, [session, open]);

  const PointView = () => {
    const pointsText = menu?.pointsText || "";
    const parts = pointsText.split("{points}");

    const formattedPointsText = (
      <>
        {parts[0]}
        <Points className="inline top-container__number">
          <span className="">{session.user?.totalPoints || 0}</span>
        </Points>
        {parts[1]?.replace("{pointsName}", POINTS_NAME)}
      </>
    );

    return (
      <>
        {textServices.findByText('header/points/br', '')}
        {formattedPointsText}
      </>
    );
  };

  const TitleComponent = ({ menu, name }) => {
    if (!menu || !menu.title) {
      return null;
    }

    const useName = menu?.title?.includes('{name}')

    if (!useName) {
      return <p className="max-w-full top-container__title"> {menu?.title} </p>
    }

    const parts = menu.title.split('{name}');

    const formattedTitle = (
      <>
        {parts[0]}
        {textServices.findByText('header/name/br', '')}
        <span className="top-container__title__name">{name}</span>
        {parts[1]}
      </>
    );

    return (
      <p className="max-w-full top-container__title">
        {formattedTitle}
      </p>
    );
  };


  return (
    <HeaderST open={open} className={headerClasses}>
      <button data-url="/home" onClick={onLinkClick} className="h-12">
        <Logo
          data-url="/home"
          className={logoClasses}
          src={`${AZURE_BASE_URL}${showAgegate
            ? open || shouldUseWhiteBg
              ? images['logo-positivo-svg']
              : images['logo-svg']
            : images['logo-svg']
          }`}
          alt={`Logo de ${PROJECT_NAME}`}
        />
      </button>
      {logged && (
        <ButtonMenu open={open} className={`w-24 h-12 px-8 header-app__button-container ${open ? 'header-app__button-container__open' : ''}`} onClick={toggle}>
          {shouldUseWhiteBg ? (
            <>
              <SpanMenuAlternative
                open={open}
                className={`transition block h-0.5 w-8 button-span ${open ? 'transform rotate-45 -mb-0.5' : 'mb-2'}`}
              />
              <SpanMenuAlternative
                open={open}
                className={`transition block h-0.5 w-8 button-span ${open ? 'transform -rotate-45 -mb-0.5' : 'mb-2'}`}
              />
              <SpanMenuAlternative
                open={open}
                className={`block h-0.5 w-8 button-span ${open ? 'hidden' : ''}`}
              />
            </>
          ) : (
            <>
              <SpanMenu
                open={open}
                className={`transition block h-0.5 w-8 button-span ${open ? 'transform rotate-45 -mb-0.5' : 'mb-2'}`}
              />
              <SpanMenu
                open={open}
                className={`transition block h-0.5 w-8 button-span ${open ? 'transform -rotate-45 -mb-0.5' : 'mb-2'}`}
              />
              <SpanMenu
                open={open}
                className={`block h-0.5 w-8 button-span ${open ? 'hidden' : ''}`}
              />
            </>
          )}
        </ButtonMenu>
      )}
      {open && (
        <Menu
          className="header header-app__menu w-full fixed top-12 p-6 flex flex-col justify-between z-50 overflow-auto"
          style={{ height: 'calc(calc(var(--vh, 1vh) * 100) - 2.95rem)' }}
        >
          <div className="text-3xl uppercase top-container">
            <TitleHeader className=''>
              <TitleComponent menu={menu} name={name} />
              {/* <p className="max-w-full top-container__title">
                {menu?.title?.replace("{name}", name)}
              </p> */}
              {(menu?.pointsStatus && menu?.pointsText) ? (
                <PointTextStyle className='top-container__points'>
                  <PointView />
                </PointTextStyle>
              ) : (
                <></>
              )}
            </TitleHeader>
            {session?.user?.level && ((menu?.singStampEnum === singStamp.ShowLevels) || (menu?.singStampEnum === singStamp.ShowCustomImageLevel)) && (
              <div className="w-full flex items-center gap-2 my-4 text-base top-container__level-container">
                <div className="bg-white rounded-full m-1">
                  <img src={session?.user?.level?.imageUrl} alt={session?.user?.level?.name} className="h-18 level-image" />
                </div>
                <TextLevel className='level-text'>{session?.user?.level?.name}</TextLevel>
              </div>
            )}
          </div>
          {((menu?.singStampEnum === singStamp.ShowCustomImage) || menu?.singStampEnum === singStamp.ShowCustomImageLevel) && (
            <ImgMenu src={`${menu?.singStampImageUrl}`} alt={`Logo de ${PROJECT_NAME}`} className="py-3 md:py-5 sello" />
          )}
          <ul className="text-2xl space-y-4 links-container">
            {menu?.items?.sort((a, b) => a.order - b.order).map((link) => {
              if (link?.menuItemSizeType?.toLowerCase() === "small") {
                return (
                  <TextHeader
                    className="w-full sm:w-72 din font-normal text-sm text-left mb-2 uppercase"
                    key={`link-${link.url}-${link.title}`}
                  >
                    {
                      link?.menuItemLinkType.toLowerCase() === "blank" ? (
                        <a
                          href={link?.url}
                          className="w-full sm:w-72 text-small"
                          rel="noreferrer"
                          target="_blank"
                          onClick={() => {
                            onAfterAction(link?.title)
                          }}
                        >
                          {link?.title}
                        </a>
                      ) : (
                        <Link to={link.url} className="w-full sm:w-72 text-small" onClick={onLinkClick} data-url={link.url}>
                          {link.title}
                        </Link>
                      )
                    }
                  </TextHeader>
                )
              }

              if (link?.menuItemLinkType?.toLowerCase() === "rally") {
                const urlParts = link?.url?.split('/')?.filter(Boolean);
                const lastPart = urlParts[urlParts.length - 1];

                if (lastPart === "upload") {
                  const [, , LinkRallyType] = urlParts;
                  const rallyType = albums?.find(album => album?.rallyType === LinkRallyType && album?.statusId === 2);
                  const isLocked = rallyType?.goalRule?.errors?.length > 0;

                  if (isLocked) {
                    return <LinkLocked link={link} key={`link-${link?.url}-${link?.title}`} />
                  }
                }
              }

              return <LinkNormal link={link} onAfterAction={onAfterAction} onLinkClick={onLinkClick} key={`link-${link.url}-${link.title}`} />
            })}
            <li>
              <button>
                <TextHeader
                  className="w-full sm:w-72 font-normal text-sm text-left uppercase underline text-small text-logout"
                  onClick={logout}
                >
                  {textServices.findByText('header/logout', { es: 'Cerrar Sesión', en: 'Log out' })}
                </TextHeader>
              </button>
            </li>
          </ul>
        </Menu>
      )}
    </HeaderST>
  )
}

export default Header
