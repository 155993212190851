import { AxiosError } from 'axios'
import ChangePassword from 'components/form-information/password-recovery'
import { useCustomForm } from 'hooks/use-custom-form'
import useRouter from 'hooks/use-router'
import { useContext, useEffect, useState } from 'react'
import ProfileService from 'services/profile-services'
import ErrorView from 'components/error-view'
import useMainClass from 'hooks/use-main-cass'
import { LoaderContext } from '../../contexts/loader-context'

const PasswordReset = () => {
  const { state, handleChangeState } = useCustomForm({ step: 1, password: '', password_confirm: '' })
  const { setLoad } = useContext(LoaderContext)
  const [error, setError] = useState(null)
  const { push, search } = useRouter()
  const query = new URLSearchParams(search)
  const oobCode = query.get('oobCode')?.replace(/&$/i, '').replaceAll(' ', '+')
  useMainClass('login__password-reset')
  useEffect(() => {
    setLoad(true)
  }, [])

  const verifyReset = async () => {
    try {
      const response = await ProfileService.verifyReset(JSON.stringify({ oobCode }))
      if (response.status === 200) setLoad(false)
      else {
        alert('enlace inválido')
        push('/start')
        setLoad(false)
      }
    } catch (err) {
      alert('enlace inválido')
      push('/start')
      setLoad(false)
    }
  }

  useEffect(() => {
    verifyReset()
  }, [oobCode, history])

  const onSubmit = async (newPassword) => {
    setLoad(true)
    try {
      await ProfileService.doReset({ oobCode, newPassword })
      push('/login')
    } catch (err) {
      const typedError = err as AxiosError
      const { code } = typedError.response?.data || {}
      setError(code)
    } finally {
      setLoad(false)
    }
  }

  if (error) {
    return (
      <ErrorView code={error} buttonMenu={() => error(null)} onRetry={() => error(null)} />
    )
  }

  return (
    <div className={`flex w-full`}>
      <ChangePassword state={state} handleChange={handleChangeState} onSubmit={onSubmit} />
    </div>
  )
}

export default PasswordReset
