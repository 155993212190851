import styled from 'styled-components'
import { Type } from '../types'

interface Types extends Type {
  isSelected: boolean
}

export const PSurveyMultiSelectOption = styled.p`
  ${(props: Type) => props.theme?.surveys?.label};
`

export const SubtitleSurveyLabel = styled.h2`
  ${(props: Type) => props.theme?.surveys?.subtitle};
`
export const SubtitleLabelSurvey = styled.h3`
  ${(props: Type) => props.theme?.surveys?.subtitleLabel};
`

export const DivSurveyMultiSelectOptionContainer = styled.div.attrs((props: Types) => ({
  isSelected: props.isSelected,
  ...props
})) <Types>`
  border-radius: ${(props: Type) => (props.theme?.surveys ?? {})['border-radius']};
  ${(props: Types) => props.isSelected ? props.theme?.surveys?.containerSelect : props.theme?.surveys?.container}
`

export const ImgSurveyMultiSelectOption = styled.img`
  border-radius: ${(props: Type) => (props.theme?.surveys ?? {})['border-radius']};
`