import React from 'react'
import MultiSelect from 'components/multi-select'
import { Option } from 'contexts/survey-context/survey.interface'
import DropDown from './dropdown'
import CheckboxMapper from './checkbox'
import RadioMapper from './radioMapper'
import TextBox from './textBox'

interface IProps {
  questionId: number
  question: string
  subtitle: string
  options: Option[]
  onChange: (data: number, select: string[], value?: string) => void
  type: 'Normal' | 'Dropdown' | 'Checkbox' | 'RadioButton' | 'TextBox'
  amountAnswers?: number
}

function Options({ questionId, question, subtitle, options, onChange, type, amountAnswers }: IProps) {

  if (type === 'Normal')
    return (
      <MultiSelect
        title={question}
        subtitle={subtitle}
        items={options}
        questionId={questionId}
        onChange={onChange}
        amountAnswers={amountAnswers}
      />
    )

  if (type === 'Dropdown')
    return (
      <DropDown
        title={question}
        subtitle={subtitle}
        items={options}
        questionId={questionId}
        onChange={onChange}
      />
    )

  if (type === 'Checkbox')
    return (
      <CheckboxMapper
        title={question}
        subtitle={subtitle}
        items={options}
        questionId={questionId}
        onChange={onChange}
      />
    )

  if (type === 'RadioButton')
    return (
      <RadioMapper
        title={question}
        subtitle={subtitle}
        items={options}
        questionId={questionId}
        onChange={onChange}
      />
    )

  if (type === 'TextBox')
    return (
      <TextBox
        title={question}
        subtitle={subtitle}
        items={options}
        questionId={questionId}
        onChange={onChange}
      />
    )

  return <></>
}

export default Options