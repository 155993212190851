/* eslint-disable @typescript-eslint/no-unused-vars */
import { TRIVIA_ANSWER, TRIVIA_LIST, TRIVIA_PLAY, TRIVIA_SET_INTEREST, TRIVIA_VALIDATE } from 'constants/api'
import axios from 'axios'
import React, { Dispatch, FC, useContext, useReducer, useEffect } from 'react'
import equals from 'lodash.isequal'
import { useHistory } from 'react-router-dom'
import textServices from 'services/text-services'
import { useSession } from '../session-context'
import reducer, { TriviaAction, TriviaActionsType, TriviaState } from './reducer'
export interface TriviaReducer {
  state: TriviaState
  dispatch: Dispatch<TriviaAction>
}

const TriviaContext = React.createContext<TriviaReducer>({} as TriviaReducer)

const TriviaProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    interests: []
  } as TriviaState)

  return <TriviaContext.Provider value={{ state, dispatch }}>{children}</TriviaContext.Provider>
}

export const useTrivia = () => {
  const history = useHistory()

  const [{ data: session }] = useSession()
  const [{ user }, { setUser, setPoints }] = useSession()

  const { state, dispatch } = useContext(TriviaContext)

  useEffect(() => {
    if (user) {
      const mappedInterests = user?.interests.map(({ id }) => id)
      const contentChanged = !equals(mappedInterests, state.interests)

      if (contentChanged) {
        dispatch({
          type: TriviaActionsType.SET_INTERESTS,
          payload: mappedInterests
        })
      }
    }
  }, [user])

  const setError = (err: any) => {
    dispatch({
      type: TriviaActionsType.SET_ERROR,
      payload: err
    })
  }

  const setInterests = async (interests: TriviaState['interests']) => {
    try {
      const response = await axios.put(TRIVIA_SET_INTEREST, interests, {
        headers: {
          Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`,
          'Ph-Api-Id': PH_API_ID ?? 1
        }
      })

      setUser(response.data)

      dispatch({
        type: TriviaActionsType.SET_INTERESTS,
        payload: interests
      })
    } catch (error) {
      console.error(error)
    }
  }

  const getTrivias = async () => {
    try {
      const response = await axios.get(TRIVIA_LIST, {
        headers: {
          Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`,
          'Ph-Api-Id': PH_API_ID ?? 1
        }
      })

      if (response.data) {
        dispatch({
          type: TriviaActionsType.SET_TRIVIAS,
          payload: response.data
        })

        return response.data
      }

      return response.data
    } catch (e) {
      console.error(e)
    }
  }

  const validateTrivia = async (triviaId: string | number, isDirectAccess: boolean) => {
    try {
      const response = await axios.post(
        TRIVIA_VALIDATE.replace(':triviaId', triviaId.toString()).replace(':isdirectaccess', isDirectAccess.toString()),
        undefined,
        {
          headers: {
            Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`,
            'Ph-Api-Id': PH_API_ID ?? 1
          }
        }
      )

      if (response.data) {
        dispatch({
          type: TriviaActionsType.SET_TRIVIA,
          payload: response.data
        })

        return true
      }

      return false
    } catch (error) {
      console.error('validate err', error.response?.data.code)
      setError(error.response?.data.code)
      throw error
    }
  }

  const playTrivia = async (triviaId: number | string, isDirectAccess: boolean) => {
    try {
      const response = await axios.put(
        TRIVIA_PLAY.replace(':triviaId', triviaId.toString()).replace(':isdirectaccess', isDirectAccess.toString()),
        undefined,
        {
          headers: {
            Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`,
            'Ph-Api-Id': PH_API_ID ?? 1
          }
        }
      )

      if (response.data.question) {
        dispatch({
          type: TriviaActionsType.SET_QUESTIONS,
          payload: response.data.question
        })

        return true
      }

      if (response.data.prize) {
        setPoints(response.data.prize.points)

        dispatch({
          type: TriviaActionsType.SET_PRIZE,
          payload: response.data.prize
        })

        return false
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const answerTrivia = async (triviaId: number | string, questionId: number | string, optionId: number | string) => {
    try {
      const response = await axios.put(TRIVIA_ANSWER.replace(':triviaId', triviaId.toString()), { triviaId, questionId, optionId }, {
        headers: {
          Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`,
          'Ph-Api-Id': PH_API_ID ?? 1
        }
      })

      if (response.data.question) {
        dispatch({
          type: TriviaActionsType.SET_QUESTIONS,
          payload: response.data.question
        })

        return true
      }

      if (response.data.prize) {
        if (response.data?.urlRedirect) {
          dispatch({
            type: TriviaActionsType.SET_CONGRATS,
            payload: {
              urlRedirect: response.data?.urlRedirect,
              buttonText: response?.data?.buttonText,
              urlRedirect2: response?.data?.urlRedirect2,
              buttonText2: response?.data?.buttonText2,
              profileTime: response?.data?.profileRanking?.time,
              useRanking: response?.data?.withRanking
            }
          })
        }

        setPoints(response.data.prize.points)

        dispatch({
          type: TriviaActionsType.SET_PRIZE,
          payload: response.data.prize
        })

        return false
      }

      return true

    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const resetTrivia = () => {
    // setPoints(points)

    dispatch({
      type: TriviaActionsType.RESET,
      payload: null
    })
  }

  const exchangePrize = (points: number) => {
    history.push(state?.congrats?.urlRedirect ?? textServices.findByText('trivias/exchange/button/url', '/profile/history'))
  }

  const methods = { setInterests, getTrivias, validateTrivia, resetTrivia, exchangePrize, setError, playTrivia, answerTrivia }

  const values = [state, methods] as [TriviaState, typeof methods]

  return values
}

export default TriviaProvider
