import textServices from 'services/text-services'
import Button from 'components/button'
import { Question, SurveyResponse } from 'contexts/survey-context/survey.interface'
import { dlTrackEvent } from 'components/dataLayer/dataLayer'
import ButtonCloseMenu from 'components/button-close'
import { ButtonMenu } from 'theme/header'
import { useState } from 'react'
import { TitleSurvey } from '../styles/styled.components'
import Options from './options'

interface IProps {
  id: number
  title: string
  questions: Question[]
  handleSubmit: (callback: () => Promise<void>) => void
  updatePolarisSurvey: (select: SurveyResponse[][], id: number) => Promise<void>
  redirect: () => void
  isOptional: boolean
}

function SurveyPolaris({
  id,
  title,
  questions,
  handleSubmit,
  updatePolarisSurvey,
  redirect,
  isOptional,
}:
  IProps) {
  const [selected, setSelected] = useState<Record<string, number[]>>({})
  const [textValue, setTextValue] = useState<Record<string, string>>({})

  const validateAmountAnwsers = (select: any) => {
    const selectedFilter = select?.filter((item, index) => {
      let amountAnswers = questions[index]?.type === 'Normal' ? questions[index]?.amountAwnsers : 1
      if (questions[index]?.type === 'Normal') {
        const anwser = questions[index]?.options.filter(
          (o) => (item as any)?.optionIds.find((x) => x === o.optionId) === o.optionId
        )
        if (anwser.some((o) => o.demandSpaceId === 0)) {
          amountAnswers = 0
        }
      }
      return (item as any)?.optionIds.length < amountAnswers
    })
    return selectedFilter?.length > 0
  }

  const onSubmit = async () => {
    const select = []
    for (const key in selected) {
      select.push({ questionId: parseInt(key), optionIds: selected[key], textValue: textValue[key] !== '' ? textValue[key] : null })
    }
    dlTrackEvent('user_interaction', {
      action: 'click',
      element: textServices.findByText('survey/button', 'ACTUALIZAR'),
      section: `survey`
    })
    if (validateAmountAnwsers(select)) {
      dlTrackEvent('error', {
        error_type: 'survey',
        error_code: 9999,
        error_message: 'Por favor, responde todas las preguntas',
        section: `survey`
      })
      return alert('Por favor, responde todas las preguntas')
    }
    await updatePolarisSurvey(select as any as SurveyResponse[][], id)
  }

  return (
    <div className="w-full">
      {isOptional && (
        <ButtonCloseMenu
          open={true}
          paramFn={() => {
            gtag('event', 'Click', {
              event_category: GTM_EVENT_CATEGORY,
              event_label: 'Survey - Cerrar modal',
              value: '10'
            })
            redirect()
          }}
        />
      )}
      {!isOptional && (
        <ButtonMenu
          open={true}
          className="w-24 h-12 py-4 px-8 absolute top-0 right-0 z-50"
          style={{ backgroundColor: '' }}
          onClick={() => { }}
          type="button"
        ></ButtonMenu>
      )}
      {
        (
          <>
            <TitleSurvey className="font-black text-4xl text-center py-4 title-survey">{title}</TitleSurvey>
            <div className="flex flex-col items-center gap-2 p-4 md:mt-10">
              {questions?.map(({ questionId, question, subtitle, options, type, amountAwnsers }, index) => (
                <Options
                  onChange={(data, select, value) => {
                    setTextValue((state) => ({ ...state, [data]: value}))
                    setSelected((state) => ({ ...state, [data]: select.map((id) => +id)}))
                  }}
                  options={options}
                  question={question}
                  subtitle={subtitle}
                  questionId={questionId}
                  type={type}
                  key={index}
                  amountAnswers={amountAwnsers}
                />
              ))}
              <Button onClick={() => handleSubmit(onSubmit)}>
                {textServices.findByText('survey/button', { es: 'ACTUALIZAR', en: 'UPDATE' })}
              </Button>
            </div>
          </>
        )
      }
    </div>
  )
}

export default SurveyPolaris
