import React from 'react'
import { Link } from 'react-router-dom'
import { ButtonNavStyle } from 'theme/home'

function BohemiaIdNav() {
  return (
    <Link to='/profile/identificacion' className="w-full" onClick={() => { gtag('event', 'event_to_ga', { event_category: 'navegacion_memorama', event_label: 'Home links' }) }}>
      <ButtonNavStyle className={`button-nav flex items-center justify-evenly py-3 px-2 h-24 text-center font-medium text-xs bg-center bg-no-repeat bg-cover`} style={{ background: 'linear-gradient(270deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.00) 99.39%)', border: 'none', backgroundImage: `url(${AZURE_BASE_URL}/textura-button-home.png)`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
        <div className='flex items-center justify-center w-full px-2'>
          <div className='flex flex-1 items-center justify-right gap-3'>
            <img src={`${AZURE_BASE_URL}/bohemia-nav.png`} alt='bohemia' />
            <p className='oswald text-xl font-normal'>MI BOHEMIA ID</p>
          </div>
          <img src={`${AZURE_ICONS_URL}/${THEME}/arrow-id.svg`} alt='Flecha' />
        </div>
      </ButtonNavStyle>
    </Link>
  )
}

export default BohemiaIdNav