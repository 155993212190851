import { useRef, useState, useEffect } from 'react';
import { DemandSpace, SurveyInfo } from 'contexts/survey-context/survey.interface'
import { useLocation } from 'react-router-dom';
import Button from 'components/button'
import YouTube from 'react-youtube';
import { HeaderST, Logo } from 'theme/header'
import ButtonCloseMenu from 'components/button-close'
import textServices from 'services/text-services';
import { ImgCardSurvey, TitleSurveyPolaris } from '../styles/styled.components'
import GamerTag from './gamerTag';


interface IProps {
  redirect: () => void
  demandSpace: DemandSpace
  surveyInfo: SurveyInfo
}

interface IPropsTwo {
  redirect: () => void
  demandSpace: DemandSpace
  handleClick: (value: boolean) => void
  SurveyInfo: SurveyInfo
}

const DemandSpaceImg = ({ demandSpace, redirect, handleClick, SurveyInfo }: IPropsTwo) => {

  const handleShare = async () => {
    try {
      const imageResponse = await fetch(SurveyInfo.resultUrl);
      const imageBlob = await imageResponse.blob();
      const file = new File([imageBlob], "avatar-mixx.jpg", { type: imageBlob.type });
  
      const filesArray = [file];

      if (navigator.share) {
        await navigator.share({
          files: filesArray,
          title: 'Mira mi avatar!',
          text: 'He compartido mi avatar contigo.',
        });
      } else {
        console.log('Web Share API no está disponible. Copia el enlace manualmente.');
      }
      
    } catch (error) {
      console.error('Error al compartir', error);
    }
  };

  const handleDownload = async () => {
    try {
      const response = await fetch(SurveyInfo.resultUrl);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'avatar-mixx.jpg');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error al descargar la imagen', error);
    }
  };

  return (
    <div className="w-full pb-7 flex-1 flex flex-col px-8">
      <ButtonCloseMenu
        open={true}
        paramFn={() => {
          redirect()
          gtag('event', 'Click', {
            event_category: GTM_EVENT_CATEGORY,
            event_label: 'Survey - Cerrar modal de alerta para ofertar',
            value: '10'
          })
        }}
      />
      <TitleSurveyPolaris className="font-black text-4xl text-center py-4 title"></TitleSurveyPolaris>
      <div className="flex-1 w-full flex items-center flex-col gap-6">
        <div className='flex flex-col items-end'>
          <ImgCardSurvey src={SurveyInfo?.resultUrl} alt="Tu avatar" />
          <div className='flex gap-5 w-full bg-black justify-end items-center py-1 px-2'>
            <button onClick={handleDownload} className='w-10 h-10 flex justify-center items-center' style={{ backgroundColor: '#F00', borderRadius: '100%' }}>
              <svg data-slot="icon" fill="none" strokeWidth="1.5" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className='w-6 h-6'>
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 3.75H6.912a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859M12 3v8.25m0 0-3-3m3 3 3-3"></path>
              </svg>
            </button>
            <button onClick={handleShare} className='w-10 h-10 flex justify-center items-center pr-1' style={{ backgroundColor: '#F00', borderRadius: '100%' }}>
              <svg data-slot="icon" fill="none" strokeWidth="1.5" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className='w-6 h-6'>
                <path strokeLinecap="round" strokeLinejoin="round" d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z"></path>
              </svg>
            </button>
          </div>
        </div>
        <ImgCardSurvey src={demandSpace.imageUrl} alt={demandSpace.name} className='w-2/4' />
        <GamerTag />
      </div>
      <div className="w-full flex items-center justify-center py-4">
        <button className='text-white underline youtube text-sm' onClick={() => handleClick(false)}>
          {textServices.findByText('survey/gamerTag/button', { es: 'Quiero saber más', en: 'I want to know more' })}
        </button>
      </div>
    </div>
  )
}

const DemandSpaceVideo = ({ demandSpace, redirect, surveyInfo }: IProps) => {
  const playerRef = useRef<any>(null);
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerWidth * 16 / 9 });
  const [avatar, setAvatar] = useState(false)

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const view = queryParams.get('view');
  const [viewHandled, setViewHandled] = useState(false);

  useEffect(() => {
    const updateDimensions = () => {
      const height = window.innerHeight * 0.7;
      let minWidth = 0
  
      // Define un rango basado en el ancho de la ventana
      const widthRange = window.innerWidth >= 430 ? '430+' : window.innerWidth >= 414 ? '414-429' : window.innerWidth === 375 ? '375' : 'default';
  
      switch (widthRange) {
      case '375':
        minWidth = 300;
        break;
      case '414-429':
        minWidth = 350;
        break;
      case '430+':
        minWidth = 400;
        break;
      default:
        minWidth = 300; // Valor por defecto para otros casos
      }
  
      const width = Math.min(minWidth, height * 16 / 9);
      setDimensions({ width, height });
    };
  
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const opts = {
    height: dimensions.height,
    width: dimensions.width,
    playerVars: {
      autoplay: 1,
      rel: 0,
    },
  };

  const headerClasses = `w-full flex font-bold justify-center absolute top-0`
  const logoClasses = `w-20 px-3`.trim()

  const handleClick = (bool: boolean) => {
    setAvatar(bool)
    setViewHandled(!viewHandled)
  }

  if ((view && !viewHandled) || avatar) return <DemandSpaceImg demandSpace={demandSpace} redirect={redirect} handleClick={handleClick} SurveyInfo={surveyInfo} />;

  return (
    <div className='flex justify-center items-center flex-col'>
      <HeaderST className={headerClasses}>
        <button className="h-12 cursor-default">
          <Logo
            className={logoClasses}
            src={`${AZURE_BASE_URL}/logo-negativo.svg`}
            alt={`Logo de ${PROJECT_NAME}`}
          />
        </button>
      </HeaderST>
      <div className='mt-5 border-4 border-white rounded-lg'>
        <YouTube 
          videoId={demandSpace.videoUrl}
          ref={playerRef} 
          opts={opts}
        />
      </div>
      <Button className='mt-10' onClick={() => handleClick(true)}>
        {surveyInfo?.buttonText ?? textServices.findByText('survey/video/button', { es: 'VER AVATAR', en: 'WATCH AVATAR' }) }
      </Button>
    </div>
  )
}

export default DemandSpaceVideo
