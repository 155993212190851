import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useUTMParams } from './useUTMParams';

export const PersistUTM = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const utmParams = useUTMParams();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    for (const key in utmParams) {
      if (utmParams[key] && !searchParams.has(key)) {
        searchParams.set(key, utmParams[key]);
      }
    }

    if (location.search.slice(1, location.search.length) !== searchParams.toString()) {
      history.replace({
        ...location,
        search: searchParams.toString(),
      });
    }
  }, [history, location, utmParams]);

  return children;
};