import { SVGProps } from 'react'

function TriviasMillerIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="58" height="47" viewBox="0 0 58 47" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M2.46024 8.16948C2.29081 6.64123 3.39235 5.26499 4.92059 5.09556L30.0764 2.30665C31.6046 2.13722 32.9809 3.23876 33.1503 4.76701L34.489 16.8418L32.9796 17.0091L31.641 4.93434C31.5639 4.23969 30.9384 3.73899 30.2437 3.816L5.08793 6.60491C4.39327 6.68192 3.89257 7.30749 3.96959 8.00215L6.64268 32.1133C6.72072 32.8172 7.36132 33.3201 8.06368 33.2287L25.6728 30.9385L25.8686 32.4444L8.25954 34.7347C6.71433 34.9356 5.30503 33.8293 5.13333 32.2806L2.46024 8.16948Z" fill="white" />
      <path d="M16.073 15.5365L15.9576 14.3475C15.9172 13.931 15.9594 13.5268 16.0841 13.135C16.2088 12.7431 16.3986 12.3925 16.6533 12.0829C16.9094 11.7868 17.2177 11.5399 17.5783 11.3422C17.9388 11.1445 18.3341 11.0248 18.764 10.983C19.2611 10.9348 19.721 10.9919 20.1436 11.1543C20.5797 11.3154 20.9496 11.5643 21.2536 11.901C21.4062 12.0761 21.5306 12.2403 21.6268 12.3937C21.7352 12.5324 21.8254 12.6932 21.8974 12.8761C22.0241 13.2028 22.1174 13.6752 22.1773 14.2933C22.2112 14.6426 22.2332 14.9388 22.2432 15.182C22.2654 15.4104 22.2715 15.6132 22.2616 15.7905C22.2516 15.9677 22.233 16.1255 22.2058 16.2638C22.1772 16.3886 22.1345 16.508 22.0778 16.622L20.918 19.135C20.8396 19.3054 20.7752 19.4811 20.725 19.6623C20.6735 19.8301 20.6568 20.008 20.6751 20.1961L20.8315 21.8083L18.7759 22.0078L18.594 20.1335C18.5653 19.838 18.585 19.5513 18.653 19.2734C18.7209 18.9956 18.8164 18.7219 18.9395 18.4522L20.0259 16.2312C20.1164 16.0461 20.1654 15.8515 20.1727 15.6473C20.1934 15.4419 20.194 15.2384 20.1745 15.0368L20.0709 13.9687C20.0435 13.6866 19.9192 13.4545 19.6982 13.2725C19.4905 13.0893 19.2456 13.0113 18.9635 13.0387C18.7216 13.0621 18.495 13.1723 18.2835 13.3691C18.0721 13.5659 17.9826 13.8323 18.0152 14.1682L18.1286 15.3371L16.073 15.5365ZM19.12 25.5548L18.9206 23.4991L20.9762 23.2997L21.1757 25.3553L19.12 25.5548Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M53.5406 18.5717L28.3034 16.6544C28.0246 16.6333 27.7815 16.8421 27.7603 17.1208L25.9197 41.3485C25.8985 41.6273 26.1073 41.8704 26.3861 41.8916L51.6233 43.8089C51.902 43.8301 52.1452 43.6213 52.1664 43.3425L54.007 19.1148C54.0281 18.836 53.8193 18.5929 53.5406 18.5717ZM28.4184 15.1402C27.3034 15.0555 26.3308 15.8907 26.246 17.0058L24.4055 41.2335C24.3207 42.3485 25.156 43.3211 26.271 43.4058L51.5082 45.3231C52.6233 45.4078 53.5959 44.5726 53.6806 43.4575L55.5212 19.2298C55.6059 18.1148 54.7706 17.1422 53.6556 17.0575L28.4184 15.1402Z" fill="white" />
      <path d="M37.3782 27.3162L37.4741 26.1254C37.5076 25.7083 37.6203 25.3178 37.8121 24.9541C38.0039 24.5903 38.2524 24.2786 38.5577 24.0187C38.8619 23.7724 39.2089 23.5836 39.5986 23.4525C39.9883 23.3213 40.3985 23.2731 40.8291 23.3077C41.3269 23.3478 41.7695 23.485 42.1569 23.7193C42.5578 23.9547 42.8782 24.2648 43.118 24.6498C43.2374 24.849 43.331 25.0325 43.3987 25.2005C43.481 25.3561 43.5415 25.5302 43.5801 25.7229C43.6473 26.0669 43.6559 26.5483 43.6061 27.1673C43.578 27.5171 43.5474 27.8126 43.5145 28.0537C43.4961 28.2824 43.4664 28.4832 43.4254 28.6559C43.3844 28.8287 43.3383 28.9807 43.2871 29.112C43.237 29.2298 43.174 29.3399 43.0981 29.4421L41.5138 31.7116C41.4066 31.8655 41.3124 32.0272 41.231 32.1967C41.1508 32.3527 41.103 32.525 41.0879 32.7133L40.958 34.3279L38.8993 34.1623L39.0504 32.2853C39.0742 31.9893 39.144 31.7105 39.2599 31.449C39.3757 31.1875 39.5179 30.9348 39.6865 30.6911L41.1471 28.696C41.2688 28.5298 41.3512 28.3468 41.3944 28.1472C41.451 27.9486 41.4874 27.7484 41.5036 27.5466L41.5897 26.4769C41.6124 26.1943 41.531 25.944 41.3454 25.7259C41.1733 25.5089 40.946 25.3891 40.6634 25.3664C40.4212 25.3469 40.1787 25.4154 39.9359 25.5719C39.6931 25.7284 39.5581 25.9748 39.5311 26.3112L39.4369 27.4818L37.3782 27.3162ZM38.6135 37.7144L38.7792 35.6558L40.8378 35.8215L40.6721 37.8801L38.6135 37.7144Z" fill="white" />
    </svg>
  )
}

export default TriviasMillerIcon
