import styled from 'styled-components';
import { Type } from 'theme/types';

export const ContainerProfile = styled.div`
  ${({ theme }: Type) => theme?.profile?.menu?.containerProfile}
`

export const TextProfile = styled.p`
  ${({ theme }: Type) => theme?.profile?.menu?.textProfile}
`

export const IconColor = styled.span`
  ${({ theme }: Type) => theme?.profile?.menu?.iconColor}
`

export const IconColorArrow = styled.span`
  ${({ theme }: Type) => theme?.profile?.menu?.iconColorArrow}
`

export const TextLabelMenu = styled.span`
  ${({ theme }: Type) => theme?.profile?.menu?.textLabelMenu}
`

export const TitlePage = styled.h1`
  ${({ theme }: Type) => theme?.profile?.menu?.titlePage}
`

export const ListItem = styled.li`
  ${({ theme }: Type) => theme?.profile?.menu?.listItem}
`

export const HeaderColorArrow = styled.span`
  ${({ theme }: Type) => theme?.profile?.menu?.headerColorArrow}
`
export const EditColorIcon = styled.span`
  ${({ theme }: Type) => theme?.profile?.menu?.editColorIcon}
`
export const ContainerMenu = styled.div`
  ${({ theme }: Type) => theme?.profile?.menu?.containerMenu}
`