import { forwardRef, ForwardRefRenderFunction, HTMLProps } from 'react'

import { InputBirthday, InputBirthdayLabel } from '../../theme/theme'
import { calendar } from './wordings'

const DateInput: ForwardRefRenderFunction<HTMLInputElement, HTMLProps<HTMLInputElement>> = (
  { type, ...props },
  ref
) => {
  return (
    <div className={`flex flex-col items-center justify-between agegate__input__container__${type}`}>
      <InputBirthday
        theme={undefined}
        ref={ref as any}
        name={type}
        type="tel"
        pattern="\d*"
        className={`text-center w-full bg-transparent text-4xl font-bold saira agegate__input agegate__input__${type}`}
        maxLength={2}
        {...props}
      />
      <InputBirthdayLabel className="text-center text-sm saira agegate__input__title">{calendar[type as keyof typeof calendar]}</InputBirthdayLabel>
    </div>
  )
}

const DateInputWithRef = forwardRef(DateInput)

export default DateInputWithRef
