import { PLAYABLE_LIST, PLAYABLE_VALIDATE, PLAYABLE_PLAY, PLAYABLE_SAVE } from 'constants/api';
import { createContext, useContext, useReducer, Dispatch, FC } from 'react';
import axios from 'axios';
import { useSession } from 'contexts/session-context';
import reducer, { PlayableState, PlayableAction, PlayableActionsType } from './reducer'
import { levels } from './data';

export interface PlayableReducer {
  state: PlayableState,
  dispatch: Dispatch<PlayableAction>
}

const PlayableContext = createContext<PlayableReducer>({} as PlayableReducer);

const PlayableProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {} as PlayableState)

  return <PlayableContext.Provider value={{ state, dispatch }}>{children}</PlayableContext.Provider>
}

export const usePlayable = () => {

  const [{ data: session }] = useSession()

  const { state, dispatch } = useContext(PlayableContext)

  const setError = (err: any) => {
    dispatch({
      type: PlayableActionsType.SET_ERROR,
      payload: err
    })
  }

  const getPlayables = async () => {
    try {
      const response = await axios.get(PLAYABLE_LIST, {
        headers: {
          Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`,
          'Ph-Api-Id': PH_API_ID??1
        }
      })
      console.log('getPlayables', response.data)

      if (response.data) {
        dispatch({
          type: PlayableActionsType.SET_PLAYABLES,
          payload: response.data
        })

        return response.data
      }

      return response.data
    } catch (e) {
      console.error(e)
    }
  }

  const validatePlayable = async (playableId: string) => {
    try {
      const response = await axios.post(PLAYABLE_VALIDATE.replace(':playableId', playableId), undefined, {
        headers: {
          Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`,
          'Ph-Api-Id': PH_API_ID??1
        }
      })

      if (response.data) {
        const levelIndex = Math.min(response.data.advance.length, levels.length - 1) ?? 0;
        dispatch({
          type: PlayableActionsType.SET_PLAYABLE,
          payload: response.data
        })

        dispatch({
          type: PlayableActionsType.SET_CURRENT_PLAYABLE,
          payload: levels[levelIndex],
        });

        dispatch({
          type: PlayableActionsType.SET_ADVANCE_BASE,
          payload: response.data.advance
        })

        return (response.data.advance.length === 0)
      }

      return true
    } catch (error) {
      console.error('validate playable err', error.response?.data.code)
      setError(error.response?.data.code)
      throw error
    }
  }

  const playPlayable = async (playableId: string) => {
    try {
      const response = await axios.put(PLAYABLE_PLAY.replace(':playableId', playableId), undefined, {
        headers: {
          Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`,
          'Ph-Api-Id': PH_API_ID??1 
        }
      })

      if (response.data) {
        dispatch({
          type: PlayableActionsType.SET_PLAY,
          payload: response.data
        })

        return response.data
      }

      return true
    } catch (error) {
      console.error(error)
    }
  }

  const savePlayableData = async (playableId: string, level: number, score: number) => {
    console.log('state?.advance', state?.advance)
    try {
      const response = await axios.put(PLAYABLE_SAVE.replace(':playableId', playableId), [
        ...(state.advance ?? []),
        { level, points: score }
      ], {
        headers: {
          Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`,
          'Ph-Api-Id': PH_API_ID??1 
        }
      });
      console.log('adv', [
        ...(state.advance ?? []),
        { level, points: score }
      ])

      dispatch({
        type: PlayableActionsType.SET_ADVANCE,
        payload: { level, points: score }
      })

      dispatch({
        type: PlayableActionsType.SET_PLAYABLE,
        payload: response.data
      })

      dispatch({
        type: PlayableActionsType.SET_PRIZE,
        payload: response?.data?.prize
      })

      console.log('Data saved successfully:', response.data);

      return response.data
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const sendScore = (score: number) => {
    console.log('state?.advance', state?.advance)
    dispatch({
      type: PlayableActionsType.SET_LAST_SCORE,
      payload: score
    })
  }
  
  const changeLevel = (data: any) => {
    const levelIndex = Math.min(data.advance.length, levels.length - 1);
    console.log('levelIndex', levelIndex)
    dispatch({
      type: PlayableActionsType.SET_CURRENT_PLAYABLE,
      payload: levels[levelIndex],
    });
  }

  const playAgain = async (playableId: string) => {
    const reponse = await playPlayable(playableId)

    dispatch({
      type: PlayableActionsType.SET_PLAY,
      payload: reponse
    })
    
    dispatch({
      type: PlayableActionsType.SET_CURRENT_PLAYABLE,
      payload: levels[0],
    });

    dispatch({
      type: PlayableActionsType.SET_ADVANCE,
      payload: []
    })
  }

  const deletePrize = () => {
    dispatch({
      type: PlayableActionsType.SET_PRIZE,
      payload: null
    })
  }

  const methods = { getPlayables, validatePlayable, playPlayable, setError, savePlayableData, sendScore, changeLevel, playAgain, deletePrize }

  const values = [state, methods] as [PlayableState, typeof methods]

  return values
}

export default PlayableProvider