import { createContext, lazy, Suspense, useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import RoutesWithNotFound from "utilities/routes-with-not-found";
import LoadingScreen from "components/loading-screen";
import useMainClass from "hooks/use-main-cass";
import { ILineup } from "./types";
import { useLineups } from "./hooks/useLineups";

const Start = lazy(() => import('./views/Start'))
const Create = lazy(() => import('./views/Create'))
const Edit = lazy(() => import('./views/Edit'))
const Details = lazy(() => import('./views/Details'))

export interface LineupContextProps {
  lineups: ILineup[]
  eventId: number
  lineupId: number
}

const LineupContext = createContext<LineupContextProps>({} as LineupContextProps)

export const useLineupContext = () => {
  const state = useContext(LineupContext)
  return state
}

export default function Lineup() {
  const { data, eventId, lineupId } = useLineups()
  useMainClass('lineup')

  if (data?.length === 0) return <LoadingScreen />

  return (
    <LineupContext.Provider value={{ lineups: data, eventId, lineupId }}>
      <Suspense fallback={<>Cargando</>}>
        <RoutesWithNotFound>
          <Route path="/lineup" component={() => <Redirect to="/lineup/start" />} exact />
          <Route path="/lineup/start" component={Start} exact />
          <Route path="/lineup/create" component={Create} exact />
          <Route path="/lineup/edit/:dayId" component={Edit} exact />
          <Route path="/lineup/details/:dayId/:artistId" component={Details} exact />
        </RoutesWithNotFound>
      </Suspense>
    </LineupContext.Provider>
  )
}