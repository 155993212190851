import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const ScriptInjector = () => {
  useEffect(() => {
    const script = document.createElement('script');

    if (ONETRUST_KEY) {
      script.src = 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js';
      script.async = true;
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('charset', 'UTF-8');
      script.setAttribute('data-domain-script', ONETRUST_KEY ?? '');
      document.body.appendChild(script);
      console.log('%c%s', 'color: green;', 'ONETRUST SCRIPT LOAD');
    }

    return () => {
      if (ONETRUST_KEY) {
        document.body.removeChild(script);
      }
    };
  }, []);

  if (!ONETRUST_KEY) return null

  return (
    <Helmet>
      <script type="text/javascript">
        {`
          function OptanonWrapper() { }      
        `}
      </script>
    </Helmet>
  )
};

export default ScriptInjector;