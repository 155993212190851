import { FunctionComponent, HTMLProps } from 'react'
import useWhiteBg from 'hooks/use-white-bg'
import { ButtonInit } from '../../theme/theme'

const Button: FunctionComponent<Omit<HTMLProps<HTMLButtonElement>, 'type'>> = ({ className, disabled, ...props }: { className: string, disabled: boolean }) => {

  const { shouldUseWhiteBg } = useWhiteBg()

  const componentClass = `${className} p-4 min-h-2 w-full mx-auto rounded-none din font-bold uppercase ${disabled && 'border-2'} button-generic`.trim()

  return <ButtonInit className={componentClass} {...props} disabled={disabled} shouldUseWhiteBg={shouldUseWhiteBg} />
}

export default Button
