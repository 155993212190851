import { GET_WARE_HOUSES } from "constants/api"
import axios from "axios"
import useMainClass from "hooks/use-main-cass"
import { useEffect, useState, useRef } from "react"
import { useSession } from "contexts/session-context"
import { useJsApiLoader } from '@react-google-maps/api';
import MapContainer from "./components/MapContainer"
import ContainerInput from "./components/ContainerInput"

const NewMarket = () => {

  useMainClass('marketplace')

  const [wareHouses, setWareHouses] = useState([])
  const [{ data: session }] = useSession()

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY_MAPS
  })

  useEffect(() => {
    const getWareHouses = async () => {
      try {
        const response = await axios.get(GET_WARE_HOUSES, {
          headers: {
            Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`,
            'Ph-Api-Id': PH_API_ID ?? 1
          }
        });

        const whs = response.data
          .filter(item => item.latitude !== undefined && item.longitude !== undefined)
          .map(item => ({
            ...item,
            latitude: Number(item.latitude),
            longitude: Number(item.longitude)
          }))
          .filter(item => !isNaN(item.latitude) && !isNaN(item.longitude))
          .filter(item => item.latitude !== 0 && item.longitude !== 0);

        setWareHouses(whs);

      } catch (error) {
        console.error("Error fetching warehouses:", error);
      }
    };

    getWareHouses();
  }, []);

  // MAPA
  const [info, setInfo] = useState({} as any)
  const [center, setCenter] = useState({ lat: 0, lng: 0 })

  const handleMouseOver = (item: any) => {
    gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'WARE HOUSES - Selección de deposito', value: '10' })
    // setCenter({
    //   lat: Number(item.latitude),
    //   lng: Number(item.longitude)
    // })

    console.log('item', item)
    setInfo(item)
  };

  // MAP - INPUT USER LOCATION
  const inputRef = useRef<HTMLInputElement>(null)
  const [searchLocation, setSearchLocation] = useState("")
  const [centerIsLoaded, setCenterIsLoaded] = useState(false)

  let geocoder;

  if (isLoaded) {
    geocoder = new google.maps.Geocoder();
  }

  // MAP - SEARCH CENTER 

  function CoordinateCenter(x, y) {
    this.x = x;
    this.y = y;
  }

  const searchCenter = (arr: { x: number, y: number }[]) => {
    let x = 0;
    let y = 0;
    let z = 0;

    // Convertir las coordenadas a radianes y sumarlas
    arr?.forEach(coord => {
      const lat = coord.x * Math.PI / 180;
      const lng = coord.y * Math.PI / 180;
      x += Math.cos(lat) * Math.cos(lng);
      y += Math.cos(lat) * Math.sin(lng);
      z += Math.sin(lat);
    });

    // Dividir por el número de puntos
    x /= arr?.length;
    y /= arr?.length;
    z /= arr?.length;

    // Convertir el promedio a coordenadas cartesianas
    const lng = Math.atan2(y, x);
    const hyp = Math.sqrt(x * x + y * y);
    const lat = Math.atan2(z, hyp);

    // Devolver el resultado en grados
    return new CoordinateCenter(lat * 180 / Math.PI, lng * 180 / Math.PI);
  }

  // MAP - SEARCH RESULT
  const [searchResult, setSearchResult] = useState(null);
  const [errorSearch, setErrorSearch] = useState(null);

  const handleInputChange = (event) => {
    setSearchLocation(event.target.value)
  }

  const handleSearch = () => {
    if (!isLoaded) return;
    geocoder.geocode({ address: `${searchLocation.trim()}`, componentRestrictions: { country: COUNTRY_MAPS } }, (results, status) => {
      if (status === "OK" && results[0]?.geometry?.location_type !== "APPROXIMATE") {
        setInfo(null)
        setSearchResult(results[0]);
        setCenter({
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng()
        })
        setErrorSearch(null)
      } else {
        setErrorSearch('No se encontraron resultados')
        console.error(status);
      }
    })
  }

  useEffect(() => {
    try {
      const validWareHouses = wareHouses?.filter(item => item.latitude !== undefined && item.longitude !== undefined)
        .map(item => ({
          ...item,
          latitude: Number(item.latitude),
          longitude: Number(item.longitude)
        }))
        .filter(item => !isNaN(item.latitude) && !isNaN(item.longitude))
        .filter(item => item.latitude !== 0 && item.longitude !== 0);


      const center = searchCenter(validWareHouses?.map(e => {
        return { x: Number(e.latitude), y: Number(e.longitude) }
      }));

      console.log('center', center);

      if (validWareHouses?.length > 0 && !centerIsLoaded && center.x !== 0 && center.y !== 0) {
        setCenter({
          lat: center.x,
          lng: center.y
        });

        setCenterIsLoaded(true);
      }
    } catch (error) {
      console.error('Error calculating center:', error);
    }
  }, [wareHouses]);

  // MAP - USER LOCATION
  const [userLocation, setUserLocation] = useState({ lat: 0, lng: 0 });

  const userLocationFn = () => {
    setSearchLocation('')
    setSearchResult(null)
    navigator.geolocation.getCurrentPosition((position) => {
      setUserLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      })

      setCenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      })
    })
  }

  return (
    <div className="marketplace">
      <div className="marketplace__container">
        <h1 className="marketplace__container__title">
          ¿DÓNDE COMPRAR?
        </h1>
        <p className="marketplace__container__subtitle">
          Averigua dónde conseguir tus etiquetas
        </p>
      </div>

      <ContainerInput
        handleInputChange={handleInputChange}
        handleSearch={handleSearch}
        searchLocation={searchLocation}
        userLocationFn={userLocationFn}
        inputRef={inputRef}
        search={searchResult}
        errorSearch={errorSearch}
      />

      <div className="marketplace__container-map">
        <MapContainer
          searchResult={searchResult?.geometry?.location}
          handleMouseOver={handleMouseOver}
          userLocation={userLocation}
          info={info}
          wareHouses={wareHouses}
          isLoaded={isLoaded}
          center={center}
        />
        {
          info?.name && (
            <div className="marketplace__container-map__info">
              <div className="marketplace__container-map__info__texts">
                <h2 className="marketplace__container-map__info__texts__title">
                  {info?.name}
                </h2>
                <p className="marketplace__container-map__info__texts__subtitle">
                  {info?.address}
                </p>
                <p className="marketplace__container-map__info__texts__subtitle">
                  {info?.description}
                </p>
              </div>

              <a href={`https://www.google.com/maps?q=${info?.latitude},${info?.longitude}`}
                rel="noreferrer" target="_blank" className="marketplace__container-map__info__icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

              </a>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default NewMarket