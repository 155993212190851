import { SVGProps } from 'react'

function Referrals(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="18.4268" cy="17.5" r="16.9" stroke="white" strokeWidth="1.2" />
      <circle cx="18.5" cy="11.5" r="5.75" stroke="white" strokeWidth="1.5" />
      <circle cx="18.5" cy="11.5" r="5.75" stroke="white" strokeWidth="1.5" />
      <path d="M7 29C7 23.2685 12.3932 18 18.0957 18C19.4472 18 20.7678 18.28 22 18.783" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M29.4243 23.4243C29.6586 23.1899 29.6586 22.8101 29.4243 22.5757L25.6059 18.7574C25.3716 18.523 24.9917 18.523 24.7574 18.7574C24.523 18.9917 24.523 19.3716 24.7574 19.6059L28.1515 23L24.7574 26.3941C24.523 26.6284 24.523 27.0083 24.7574 27.2426C24.9917 27.477 25.3716 27.477 25.6059 27.2426L29.4243 23.4243ZM22.3333 30.6C22.6647 30.6 22.9333 30.3314 22.9333 30C22.9333 29.6686 22.6647 29.4 22.3333 29.4L22.3333 30.6ZM20.5 30L20.5 29.4L20.5 30ZM29 22.4L20.5 22.4L20.5 23.6L29 23.6L29 22.4ZM20.5 30.6L22.3333 30.6L22.3333 29.4L20.5 29.4L20.5 30.6ZM16.4 26.5C16.4 28.7644 18.2356 30.6 20.5 30.6L20.5 29.4C18.8984 29.4 17.6 28.1016 17.6 26.5L16.4 26.5ZM20.5 22.4C18.2356 22.4 16.4 24.2356 16.4 26.5L17.6 26.5C17.6 24.8984 18.8984 23.6 20.5 23.6L20.5 22.4Z" fill="white" />
    </svg>
  )
}

export default Referrals
