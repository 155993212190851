import { Link } from 'react-router-dom'
import Arrow from 'components/icon/menu-profile/arrow'
import { dlTrackEvent } from 'components/dataLayer/dataLayer'
import { ButtonHelperProfile } from 'theme/profile'
import textServices from 'services/text-services'
import CONFIGS from 'build/Config'
import { TitlePage, IconColorArrow, TextLabelMenu, IconColor, ListItem, HeaderColorArrow, ContainerMenu } from '../styles/header.style'

const MenuProfile = ({ definitions }: any) => {

  const definitionsFiltered = definitions.filter((item: any) => !item.dontShowItem)

  return (
    <ContainerMenu className="menuprofile w-full bg-white absolute text-black" style={{ height: CONFIGS?.footer ? 'auto' : 'calc(100vh - 50px)' }}>
      <div className='p-5 flex justify-between items-center menuprofile__container'>
        <Link to="/home" >
          <HeaderColorArrow>
            {textServices.findByText('profile/view/arrow', <Arrow />)}
          </HeaderColorArrow>
        </Link>
        <TitlePage className="text-center text-4xl font-bold">{textServices.findByText('profile/menu/title', { es: 'Mi información', en: 'My information' })}</TitlePage>
        <HeaderColorArrow className='opacity-0'>
          <Arrow />
        </HeaderColorArrow>
      </div>
      <ul className='menuprofile__list-container'>
        {definitionsFiltered.map((item: any) => {
          return (
            <ListItem key={item.label} className='px-5 transition-colors list-item-container'>
              <Link to={item.url} className="flex justify-between items-center py-4" onClick={() => {
                dlTrackEvent('user_interaction', {
                  action: "click",
                  element: item.label.toLowerCase(),
                  section: "mi perfil",
                })
              }}>
                <div className="flex items-center link-container">
                  <IconColor className='w-8 flex justify-center items-center link-icon'>
                    {item?.icon && <item.icon />}
                  </IconColor>
                  <TextLabelMenu className="ml-4 text-base font-normal link-label">{item.label}</TextLabelMenu>
                  {item.helper && <ButtonHelperProfile className="h-2 w-2 rounded-full ml-2 animate-ping link-helper"></ButtonHelperProfile>}
                </div>
                <IconColorArrow className='icon-arrow'>
                  {!item.noArrow && <Arrow />}
                </IconColorArrow>
              </Link>
            </ListItem>
          )
        })}
      </ul>
    </ContainerMenu>
  )
}

export default MenuProfile
