// Función para cifrar usando AES
export function encryptAES(text, key) {
  const cryptoKey = window.crypto.subtle.importKey(
    "raw",
    new TextEncoder().encode(key),
    { name: "AES-CBC" },
    false,
    ["encrypt"]
  );

  return cryptoKey.then(key => {
    const iv = window.crypto.getRandomValues(new Uint8Array(16));
    return window.crypto.subtle.encrypt(
      { name: "AES-CBC", iv },
      key,
      new TextEncoder().encode(text)
    ).then(encrypted => {
      // Convertir datos cifrados a Base64
      const encryptedData = new Uint8Array(encrypted);
      const ivAndData = new Uint8Array(16 + encryptedData.length);
      ivAndData.set(iv);
      ivAndData.set(encryptedData, 16);
      return btoa(String.fromCharCode.apply(null, ivAndData));
    });
  });
}

// Función para descifrar usando AES
export function decryptAES(encryptedDataBase64, key) {
  // Eliminar espacios en blanco de la cadena Base64
  const encryptedDataWithoutSpaces = encryptedDataBase64.replace(/\s/g, '');
  const encryptedData = new Uint8Array(atob(encryptedDataWithoutSpaces).split('').map(c => c.charCodeAt(0)));

  const iv = new Uint8Array(encryptedData.slice(0, 16));
  const encryptedBytes = encryptedData.slice(16);

  const cryptoKey = window.crypto.subtle.importKey(
    "raw",
    new TextEncoder().encode(key),
    { name: "AES-CBC" },
    false,
    ["decrypt"]
  );

  return cryptoKey.then(key => {
    return window.crypto.subtle.decrypt(
      { name: "AES-CBC", iv },
      key,
      encryptedBytes
    );
  }).then(decrypted => {
    return new TextDecoder().decode(decrypted);
  });
}