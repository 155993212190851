import React from 'react'

const SiteMapIcon = () => {
  return (
    <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.375 0.03125H14.625V6.28125H12.2812V7.84375H20.0938V10.9688H22.4375V17.2188H16.1875V10.9688H18.5312V9.40625H12.2812V10.9688H14.625V17.2188H8.375V10.9688H10.7188V9.40625H4.46875V10.9688H6.8125V17.2188H0.5625V10.9688H2.90625V7.84375H10.7188V6.28125H8.375V0.03125ZM9.9375 1.59375V4.71875H13.0625V1.59375H9.9375ZM2.125 12.5312V15.6562H5.25V12.5312H2.125ZM9.9375 12.5312V15.6562H13.0625V12.5312H9.9375ZM17.75 12.5312V15.6562H20.875V12.5312H17.75Z" fill="#027219" />
    </svg>
  )
}

export default SiteMapIcon