import { Type } from 'theme/types';
import styled from 'styled-components';

export const ContainerPassword = styled.div`
  ${({ theme }: Type) => theme?.inputPassword?.container}
`

export const InputPassword = styled.input`
  ${({ theme }: Type) => theme?.inputPassword?.input}
`

export const IconPassword = styled.div`
  ${({ theme }: Type) => theme?.inputPassword?.icon}
`