import GenericError from "components/generic-error"
import ButtonCloseMenu from "components/button-close"
import { useSession } from "contexts/session-context"
import { useEffect } from "react"

function MaxChange({ nextStep, setError, prevStep, handleChangeState }) {

  const [{ user }] = useSession()
  const diffChange = MAX_CHANGE_ADDRESS_COUNT - user?.addressCount

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (user?.addressCount >= MAX_CHANGE_ADDRESS_COUNT && MAX_CHANGE_ADDRESS_COUNT != 0) {
      setError(61)
    }
    // eslint-disable-next-line eqeqeq
    if (MAX_CHANGE_ADDRESS_COUNT == 0) {
      handleChangeState({ step: 1 } as any)
    }
  }, [])

  return (
    <div className="flex-1 flex justify-center w-full text-white">
      <ButtonCloseMenu 
        open={true}
        paramFn={() => {
          gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'MODIFICAR DIRECCION - Volver atras', value: '10' })
          prevStep()
        }}
      />
      <GenericError
        error={
          <>
            ¡Aguas! <br />
          </>
        }
        description={
          <>
            Sólo puedes cambiar tu dirección {diffChange} ve{diffChange > 1 ? 'ces' : 'z'} más. <br />
            ¿Estás seguro/a?
          </>
        }
        onRetry={() => {
          gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'MODIFICAR DIRECCION - Continuar Cambio Direccion', value: '10' })
          nextStep()
        }}
        buttonText="CONTINUAR"
      />
    </div>
  )
}

export default MaxChange