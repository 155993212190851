import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import Button from "components/button";
import { dlTrackError, dlTrackEvent } from "components/dataLayer/dataLayer";
import ErrorView from "components/error-view";
import OtpInput from "components/otp-input";
import { LoaderContext } from "contexts/loader-context";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import formServices from "services/form-builder-services";
import ProfileService from "services/profile-services";
import { ErrorInput } from "theme/theme";
import { ColorDiv } from "views/sign-up/styles/custom.style";

interface IProps {
  email: string
  accessToken: string
  setStepper: React.Dispatch<React.SetStateAction<"id" | "data" | "email" | "complete">>
  handleResend: () => Promise<void>
  resend: number
}

export default function ViewEmail({ email, accessToken, setStepper, handleResend, resend }: IProps) {
  const { handleSubmit, formState: { errors }, setValue } = useForm({ resolver: yupResolver(formServices.formGenerateSchema(["otp"])) });

  const { setLoad } = useContext(LoaderContext);
  const [error, setError] = useState<number>(null)

  const onSubmit = async (data) => {
    dlTrackEvent('form_send', {
      element: 'verificar',
      form_name: 'verifica tu email',
      section: 'sign up'
    })
    setLoad(true)
    try {
      const response = await ProfileService.otpVerifyEmailWithAccessToken(data.otp, accessToken)
      if (response?.status === 200) {
        gtag('event', 'email_verificado', {
          event_label: 'Registro - Email',
          method: "Email"
        })
        dlTrackEvent('sign_up', {
          method: "Email"
        })
        setStepper('complete')
      }
    } catch (err) {
      const typedError = err as AxiosError
      const { code, message } = typedError.response?.data || {}
      dlTrackError({
        error_type: 'login',
        error_code: { code },
        error_message: { message },
        section: 'sign up'
      })
      setError(code)
    } finally {
      setLoad(false)
    }
  }

  if (error) return <ErrorView code={error} buttonMenu={() => setError(null)} onRetry={() => setError(null)} />

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full px-7 my-8 flex flex-col items-center flex-1 gap-4">
      <header>
        <h1 className="title medium text-center">Validemos tu mail</h1>
        <p className="description medium text-center">Ingresa el código de 6 dígitos que recibiste para verificar tu mail.</p>
      </header>
      <section className="w-full flex-1 flex flex-col gap-4">
        <ColorDiv className="gap-2 flex flex-col mb-3 input-container">
          <OtpInput setValue={setValue} />
          <ErrorInput className='input-error'>
            <ErrorMessage errors={errors} name="otp" />
          </ErrorInput>
        </ColorDiv>
        <div className="text-center space-y-4 resend">
          <p className="text medium resend-description text-center">Una contraseña de un solo uso se envió a <b>{email}</b></p>
          {!(resend > 2) && (
            <div>
              <p className="text medium resend-question text-center">¿No la recibiste?</p>
              <button type="button" className="button underline medium cursor-pointer resend-button text-center" onClick={handleResend}>Reenviar código</button>
            </div>
          )}
        </div>
      </section>
      <Button>Verificar</Button>
    </form>
  )
}