import { useHistory } from 'react-router'
import { useContext, useState } from 'react'
import FormInformation from 'components/form-information'
import { AxiosError } from 'axios'
import ProfileService from 'services/profile-services'
import useLocalStorage from "hooks/use-local-storage";
import useRouter from 'hooks/use-router'
import useRedirect from 'hooks/use-redirect'
import { getBirthdayLocal } from 'utilities/birthday'
import { useSession } from '../../contexts/session-context'
import { LoaderContext } from '../../contexts/loader-context'

function ProfileInformationUpdate() {
  const history = useHistory()
  const [{ user, data: session }, { setUser }] = useSession()
  const { setLoad } = useContext(LoaderContext)
  const [{ url }, { redirect }] = useRedirect();
  const [state, setState] = useLocalStorage('state', {})
  const [birthday] = useState(getBirthdayLocal())
  const { query } = useRouter()


  const handleOnSubmit = async (data: any, setError: (error: number) => void) => {
    setLoad(true)
    try {
      const payload = { user: data, session }
      console.log(payload)
      const response = await ProfileService.updateProfile(payload)
      setUser(response.data)
      setState({ ...state, user: response.data })
      if (url) return redirect()
      if ((query as any)?.isNew) return history.push('/home')
      history.goBack()
    } catch (err) {
      const typedError = err as AxiosError
      const { code } = typedError.response?.data || {}
      setError(code)
    } finally {
      setLoad(false)
    }
  }

  return (
    <div className="mt-7 flex flex-col w-full">
      <FormInformation handleSubmit={handleOnSubmit} user={{ ...user, birthday: user?.birthday ?? birthday as any ?? '' }} quantityStep={2} />
    </div>
  )
}

export default ProfileInformationUpdate
