import Button from 'components/button'
import { dlTrackEvent } from 'components/dataLayer/dataLayer';
import { ReactChild, VFC } from 'react'
import { BackBtn } from 'views/login/text.style';
import { SectionError } from 'theme/error';
import { TitleError, TextError } from './components/style/text'

export interface GenericErrorProps {
  error?: ReactChild
  description?: ReactChild
  onRetry?: () => void
  buttonText?: ReactChild
  subButtonText?: ReactChild
  onSubRetry?: () => void
}

const GenericError: VFC<GenericErrorProps> = ({ error = '', description = '', onRetry, buttonText, onSubRetry, subButtonText }) => {
  const safeError = error || (
    <>
      ¡Chin! <br />
      Algo no salió como debería.
    </>
  )

  const safeDescription = description || <>Tranqui... que todo tiene solución.</>

  return (
    <SectionError className="w-full flex-1 flex flex-col justify-center items-center max-w-full space-y-4 mx-8 my-8">
      <div className="flex-1  flex flex-col itemx-center justify-center gap-4">
        <TitleError className="text-center text-3xl uppercase font-bold error__title">{safeError}</TitleError>
        <TextError className="text-center mx-2 error__description">{safeDescription}</TextError>
      </div>
      {(onRetry && buttonText) && (
        <Button
          onClick={() => {
            dlTrackEvent('user_interaction', { action: 'click', element: buttonText || 'REINTENTAR', })
            gtag('event', 'Click', {
              event_category: GTM_EVENT_CATEGORY,
              event_label: 'ERROR - Reintentar',
              value: '10'
            })
            onRetry()
          }}
        >
          {buttonText || 'REINTENTAR'}
        </Button>
      )}
      {(onSubRetry && subButtonText) && (
        <BackBtn className={`din text-center mt-3 uppercase cursor-pointer underline button-back`} style={{ fontSize: 10 }}
          onClick={() => {
            dlTrackEvent('user_interaction', { action: 'click', element: subButtonText, })
            gtag('event', 'Click', {
              event_category: GTM_EVENT_CATEGORY,
              event_label: `ERROR - ${subButtonText}`,
              value: '10'
            })
            onSubRetry()
          }}
        >
          {subButtonText}
        </BackBtn>
      )}
    </SectionError>
  )
}

export default GenericError
