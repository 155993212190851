import React from 'react'

function GPS() {
  return (
    <svg width="15" height="22" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 0.46875C6.23047 0.46875 5.05046 0.786133 3.95996 1.4209C2.90202 2.05566 2.05566 2.90202 1.4209 3.95996C0.786133 5.05046 0.46875 6.23047 0.46875 7.5C0.46875 8.00456 0.566406 8.59049 0.761719 9.25781C0.924479 9.8112 1.17676 10.4704 1.51855 11.2354C2.07194 12.4886 2.79622 13.8883 3.69141 15.4346C4.34245 16.5739 5.06673 17.7458 5.86426 18.9502C6.27116 19.5687 6.60482 20.0651 6.86523 20.4395L7.5 21.3672L9.13574 18.9502C9.93327 17.7458 10.6576 16.5739 11.3086 15.4346C12.2038 13.8883 12.9281 12.4886 13.4814 11.2354C13.8232 10.4704 14.0755 9.8112 14.2383 9.25781C14.4336 8.59049 14.5312 8.00456 14.5312 7.5C14.5312 6.23047 14.2139 5.05046 13.5791 3.95996C12.9443 2.90202 12.098 2.05566 11.04 1.4209C9.94954 0.786133 8.76953 0.46875 7.5 0.46875ZM7.5 2.03125C8.49284 2.03125 9.4043 2.27539 10.2344 2.76367C11.0807 3.25195 11.748 3.91927 12.2363 4.76562C12.7246 5.5957 12.9688 6.50716 12.9688 7.5C12.9688 7.80924 12.8874 8.2487 12.7246 8.81836C12.5618 9.37174 12.3421 9.96582 12.0654 10.6006C11.5934 11.6748 10.8854 13.0257 9.94141 14.6533C9.14388 16.0205 8.34635 17.3063 7.54883 18.5107L7.5 18.5596L7.45117 18.5107C6.65365 17.3063 5.85612 16.0205 5.05859 14.6533C4.11458 13.0257 3.40658 11.6748 2.93457 10.6006C2.65788 9.96582 2.43815 9.37174 2.27539 8.81836C2.11263 8.2487 2.03125 7.80924 2.03125 7.5C2.03125 6.50716 2.27539 5.5957 2.76367 4.76562C3.25195 3.91927 3.91113 3.25195 4.74121 2.76367C5.58757 2.27539 6.50716 2.03125 7.5 2.03125ZM7.5 5.9375C7.06055 5.9375 6.6862 6.09212 6.37695 6.40137C6.08398 6.69434 5.9375 7.06055 5.9375 7.5C5.9375 7.93945 6.08398 8.3138 6.37695 8.62305C6.6862 8.91602 7.06055 9.0625 7.5 9.0625C7.93945 9.0625 8.30566 8.91602 8.59863 8.62305C8.90788 8.3138 9.0625 7.93945 9.0625 7.5C9.0625 7.06055 8.90788 6.69434 8.59863 6.40137C8.30566 6.09212 7.93945 5.9375 7.5 5.9375Z" fill="currentColor" />
    </svg>

  )
}

export default GPS