import { useEffect, useState } from "react";
import { Step } from "theme/theme";

export function useCustomForm<T>(initialValues: T) {
  const [state, setState] = useState<T>(initialValues)
  const [history, setHistory] = useState<number>((initialValues as { step?: number })?.step)
  const [stepsArray, setStepsArray] = useState<number[]>([])
  const [htmlSteps, setHtmlSteps] = useState<JSX.Element>(<></>)

  const prevStep = () => {
    const { step } = state as any;
    setHistory(step - 1)
    setState((state) => ({ ...state, step: step - 1 }));
  }

  const nextStep = () => {
    const { step } = state as any;
    setHistory(step + 1)
    setState((state) => ({ ...state, step: step + 1 }));
  }

  const handleChangeState = (object: T) => {
    setState((state) => ({ ...state, ...object }));
  }

  const backHistory = () => {
    setState((state) => ({ ...state, step: history }))
  }

  const setQuantitySteps = (quantity: number) => {
    setStepsArray(Array.from(Array(quantity).keys()))
  }

  useEffect(() => {
    setHtmlSteps(
      <>
        {
          stepsArray.map((item) => (
            <Step active={item + 1 === (state as any)?.step} className={`text-center step ${item + 1 === (state as any)?.step ? 'step-active' : ''}`} key={item}>0{item + 1}</Step>
          ))
        }
      </>
    )
  }, [state, stepsArray])

  return { state, handleChangeState, prevStep, nextStep, backHistory, setQuantitySteps, htmlSteps };
}