import { CLIENT_CODE } from 'constants/api'
import { useState, useContext } from 'react'
import { LoaderContext } from 'contexts/loader-context'
import { Link } from 'react-router-dom'
import { ClientContext } from 'contexts/client-num-context'
import Button from 'components/button'
import { Input, ErrorInput } from 'theme/theme'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import formServices from 'services/form-builder-services'
import { ErrorMessage } from '@hookform/error-message'
import axios from 'axios'
import { useSession } from 'contexts/session-context'
import ProfileService from 'services/profile-services'
import PopupClient from 'views/album/views/multimedia/components/PopupClient'

const ClientNum = () => {
  const [{ data: session }, { setUser }] = useSession()
  const { setLoad } = useContext(LoaderContext)
  const [confirmData, setConfirmData] = useState(false)
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({ resolver: yupResolver(formServices.formGenerateSchema(['clientNum'])) })
  const [consumptionData, setConsumptionData] = useState(null)
  const [error, setError] = useState(false)
  const [popup, setPopup] = useState(false)
  const { setClientNum, setShowInputNumber } = useContext(ClientContext)

  interface IData {
    clientNum: string
  }

  const handleSubmitForm = async (data: IData) => {
    setLoad(true)
    try {
      const response = await axios.post(CLIENT_CODE.replace(':clientNum', data?.clientNum), undefined, {
        headers: {
          Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`,
          'Ph-Api-Id': PH_API_ID ?? 1
        }
      })

      if (response?.data?.length) {
        setConsumptionData(response?.data[0])
        setConfirmData(!confirmData)
      } else {
        setError(true)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoad(false)
    }
  }

  const handlePush = () => {
    setClientNum(true)
    setShowInputNumber(false)
  }

  if (popup) {
    return <PopupClient isError={false} setError={setError} />
  }

  const confirmFn = async () => {
    try {
      setLoad(true)
      const response = await ProfileService.updateConsumption({
        session: session,
        consumptionId: consumptionData?.id
      })
      setUser(response?.data)
      setPopup(!popup)
    } catch (error) {
      console.log(error)
    } finally {
      setLoad(false)
    }
  }

  if (confirmData) {
    return (
      <div className="flex flex-col py-5 justify-between mx-auto">
        <div className="flex flex-col gap-5">
          <h2 className="title__client">¿Cuál es tu NÚMERO DE CLIENTE?</h2>
          <p className="subtitle__confirm">CONFIRMA QUE LOS DATOS SON CORRECTOS</p>
          <div className="input__client">
            <p>{consumptionData?.internalId}</p>
          </div>

          <Input value={consumptionData?.state} readOnly className="input__name" />
          <Input value={consumptionData?.city} readOnly className="input__name" />
          <Input value={consumptionData?.name} readOnly className="input__name" />

          <Link to="/consumption/update" onClick={handlePush} className="link__client">
            Si no son correctos, ingresa aquí
          </Link>
        </div>

        <div className="flex flex-col gap-5">
          <p className="text__client">
            <b>¿NO PUEDES AVANZAR?</b> <br />
            Ponte en contacto con nosotros para que podamos <br />
            ayudarte.{' '}
            <Link to="/help" className="underline">
              Envíanos un mensaje.
            </Link>
          </p>
          <Button onClick={confirmFn}>CONFIRMAR</Button>
        </div>
      </div>
    )
  }

  if (error) {
    return <PopupClient isError={true} setError={setError} />
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)} className="flex justify-between flex-col py-8 mx-7">
      <div className="flex flex-col gap-2">
        <h2 className="title__client">¿Cuál es tu NÚMERO DE CLIENTE?</h2>
        <p className="subtitle__client">BúSCALO EN LOS MATERIALES IMPRESOS E INGRESALO en el siguiente campo:</p>
        <Input placeholder="Número de Cliente (9 DÍGITOS)" {...register('clientNum')} />
        <ErrorInput>
          <ErrorMessage name="clientNum" errors={errors} />
        </ErrorInput>
        <Link to="/consumption/update" onClick={handlePush} className="link__client">
          Si no lo sabes, ingresa aquí
        </Link>
      </div>
      <div className="flex flex-col gap-5">
        <p className="text__client">
          <b>¿NO PUEDES AVANZAR?</b> <br />
          Ponte en contacto con nosotros para que podamos <br />
          ayudarte.{' '}
          <Link to="/help" className="underline">
            Envíanos un mensaje.
          </Link>
        </p>
        <Button>Ingresar</Button>
      </div>
    </form>
  )
}

export default ClientNum
