import Button from "components/button"

interface IProps {
  handleLogin: (credentials: any) => Promise<void>
  displayName: string
}

export default function ViewComplete({ handleLogin, displayName }: IProps) {

  return (
    <article className="w-full px-7 my-8 flex flex-col items-center flex-1 gap-4">
      <section className="flex-1 flex flex-col items-center justify-center gap-3">
        <h1 className="title big text-center">¡EXCELENTE, {displayName}!</h1>
        <p className="description medium text-center">Has confirmado con exito tu cuenta.<br />Ya puedes ingresar.</p>
      </section>
      <Button onClick={handleLogin}>Ingresar</Button>
    </article>
  )

}