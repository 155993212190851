import styled from "styled-components";
import { Type } from "theme/types";
import { Title, SubTitle, Text } from '../../theme/theme';

export const TitleHeader = styled(Title)`
  ${({ theme }: Type) => theme?.header?.text?.title}
`;

export const SubTitleHeader = styled(SubTitle)`
  ${({ theme }: Type) => theme?.header?.text?.subtitle}
`;

export const TextHeader = styled(Text)`
  ${({ theme }: Type) => theme?.header?.text?.text}
`

export const TextLevel = styled(Text)`
  ${({ theme }: Type) => theme?.header?.text?.level}
`