import { GET_ALL_SURVEY, PUT_SURVEY, SURVEY_VALIDATE } from "constants/api"
import axios from "axios"

const SurveyService = {
  getSurveys(data) {
    return axios.get(GET_ALL_SURVEY, { headers: { Authorization: `Bearer ${((data.session as any)?._tokenResponse as any)?.idToken}`, 'Ph-Api-Id': PH_API_ID ?? 1 } })
  },

  answerSurvey(data) {
    return axios.put(PUT_SURVEY.replace(':surveyId', data?.surveyId.toString()), { optionIds: data?.select }, { headers: { Authorization: `Bearer ${((data.session as any)?._tokenResponse as any)?.idToken}`, 'Ph-Api-Id': PH_API_ID ?? 1 } })
  },

  validateSurvey(data) {
    return axios.get(
      SURVEY_VALIDATE.replace(':surveyId', data?.surveyId.toString()).replace(':isdirectaccess', data?.isDirectAccess.toString()),
      {
        headers: {
          Authorization: `Bearer ${((data.session as any)?._tokenResponse as any)?.idToken}`,
          'Ph-Api-Id': PH_API_ID ?? 1
        }
      }
    )
  }
}

export default SurveyService