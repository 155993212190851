import { ButtonMenu, SpanMenu } from "theme/header"
import { isAndroid, isIOS } from "utilities/detectDeviceAgent"

export interface IProps {
  open: boolean
  setOpen: (value: boolean) => void
  social: 'Google' | 'Twitch'
}

export default function AlertAppView({ open, social, setOpen }: IProps) {
  const soName = isAndroid() ? 'Android' : (isIOS() ? 'iOS' : '')

  if (!open) return <></>

  return (
    <article className="min-h-view fixed space-y-4 top-0 z-50 bg-white px-4 py-2 bg-opacity-90 backdrop-blur backdrop-filter w-full left-0">
      <header className="flex items-center justify-between">
        <ButtonMenu
          open={true}
          className="h-9 w-9 px-1 py-1"
          style={{ backgroundColor: `transparent` }}
          onClick={() => setOpen(false)}
          type="button"
        >
          <SpanMenu open={open} style={{ backgroundColor: 'black' }} className={`transition block h-0.5 w-8 transform rotate-45 -mb-0.5`} />
          <SpanMenu open={open} style={{ backgroundColor: 'black' }} className={`transition block h-0.5 w-8 transform -rotate-45 -mb-0.5`} />
        </ButtonMenu>
        <svg width="15" height="31" viewBox="0 0 15 31" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-9">
          <path d="M7.53033 0.46967C7.23744 0.176777 6.76256 0.176777 6.46967 0.46967L1.6967 5.24264C1.40381 5.53553 1.40381 6.01041 1.6967 6.3033C1.98959 6.59619 2.46447 6.59619 2.75736 6.3033L7 2.06066L11.2426 6.3033C11.5355 6.59619 12.0104 6.59619 12.3033 6.3033C12.5962 6.01041 12.5962 5.53553 12.3033 5.24264L7.53033 0.46967ZM6.25 1L6.25 28L7.75 28L7.75 1L6.25 1Z" fill="#027219" />
          <circle cx="7.2" cy="23.8" r="6.54444" fill="#D9D9D9" stroke="white" strokeWidth="0.688889" />
          <path d="M9.28041 27.2996H5.1333V26.663H6.91752V21.0114L5.1333 21.4936V20.8378L6.90788 20.3556H7.61192V26.663H9.28041V27.2996Z" fill="black" />
        </svg>
      </header>
      <section className="space-y-6 text-black">
        <h3 className="uppercase font-bold text-3xl">No puedes iniciar sesión en este navegador por políticas de {social}</h3>
        <div className="space-y-4">
          <p className="text-base font-normal">Sigue estos pasos para resolverlo:</p>
          {
            (soName === 'Android' || soName === '') && (
              <>
                <hr className="w-full border-t border-solid border-black border-opacity-30" />
                <div className="space-y-2">
                  <p className="font-bold uppercase text-sm">Dispositivos android</p>
                  <ol className="font-normal text-sm list-decimal pl-4">
                    <li>
                      <p className="flex items-center">
                        Presiona en los tres puntos
                        <span>
                          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-1">
                            <rect y="0.810547" width="18" height="18" rx="9" fill="#E3E3E3" />
                            <path d="M9 10.5605C9.41421 10.5605 9.75 10.2248 9.75 9.81055C9.75 9.39633 9.41421 9.06055 9 9.06055C8.58579 9.06055 8.25 9.39633 8.25 9.81055C8.25 10.2248 8.58579 10.5605 9 10.5605Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9 5.31055C9.41421 5.31055 9.75 4.97476 9.75 4.56055C9.75 4.14633 9.41421 3.81055 9 3.81055C8.58579 3.81055 8.25 4.14633 8.25 4.56055C8.25 4.97476 8.58579 5.31055 9 5.31055Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9 15.8105C9.41421 15.8105 9.75 15.4748 9.75 15.0605C9.75 14.6463 9.41421 14.3105 9 14.3105C8.58579 14.3105 8.25 14.6463 8.25 15.0605C8.25 15.4748 8.58579 15.8105 9 15.8105Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </span>
                      </p>
                      <p>
                        ubicados en la esquina superior a la derecha.
                      </p>
                    </li>
                    <li>Presiona en la opción “Abrir en Chrome”.</li>
                    <li>Prueba iniciar sesión nuevamente con tu cuenta de {social}.</li>
                  </ol>
                </div>
                {soName !== '' && <hr className="w-full border-t border-solid border-black border-opacity-30" />}
              </>
            )
          }
          {
            (soName === 'iOS' || soName === '') && (
              <>
                <hr className="w-full border-t border-solid border-black border-opacity-30" />
                <div className="space-y-2">
                  <p className="font-bold uppercase text-sm">Dispositivos iOS</p>
                  <ol className="font-normal text-sm list-decimal pl-4">
                    <li>
                      <p className="flex items-center">
                        Presiona en los tres puntos
                        <span>
                          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-1">
                            <rect y="0.810547" width="18" height="18" rx="9" fill="#E3E3E3" />
                            <path d="M9 10.5605C9.41421 10.5605 9.75 10.2248 9.75 9.81055C9.75 9.39633 9.41421 9.06055 9 9.06055C8.58579 9.06055 8.25 9.39633 8.25 9.81055C8.25 10.2248 8.58579 10.5605 9 10.5605Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9 5.31055C9.41421 5.31055 9.75 4.97476 9.75 4.56055C9.75 4.14633 9.41421 3.81055 9 3.81055C8.58579 3.81055 8.25 4.14633 8.25 4.56055C8.25 4.97476 8.58579 5.31055 9 5.31055Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9 15.8105C9.41421 15.8105 9.75 15.4748 9.75 15.0605C9.75 14.6463 9.41421 14.3105 9 14.3105C8.58579 14.3105 8.25 14.6463 8.25 15.0605C8.25 15.4748 8.58579 15.8105 9 15.8105Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </span>
                      </p>
                      <p>
                        ubicados en la esquina superior a la derecha.
                      </p>
                    </li>
                    <li>Presiona en la opción “Abrir en navegador externo”.</li>
                    <li>Prueba iniciar sesión nuevamente con tu cuenta de {social}.</li>
                  </ol>
                </div>
                <hr className="w-full border-t border-solid border-black border-opacity-30" />
              </>
            )
          }
        </div>
      </section>
    </article>
  )
}