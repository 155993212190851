const Rank = () => (
  <svg width="30" height="34" viewBox="0 0 30 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="11.574" y="9.59766" width="6.99859" height="23.3967" rx="1.5" stroke="currentColor" />
    <rect x="1.23071" y="17.1624" width="6.99859" height="15.8323" rx="1.5" stroke="currentColor" />
    <rect x="21.9172" y="22.7893" width="6.99859" height="10.205" rx="1.5" stroke="currentColor" />
    <path d="M4.73005 8.16797L5.74042 10.3734L8.15018 10.6528L6.36487 12.2953L6.8438 14.6734L4.73005 13.4831L2.61629 14.6734L3.09523 12.2953L1.30992 10.6528L3.71967 10.3734L4.73005 8.16797Z" fill="currentColor" />
    <path d="M15.0733 0.479492L16.0837 2.68497L18.4934 2.96436L16.7081 4.60682L17.1871 6.98497L15.0733 5.79458L12.9596 6.98497L13.4385 4.60682L11.6532 2.96436L14.0629 2.68497L15.0733 0.479492Z" fill="currentColor" />
    <path d="M25.4164 14.1038L26.4268 16.3092L28.8366 16.5886L27.0513 18.2311L27.5302 20.6092L25.4164 19.4189L23.3027 20.6092L23.7816 18.2311L21.9963 16.5886L24.4061 16.3092L25.4164 14.1038Z" fill="currentColor" />
  </svg>

)

export default Rank;