import { QRCodeCanvas } from "qrcode.react"
import { useCallback, useEffect, useRef } from "react"
import idConfigTheme from "views/profile/views/profile-identification/id-config"

interface IProps {
  id: number
}

export default function CardId({ id }: IProps) {
  const refCanvas = useRef(null)

  const generateCard = useCallback(async () => {
    try {
      const canvasQR = document.getElementById('qr-id') as HTMLCanvasElement;
      const canvas = refCanvas.current;
      const context = canvas.getContext('2d');

      const backgroundImage = new Image();
      backgroundImage.crossOrigin = 'anonymous'
      backgroundImage.src = `${AZURE_BASE_URL}/card-qr-id.webp`;

      backgroundImage.onload = () => {
        context.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);
        const imageDataUrl = canvasQR.toDataURL();

        const borderRadius = 52;
        context.fillStyle = 'white';
        context.beginPath();
        context.moveTo(53 * 4 + borderRadius, 93 * 4);
        context.arcTo(53 * 4 + 220 * 4, 93 * 4, 53 * 4 + 220 * 4, 93 * 4 + 220 * 4, borderRadius);
        context.arcTo(53 * 4 + 220 * 4, 93 * 4 + 220 * 4, 53 * 4, 93 * 4 + 220 * 4, borderRadius);
        context.arcTo(53 * 4, 93 * 4 + 220 * 4, 53 * 4, 93 * 4, borderRadius);
        context.arcTo(53 * 4, 93 * 4, 53 * 4 + borderRadius, 93 * 4, borderRadius);
        context.closePath();
        context.fill();

        const image = new Image();
        image.crossOrigin = 'anonymous'
        image.src = imageDataUrl;

        image.onload = () => {
          context.drawImage(image, 69 * 4, 109 * 4, 188 * 4, 188 * 4);

          idConfigTheme[THEME](canvas, id)
        };
      }
    } catch (error) {
      console.error(error)
    }
  }, [id]);

  useEffect(() => {
    generateCard()
  }, [generateCard])

  return (
    <>
      <QRCodeCanvas id='qr-id' value={`${id}`} className='hidden' />
      <canvas ref={refCanvas} width={1296} height={1788} className='w-72 box-shadow-card-qr' />
    </>
  )
}