import { lazy, Suspense } from 'react'
import { Redirect, Route } from 'react-router-dom'
import LoadingScreen from 'components/loading-screen'
import RoutesWithNotFound from 'utilities/routes-with-not-found'
import { MemoramaProvider } from './context'
const List = lazy(() => import('./views/list'))
const GameMemorama = lazy(() => import('./views/game'))
const Error = lazy(() => import('./views/error'))
const Win = lazy(() => import('./views/win'))

const Memorama = () => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <MemoramaProvider>
        <RoutesWithNotFound>
          <Route path="/memorama" component={() => <Redirect to="/memorama/list" />} exact />
          <Route path="/memorama/list" component={List} exact />
          <Route path="/memorama/game" component={GameMemorama} exact />
          <Route path="/memorama/win" component={Win} exact />
          <Route path="/memorama/error/:error" component={Error} exact />
        </RoutesWithNotFound>
      </MemoramaProvider>
    </Suspense>
  )
}

export default Memorama