const firebaseConfig = {
  // @ts-ignore
  apiKey: `${FIREBASE_APIKEY}`,
  // @ts-ignore
  authDomain: `${FIREBASE_AUTHDOMAIN}`,
  // @ts-ignore
  projectId: `${FIREBASE_PROJECTID}`,
  // @ts-ignore
  storageBucket: `${FIREBASE_STORAGEBUCKET}`,
  // @ts-ignore
  messagingSenderId: `${FIREBASE_MESSAGINGSENDERID}`,
  // @ts-ignore
  appId: `${FIREBASE_APPID}`,
  // @ts-ignore
  measurementId: `${FIREBASE_MEASUREMENTID}`
}

export default firebaseConfig
