import Button from 'components/button'
import { useSession } from 'contexts/session-context'
import dayjs from 'dayjs'
import useMainClass from 'hooks/use-main-cass'
import { Link } from 'react-router-dom'
import textServices from 'services/text-services'
import { TextProfile, TextProfileSecondary } from 'views/profile/text.style'
import CONFIGS from 'build/Config'

const Information = () => {
  const [{ user }] = useSession()
  useMainClass('profile__information')
  const birthday = dayjs(user?.birthday).format('DD/MM/YY')

  return (
    <div className="font-bold text-2xl px-5 py-6 flex-1 flex flex-col break-all">
      <div className="flex-1">
        <TextProfile className='profile__label'>{textServices.findByText('profile/miInformation/name', { es: 'Nombre y apellido', en: 'First and last name' })}</TextProfile>
        <TextProfileSecondary className="font-normal dinPro text-lg normal-case mb-3 profile__response">
          {user?.firstName} {user?.lastName}
        </TextProfileSecondary>
        {!CONFIGS.notShowFields?.includes('nickName') ? (
          <>
            <TextProfile className='profile__label'>{textServices.findByText('profile/miInformation/nickname', { es: 'Apodo', en: 'Nickname' })}</TextProfile>
            <TextProfileSecondary className="font-normal dinPro text-lg normal-case mb-3 profile__response">
              {user?.nickName ? user?.nickName : 'Sin información'}
            </TextProfileSecondary>
          </>
        ) : null}
        <TextProfile className="mt-3 profile__label">{textServices.findByText('profile/miInformation/agegate', { es: 'Fecha de nacimiento', en: 'Date of birth' })}</TextProfile>
        <TextProfileSecondary className="font-normal dinPro text-lg normal-case profile__response">
          {(birthday !== 'Invalid Date' && birthday) ? birthday : 'Sin informacion'}
        </TextProfileSecondary>
        {user?.email && (
          <>
            <TextProfile className="mt-3 profile__label">Mail</TextProfile>
            <TextProfileSecondary className="font-normal dinPro text-lg normal-case profile__response">
              {user?.email}
            </TextProfileSecondary>
          </>
        )}
        <TextProfile className="mt-3 profile__label">{textServices.findByText('profile/miInformation/genre', { es: 'Género', en: 'Genre' })}</TextProfile>
        <TextProfileSecondary className="font-normal dinPro text-lg normal-case profile__response">
          {user?.genre || user?.genreId || 'Sin informacion'}
        </TextProfileSecondary>
      </div>
      {
        CONFIGS.profile?.notShowButtonInformation ? null : (
          <Link to={textServices.findByText('config/prizes', '/prizes')}>
            <Button>{textServices.findByText('profile/button', { es: 'IR A VER LOS PREMIOS', en: 'VIEW PRIZES' })}</Button>
          </Link>
        )
      }
    </div>
  )
}

export default Information
