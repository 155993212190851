import Button from "components/button"
import CardId from "components/card-id"

interface IProps {
  id: number
  setStepper: React.Dispatch<React.SetStateAction<"id" | "data" | 'email' | "phone" | "complete">>
}

export default function ViewId({ id, setStepper }: IProps) {

  return (
    <article className="w-full px-7 my-8 flex flex-col items-center flex-1 gap-4">
      <header>
        <h1 className="title medium text-center">Perfecto, ya tienes tu ID</h1>
      </header>
      <section className="flex-1 flex flex-col items-center justify-center gap-3">
        <CardId id={id} />
        <p className="description medium text-center">Para poder utilizar tu cuenta es necesario que valides tus datos</p>
      </section>
      <Button onClick={() => setStepper('data')}>Continuar</Button>
    </article>
  )
} 