import { YEAR_REGEXP } from 'constants/regexp'
import { useEffect, useState } from 'react';
import { calculateAge } from 'components/birthday-input/wordings'
import Cookies from 'js-cookie';
import dayjs from 'dayjs'
import useLocalStorage from './use-local-storage'

export function useAgegate() {
  const [value, setValue, , recheckValue] = useLocalStorage('agegate', {
    valid: 'false',
    age: null,
    minor: 'false'
  })
  const [age, setAge] = useState<string | null>(value.age)
  const [showAgegate, setShowAgegate] = useState<boolean>(value.valid === 'true')
  const [isMinor, setIsMinor] = useState<boolean>(value.minor === 'true')

  const setValues = (valid: string, age: string | null, minor: string) => {
    setAge(age)
    setIsMinor(minor === 'true')
    setShowAgegate(valid === 'true')
    setValue({ valid: valid, age: age, minor: minor })
  }

  const recheckAge = () => {
    const value = recheckValue()
    setValues(value.valid, value.age, value.minor)
  }

  const parsedDate = (day?: string, month?: string, year?: string) => {
    const parsedYear = parseInt(`${year}`, 10)
    const parsedMonth = parseInt(month, 10)
    const parsedDay = parseInt(day, 10)
    const monthDays = new Date(parsedYear, parsedMonth, 0).getDate()
    const newDate = new Date(parsedYear, parsedMonth - 1, parsedDay)
    const isValid =
      !(!day || !month || !year) && parsedDay > 0 && parsedDay <= monthDays && parsedMonth > 0 && parsedMonth <= 12
    const isValidDate = !isNaN(newDate as unknown as number)
    return { newDate, isValid, isValidDate }
  }

  const setAgeLocal = (day?: string, month?: string, year?: string) => {
    if (!day || !month || !year) return setValues('true', null, 'false')
    const { newDate, isValid, isValidDate } = parsedDate(day, month, year)
    if (!isValid || !isValidDate) {
      alert('Fecha invalida')
      return 'Fecha invalida'
    }
    const isLegal = calculateAge(newDate) >= 18
    if (!isLegal) return setValues('true', dayjs(newDate).format('DD-MM-YY'), 'true')
    setValues('true', dayjs(newDate).format('DD-MM-YY'), 'false')
  }

  const setAgeLocalForm = (birthday: Date) => {
    const isLegal = calculateAge(birthday) >= 18
    if (!isLegal) return { error: 'Tienes que ser mayor de edad' }
    setValues('true', dayjs(birthday).format('DD-MM-YY'), 'false')
    return { error: null }
  }

  const removeAge = () => setValues('false', null, 'false')
  const getAgeData = () => value
  const saveAgeData = (data) => {
    setValue(data)
  }

  return { age, showAgegate, isMinor, setAgeLocal, removeAge, recheckAge, setAgeLocalForm, getAgeData, saveAgeData }
}

export function useAgegateConfig() {

  const { removeAge, showAgegate, setAgeLocal } = useAgegate()

  const DD_MM_YYYY = (date: string): Date => {
    const format = date.replace(/-/g, '/')
    const [day, month, years] = format.split('/');
    const age = new Date(+years, month as any - 1, +day)
    return age
  }

  const DATE = (date: string): Date => {
    return new Date(date)
  }

  const format = (type: string, date: string): Date => {
    const functions = {
      'DD-MM-YYYY': DD_MM_YYYY,
      'DATE': DATE
    }
    return functions[type](date)
  }

  useEffect(() => {
    if (showAgegate) return 
    const local = localStorage.getItem(AGE_GATE_COOKIE) || sessionStorage.getItem(AGE_GATE_COOKIE) || Cookies.get(AGE_GATE_COOKIE)
    if (!local) return removeAge()
    const age = format(AGE_GATE_FORMAT, local)
    const year = age.getFullYear().toString().replace(YEAR_REGEXP, '')
    setAgeLocal(age.getDate().toString(), (age.getMonth() + 1).toString(), year)
  },[])

}