import styled from "styled-components";
import { Type } from 'theme/types';
import { Text, Title } from "theme/theme";

export const TitleLogin = styled(Title)`
  ${({ theme }: Type) => theme?.login?.text?.title}
`

export const TitleRecovery = styled(Title)`
  ${({ theme }: Type) => theme?.login?.text?.titleRecovery}
`

export const TextRecovery = styled(Text)`
  ${({ theme }: Type) => theme?.login?.text?.textRecovery}
`

export const TextResend = styled(TextRecovery)`
  ${({ theme }: Type) => theme?.login?.text?.textResend}
`

export const BackBtn = styled.p`
  ${({ theme }: Type) => theme?.login?.text?.backBtn}
`