import { lazy, Suspense } from 'react'
import { Redirect, Route } from 'react-router-dom'
import RoutesWithNotFound from 'utilities/routes-with-not-found'
import { useSurveyRedirect } from 'contexts/survey-context/useSurvey'
import { SurveyTypeEnum } from 'contexts/survey-context/survey.interface'
import { AlbumProvider } from './context'
import { RoutesAlbumDefinitions } from './configs/routes/definitions'
const Start = lazy(() => import('./views/start'))
const Error = lazy(() => import('./views/error'))
const Congrats = lazy(() => import('./views/congrats'))
const RedirectView = lazy(() => import('./views/redirect'))

const Album = () => {
  useSurveyRedirect(SurveyTypeEnum.VOTE)

  return (
    <Suspense fallback={<>Cargando</>}>
      <AlbumProvider>
        <RoutesWithNotFound>
          <Route path="/album" component={() => <Redirect to="/album/start" />} exact />
          <Route path="/album/start" component={Start} exact />
          <Route path="/album/error" component={Error} exact />
          <Route path="/album/congrats" component={Congrats} exact />
          <Route path="/album/redirect/:rallyType/:url" component={RedirectView} exact />
          {
            RoutesAlbumDefinitions.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                component={route.component}
                exact={route.exact}
              />
            ))
          }
        </RoutesWithNotFound>
      </AlbumProvider>
    </Suspense>
  )
}

export default Album
