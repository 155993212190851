import Button from "components/button"
import styled from 'styled-components'
import { Type, HeaderType } from '../types'
export const HeaderST = styled.header.attrs((props: HeaderType) => ({
  open: props.open,
  theme: props.theme
})) <HeaderType>`
    background-color: ${(props: HeaderType) => props.open ? props.theme?.header?.headerBackground : props?.theme?.header?.headerBackgroundClose};
`

export const ButtonMenu = styled.button.attrs((props: HeaderType) => ({
  open: props.open,
  theme: props.theme
})) <HeaderType>`
    background-color: ${(props: HeaderType) => props.open ? props.theme?.header?.buttonOpen : props.theme?.header?.button};
`

export const SpanMenu = styled.span.attrs((props: HeaderType) => ({
  open: props.open,
  theme: props.theme
})) <HeaderType>`
    background-color: ${({ theme, open }: HeaderType) => open ? theme?.header?.spansButtonVariation : theme?.header?.spansButton};
`

export const SpanMenuAlternative = styled.span.attrs((props: HeaderType) => ({
  open: props.open,
  theme: props.theme
})) <HeaderType>`
    background-color: ${({ theme, open }: HeaderType) => open ? theme?.header?.spansButtonVariation : theme?.header?.spansButtonSecondary};
`

export const Menu = styled.div`
  top: 2.95rem;
  background-color: ${({ theme }: Type) => theme?.header?.menuBackground};
  color: ${({ theme }: Type) => theme?.header?.menuText};
  ${({ theme }: Type) => `
    ${theme?.header?.useTexture && `background:
      ${!theme?.header?.dontUseTextureBg ? `url(${AZURE_BASE_URL}/textura.${theme?.loading?.config?.bgTexture || 'svg'}), ` : ''}
      url(${AZURE_BASE_URL}/logo-outline-menu.svg) no-repeat right center, ${theme?.header?.menuBackground};
    `}
  `};
  ${({ theme }: Type) => theme?.header?.backgroundRest};
`


export const Logo = styled.img`
  width: ${(props: Type) => props.theme?.imagesConfig?.logo};
  padding: ${(props: Type) => props.theme?.header?.paddingLogo};
`

export const Points = styled.p`
  color: ${({ theme }: Type) => theme?.header?.points};
`

export const ModalMessage = styled.h1`
  ${({ theme }: Type) => theme?.trivias?.modalMessage};
`

export const ModalDescription = styled.h1`
  ${({ theme }: Type) => theme?.trivias?.modalDescription};
`

export const ModalCancel = styled.button`
  ${({ theme }: Type) => theme?.trivias?.modalCancel};
`
export const ModalCancelP = styled.p`
  ${({ theme }: Type) => theme?.trivias?.modalCancelP};
`

export const PointTextStyle = styled.p`
  ${({ theme }: Type) => theme?.header?.pointText};
`

export const ButtonModalAccept = styled(Button)`
  ${({ theme }: Type) => theme?.trivias?.buttonModalAccept};
`

export const ButtonModalAcceptP = styled.p`
  ${({ theme }: Type) => theme?.trivias?.buttonModalAcceptP};
`

export const ImgMenu = styled.img`
  ${({ theme }: Type) => theme?.header?.ImgMenu};
`