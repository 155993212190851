import { Collection, Sticker } from '../types'

export type CollectionsActionType = 'SET_COLLECTIONS' | 'SET_SELECT_STICKER' | 'SET_ERROR' | 'SET_CONGRATS'

export interface CollectionState {
  collections?: Collection[]
  selectPrize?: Sticker
  error?: number
  congrats?: boolean
}

export interface CollectionsAction {
  type: CollectionsActionType
  payload: CollectionState | Collection[] | Collection | Sticker | number  | boolean
}

function collectionReducer(state: CollectionState, action: CollectionsAction): CollectionState {
  switch (action.type) {
  case 'SET_COLLECTIONS':
    return { ...state, collections: action.payload as Collection[] }
  case 'SET_SELECT_STICKER':
    return { ...state, selectPrize: action.payload as Sticker }
  case 'SET_CONGRATS':
    return { ...state, congrats: action.payload as boolean }  
  case 'SET_ERROR':
    return { ...state, error: action.payload as number }  
  default:
    return state
  }
}

export default collectionReducer