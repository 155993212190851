import { useMemo, VoidFunctionComponent } from 'react'
import Button from 'components/button'
import ButtonCloseMenu from 'components/button-close'
import { useHistory } from 'react-router-dom'
import useMainClass from 'hooks/use-main-cass'
import textServices from 'services/text-services'
import { useTrivia } from 'contexts/trivia-context'
import dayjs from 'dayjs'
import Countdown from 'react-countdown'
import { DivModalContinue, ButtonModalContinue, TriviaModalH1, TriviaModelP, TriviaModalWarning } from '../../../theme/trivias'

export interface TriviaModalProps {
  onAccept: () => void
}

const TriviaModal: VoidFunctionComponent<TriviaModalProps> = ({ onAccept }) => {
  useMainClass('trivias__popup__intro')

  const history = useHistory()
  const [{ currentTrivia }] = useTrivia()

  const date = useMemo(() => {
    let dateCountdown: Date
    let countdownType: string
    const nowDate = dayjs(new Date())
    if (currentTrivia.validFrom) {
      const validFrom = new Date(currentTrivia.validFrom as string)
      const date = dayjs(validFrom)
      if (nowDate <= date) {
        countdownType = textServices.findByText('trivias/list/card/start', { es: 'Comienza en', en: 'Starts at' })
        dateCountdown = date.toDate()
      }
    }

    if (currentTrivia.validTo && !countdownType) {
      const validTo = new Date(currentTrivia.validTo as string)
      const date = dayjs(validTo)
      if (nowDate <= date) {
        countdownType = textServices.findByText('trivias/list/card/end', { es: 'Finaliza en', en: 'Ends at' })
        dateCountdown = date.toDate()
      }
    }

    return { dateCountdown, countdownType }
  }, [currentTrivia])

  return (
    <DivModalContinue className="flex flex-col w-full p-8 min-h-view">
      <ButtonCloseMenu paramFn={history.goBack} open={true} />
      <div className="flex-1 flex items-center justify-center flex-col gap-4 popup__intro-body">
        {currentTrivia?.imageUrl ? <img src={currentTrivia?.imageUrl ?? ''} alt={currentTrivia?.title} className='popup__intro-img' /> : <></>}
        <TriviaModalH1 className="uppercase text-3xl text-center font-bold popup__intro-title">{currentTrivia?.title}</TriviaModalH1>
        <div className='w-full grid grid-cols-2 justify-items-center gap-2 popup__intro-information'>
          <div className='w-full flex flex-col items-center'>
            <p className='text small'>{date?.countdownType}:</p>
            <p className='border border-solid w-full px-3 py-2 flex items-center justify-center rounded-sm description medium'><b><Countdown date={date?.dateCountdown} /></b></p>
          </div>
          <div className='w-full flex flex-col items-center'>
            <p className='text small'>{textServices.findByText('trivias/challenge/modal/value', { es: 'Valor:', en: 'Value:' })}</p>
            <p className='border border-solid w-full px-3 py-2 flex items-center justify-center rounded-sm description medium'><b>{currentTrivia?.costInPoints > 0 ? `${currentTrivia?.costInPoints} ${POINTS_NAME}` : textServices.findByText('trivias/challenge/modal/free', { es: 'GRATIS', en: 'FREE' })}</b></p>
          </div>
        </div>
        <TriviaModelP className="din text-center popup__intro-description">
          {currentTrivia?.costInPoints > 0 && (
            textServices.findByText('tivias/challenge/modal/intro', { es: <>Estás a punto de canjear {currentTrivia?.costInPoints} {POINTS_NAME} para participar en esta trivia y ganar:</>, en: <>You are about to redeem {currentTrivia?.costInPoints} {POINTS_NAME} to participate in this trivia and win:</> })

          )}
          {currentTrivia?.costInPoints === 0 && (
            textServices.findByText('tivias/challenge/modal/intro2', { es: <>Estás a punto de participar en esta trivia y ganar:</>, en: <>You are about to participate in this trivia and win:</> })
          )}
          <b> {currentTrivia?.prize}</b>
        </TriviaModelP>
        <TriviaModalWarning className="din text-center popup__intro-warning">
          {textServices.findByText('tivias/challenge/modal/description', { es: '¿Segurísimo que quieres jugarla? Recuerda que una vez que des clic no hay vuelta atrás.', en: 'Are you sure you want to play it? Remember that once you click there is no turning back.' })}
        </TriviaModalWarning>
      </div>

      <div className="flex justify-center flex-col pt-3">
        <Button onClick={onAccept}>
          {textServices.findByText('tivias/challenge/modal/button', { es: 'Sí, claro', en: 'Yes, of course' })}
        </Button>

        <ButtonModalContinue className="uppercase underline text-xs din text-center mt-4 button-back" onClick={history.goBack}>
          {textServices.findByText('backButton', { es: 'VOLVER', en: 'BACK TO' })}
        </ButtonModalContinue>
      </div>
    </DivModalContinue>
  )
}

export default TriviaModal
