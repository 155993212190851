import { GET_MENU } from 'constants/api';
import { createContext, Dispatch, ReactNode, useReducer, useContext, useCallback } from 'react';
import axios from 'axios';
import { IMenu, menuOffline } from 'components/header/types';
import { useSession } from '../session-context';
import { GeneralState, GeneralActions, generalReducer, initialState, GeneralActionsType } from './general-reducer';

export interface GeneralContextProps {
  state: GeneralState;
  dispatch: Dispatch<GeneralActions>;
}

export const GeneralContext = createContext<GeneralContextProps>({} as GeneralContextProps);

interface GeneralProviderProps {
  children: ReactNode;
}

export const GeneralProvider = ({ children }: GeneralProviderProps) => {
  const [state, dispatch] = useReducer(generalReducer, initialState);

  return (
    <GeneralContext.Provider value={{ state, dispatch }}>
      {children}
    </GeneralContext.Provider>
  );
};

export const useGeneralCfg = () => {
  const { state, dispatch } = useContext(GeneralContext);
  const [{ data: session }] = useSession();

  const getMenu = useCallback(async () => {
    try {
      const response = await axios(GET_MENU, {
        headers: {
          Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`,
          'Ph-Api-Id': PH_API_ID ?? 1,
        },
      });

      if (response.status === 204) {
        dispatch({
          type: GeneralActionsType.SET_MENU,
          payload: menuOffline
        });
      }

      if (response.data) {
        dispatch({
          type: GeneralActionsType.SET_MENU,
          payload: response.data,
        });
        return response.data;
      }

      return response.data;
    } catch (error) {
      console.error(error);
      dispatch({
        type: GeneralActionsType.SET_MENU,
        payload: menuOffline
      });
    }
  }, [dispatch, session]);

  return {
    menu: state.menu,
    setMenu: (menu: IMenu | null) => dispatch({ type: GeneralActionsType.SET_MENU, payload: menu }),
    getMenu,
  };
};


