import { Type } from 'theme/types';
import styled from "styled-components";
import { Text, Title } from "theme/theme";

export const TitleEmail = styled(Title)`
  ${({ theme }: Type) => theme?.email?.text?.title}
`

export const TextEmail = styled(Text)`
  ${({ theme }: Type) => theme?.email?.text?.text}
`