import Button from 'components/button'
import PasswordRecovery from 'components/form-steps/password-recovery'
import { LoaderContext } from 'contexts/loader-context'
import useMainClass from 'hooks/use-main-cass'
import { useContext, useEffect } from 'react'
import textServices from 'services/text-services'
import { TextRecovery, TextResend, TitleRecovery } from 'views/login/text.style'

interface IProps {
  isSignUp: boolean,
  recoverPassword: React.MouseEventHandler<HTMLButtonElement>
  mailSent: boolean,
  setMailSent: any
}

function MailSentPassword({ isSignUp, mailSent, recoverPassword, setMailSent }: IProps) {
  useMainClass('login__mail-sent-password')

  const { setFooter } = useContext(LoaderContext)


  useEffect(() => {
    setFooter(false)
    return () => setFooter(true)
  }, [])


  return (
    <article className="flex flex-col flex-1 md:pt-10">
      <section className="flex h-full justify-between flex-col gap-4 mx-7 mb-7 text-center">
        <div className="flex flex-1 flex-col gap-4 items-center justify-center">
          <TitleRecovery className="text-center text-5xl font-bold uppercase login__mail-sent-password__title">{textServices.findByText('email/title', { es: '¿Olvidaste tu contraseña?', en: 'Forgot your password?' })}</TitleRecovery>
          <TextRecovery className="text-center text-base font-normal uppercase saira login__mail-sent-password__description">
            {textServices.findByText('email/recovery', { es: 'Ya te enviamos el mail con las instrucciones para resetear tu contraseña. Revisa tus correos para poder volver a ingresar.', en: 'We have already sent you the email with the instructions to reset your password. Please check your emails to be able to log in again.' })}
          </TextRecovery>
          <div>
            <TextResend className="text-center text-base uppercase saira font-bold login__mail-sent-password__subtitle">
              {textServices.findByText('email/send', { es: '¿No te llegó ningún mail?', en: 'You did not receive any email?' })}
            </TextResend>
            <TextRecovery className="text-center text-base font-normal uppercase saira login__mail-sent-password__description 2">
              {textServices.findByText('email/resend', { es: 'Intentémoslo de nuevo.', en: "Let's try it again." })}
            </TextRecovery>
          </div>
          <div className='login__mail-sent-password__recovery'>
            <PasswordRecovery shouldEnable={!isSignUp} recoverPassword={recoverPassword} mailSent={mailSent} />
          </div>
        </div>
        <Button
          onClick={() => {
            gtag('event', 'Click', {
              event_category: GTM_EVENT_CATEGORY,
              event_label: 'MODIFICAR INFORMACION - Volver atras',
              value: '10'
            })
            setMailSent(null)
          }}
        >
          <p className="uppercase">
            {textServices.findByText('backButton', { es: 'VOLVER', en: 'BACK TO' })}
          </p>
        </Button>
      </section>
    </article>
  )
}

export default MailSentPassword