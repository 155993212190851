import Button from 'components/button'
import textServices from 'services/text-services'
import useMainClass from 'hooks/use-main-cass'
import { ContainerSurveyQr, DescriptionSurveyQr, ImgSurveyQr, TitleSurveyQr } from '../styles/styled.components'

function SurveyOnBoarding({ handleClick }: { handleClick: () => void }) {
  useMainClass('trivia-qr__pop-up')
  useMainClass('survey-qr__pop-up')

  return (
    <ContainerSurveyQr className="flex-1 w-full flex flex-col gap-6 py-7">
      {textServices?.findByText('survey/onboarding/html', <div className="w-full flex flex-col gap-6 px-7 flex-1">
        <div className="flex-1 w-full flex flex-col items-center justify-center gap-6 text-center">
          <TitleSurveyQr>{textServices?.findByText('survey/onboarding/title', { es: <>CONTESTA<br />Y GANA</>, en: <>ANSWER<br />AND WIN</> })}</TitleSurveyQr>
          <DescriptionSurveyQr className="flex flex-col items-center justify-center gap-5">{textServices?.findByText('survey/onboarding/description', { es: <><b>¿Ya tienes tu ID?</b>Si no lo tienes córrele a registrarte para poder participar</>, en: <><b>Do you already have your ID?</b> If you don&apos;t have it, please register to participate.</> })}</DescriptionSurveyQr>
        </div>
        <ImgSurveyQr src={`${AZURE_BASE_URL}/access-qr-card-id.${textServices.findByText('survey/onboarding/typeImage', 'webp')}`} alt="card id" />
      </div>)}
      <div className="w-full px-7">
        <Button onClick={handleClick}>{textServices?.findByText('survey/onboarding/button', { es: 'COMENCEMOS', en: 'START' })}</Button>
      </div>
    </ContainerSurveyQr>
  )
}

export default SurveyOnBoarding