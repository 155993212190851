import Button from 'components/button'
import { LoaderContext } from 'contexts/loader-context'
import useMainClass from 'hooks/use-main-cass'
import { useContext, useEffect } from 'react'
import { useLocalStorage } from 'react-use'
import { TextEmail, TitleEmail } from 'views/mail-verify/text.style'

interface IProps {
  handleLogin: () => Promise<void> | void
}

function AlreadyParticipate({ handleLogin }: IProps) {

  useMainClass('sign-up__done')
  const [, setFlowType] = useLocalStorage('flowType')

  const { setFooter } = useContext(LoaderContext)

  useEffect(() => {
    setFooter(false)
    return () => setFooter(true)
  }, [])

  return (
    <section className='w-full flex-1 flex flex-col items-center gap-4 p-7'>
      <div className='flex-1 flex flex-col items-center justify-center gap-6 w-full'>
        <TitleEmail className="text-center text-5xl font-bold uppercase mx-7 overflow-ellipsis overflow-hidden w-full sign-up__done__title">¡YA ESTÁS PARTICIPANDO!</TitleEmail>
        <TextEmail className="din text-center mt-4 sign-up__done__description">Felicitaciones has confirmado con éxito tu cuenta. Puedes descargar tu TECATE ID para llevarlo en tu dispositivo. </TextEmail>
      </div>
      <Button className='uppercase' onClick={() => {
        setFlowType(null)
        handleLogin()
      }}>DESCARGA TU TECATE ID</Button>
    </section>
  )
}

export default AlreadyParticipate;