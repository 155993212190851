import { ALBUM_GET, ALBUM_GET_ID, ALBUM_VOTE_MULTIMEDIA, ALBUM_VALIDATE, ALBUM_GET_PROFILE } from 'constants/api'
import useAxios, { RefetchOptions } from 'axios-hooks'
import { useSession } from 'contexts/session-context'
import { createContext, Dispatch, FunctionComponent, useContext, useEffect, useReducer } from 'react'
import { LoaderContext } from 'contexts/loader-context'
import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'
import useRouter from 'hooks/use-router'
import AlbumConfig from '../configs/album-config'
import { Album, Multimedia } from '../types'
import albumReducer, { AlbumAction, AlbumState } from './album-reducer'

export interface AlbumContextProps {
  state: AlbumState
  dispatch: Dispatch<AlbumAction>
  refetch: (config?: AxiosRequestConfig, options?: RefetchOptions) => AxiosPromise<Album[]>
}

const AlbumContext = createContext<AlbumContextProps>({} as AlbumContextProps)

export const AlbumProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(albumReducer, { showRules: false })
  const { setLoad } = useContext(LoaderContext)
  const [{ data: session }] = useSession()
  const [{ data = [], loading }, refetch] = useAxios<Album[]>({
    url: ALBUM_GET,
    headers: { Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`, 'Ph-Api-Id': PH_API_ID ?? 1 }
  }, { useCache: false })
  useEffect(() => {
    setLoad(loading)
    return () => {
      setLoad(false)
    }
  }, [loading])

  useEffect(() => {
    if (data && !loading) {
      dispatch({ type: 'SET_ALBUMS', payload: data })
    }
  }, [data])

  return <AlbumContext.Provider value={{ state, dispatch, refetch }}>{children}</AlbumContext.Provider>
}

export const useAlbum = () => {
  const { state, dispatch, refetch } = useContext(AlbumContext)
  const { push } = useRouter()
  const [{ data: session }] = useSession()

  const fetchAlbums = async () => {
    const { data = [] } = await axios.get<Album[]>(ALBUM_GET, {
      headers: {
        Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`,
        'Ph-Api-Id': PH_API_ID ?? 1
      }
    });
    dispatch({ type: 'SET_ALBUMS', payload: data });
    return data;
  }

  const getMultimedia = async (id: number) => {
    const { data } = await axios.get(`${ALBUM_GET_ID}${id}`, {
      headers: { Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`, 'Ph-Api-Id': PH_API_ID ?? 1 }
    })
    const config = new AlbumConfig(data)
    dispatch({ type: 'SET_SELECT_MULTIMEDIA', payload: data })
    dispatch({ type: 'SET_ALBUM_CONFIG', payload: config })
    return config
  }

  const getMultimediaProfile = async (id: number, multimediaOutput: 'url' | 'base64') => {
    const { data } = await axios.get(`${ALBUM_GET_PROFILE}?id=${id}&multimediaOutput=${multimediaOutput}`, {
      headers: { Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`, 'Ph-Api-Id': PH_API_ID ?? 1 }
    })
    const config = new AlbumConfig(data)
    dispatch({ type: 'SET_SELECT_MULTIMEDIA_PROFILE', payload: data })
    dispatch({ type: 'SET_ALBUM_CONFIG', payload: config })
    return config
  }

  const voteMultimedia = async (rallyMultimediaId: number) => {
    await axios.post(`${ALBUM_VOTE_MULTIMEDIA}?rallyMultimediaId=${rallyMultimediaId}`, {}, {
      headers: { Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`, 'Ph-Api-Id': PH_API_ID ?? 1 }
    })
  }

  const setShowRules = (showRules: boolean) => dispatch({ type: 'SET_SHOW_RULES', payload: showRules })

  const setAlbum = (album: Album[]) => dispatch({ type: 'SET_ALBUMS', payload: album })

  const refetchAlbum = (config?: AxiosRequestConfig) => refetch(config)

  const setSelectAlbum = (multimedia?: Multimedia) => dispatch({ type: 'SET_SELECT_MULTIMEDIA', payload: multimedia })

  const setError = (error: number) => {
    dispatch({ type: 'SET_ERROR', payload: error })
    if (error) return push('/album/error')
  }

  const validateUpload = async (rallyId: number) => {
    const response = await axios.post(ALBUM_VALIDATE.replace('{ID}', `${rallyId}`), {}, {
      headers: { Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`, 'Ph-Api-Id': PH_API_ID ?? 1 }
    })

    return response
  }

  const actions = { setAlbum, refetchAlbum, setSelectAlbum, setError, getMultimedia, voteMultimedia, getMultimediaProfile, validateUpload, setShowRules, fetchAlbums }

  const returnValue: [typeof state, typeof actions] = [state, actions]
  return returnValue
}
