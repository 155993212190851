

const SolMezclasId = () => {
  return (
    <img src={`${AZURE_BASE_URL}/boton_id.webp`} className='w-full' />
  )
}

export default SolMezclasId

