import { Suspense, useEffect } from 'react'
import { Route } from 'react-router-dom'
import RoutesWithNotFound from 'utilities/routes-with-not-found'
import { useSurveyRedirect } from 'contexts/survey-context/useSurvey'
import { SurveyTypeEnum } from 'contexts/survey-context/survey.interface'
import useRouter from 'hooks/use-router'
import { PhotographerProvider } from './context'
import Start from './views/start'
import Winners from './views/winners'
import Upload from './views/upload'
import Error from './views/error'

function Photographer() {

  const { pathname, history } = useRouter()
  useSurveyRedirect(SurveyTypeEnum.RALLY)

  useEffect(() => {
    if (pathname === '/photographer') return history.push('/photographer/start')
  }, [pathname])

  return (
    <Suspense fallback={<>Cargando</>}>
      <PhotographerProvider>
        <RoutesWithNotFound>
          <Route path="/photographer/start" component={Start} exact />
          <Route path="/photographer/upload" component={Upload} exact />
          <Route path="/photographer/winners" component={Winners} exact />
          <Route path="/photographer/error" component={Error} exact />
        </RoutesWithNotFound>
      </PhotographerProvider>
    </Suspense>
  )
}

export default Photographer