import { GET_LEVELS } from "constants/api"
import { useEffect, useState } from "react"
import { useSession } from "contexts/session-context"
import axios from "axios"
import Accordion from "./components/accordion"
import { TitleProgress } from "./styles/custom.styles"

const Progress = () => {

  const [{ user }] = useSession()

  const [levels, setLevels] = useState([])

  useEffect(() => {
    axios.get(GET_LEVELS).then((res) => {
      setLevels(res.data)
    })
  }, [])

  return (
    <div>
      <TitleProgress>PROGRESO</TitleProgress>
      {
        levels?.map((level, i) => (
          <Accordion level={level} key={i} userPoints={user?.level?.id} />
        ))
      }
    </div>
  )
}

export default Progress