import { WALLET_CREATE } from 'constants/api'
import textServices from 'services/text-services'
import { useSession } from 'contexts/session-context'
import Button from 'components/button'
import GoogleWallet from 'components/icon/google-wallet'
import IOSWallet from 'components/icon/ios-wallet'
import useMainClass from 'hooks/use-main-cass'
import { useContext, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import useRouter from 'hooks/use-router'
import { LoaderContext } from 'contexts/loader-context'
import { dlTrackEvent } from 'components/dataLayer/dataLayer'
import { isAndroid, isIOS } from 'utilities/detectDeviceAgent'
import { QRCodeCanvas } from 'qrcode.react'
import { BackBtn } from 'views/login/text.style'
import CONFIGS from 'build/Config'
import { PointsIdContainer, PointsIdName, PointsIdNumber } from './styles'
import idConfigTheme from './id-config'
import InstructionPopUp from './instruction-pop-up'

const Identification = () => {
  const [{ user, data: session }] = useSession()
  useMainClass('profile__identification')
  const { setLoad } = useContext(LoaderContext)
  const [status, setStatus] = useState('' as any)
  const refCanvas = useRef(null)
  const [isOpenPopUp, setIsOpenPopUp] = useState(false)
  const [urlWallet, setUrlWallet] = useState('')
  const soName = isAndroid() ? 'Android' : (isIOS() ? 'iOS' : '')
  const { push } = useRouter()

  const handleDownload = async () => {
    const canvas = refCanvas.current
    const dataURL = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
    const a = document.createElement('a')
    a.href = dataURL
    a.download = 'my-id.png'
    a.click()
  }

  const getStatus = async () => {
    if (isAndroid() || isIOS()) {
      setLoad(true)
      try {
        // await axios.get(WALLET_STATUS.replace(':type', soName), {
        //   headers: {
        //     Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`,
        //     'Ph-Api-Id': PH_API_ID ?? 1
        //   }
        // })

        const response = await axios.post(WALLET_CREATE.replace(':type', soName), null, {
          headers: {
            Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`,
            'Ph-Api-Id': PH_API_ID ?? 1
          }
        })
        const urlToOpen = response?.data?.wallets?.find((item) => item?.walletSOType === soName)?.url
        if (urlToOpen)
          setUrlWallet(urlToOpen)

        setStatus(soName.toLowerCase())
      } catch (err) {
        console.error(err)
      } finally {
        setLoad(false)
      }
    }
  }

  const createWallet = async () => {
    dlTrackEvent('user_interaction', {
      action: 'click',
      element: `Agregar a ${soName === 'Android' ? 'Google' : 'Apple'} Wallet`,
      section: 'ID :: Wallet'
    })
  }

  useEffect(() => {
    if (!status && session) getStatus()
  }, [session])

  const themes = [
    {
      theme: 'miller',
      style: {
        strokeStyle: '#B98B39',
        lineWidth: 25,
        borderRadius: 0
      }
    }
  ]

  const themeIndex = (arr: any[]) => {
    return arr.findIndex(item => item.theme === THEME)
  }

  useEffect(() => {
    if (user?.id && idConfigTheme[THEME]) {
      const canvasQR = document.getElementById('qr-id') as HTMLCanvasElement
      const canvas = refCanvas.current
      const context = canvas.getContext('2d')

      const backgroundImage = new Image()
      backgroundImage.crossOrigin = 'anonymous'
      backgroundImage.src = `${AZURE_BASE_URL}/card-qr-id.webp`

      backgroundImage.onload = () => {
        context.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height)
        const imageDataUrl = canvasQR.toDataURL()

        const currentTheme = themes[themeIndex(themes)]
        const borderRadius = currentTheme ? currentTheme?.style?.borderRadius : 52

        context.fillStyle = 'white'
        context.beginPath()
        context.moveTo(53 * 4 + borderRadius, 93 * 4)
        context.arcTo(53 * 4 + 220 * 4, 93 * 4, 53 * 4 + 220 * 4, 93 * 4 + 220 * 4, borderRadius)
        context.arcTo(53 * 4 + 220 * 4, 93 * 4 + 220 * 4, 53 * 4, 93 * 4 + 220 * 4, borderRadius)
        context.arcTo(53 * 4, 93 * 4 + 220 * 4, 53 * 4, 93 * 4, borderRadius)
        context.arcTo(53 * 4, 93 * 4, 53 * 4 + borderRadius, 93 * 4, borderRadius)
        context.closePath()
        context.fill()

        // custom style QR
        if (currentTheme?.style?.strokeStyle && currentTheme?.style?.lineWidth) {
          const { strokeStyle, lineWidth } = currentTheme.style
          context.strokeStyle = strokeStyle
          context.lineWidth = lineWidth
          context.stroke()
        }

        const image = new Image()
        image.crossOrigin = 'anonymous'
        image.src = imageDataUrl

        image.onload = () => {
          context.drawImage(image, 69 * 4, 109 * 4, 188 * 4, 188 * 4)

          idConfigTheme[THEME](canvas, user?.id)
        }
      }
    }
  }, [user?.id])

  const handleOpenPopUp = () => {
    setIsOpenPopUp(!isOpenPopUp)
  }

  return (
    <div
      className={`font-bold text-2xl pb-7 flex-1 flex flex-col break-all ${CONFIGS?.showPointsId ? 'justify-between' : 'justify-evenly'}`}
    >
      {isOpenPopUp && <InstructionPopUp setPopUp={handleOpenPopUp} />}
      {CONFIGS?.showPointsId && (
        <PointsIdContainer>
          <PointsIdName>{POINTS_NAME}</PointsIdName>
          <PointsIdNumber>{user?.totalPoints}</PointsIdNumber>
        </PointsIdContainer>
      )}
      <div className="flex flex-col gap-4 items-center py-5">
        <QRCodeCanvas id="qr-id" value={`${user?.id}`} className="hidden" />
        <canvas ref={refCanvas} width={1296} height={1788} className="w-72 box-shadow-card-qr" />
        <div className="w-full px-5 max-w-sm flex flex-col gap-4">
          {status && (
            <>
              {urlWallet && (
                <>
                  <a href={urlWallet}
                    onClick={createWallet}
                    className={`${status === 'android' ? 'google-wallet' : ''} w-full flex items-center justify-center`}
                  >
                    {status === 'android' && <GoogleWallet />}
                    {status === 'ios' && <IOSWallet />}
                  </a>
                  <BackBtn className={`din text-center mt-3 hover:text-red-100 uppercase underline button-back`} style={{ fontSize: 10 }} onClick={() => push('/help')}>
                    {textServices.findByText('profile/identification/dude', { es: '¿Tienes alguna duda?', en: 'Do you have any doubts?' })}
                  </BackBtn>
                </>
              )}
            </>
          )}
          {(!status) && (
            <>
              <Button
                className={`mixx-button w ${!window.navigator.share ? 'col-span-2' : ''}`}
                onClick={handleDownload}
              >
                {textServices.findByText('profile/identification/button', { es: 'DESCARGAR MI ID', en: 'DOWNLOAD MY ID' })}
              </Button>
            </>
          )}
        </div>
      </div>
      <div className="px-5 space-y-3 max-w-sm"></div>
    </div>
  )
}

export default Identification
