import { SVGProps } from 'react'

function Check(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0.5 4.5L5.5 9.5L14 1" stroke="currentColor" />
    </svg>

  )
}

export default Check