import useMainClass from 'hooks/use-main-cass'
import PopUp from 'components/popUps'
import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import Button from 'components/button'
import { LoaderContext } from 'contexts/loader-context'
import { Link, useLocation, useHistory } from 'react-router-dom';
import { ClientContext } from 'contexts/client-num-context';

interface IProps {
  setError: Dispatch<SetStateAction<boolean>>;
  isError: boolean;
}

const PopupClient = ({ isError = false, setError}: IProps) => {
  useMainClass('album__multimedia__upload__popup')
  const { setFooter } = useContext(LoaderContext)
  const { setClientNum } = useContext(ClientContext)
  const location = useLocation()
  const history = useHistory()
  useEffect(() => {
    setFooter(false)
  }, [])

  const handleClick = () => {
    if (isError) {
      setError(false)
      return 
    }
    setClientNum(false)
    if (location.pathname === '/consumption/update') {
      history.push('/profile/consumption')
    }
  }

  return (
    <PopUp handleMenu={handleClick}>
      <div className='flex flex-col justify-between container__pp'>
        <span></span>
        <div className='flex flex-col justify-center gap-3'>
          {
            isError 
              ? (
                <>
                  <h2>Ha ocurrido <br/> un error</h2>
                  <p>Es posible que hayas ingresado un número <br/> incorrecto o inexistente. <br/> Por favor inténtalo nuevamente</p>
                </>
              )
              : (
                <>
                  <h2>¡Excelente!</h2>
                  <p>Has ingresado con éxito tu número de cliente. Puedes continuar con la dinámica.</p>
                </>
                
              )
          }

        </div>
        <div className='flex flex-col justify-between gap-5'>
          {
            isError && (
              <p>
                <b>¿NO PUEDES AVANZAR?</b> <br/>
                Ponte en contacto con nosotros para que podamos
                 ayudarte. <Link to="/help" className='underline'>Envíanos un mensaje</Link>.
              </p>
            )
          }
          <Button onClick={handleClick}>
            {isError ? 'VOLVER A INTENTAR' : 'Entendido'}
          </Button>
        </div>
      </div>
    </PopUp>
  )
}

export default PopupClient