import axios, { AxiosRequestConfig } from 'axios'
import { createContext, useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'

export interface OnlineContextProps {
  isOnline: boolean
}

const OnlineContext = createContext<OnlineContextProps>({} as OnlineContextProps)

const OnlineProvider = ({ children }) => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine)

  const handleOnline = () => {
    setIsOnline(true)
    toast.dismiss()
    toast.success('¡Genial tiene conexión nuevamente!', { position: 'bottom-center' })
  }

  const handleOffline = () => {
    setIsOnline(false)
    toast.dismiss()
    toast.loading('¡Ups!... Estas sin conexión', { position: 'bottom-center' })
  }

  const checkLocalStorageUpdates = async () => {
    const offlineUpdater = localStorage.getItem('offline-updater');
    if (offlineUpdater) {
      const array: { id: string, config: AxiosRequestConfig }[] = JSON.parse(offlineUpdater);

      if (array && array?.length > 0) {
        await Promise.all(array.map(async ({ config }) => {
          return await axios(config)
        }))
      }

      localStorage.setItem('offline-updater', JSON.stringify([]));
    }
  };

  useEffect(() => {
    let intervalId: any = null;

    if (isOnline) {
      intervalId = setInterval(() => {
        checkLocalStorageUpdates();
      }, 60000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }
  }, [isOnline])


  useEffect(() => {
    window.addEventListener('offline', handleOffline)
    window.addEventListener('online', handleOnline)

    return () => {
      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('online', handleOnline)
    }
  }, [])

  return (
    <OnlineContext.Provider value={{ isOnline }}>
      {children}
    </OnlineContext.Provider>
  )
}

export const useOnline = () => {
  const { isOnline } = useContext(OnlineContext)
  return isOnline
}

export default OnlineProvider