import { AUTH, XEERPA_CALLBACK } from "constants/api";
import axios from "axios";
import LoadingScreen from "components/loading-screen";
import { useUTMParams } from "components/utms/useUTMParams";
import useLocalStorage from "hooks/use-local-storage";
import useQuery from "hooks/use-query";
import useRedirect from "hooks/use-redirect";
import useRouter from "hooks/use-router";
import { useEffect, useState } from "react";
import { getAuth, signInWithCustomToken, UserCredential } from "firebase/auth";
import utmServices from "services/utm-services";
import textServices from "services/text-services";
import { createUserProperties, dlSetUserInfo, dlTrackEvent } from "components/dataLayer/dataLayer";
import { useSession } from "contexts/session-context";
import { UserData } from "contexts/session-context/session-reducer";
import CONFIGS from "build/Config";
import { useAgegate } from "hooks/use-agegate";

const validatorsQuery = ['toexternal', 't', 'tointernal']

export default function XeerpaManager() {
  const [isGetParams, setIsGetParams] = useState(false)
  const utmParams = useUTMParams();
  const query = useQuery()
  const firbaseAuth = getAuth();
  const { push } = useRouter()
  const [toExternal, , removeValue] = useLocalStorage<string>('toexternal');
  const [ { user } , { setSession, setUser }] = useSession()
  const [, { redirectByLocal, getRedirectLocal }] = useRedirect();
  const { setAgeLocalForm } = useAgegate();

  const getUrlUtm = (urlBase: string) => {
    const url = new URL(decodeURI(urlBase));

    for (const key in utmParams) {
      if (utmParams[key] && !url?.searchParams.has(key)) {
        url?.searchParams.set(key, utmParams[key]);
      }
    }

    return url
  }

  const redirectToXeerpa = () => {
    let url = getUrlUtm(XEERPA_URL)
    // Implements an A/B Testing based on utm_term, only for DosEquis
    if (false as any || ["dosequis"].includes(THEME)) {
      for (const key in utmParams) {
        if (key==='utm_term' && utmParams[key]==='VideoAd_TVCFranquicia_V1') {
          url = getUrlUtm(`${XEERPA_URL}social`);
        }
      }
    }
    window.location.assign(`${url?.toString()}`)
  }

  const completeData = (user: UserData, session: UserCredential) => {
    setSession(session)
    setUser(user)

    dlTrackEvent('login', { method: 'email', section: 'xeerpa :: complete' })

    if (toExternal) {
      removeValue()
      const url = getUrlUtm(toExternal)
      return window.location.assign(url?.toString())
    };

    if (getRedirectLocal()) return redirectByLocal();

    const availableFields = CONFIGS?.notAvailableFields
    const isComplete = ((availableFields?.includes('nickName') ? false : !user?.nickName) || !user?.birthday || (!user?.genre || !user?.genreId))
    if (!CONFIGS?.signUp?.base && isComplete) return push('/information/update?isNew=true');

    return push(`/home`)
  }

  const handleAuth = async () => {
    try {
      const { data: access } = await axios.post(`${XEERPA_CALLBACK}`, { body: query?.get('t') }, { headers: { 'Ph-Api-Id': PH_API_ID ?? 1 } });
      const session = await signInWithCustomToken(firbaseAuth, access.firebaseAccessToken);
      const utm = utmServices.utmRead()
      const lang = textServices.language
      const accessToken = (session.user as any).accessToken;
      const body: any = { accessToken: access?.providerAccessToken, firstName: access?.displayName, utm, lang }
      const { data: { profile: user } } = await axios.post(AUTH, body, { headers: { Authorization: `Bearer ${accessToken}`, 'Ph-Api-Id': PH_API_ID ?? 1 } })
      gtag('event', 'login', {
        event_label: `Login - Xeerpa`,
        method: `Xeerpa`
      })
      dlTrackEvent('user_interaction', {
        action: `redirect`, 
        section: 'xeerpa :: redirect'
      })
      dlSetUserInfo(user?.hashId ?? user?.id)
      setAgeLocalForm(new Date(user?.birthday ?? ''))
      createUserProperties(user, 'xeerpa')
      completeData(user, session)
    } catch (err) {
      alert('Ha ocurrido un error al iniciar sesión')
      if (toExternal) window.location.replace(getUrlUtm(toExternal ?? ''))
      else push('/start')
    }
  }

  useEffect(() => {
    if(user) return push('/home');
    if (isGetParams) {
      if (Array.from(query)?.some(([key]) => validatorsQuery?.includes(key))) {
        if (query?.get('t')) handleAuth()
        else redirectToXeerpa()
      } else {
        push('/home')
      }
    }
  }, [isGetParams])

  useEffect(() => {
    setTimeout(() => {
      setIsGetParams(true)
    }, 1000)
  }, [])

  return <LoadingScreen />
}